'use strict';

/**
 * @ngdoc overview
 * @name myStrengthAdminApp
 * @description
 * # The new admin page for myStrength. This file contains all of the module declarations.
 * In the future, if we think this is getting a little too complicated, we may move these out into files per module.
 *
 * This also contains all of the routing information - this tells angular what controller and what fragment to load into the main page
 * If you're adding a new page you'll want to make sure to add them to the route
 *
 * Main module of the application.
 */
angular
  .module('myStrengthAdminApp', [
    'templates',
    'ngCookies',
    'ngAnimate',
    'ngMaterial',
    'ngMessages',
    'ui.bootstrap',
    'ui.router',
    'ui.grid',
    'restangular',
    'textAngular',
    'toggle-switch',
    'ngOrderObjectBy',
    'orgControllers',
    'accessCodeControllers',
    'discussion',
    'providerPreferencesControllers',
    'assessmentControllers',
    'quizControllers',
    'groupControllers',
    'headerControllers',
    'notificationControllers',
    'signupControllers',
    'userControllers',
    'metadataControllers',
    'quoteControllers',
    'emailControllers',
    'moodSliderControllers',
    'identityProviderControllers',
    'activityControllers',
    'activityPrograms',
    'actionplan',
    'redirects',
    'ssoControllers',
    'telehealthControllers',
    'referralControllers',
    'reportingControllers',
    'files',
    'copy',
    'angular-clipboard',
    'coaching',
    'encryption',
    'systemconfiguration'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    '$httpProvider',
    'RestangularProvider',
    '$locationProvider',
    '$qProvider',
    function(
      $stateProvider,
      $urlRouterProvider,
      $httpProvider,
      RestangularProvider,
      $locationProvider,
      $qProvider
    ) {
      $urlRouterProvider.otherwise('/404');
      $stateProvider
        .state('main', {
          url: '/',
          templateUrl: 'common/templates/main.html'
        })
        .state('apidoc', {
          url: '/apidoc',
          templateUrl: 'common/templates/partnerApiDoc.html'
        })
        .state('404', {
          url: '/404',
          templateUrl: 'common/templates/404.html'
        });
      $httpProvider.interceptors.push('authInterceptor');
      RestangularProvider.setBaseUrl('API');
      RestangularProvider.setRestangularFields({
        id: 'shortName'
      });
      RestangularProvider.setRequestInterceptor(function(elem, operation) {
        if (operation === 'remove') {
          return undefined;
        }
        return elem;
      });
      $locationProvider.hashPrefix('');
      $qProvider.errorOnUnhandledRejections(false);
    }
  ])
  .run([
    '$rootScope',
    '$log',
    '$state',
    '$http',
    'Restangular',
    'alertService',
    'authInterceptor',
    'domainSvc',
    'windowSvc',
    'httpSvc',
    function(
      $rootScope,
      $log,
      $state,
      $http,
      Restangular,
      alertService,
      authInterceptor,
      domainSvc,
      windowSvc,
      httpSvc
    ) {
      Restangular.setErrorInterceptor(function(response) {
        $log.debug(response);
        if (response.status === 0) {
          alertService.addAlert({
            type: 'danger',
            message:
              'A networking error occured - please check your internet connection.'
          });
        }
        // on 401s with bad tokens we will attempt a reauth and replay of the request
        if (
          response.status === 401 &&
          response.data.errorCode &&
          (response.data.errorCode === 'TOKEN_EXPIRED' ||
            response.data.errorCode === 'ACCESS_DENIED')
        ) {
          if (authInterceptor.hasReauthCookie()) {
            httpSvc.reauth().then(() => {
              // Repeat the request and then call the handlers the usual way.
              // - Be aware that no request interceptors are called this way.
              $http(response.config);
            });
          }
        }
      });

      $rootScope.$on('$stateChangeStart', function(event, toState, toParams) {
        // if we are not logged in and we don't have a valid reauth cookie - bounce to login page with a redirect
        if (
          !authInterceptor.isLoggedIn() &&
          !authInterceptor.hasReauthCookie()
        ) {
          $log.debug(
            'stateChangeStart - No user logged in - redirecting to login page.'
          );
          $log.debug('stateChangeStart - ', toState);
          // user is not logged in and the route requires auth, send them to login
          var _redirect =
            'https://' +
            domainSvc.getCurrentDomain() +
            $state.href(
              toState.name === '404' ? 'main' : toState.name,
              toParams
            );
          $log.debug(_redirect);
          var loginUrl = domainSvc.getLoginUrl(encodeURI(_redirect));
          $log.debug('loginUrl - ' + loginUrl);
          windowSvc.windowNavigate(loginUrl);
        }
        if (toState.resolve) {
          $rootScope.loadingState = true;
        }
      });
      $rootScope.$on('$stateChangeSuccess', function() {
        $rootScope.loadingState = false;
      });
      $rootScope.$on('$stateChangeError', function(
        event,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) {
        $log.error('state change error', error);
        $rootScope.loadingState = false;
        if (error.status === 404) {
          alertService.addAlert({
            type: 'danger',
            message: 'Could not find requested resource'
          });
        } else if (error.status === 500) {
          alertService.addAlert({
            type: 'danger',
            message: 'A server error occured - ' + error.statusText,
            timeout: 600000
          });
        } else if (error.status === 401) {
          // NO-OP 401 is handled by authInterceptor
        } else {
          alertService.addAlert({
            type: 'danger',
            message: 'Failed to communicate with server'
          });
        }
      });
    }
  ]);

'use strict';

angular.module('actionplan', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('actionplan', {
        abstract: true,
        template: '<ui-view/>',
        url: '/actionplan',
        crumb: {
          name: 'Action Plan',
          location: 'actionplan.list'
        }
      })
      .state('actionplan.list', {
        url: '',
        templateUrl: 'activities/templates/actionPlanList.html',
        controller: 'ActionPlanListCtrl as vm',
        resolve: {
          plans: [
            'actionPlanSvc',
            function(actionPlanSvc) {
              return actionPlanSvc.getPlans();
            }
          ]
        }
      })
      .state('actionplan.edit', {
        url: '/edit/{id:int}',
        templateUrl: 'activities/templates/actionPlanEdit.html',
        controller: 'ActionPlanEditCtrl as vm',
        resolve: {
          plan: [
            'actionPlanSvc',
            '$stateParams',
            function(actionPlanSvc, $stateParams) {
              return actionPlanSvc.getPlan($stateParams.id);
            }
          ]
        }
      })
      .state('actionplan.new', {
        url: '/new',
        templateUrl: 'activities/templates/actionPlanEdit.html',
        controller: 'ActionPlanEditCtrl as vm',
        resolve: {
          plan: [() => null]
        }
      });
  }
]);

'use strict';

angular
  .module('activityControllers', [])
  .config([
    '$stateProvider',
    function($stateProvider) {
      var baseState = {
        url: '',
        templateUrl: 'activities/templates/activityEdit.html'
      };
      var sectionState = {
        url: '/section/:section',
        templateUrl: 'activities/templates/sectionEdit.html',
        controller: 'SectionEditCtrl',
        params: {
          section: {
            value: null,
            squash: true
          }
        }
      };
      $stateProvider
        .state('activities', {
          abstract: true,
          template: '<ui-view/>',
          url: '/activities',
          crumb: {
            name: 'Activities',
            location: 'activities.list'
          }
        })
        .state('activities.list', {
          url: '',
          templateUrl: 'activities/templates/activityList.html',
          controller: 'ActivityListCtrl',
          controllerAs: 'vm'
        })
        .state('activities.assessmentslist', {
          url: '/assessments',
          templateUrl: 'activities/templates/activityList.html',
          controller: 'AssessmentActivityListCtrl',
          controllerAs: 'vm'
        })
        .state('activities.coreinspirationslist', {
          url: '/coreinspirations',
          templateUrl: 'activities/templates/coreInspirationList.html',
          controller: 'CoreInspirationListCtrl',
          controllerAs: 'vm',
          resolve: {
            inspirations: [
              'activitySvc',
              function(activitySvc) {
                return activitySvc.getAll({ type: 'CORE_INSPIRATION' });
              }
            ]
          }
        })
        .state('activities.communityinspirationslist', {
          url: '/communityinspirations',
          templateUrl: 'activities/templates/communityInspirationList.html',
          controller: 'CoreInspirationListCtrl',
          controllerAs: 'vm',
          resolve: {
            inspirations: [
              'httpSvc',
              '$q',
              function(httpSvc, $q) {
                var list1 = httpSvc.get(
                  'API/editor/communityinspirations/REVIEW'
                );
                var list2 = httpSvc.get(
                  'API/editor/communityinspirations/REPORTED'
                );
                return $q.all([list1, list2]).then(function(data) {
                  return data[0].concat(data[1]);
                });
              }
            ]
          }
        })
        .state('activities.coreinspirationsedit', {
          url: '/coreinspirations/edit/:url?version',
          templateUrl: 'activities/templates/inspirationEdit.html',
          controller: 'CoreInspirationCtrl',
          controllerAs: 'vm',
          resolve: {
            inspiration: [
              '$stateParams',
              'activitySvc',
              function($stateParams, activitySvc) {
                return $stateParams.url
                  ? activitySvc
                      .get($stateParams.url, $stateParams.version)
                      .get()
                  : undefined;
              }
            ]
          }
        })
        .state('activities.train', {
          url: '/train',
          templateUrl: 'activities/templates/train.html',
          controller: 'TrainCtrl as vm'
        })
        .state('activities.edit', {
          url: '/edit/:url?version',
          template: '<ui-view/>',
          resolve: {
            activity: [
              '$stateParams',
              'activitySvc',
              function($stateParams, activitySvc) {
                return activitySvc
                  .get($stateParams.url, $stateParams.version)
                  .get();
              }
            ]
          },
          controller: 'ActivityEditCtrl',
          controllerAs: 'activity',
          abstract: true
        })
        .state('activities.new', {
          url: '/new',
          template: '<ui-view/>',
          resolve: {
            activity: function() {
              return null;
            }
          },
          controller: 'ActivityEditCtrl',
          controllerAs: 'activity',
          abstract: true
        })
        .state('activities.new.activity', angular.copy(baseState))
        .state('activities.edit.activity', angular.copy(baseState))
        .state('activities.edit.section', angular.copy(sectionState))
        .state('activities.new.section', angular.copy(sectionState))
        .state('tags', {
          abstract: true,
          template: '<ui-view/>',
          url: '/tags',
          crumb: {
            name: 'Activities',
            location: 'tags.list'
          }
        })
        .state('tags.list', {
          url: '',
          templateUrl: 'activities/templates/tagList.html',
          controller: 'TagListCtrl',
          controllerAs: 'tagsVm'
        })
        .state('activities.hidden', {
          url: '/hidden',
          templateUrl: 'activities/templates/hidden.html',
          controller: 'HiddenCtrl',
          controllerAs: 'vm'
        });
    }
  ])
  .constant('tagColors', {
    CATEGORY: 'label-success',
    TAG: 'label-primary',
    METADATA: 'label-info',
    FAKE: 'label-danger'
  })
  .constant('activityTypeIcons', {
    ACTIVITY_SERIES: 'fa-object-ungroup',
    ACTIVITY_PROGRAM: 'fa-object-group',
    YOUTH: 'fa-graduation-cap',
    CORE: 'fa-circle',
    FLASH_MIGRATION: 'fa-flash',
    CORE_ARTICLE: 'fa-file-text'
  })
  .constant('activityStatusColours', {
    PUBLISHED: 'ok',
    DRAFT: 'warn',
    INACTIVE: '',
    DELETED: 'error',
    REVIEW: 'info'
  });

'use strict';

angular.module('activityPrograms', ['dndLists']).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('activityPrograms', {
        abstract: true,
        template: '<ui-view/>',
        url: '/activities/programs',
        crumb: {
          name: 'Activity Programs',
          location: 'activityPrograms.list'
        }
      })
      .state('activityPrograms.list', {
        url: '',
        templateUrl: 'activityPrograms/templates/programList.html',
        controller: 'ProgramListCtrl',
        controllerAs: 'vm',
        resolve: {
          programs: [
            'programSvc',
            function(programSvc) {
              return programSvc.getPrograms();
            }
          ],
          series: [
            'programSvc',
            function(programSvc) {
              return programSvc.getSeriesList();
            }
          ]
        }
      })
      .state('activityPrograms.edit', {
        url: '/edit/:programId',
        templateUrl: 'activityPrograms/templates/programEdit.html',
        controller: 'ProgramEditCtrl',
        controllerAs: 'vm',
        resolve: {
          program: [
            '$stateParams',
            'programSvc',
            function($stateParams, programSvc) {
              return !!$stateParams.programId
                ? programSvc.getProgram($stateParams.programId)
                : null;
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('assessmentControllers', ['activityControllers']).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('assessments', {
        abstract: true,
        template: '<ui-view/>',
        url: '/assessments',
        crumb: {
          name: 'Assessments',
          location: 'assessments.list'
        }
      })
      .state('assessments.list', {
        url: '',
        templateUrl: 'assessments/templates/assessmentList.html',
        controller: 'AssessmentListCtrl',
        controllerAs: 'vm'
      })
      .state('assessments.edit', {
        url: '/edit/:shortName',
        templateUrl: 'assessments/templates/assessmentEdit.html',
        controller: 'AssessmentEditCtrl',
        controllerAs: 'vm',
        resolve: {
          assessment: [
            '$stateParams',
            'assessmentSvc',
            function($stateParams, assessmentSvc) {
              return assessmentSvc.get($stateParams.shortName).get();
            }
          ],
          frequencies: [
            'assessmentSvc',
            function(assessmentSvc) {
              return assessmentSvc.getFrequencies();
            }
          ]
        }
      })
      .state('assessments.frequencyedit', {
        url: '/frequency/edit/:id',
        templateUrl: 'assessments/templates/frequencyEdit.html',
        controller: 'FrequencyEditCtrl',
        controllerAs: 'vm',
        resolve: {
          frequency: [
            '$stateParams',
            'assessmentSvc',
            function($stateParams, assessmentSvc) {
              return assessmentSvc.getFrequency($stateParams.id).get();
            }
          ]
        }
      })
      .state('assessments.new', {
        url: '/new',
        templateUrl: 'assessments/templates/assessmentEdit.html',
        controller: 'AssessmentEditCtrl',
        controllerAs: 'vm',
        resolve: {
          assessment: function() {
            return null;
          },
          frequencies: [
            'assessmentSvc',
            function(assessmentSvc) {
              return assessmentSvc.getFrequencies();
            }
          ]
        }
      })
      .state('assessments.frequencynew', {
        url: '/frequency/new',
        templateUrl: 'assessments/templates/frequencyEdit.html',
        controller: 'FrequencyEditCtrl',
        controllerAs: 'vm',
        resolve: {
          frequency: function() {
            return null;
          }
        }
      });
  }
]);

'use strict';
/**
 * Handles the routing & config for the coaching module
 */
angular.module('coaching', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('coaching', {
        abstract: true,
        template: '<ui-view/>',
        url: '/coaching'
      })
      .state('coaching.enablement', {
        url: '/enablement',
        templateUrl: 'coaching/templates/coachingEnablement.html',
        controller: ['coachingSvc', CoachingEnablementCtrl],
        controllerAs: 'vm',
        resolve: {}
      })
      .state('coaching.settings', {
        url: '/settings',
        templateUrl: 'coaching/templates/coachingSettings.html',
        controller: ['coachingSvc', CoachingSettingsCtrl],
        controllerAs: 'vm',
        resolve: {}
      })
      .state('coaching.coaches', {
        url: '/coaches',
        templateUrl: 'coaching/templates/manageCoaches.html',
        controller: 'ManageCoachesCtrl',
        controllerAs: 'vm',
        resolve: {}
      });
  }
]);

/**
 * CoachingEnablementCtrl - CRUD on assigning a coaching_setting_id to
 * partner_collateral
 */
function CoachingEnablementCtrl(coachingSvc) {
  var vm = this;

  vm.entitiesWithCoachingSettings = [];
  vm.coachingSettings = [];
  vm.coachingSettingsDictionary = {};

  _getCoachingEntities();

  // Build dictionary object to map a coaching setting id to coach setting name name
  coachingSvc.getAllCoachingSettings().then(settings => {
    settings.map(item => {
      vm.coachingSettingsDictionary[item.id] = item.name;
    });
  });

  // New/edit modal form
  vm.editCoachingSettings = function(partnerCollateral) {
    var modal = coachingSvc.editPartnerCollateralCoachingSetting(
      partnerCollateral
    );

    modal.result.then(function() {
      // Update coaching list on modal close
      _getCoachingEntities();
    });
  };

  // Collect list of org/group/ac with coaching settings
  function _getCoachingEntities() {
    coachingSvc.getCoachingEntities().then(entities => {
      // Remove restangular properties & sort alphabetically by org.shortName
      var entitiesWithCoachingSettings = entities
        .map(entity => {
          return entity.plain();
        })
        .sort((a, b) => {
          var nameA = a.organizationName.toUpperCase();
          var nameB = b.organizationName.toUpperCase();

          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

      vm.entitiesWithCoachingSettings = entitiesWithCoachingSettings;
    });
  }
}

/**
 * CoachingSettingsCtrl - CRUD on coaching_settings_id
 */
function CoachingSettingsCtrl(coachingSvc) {
  var vm = this;

  vm.coachingSettings = [];

  _getCoachingSettings();

  vm.editCoachingSetting = function(setting) {
    var modal = coachingSvc.editCoachingSetting(setting);

    modal.result.then(function() {
      // Update coachingSettings list on modal close
      _getCoachingSettings();
    });
  };

  function _getCoachingSettings() {
    coachingSvc.getAllCoachingSettings().then(settings => {
      vm.coachingSettings = settings.plain();
    });
  }
}

'use strict';
/**
 * Configuration for textangular. The most notable item in here is the base64 image button.
 */
angular.module('myStrengthAdminApp').config([
  '$provide',
  function($provide) {
    $provide.decorator('taOptions', [
      'taRegisterTool',
      '$delegate',
      function(taRegisterTool, taOptions) {
        taRegisterTool('base64Image', {
          tooltiptext: 'Insert image from file',
          display:
            '<span class="btn btn-file">' +
            '<i class="fa fa-file-image-o"></i>' +
            '<input type="file" upload-resize-image max-height="300" max-width=300 accept=".jpg,.jpeg,.png,.gif">' +
            '</span>',
          action: function(defer) {
            // store the defer so we can resolve it once the image is actually uploaded.
            this.defer = defer;
            return false;
          }
        });
        taOptions.toolbar = [
          ['bold', 'italics', 'underline', 'strikeThrough'],
          ['h2', 'h4', 'ul', 'ol'],
          ['undo', 'redo'],
          ['base64Image', 'insertImage', 'insertLink', 'html']
        ];
        return taOptions;
      }
    ]);
    $provide.decorator('taTools', [
      '$delegate',
      function(taTools) {
        taTools.insertImage.display = '<div style="display: none;"></div>';
        return taTools;
      }
    ]);
  }
]);

'use strict';
/**
 * Handles the routing & config for the quote module
 */
angular.module('copy', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('copy', {
        abstract: true,
        template: '<ui-view/>',
        url: '/copy',
        crumb: {
          name: 'Copy',
          location: 'copy.list'
        }
      })
      .state('copy.list', {
        url: '?language',
        templateUrl: 'copy/templates/copyList.html',
        controller: 'CopyListCtrl',
        controllerAs: 'vm',
        resolve: {
          copy: [
            '$stateParams',
            'copySvc',
            function($stateParams, copySvc) {
              return copySvc.getCopy($stateParams.language || 'en-US');
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('discussion', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('discussions', {
        abstract: true,
        template: '<ui-view/>',
        url: '/discussions',
        crumb: {
          name: 'Discussions',
          location: 'discussions.list'
        }
      })
      .state('discussions.list', {
        url: '',
        templateUrl: 'discussions/templates/discussionList.html',
        controller: 'DiscussionListCtrl',
        controllerAs: 'vm'
      });
  }
]);

'use strict';
/**
 * Handles the routing & config for the email templates module
 */
angular.module('emailControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('emailaudits', {
        url: '/emailaudits',
        templateUrl: 'email/templates/emailAuditList.html',
        controller: 'EmailAuditListCtrl',
        controllerAs: 'vm'
      })
      .state('email-send', {
        url: '/email-send',
        templateUrl: 'email/templates/emailSendForm.html',
        controller: 'EmailSendCtrl',
        controllerAs: 'vm'
      });
  }
]);

'use strict';

angular.module('encryption', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('encryption', {
        abstract: true,
        template: '<ui-view/>',
        url: '/encryptionsettings'
      })
      .state('encryption.enablement', {
        url: '/enablement',
        templateUrl: 'encryption/templates/encryptionEnablement.html',
        controller: 'EncryptionEnablementCtrl',
        controllerAs: 'vm',
        resolve: {}
      });
  }
]);

'use strict';
angular.module('files', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('files', {
        abstract: true,
        template: '<ui-view/>',
        url: '/files',
        crumb: {
          name: 'Provider Connect',
          location: 'files.home'
        }
      })
      .state('files.home', {
        url: '',
        templateUrl: 'files/templates/fileHome.html'
      })
      .state('files.list', {
        url: '/list?folder',
        templateUrl: 'files/templates/fileList.html',
        controller: 'FileListCtrl',
        controllerAs: 'vm',
        resolve: {
          files: [
            'fileSvc',
            function(fileSvc) {
              return fileSvc.getAll();
            }
          ],
          categories: [
            'fileSvc',
            function(fileSvc) {
              return fileSvc.getCategories();
            }
          ]
        }
      })
      .state('files.categories', {
        url: '/categories',
        templateUrl: 'files/templates/fileFolderList.html',
        controller: [
          'categories',
          'fileSvc',
          function(categories, fileSvc) {
            var vm = this;
            vm.categories = categories;
            vm.editCategory = function(categoryId) {
              var modal = fileSvc.editCategory(categoryId);
              modal.result.then(function() {
                fileSvc.getCategories().then(function(categories) {
                  vm.categories = categories;
                });
              });
            };
          }
        ],
        controllerAs: 'vm',
        resolve: {
          categories: [
            'fileSvc',
            function(fileSvc) {
              return fileSvc.getCategories();
            }
          ]
        }
      })
      .state('files.foldergroups', {
        url: '/foldergroups',
        templateUrl: 'files/templates/fileFolderGroups.html',
        controller: 'FileFolderGroupCtrl',
        controllerAs: 'vm'
      })
      .state('files.preview', {
        url: '/preview',
        templateUrl: 'files/templates/filesPreview.html',
        controller: 'FilesPreviewCtrl',
        controllerAs: 'vm'
      })
      .state('files.file', {
        url: '/file/:fileId',
        templateUrl: 'files/templates/fileEdit.html',
        controller: 'FileEditCtrl',
        controllerAs: 'vm',
        resolve: {
          file: [
            'fileSvc',
            '$stateParams',
            function(fileSvc, $stateParams) {
              return !$stateParams.fileId
                ? null
                : fileSvc.get($stateParams.fileId);
            }
          ],
          categories: [
            'fileSvc',
            function(fileSvc) {
              return fileSvc.getCategories();
            }
          ]
        },
        params: {
          fileId: {
            squash: true,
            default: null
          }
        }
      });
  }
]);

'use strict';
/**
 * Handles the routing & config for the email templates module
 */
angular
  .module('moodSliderControllers', ['dndLists', 'activityControllers'])
  .config([
    '$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state('moodsliders', {
          abstract: true,
          template: '<ui-view/>',
          url: '/trackers',
          crumb: {
            name: 'Trackers',
            location: 'moodsliders.list'
          }
        })
        .state('moodsliders.list', {
          url: '',
          resolve: {
            components: [
              'moodSlidersSvc',
              '$q',
              function(moodSlidersSvc, $q) {
                return $q
                  .all([
                    // Get Components
                    moodSlidersSvc.getAllMoodSliders().then(function(data) {
                      return _.sortBy(data, function(c) {
                        return c.name;
                      });
                    }),
                    // Get Groups
                    moodSlidersSvc.getAllMoodGroups().then(function(data) {
                      return _.sortBy(data, function(g) {
                        return g.groupName;
                      });
                    }),
                    // Get Types
                    moodSlidersSvc.getAllGroupTypes().then(function(data) {
                      return _.sortBy(data, function(t) {
                        return t.type;
                      });
                    }),
                    // Get Groups in use
                    moodSlidersSvc.groupsInUse().then(function(data) {
                      return data;
                    })
                  ])
                  .then(function(data) {
                    return {
                      components: data[0],
                      groups: data[1],
                      types: data[2],
                      groupsInUse: data[3]
                    };
                  });
              }
            ]
          },
          template: '<mood-sliders components="$resolve.components"/>'
        });
    }
  ]);

'use strict';

angular.module('orgControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs', {
        abstract: true,
        template: '<ui-view/>',
        url: '/organizations',
        crumb: {
          name: 'Organizations',
          location: 'orgs.list'
        }
      })
      .state('orgs.list', {
        url: '',
        templateUrl: 'partner/organizations/templates/orglist.html',
        controller: 'OrgListCtrl'
      })
      .state('orgs.new', {
        url: '/new',
        templateUrl: 'partner/organizations/templates/orgedit.html',
        controller: 'OrgEditCtrl'
      })
      .state('orgs.org', {
        abstract: true,
        template: '<ui-view/>',
        url: '/:orgid',
        resolve: {
          organization: [
            '$stateParams',
            'Restangular',
            function($stateParams, Restangular) {
              return Restangular.one(
                'editor/organizations',
                $stateParams.orgid
              );
            }
          ]
        },
        crumb: {
          name: 'orgid',
          location: 'orgs.org.detail'
        }
      })
      .state('orgs.org.detail', {
        url: '',
        templateUrl: 'partner/organizations/templates/orgdetail.html',
        controller: 'OrgDetailCtrl',
        controllerAs: 'detailorg'
      });
  }
]);

'use strict';
angular.module('quizControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('quizzes', {
        abstract: true,
        template: '<ui-view/>',
        url: '/quizzes',
        crumb: {
          name: 'Quizzes',
          location: 'quizzes.list'
        }
      })
      .state('quizzes.list', {
        url: '',
        templateUrl: 'quizzes/templates/quizList.html',
        controller: 'QuizListCtrl',
        controllerAs: 'vm'
      })
      .state('quizzes.edit', {
        url: '/edit/:id',
        templateUrl: 'quizzes/templates/quizEdit.html',
        controller: 'QuizEditCtrl',
        controllerAs: 'vm',
        params: {
          id: null,
          quiz: null
        }
      })
      .state('quizzes.new', {
        url: '/new',
        templateUrl: 'quizzes/templates/quizEdit.html',
        controller: 'QuizEditCtrl',
        controllerAs: 'vm',
        resolve: {
          quiz: function() {
            return null;
          }
        }
      });
  }
]);

'use strict';
/**
 * Handles the routing & config for the quote module
 */
angular.module('quoteControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('quotes', {
        abstract: true,
        template: '<ui-view/>',
        url: '/quotes',
        crumb: {
          name: 'Quotes',
          location: 'quotes.list'
        }
      })
      .state('quotes.list', {
        url: '',
        templateUrl: 'quote/templates/quoteList.html',
        controller: 'QuoteListCtrl',
        controllerAs: 'vm',
        resolve: {
          quotes: [
            'quoteSvc',
            function(quoteSvc) {
              return quoteSvc.findQuotes().then(function(resp) {
                return resp;
              });
            }
          ],
          topics: [
            'quotes',
            function(quotes) {
              var _uniqueTopics = [];
              _.each(quotes, function(quote) {
                if (!_.includes(_uniqueTopics, quote.topic)) {
                  _uniqueTopics.push(quote.topic);
                }
              });
              return _uniqueTopics;
            }
          ]
        }
      });
  }
]);

'use strict';
angular.module('redirects', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('redirects', {
        abstract: true,
        template: '<ui-view/>',
        url: '/redirects',
        crumb: {
          name: 'Redirects',
          location: 'redirects.list'
        }
      })
      .state('redirects.list', {
        url: '',
        templateUrl: 'redirects/templates/redirectList.html',
        controller: 'RedirectListCtrl',
        controllerAs: 'vm',
        resolve: {
          redirects: [
            'redirectSvc',
            function(redirectSvc) {
              return redirectSvc.getAll().then(function(resp) {
                return resp.plain();
              });
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('reportingControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('reports', {
        abstract: true,
        template: '<ui-view/>',
        url: '/reports',
        crumb: {
          name: 'Reports',
          location: 'reports.list'
        }
      })
      .state('reports.list', {
        url: '',
        templateUrl: 'reporting/templates/reportList.html',
        controller: 'ReportListCtrl',
        controllerAs: 'vm'
      })
      .state('reports.flash', {
        url: '/daily',
        templateUrl: 'reporting/templates/report.html',
        controller: 'ReportViewCtrl',
        controllerAs: 'vm',
        resolve: {
          reportName: [
            function() {
              return 'DailyConsumerFlash&#47;DailyConsumerFlash';
            }
          ]
        },
        crumb: {
          name: 'Daily Consumer Flash',
          location: 'reports.flash'
        }
      })
      .state('reports.consumerBreakout', {
        url: '/consumerBreakout',
        templateUrl: 'reporting/templates/report.html',
        controller: 'ReportViewCtrl',
        controllerAs: 'vm',
        resolve: {
          reportName: [
            function() {
              return 'ConsumerBreakout&#47;ConsumerBreakout';
            }
          ]
        },
        crumb: {
          name: 'Consumer Breakout',
          location: 'reports.consumerBreakout'
        }
      })
      .state('reports.commercialEngagement', {
        url: '/commercialEngagement',
        templateUrl: 'reporting/templates/report.html',
        controller: 'ReportViewCtrl',
        controllerAs: 'vm',
        resolve: {
          reportName: [
            function() {
              return 'DRAFTEngagementResults-Commercialnotapprovedforexternalrelease&#47;EngagementDashboard';
            }
          ]
        },
        crumb: {
          name: 'Commercial Engagement',
          location: 'reports.commercialEngagement'
        }
      })
      .state('reports.publicEngagement', {
        url: '/publicEngagement',
        templateUrl: 'reporting/templates/report.html',
        controller: 'ReportViewCtrl',
        controllerAs: 'vm',
        resolve: {
          reportName: [
            function() {
              return 'DRAFTEngagementResults-Publicnotapprovedforexternalrelease&#47;EngagementDashboard';
            }
          ]
        },
        crumb: {
          name: 'Public Engagement',
          location: 'reports.publicEngagement'
        }
      });
  }
]);

'use strict';

angular.module('systemconfiguration', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('systemconfiguration', {
        abstract: true,
        template: '<ui-view/>',
        url: '/systemconfiguration'
      })
      .state('systemconfiguration.clientapplications', {
        url: '/client/applications',
        templateUrl: 'systemconfiguration/templates/clientApplications.html',
        controller: 'ClientApplicationCtrl',
        controllerAs: 'vm'
      })
      .state('systemconfiguration.clientactions', {
        url: '/client/actions',
        templateUrl: 'systemconfiguration/templates/clientActions.html',
        controller: 'ClientActionsCtrl',
        controllerAs: 'vm'
      })
      .state('systemconfiguration.clientfeatures', {
        url: '/client/features',
        templateUrl: 'systemconfiguration/templates/clientFeatures.html',
        controller: 'ClientFeaturesCtrl',
        controllerAs: 'vm'
      });
  }
]);

'use strict';
/**
 * Handles the routing & config for the user module
 */
angular.module('userControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('users', {
        abstract: true,
        template: '<ui-view/>',
        url: '/users'
      })
      .state('users.search', {
        url: '',
        templateUrl: 'users/templates/usersearch.html',
        controller: 'UserSearchCtrl',
        crumb: {
          name: 'Users',
          location: 'users.search'
        }
      })
      .state('users.record', {
        url: '/:id',
        templateUrl: 'users/templates/userRecord.html',
        resolve: {
          user: [
            'userSvc',
            '$stateParams',
            function(userSvc, $stateParams) {
              return userSvc.get($stateParams.id);
            }
          ]
        },
        controller: [
          '$scope',
          'user',
          function($scope, user) {
            $scope.user = user;
          }
        ]
      })
      .state('deletionrequests', {
        url: '/deletionrequests',
        templateUrl: 'users/templates/deletionRequests.html',
        controller: 'UserDeletionRequestsCtrl',
        controllerAs: 'vm',
        resolve: {},
        crumb: {
          name: 'Deletion Requests',
          location: 'users.deletionrequests'
        }
      });
  }
]);

(function() {
  'use strict';

  angular
    .module('actionplan')
    .controller('ActionItemEditCtrl', ActionItemEditCtrl);

  ActionItemEditCtrl.$inject = ['action', '$modalInstance', 'activitySvc'];

  function ActionItemEditCtrl(action, $modalInstance, activitySvc) {
    var vm = this;
    vm.action = action;
    vm.addTip = addTip;
    vm.done = done;
    vm.cancel = cancel;
    vm.removeTip = removeTip;
    vm.setActivity = setActivity;
    activate();

    function activate() {
      activitySvc
        .getAll({ type: 'CORE', publishedOnly: true })
        .then(activities => (vm.activities = activities));
    }

    function addTip() {
      vm.action.tips.push({ type: vm.newTipType });
      vm.newTipType = '';
    }

    function done() {
      $modalInstance.close('');
    }

    function cancel() {
      $modalInstance.dismiss('User rejected');
    }

    function removeTip(tip) {
      vm.action.tips.splice(vm.action.tips.indexOf(tip), 1);
    }

    /**
     * We don't actually do a "real" link to the activity - instead we just save the fields as they appear now on the tip.
     *
     * This goes through and sets up the tip correctly to match the activity that has been selected.
     *
     * The tip should already have the URL set as the media
     */
    function setActivity(tip) {
      let activity = vm.activities.find(a => a.url === tip.media);
      tip.title = activity.title;
      tip.description = activity.description;
      tip.mediaAlt = activity.image;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('actionplan')
    .controller('ActionPlanEditCtrl', ActionPlanEditCtrl);

  ActionPlanEditCtrl.$inject = [
    '$modal',
    '$state',
    'actionPlanSvc',
    'alertService',
    'plan'
  ];

  /* @ngInject */
  function ActionPlanEditCtrl(
    $modal,
    $state,
    actionPlanSvc,
    alertService,
    plan
  ) {
    var vm = this;
    vm.title = 'ActionPlanEditCtrl';
    vm.isEdit = !!plan;
    vm.plan = plan || {
      actionItems: []
    };

    vm.addAction = addAction;
    vm.editAction = editAction;
    vm.deleteAction = deleteAction;
    vm.savePlan = savePlan;

    ////////////////

    function deleteAction(action) {
      vm.plan.actionItems.splice(vm.plan.actionItems.indexOf(action), 1);
    }

    function editAction(action) {
      var isNew = !action;
      if (!action) {
        action = {
          tips: []
        };
        vm.plan.actionItems.push(action);
      }
      var backup = angular.copy(action);
      var modal = $modal.open({
        templateUrl: 'activities/templates/actionItemEdit.html',
        controller: 'ActionItemEditCtrl as vm',
        size: 'lg',
        resolve: {
          action: () => action
        }
      });
      modal.result.then(null, () => {
        if (!isNew) {
          vm.plan.actionItems[vm.plan.actionItems.indexOf(action)] = backup;
        } else {
          vm.plan.actionItems.splice(vm.plan.actionItems.indexOf(action), 1);
        }
      });
    }

    function addAction() {
      editAction(null);
    }

    function savePlan() {
      if (!vm.form.$valid) {
        angular.forEach(vm.form.$error.required, function(field) {
          field.$setDirty();
        });
        alertService.addAlert({
          message: 'Check that all fields have been filled out correctly.',
          type: 'danger'
        });
        return;
      }
      actionPlanSvc
        .upsertPlan(vm.plan)
        .then(() => $state.go('actionplan.list'));
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('actionplan')
    .controller('ActionPlanListCtrl', ActionPlanListCtrl);

  ActionPlanListCtrl.$inject = ['plans'];

  /* @ngInject */
  function ActionPlanListCtrl(plans) {
    this.plans = plans;
  }
})();

'use strict';
angular.module('activityControllers').controller('ActivityEditCtrl', [
  '$scope',
  '$state',
  'activity',
  'activitySvc',
  'alertService',
  function($scope, $state, activity, activitySvc, alertService) {
    var vm = this;
    vm.isEdit = !!activity;
    vm.activity = activity || {
      url: '',
      status: 'DRAFT',
      version: 'v1',
      sections: [],
      tags: [],
      metadata: []
    };
    /**
     * This is a complex object that determines what actions can be taken with this activity.
     * There is a property for every possible state of an activity.
     * Each property contains the info for displaying the button as well as the next that that the activity will transition into.
     * Diagram:
     * https://github.com/myStrength/resources/blob/master/wiki/images/activitystatusflow.png
     */
    vm.nextState = {
      DRAFT: {
        label: 'Mark for review',
        btnClass: 'btn-success',
        icnClass: 'fa-eye',
        next: 'REVIEW',
        help: 'Marking for review prepares the activity to be published'
      },
      REVIEW: {
        label: 'Publish',
        btnClass: 'btn-success',
        icnClass: 'fa-check-circle',
        next: 'PUBLISHED',
        help:
          'Publishing this draft will make it available to users. If there was another version of this activity published, it will be unpublished and replaced with this version. Unpublishing the currently published activity will make it inaccessable to users.'
      },
      PUBLISHED: {
        label: 'Unpublish',
        btnClass: 'btn-danger',
        icnClass: 'fa-times',
        next: 'DELETED',
        help:
          'Unpublishing effectively deletes the activity from the activities list'
      },
      INACTIVE: {
        label: 'Convert to draft',
        btnClass: 'btn-info',
        icnClass: 'fa-edit',
        next: 'DRAFT',
        help:
          'This will convert the activity back to DRAFT state, where it can then be published'
      },
      DELETED: {
        label: 'Convert to draft',
        btnClass: 'btn-info',
        icnClass: 'fa-edit',
        next: 'DRAFT',
        help:
          'This will convert the activity back to DRAFT state, where it can then be published'
      }
    };
    vm.inputWidgets = getExistingWidgets();
    //get the list of activity types from the server.
    activitySvc.getAvailableTypes().then(function(data) {
      if (!vm.isEdit) {
        vm.activity.type = data[0];
      }
      vm.availableTypes = data;
    });
    // related content tag pulls from all tags
    vm.urlPattern = /^[a-z0-9\-\_]*$/;
    $scope.$watch('activity.activity.title', function(title) {
      if (title && !vm.isEdit) {
        var url = title
          .replace(/ +/g, '_')
          .toLowerCase()
          .replace(/\W/g, '');
        // bring it down to 50 chars, hopefully on a word break
        while (url.length > 50) {
          url = url.substring(0, url.lastIndexOf('_'));
        }
        vm.activity.url = url || vm.activity.url;
      }
    });
    vm.changePromoted = function() {
      if (!vm.activity.promotedWidget) {
        delete vm.activity.promotedWidget;
      }
    };
    vm.saveAndRedirect = function() {
      vm.save(true);
    };
    vm.save = function(redirect) {
      if (!vm.form.$valid) {
        angular.forEach(vm.form.$error.required, function(field) {
          field.$setDirty();
        });
        alertService.addAlert({
          message: 'Check that all fields have been filled out correctly.',
          type: 'danger'
        });
        return;
      }

      removeUnusedQuizSections();
      return activitySvc.save(vm.activity).then(
        function() {
          alertService.addAlert({
            message: 'Activity Saved!',
            type: 'success',
            persist: true
          });
          // if we come in from save button we have a form
          if (redirect && vm.isEdit) {
            $state.go($state.current, {}, { reload: true });
          }
          if (redirect && !vm.isEdit) {
            $state.go('activities.list');
          }
        },
        function(err) {
          alertService.addAlert({
            message: 'Failed to save the Activity: ' + err.statusText,
            type: 'danger',
            persist: true
          });
        }
      );
    };
    vm.updateStatus = function() {
      activitySvc
        .updateStatus(activity, vm.nextState[activity.status].next)
        .then(function() {
          alertService.addAlert({
            message: 'Success!',
            type: 'success',
            persist: true
          });
          $state.go($state.current, {}, { reload: true });
        });
    };
    vm.updateWidgets = function() {
      vm.inputWidgets = getExistingWidgets();
    };

    /** Helper methods **/
    function getExistingWidgets() {
      return vm.activity.sections.reduce(function(prev, cur) {
        if (!cur.widgets || !prev) {
          return [];
        }
        var wid = cur.widgets
          .filter(function(widget) {
            return widget.type.toLowerCase().indexOf('text') !== -1;
          })
          .map(function(widget) {
            return {
              label:
                cur.name +
                ' - ' +
                widget.type.toLowerCase().replace('_', ' ') +
                ' - ' +
                widget.position,
              uuid: widget.uuid
            };
          });
        return prev.concat(wid);
      }, []);
    }

    const SECTION_TYPE_GENERAL = 'GENERAL';
    function removeUnusedQuizSections() {
      //get the quizUuids currently in use
      var quizUuids = [];
      var section;
      for (var x = 0; x < vm.activity.sections.length; x++) {
        section = vm.activity.sections[x];
        if (section.type !== SECTION_TYPE_GENERAL) {
          continue;
        }
        for (var y = 0; y < section.widgets.length; y++) {
          if (section.widgets[y].type === 'tools/quiz') {
            quizUuids.push(section.widgets[y].properties.section);
          }
        }
      }

      if (quizUuids.length > 0) {
        deleteQuizSectionsNotIn(quizUuids);
      }
    }

    function deleteQuizSectionsNotIn(uuids) {
      var i = vm.activity.sections.length;
      while (i--) {
        if (vm.activity.sections[i].type === SECTION_TYPE_GENERAL) {
          continue;
        }
        var found = false;
        for (var x = 0; x < uuids.length; x++) {
          if (uuids[x] === vm.activity.sections[i].uuid) {
            found = true;
          }
        }
        if (!found) {
          vm.activity.sections.splice(i, 1);
        }
      }
    }
  }
]);

'use strict';
angular.module('activityControllers').controller('ActivityListCtrl', [
  '$scope',
  '$state',
  'activitySvc',
  'tagSvc',
  '$timeout',
  'activityStatusColours',
  'activityTypeIcons',
  'tagColors',
  function(
    $scope,
    $state,
    activitySvc,
    tagSvc,
    $timeout,
    activityStatusColours,
    activityTypeIcons,
    tagColors
  ) {
    var vm = this;
    vm.statusColours = activityStatusColours;
    vm.typeIcons = activityTypeIcons;
    vm.tagColors = tagColors;
    // default everything to core to save time on initial load
    vm.typeFilter = 'CORE';
    // a list of all the activities we've loaded
    vm.activities = [];
    // keeps a list of all the activity types that we've already loaded
    var loadedTypes = [];
    // Keep track of the type filter and load the types that we need
    $scope.$watch('vm.typeFilter', function(filter) {
      // we already have the correct type in the list of activities - don't worry about fetching them
      if (loadedTypes.indexOf(filter) !== -1) {
        return;
      }
      var existing = vm.activities;
      // if there is no value in the filter, that means 'All was selected' - so get all the activity types
      var types = !filter
        ? vm.activityTypes
            .map(function(at) {
              return at.name;
            })
            .filter(function(type) {
              return loadedTypes.indexOf(type) === -1;
            })
        : [filter];
      // nothing more to load - do nothing!
      if (types.length === 0) {
        return;
      }
      // null out the activities to trigger the loading message
      vm.activities = null;
      activitySvc
        .getPreviews({ include: 'tags', type: types })
        .then(function(resp) {
          vm.activities = existing.concat(resp);
          // inject a fake no-tag
          _.each(vm.activities, function(a) {
            if (!a.tags || a.tags.length === 0) {
              a.tags = [{ shortName: 'no-tags', type: 'FAKE' }];
            }
          });
          loadedTypes = loadedTypes.concat(types);
        });
    });
    activitySvc.getAvailableTypes().then(function(data) {
      vm.activityTypes = data;
    });
    tagSvc.getAll().then(
      function(resp) {
        vm.tags = resp;
      },
      function(error) {
        vm.tags = [
          {
            shortName: 'Failed to load Tags: ' + error.statusText
          }
        ];
      }
    );
    vm.newVersion = function(activity) {
      var newVersion = window.prompt(
        'Create a new version',
        'New version name'
      );
      if (newVersion) {
        activitySvc.createNewVersion(activity, newVersion).then(function() {
          $state.go('activities.edit.activity', {
            url: activity.url,
            version: newVersion
          });
        });
      }
    };

    /**
     * Make a back end call to index all existing activities into ElasticSearch.
     */
    vm.indexAll = function() {
      vm.indexing = true;
      activitySvc.indexAll();
      // This call is queued into RabbitMQ so we can't know
      // when it will complete.  Pause 5 seconds to give the user some
      // feedback when the button is clicked.  3 seconds is about how long
      // it currently takes to index 163 activities.
      $timeout(function() {
        vm.indexing = false;
      }, 3000);
    };
    vm.order = function(prop) {
      vm.predicate = prop;
    };
    vm.statusList = [
      {
        friendlyName: 'Published',
        name: 'PUBLISHED'
      },
      {
        name: 'DRAFT',
        friendlyName: 'Draft'
      },
      {
        name: 'INACTIVE',
        friendlyName: 'Inactive'
      },
      {
        name: 'REVIEW',
        friendlyName: 'Ready for review'
      },
      {
        name: undefined,
        friendlyName: 'All'
      }
    ];
  }
]);

(function() {
  'use strict';

  angular
    .module('activityControllers')
    .controller('TrainCtrl', TrainCtrl)
    .filter('trainRecommendationFiltered', TrainFilteredRecommendationFilter);

  TrainCtrl.$inject = ['activityTypeIcons', 'httpSvc'];

  /* @ngInject */
  function TrainCtrl(activityTypeIcons, httpSvc) {
    var vm = this;
    vm.find = find;
    vm.updateAllRecommenders = updateAllRecommenders;
    vm.typeIcons = activityTypeIcons;

    activate();

    ////////////////

    function activate() {
      httpSvc
        .get('API/user/activities/tags/condition')
        .then(conditionTags => (vm.conditionTags = conditionTags));
    }

    function find(email, condition) {
      condition = condition || 'condition:depression';
      var url;
      if (email) {
        url = 'API/admin/activities/train/' + email + '/' + condition;
      } else {
        url = 'API/admin/activities/train/';
      }
      vm.processing = true;
      vm.recommendations = null;
      httpSvc
        .get(url)
        .then(res => (vm.recommendations = res))
        .finally(() => (vm.processing = false));
    }

    function updateAllRecommenders() {
      vm.updateClicked = true;
      var url = 'API/admin/activities/train/update/all';
      httpSvc.post(url).finally(() => (vm.updateClicked = false));
    }
  }

  /**
   * This filter removes any recommendations that have been hidden by any train filter.
   * This allows a quicker look at which activities will actually appear in the train.
   *
   * Optional param disables the filter
   */
  function TrainFilteredRecommendationFilter() {
    return function(recommendations, active) {
      if (!recommendations || !active) {
        return recommendations;
      }
      return recommendations.filter(isFiltered);
    };

    function isFiltered(recommendation) {
      let filters = recommendation.filters;
      for (let key in filters) {
        if (filters.hasOwnProperty(key) && filters[key]) {
          return false;
        }
      }
      return true;
    }
  }
})();

'use strict';
angular.module('activityControllers').controller('AssessmentActivityListCtrl', [
  '$scope',
  '$state',
  'activitySvc',
  'tagSvc',
  '$timeout',
  function($scope, $state, activitySvc, tagSvc, $timeout) {
    var vm = this;
    vm.statusColours = {
      PUBLISHED: 'ok',
      DRAFT: 'warn',
      INACTIVE: '',
      DELETED: 'error',
      REVIEW: 'info'
    };
    vm.typeIcons = {
      YOUTH: 'fa-graduation-cap',
      CORE: 'fa-circle',
      FLASH_MIGRATION: 'fa-flash'
    };
    vm.tagColors = {
      CATEGORY: 'label-success',
      TAG: 'label-primary',
      METADATA: 'label-info'
    };
    vm.activities = [
      {
        title: 'Loading...'
      }
    ];
    vm.listOnly = true;
    activitySvc.getAll({ include: 'tags', type: ['WELLNESS_ASSESSMENT'] }).then(
      function(resp) {
        vm.activities = resp;
      },
      function(error) {
        vm.activities = [
          {
            shortName: 'Failed to load Activities: ' + error.statusText
          }
        ];
      }
    );
    activitySvc.getAvailableTypes().then(function(data) {
      vm.activityTypes = data;
    });
    tagSvc.getAll().then(
      function(resp) {
        vm.tags = resp;
      },
      function(error) {
        vm.tags = [
          {
            shortName: 'Failed to load Tags: ' + error.statusText
          }
        ];
      }
    );
    vm.newVersion = function(activity) {
      var newVersion = window.prompt(
        'Create a new version',
        'New version name'
      );
      if (newVersion) {
        activitySvc.createNewVersion(activity, newVersion).then(function() {
          $state.go('activities.edit.activity', {
            url: activity.url,
            version: newVersion
          });
        });
      }
    };

    /**
     * Make a back end call to index all existing activities into ElasticSearch.
     */
    vm.indexAll = function() {
      vm.indexing = true;
      activitySvc.indexAll();
      // This call is queued into RabbitMQ so we can't know
      // when it will complete.  Pause 5 seconds to give the user some
      // feedback when the button is clicked.  3 seconds is about how long
      // it currently takes to index 163 activities.
      $timeout(function() {
        vm.indexing = false;
      }, 3000);
    };
    vm.order = function(prop) {
      vm.predicate = prop;
    };
    vm.filterBy = function(prop) {
      vm.state = prop;
    };
    vm.statusList = [
      {
        friendlyName: 'Published',
        name: 'PUBLISHED'
      },
      {
        name: 'DRAFT',
        friendlyName: 'Draft'
      },
      {
        name: 'INACTIVE',
        friendlyName: 'Inactive'
      },
      {
        name: 'REVIEW',
        friendlyName: 'Ready for review'
      },
      {
        name: undefined,
        friendlyName: 'All'
      }
    ];
  }
]);

'use strict';
angular
  .module('activityControllers')
  .controller('CommunityInspirationListCtrl', [
    '$scope',
    '$state',
    'activitySvc',
    '$timeout',
    'activityStatusColours',
    function($scope, $state, activitySvc, $timeout, activityStatusColours) {
      $scope.statusColours = activityStatusColours;
      $scope.activities = [
        {
          title: 'Loading...'
        }
      ];
      activitySvc.getAll({ include: 'tags' }).then(
        function(resp) {
          $scope.activities = _.filter(resp, function(item) {
            return item.type === 'COMMUNITY_INSPIRATIONS';
          });
        },
        function(error) {
          $scope.activities = [
            {
              shortName: 'Failed to load Activities: ' + error.statusText
            }
          ];
        }
      );
      $scope.newVersion = function(activity) {
        var newVersion = window.prompt(
          'Create a new version',
          'New version name'
        );
        if (newVersion) {
          activitySvc.createNewVersion(activity, newVersion).then(function() {
            $state.go('activities.edit.activity', {
              url: activity.url,
              version: newVersion
            });
          });
        }
      };

      /**
       * Make a back end call to index all existing activities into ElasticSearch.
       */
      $scope.indexAll = function() {
        $scope.indexing = true;
        activitySvc.indexAll();
        // This call is queued into RabbitMQ so we can't know
        // when it will complete.  Pause 5 seconds to give the user some
        // feedback when the button is clicked.  3 seconds is about how long
        // it currently takes to index 163 activities.
        $timeout(function() {
          $scope.indexing = false;
        }, 3000);
      };
      $scope.order = function(prop) {
        $scope.predicate = prop;
      };
      $scope.filterBy = function(prop) {
        $scope.state = prop;
      };
    }
  ]);

(function() {
  'use strict';

  angular
    .module('activityControllers')
    .controller('CoreInspirationCtrl', CoreInspirationCtrl);

  CoreInspirationCtrl.$inject = [
    '$state',
    'inspiration',
    'activitySvc',
    'httpSvc',
    'uuidSvc'
  ];

  /* @ngInject */
  function CoreInspirationCtrl(
    $state,
    inspiration,
    activitySvc,
    httpSvc,
    uuidSvc
  ) {
    var vm = this;
    vm.title = 'CoreInspirationCtrl';

    activate();

    ////////////////

    function activate() {
      vm.isEdit = !!inspiration;
      vm.inspiration = inspiration || {
        type: { name: 'CORE_INSPIRATION' },
        url: uuidSvc.newUuid(),
        tags: [],
        sections: [],
        metadata: [],
        status: 'PUBLISHED'
      };
    }
    vm.save = function() {
      activitySvc.save(vm.inspiration).then(function() {
        $state.go('activities.coreinspirationslist');
      });
    };
    vm.approve = function() {
      vm.inspiration.status = 'PUBLISHED';
      httpSvc.post('API/editor/communityinspirations/update', vm.inspiration);
    };
    vm.reject = function() {
      vm.inspiration.status = 'DELETED';
      httpSvc.post('API/editor/communityinspirations/update', vm.inspiration);
    };
  }
})();

(function() {
  'use strict';

  angular
    .module('activityControllers')
    .controller('CoreInspirationListCtrl', CoreInspirationListCtrl);

  CoreInspirationListCtrl.$inject = ['inspirations'];

  /* @ngInject */
  function CoreInspirationListCtrl(inspirations) {
    var vm = this;

    activate();

    ////////////////

    function activate() {
      vm.inspirations = inspirations;
    }
  }
})();

'use strict';

angular.module('activityControllers').controller('HiddenCtrl', [
  '$scope',
  '$log',
  '$http',
  '$q',
  '$timeout',
  'activitySvc',
  'programSvc',
  'alertService',
  'MetadataService',
  'activityTypeIcons',
  'activityStatusColours',
  'tagColors',
  function(
    $scope,
    $log,
    $http,
    $q,
    $timeout,
    activitySvc,
    programSvc,
    alertService,
    MetadataService,
    activityTypeIcons,
    activityStatusColours,
    tagColors
  ) {
    var vm = this;
    vm.statusColours = activityStatusColours;
    vm.statusList = [
      {
        friendlyName: 'Published',
        name: 'PUBLISHED'
      },
      {
        name: 'DRAFT',
        friendlyName: 'Draft'
      },
      {
        name: 'INACTIVE',
        friendlyName: 'Inactive'
      },
      {
        name: 'REVIEW',
        friendlyName: 'Ready for review'
      },
      {
        name: undefined,
        friendlyName: 'All'
      }
    ];
    vm.typeIcons = activityTypeIcons;
    vm.tagColors = tagColors;
    vm.url = 'https://api.mystrength.com';
    vm.listOnly = true;
    vm.importMode = true;
    _loadAllDestination();

    function _loadAllDestination() {
      activitySvc.getAll().then(function(dat) {
        vm.oldActivities = dat.plain();
      });
      MetadataService.fetchMetadataComponents('ACTIVITY').then(function(
        mdComps
      ) {
        vm.existingComponents = mdComps.plain();
      });
      programSvc.getPrograms().then(function(dat) {
        vm.oldPrograms = dat.plain();
      });
      programSvc.getSeriesList().then(function(dat) {
        vm.oldSeries = dat.plain();
      });
      activitySvc.getAvailableTypes().then(function(data) {
        vm.activityTypes = data;
      });
    }

    function _getExisting(act) {
      var olds = vm.oldActivities.filter(function(old) {
        return old.url === act.url;
      });
      return olds;
    }

    function _getExistingProgram(prg) {
      var olds = vm.oldPrograms.filter(function(old) {
        return old.url === prg.url;
      });
      return olds;
    }

    function _getExistingSeries(srs) {
      var olds = vm.oldSeries.filter(function(old) {
        return old.url === srs.url;
      });
      return olds;
    }
    vm.order = function(prop) {
      vm.predicate = prop;
    };

    function impProgram(program) {
      if (!program || program.imported) {
        return;
      }
      $http
        .get(vm.url + '/editor/activities/programs/' + program.id, {
          headers: {
            'x-session-token': vm.token
          }
        })
        .then(function(dat) {
          var deferred = $q.defer();
          var hydratedProgram = dat.data;
          var existingProgram = _getExistingProgram(program),
            destProgram;
          if (existingProgram && existingProgram.length > 0) {
            destProgram = existingProgram[existingProgram.length - 1];
            hydratedProgram.id = destProgram.id;
          } else {
            delete hydratedProgram.id;
          }
          if (destProgram) {
            programSvc.getProgram(destProgram.id).then(function(data) {
              deferred.resolve(data.plain());
            });
          } else {
            deferred.resolve();
          }
          deferred.promise.then(function(destProgram) {
            var missingActivities = false;
            _.each(hydratedProgram.series, function(series) {
              var existingSeries, destSeries;
              if (destProgram) {
                existingSeries = destProgram.series.filter(function(old) {
                  return old.url === series.url;
                });
              }
              if (existingSeries && existingSeries.length > 0) {
                destSeries = existingSeries[existingSeries.length - 1];
                series.id = destSeries.id;
              } else {
                delete series.id;
              }
              _.each(series.activities, function(a) {
                var existingActivity = _getExisting(a);
                if (existingActivity && existingActivity.length > 0) {
                  var mostRecent =
                    existingActivity[existingActivity.length - 1];
                  a.id = mostRecent.id;
                } else {
                  missingActivities = true;
                }
              });
            });

            if (!missingActivities) {
              programSvc.save(hydratedProgram).then(function() {
                program = hydratedProgram;
                program.imported = true;
                alertService.addAlert({
                  message: 'Program Imported!',
                  type: 'success'
                });
              });
            } else {
              alertService.addAlert({
                message: 'Program Activities Must Be Imported First!',
                type: 'error'
              });
            }
          });
        });
    }

    function impSeries(series) {
      if (!series || series.imported) {
        return;
      }
      var existing = _getExistingSeries(series),
        missingActivities = false;
      if (existing && existing.length > 0) {
        var mostRecent = existing[existing.length - 1];
        series.id = mostRecent.id;
      } else {
        delete series.id;
      }

      _.each(series.activities, function(a) {
        var existingActivity = _getExisting(a);
        if (existingActivity && existingActivity.length > 0) {
          var mostRecent = existingActivity[existingActivity.length - 1];
          a.id = mostRecent.id;
        } else {
          missingActivities = true;
        }
      });
      if (!missingActivities) {
        programSvc.saveSeries(series).then(function() {
          alertService.addAlert({
            message: 'Series Imported!',
            type: 'success'
          });
        });
      } else {
        alertService.addAlert({
          message: 'Series Activities Must Be Imported First!',
          type: 'error'
        });
      }
    }

    function cleanTags(act) {
      act.tags = act.tags.filter(function(t) {
        return t.type !== 'FAKE';
      });
      _.each(act.tags, function(tag) {
        delete tag.id;
        if (tag.componentId) {
          //find the source component
          var match = vm.components.filter(function(c) {
            return c.id === tag.componentId;
          });
          if (match && match.length > 0) {
            //find the matching destination component by name
            var destinationMatch = vm.existingComponents.filter(function(c) {
              return c.displayName === match[0].displayName;
            });
            if (destinationMatch && destinationMatch.length > 0) {
              //replace the tag componentId with the destination ID
              tag.componentId = destinationMatch[0].id;
            } else {
              delete tag.componentId;
              alertService.addAlert({
                message:
                  'Warning: A tag associated with this Activity was orphaned from metadata',
                type: 'warn'
              });
            }
          }
        }
      });
    }

    function imp(act) {
      var deferred = $q.defer();
      if (!act || act.imported) {
        deferred.resolve();
        return deferred.promise;
      }
      var existing = _getExisting(act);
      //set the status
      act.status = 'DRAFT';
      if (!act.type) {
        act.type = 'ALL';
      }
      // kill the id & version on the incoming activity as it will be net-new in this env
      delete act.id;
      delete act.version;
      cleanTags(act);
      if (existing && existing.length > 0) {
        $log.debug('preparing new version : ', act.url);
        //Create a new version of an existing activity...
        var mostRecent = existing[existing.length - 1];
        activitySvc
          .createNewVersionWithContent(
            mostRecent,
            mostRecent.version + '_import',
            act
          )
          .then(
            function(ret) {
              act.imported = true;
              act.id = ret;
              if (act.tags.length === 0) {
                act.tags = [
                  {
                    shortName: 'no-tags',
                    type: 'FAKE'
                  }
                ];
              }
              $log.debug('created new version : ', act);
              deferred.resolve(act);
            },
            function(e) {
              $log.error('unable to import activity to a new version: ', e);
              alertService.addAlert({
                message: 'Error: ' + e.statusText,
                type: 'danger'
              });
              deferred.resolve();
            }
          );
      } else {
        $log.debug('No activity found in current environment : ', act.url);
        //new activity not in current env.
        _.each(act.sections, function(section) {
          delete section.id;
          _.each(section.widgets, function(w) {
            delete w.id;
          });
        });
        if (!act.metadata) {
          act.metadata = [];
        }
        activitySvc.save(act).then(
          function() {
            act.imported = true;
            if (act.tags.length === 0) {
              act.tags = [
                {
                  shortName: 'no-tags',
                  type: 'FAKE'
                }
              ];
            }
            $log.debug('successfully created new activity : ' + act);
            deferred.resolve(act);
          },
          function(e) {
            $log.error('unable to create new actiivty : ', e);
            alertService.addAlert({
              message: 'Error: ' + e.statusText,
              type: 'danger'
            });
            deferred.resolve();
          }
        );
      }
      return deferred.promise;
    }
    vm.importAll = function() {
      if (vm.activities) {
        vm.activities.forEach(function(act) {
          imp(act);
        });
      }
      if (vm.programs) {
        vm.programs.forEach(function(act) {
          imp(act);
        });
      }
      if (vm.series) {
        vm.series.forEach(function(act) {
          imp(act);
        });
      }
    };
    vm.importAllArticles = function() {
      if (vm.activities) {
        var funcs = [],
          sequence;
        vm.activities.forEach(function(act) {
          if (
            act.type &&
            act.type.name === 'CORE_ARTICLE' &&
            act.status === 'PUBLISHED'
          ) {
            funcs.push(function() {
              return imp(act);
            });
          }
        });
        funcs.forEach(function(f) {
          if (!sequence) {
            sequence = f();
          } else {
            sequence = sequence.then(f);
          }
        });
        sequence.then(function() {
          $log.debug('Core Article Migration complete...');
          alertService.addAlert({
            message: 'Core Article Bulk Import Complete!',
            type: 'success'
          });
        });
        _loadAllDestination();
      }
    };
    vm.importAllNew = function() {
      if (vm.activities) {
        var funcs = [],
          sequence;
        vm.activities.forEach(function(act) {
          if (vm.exists(act) === 'CREATE') {
            if (
              act.type &&
              act.status === 'PUBLISHED' &&
              act.type.name !== 'ACTIVITY_PROGRAM' &&
              act.type.name !== 'ACTIVITY_SERIES'
            ) {
              funcs.push(function() {
                return imp(act);
              });
            }
          }
        });
        funcs.forEach(function(f) {
          if (!sequence) {
            sequence = f();
          } else {
            sequence = sequence.then(f);
          }
        });
        sequence.then(function() {
          $log.debug('NEW Core Activity Migration complete...');
          alertService.addAlert({
            message: 'Core (New) Bulk Import Complete!',
            type: 'success'
          });
        });
        _loadAllDestination();
      }
    };

    vm.importAllCore = function() {
      if (vm.activities) {
        var funcs = [],
          sequence;
        vm.activities.forEach(function(act) {
          if (
            act.type &&
            act.type.name === 'CORE' &&
            act.status === 'PUBLISHED'
          ) {
            funcs.push(function() {
              return imp(act);
            });
          }
        });
        funcs.forEach(function(f) {
          if (!sequence) {
            sequence = f();
          } else {
            sequence = sequence.then(f);
          }
        });
        sequence.then(function() {
          $log.debug('Core Activity Migration complete...');
          alertService.addAlert({
            message: 'Core Activity Bulk Import Complete!',
            type: 'success'
          });
        });
        _loadAllDestination();
      }
    };
    vm.importAllCoreInspirations = function() {
      if (vm.activities) {
        var funcs = [],
          sequence;
        vm.activities.forEach(function(act) {
          if (
            act.type &&
            act.type.name === 'CORE_INSPIRATION' &&
            act.status === 'PUBLISHED'
          ) {
            funcs.push(function() {
              return imp(act);
            });
          }
        });
        funcs.forEach(function(f) {
          if (!sequence) {
            sequence = f();
          } else {
            sequence = sequence.then(f);
          }
        });
        sequence.then(function() {
          $log.debug('Core Inspiration Activity Migration complete...');
          alertService.addAlert({
            message: 'Core Inspiration Bulk Import Complete!',
            type: 'success'
          });
        });
        _loadAllDestination();
      }
    };
    vm.import = function(act) {
      if (act.type && act.type.name === 'ACTIVITY_PROGRAM') {
        impProgram(act);
      } else if (act.type && act.type.name === 'ACTIVITY_SERIES') {
        impSeries(act);
      } else {
        imp(act);
      }
    };
    vm.load = function(url, token) {
      $http
        .get(
          url +
            '/editor/activities?include=tags&include=sections&type=YOUTH&type=FLASH_MIGRATION&type=CORE&type=CORE_ARTICLE&type=CORE_INSPIRATION',
          {
            headers: {
              'x-session-token': token
            }
          }
        )
        .then(function(dat) {
          vm.activities = dat.data;
          _.each(vm.activities, function(a) {
            if (!a.tags || a.tags.length === 0) {
              a.tags = [
                {
                  shortName: 'no-tags',
                  type: 'FAKE'
                }
              ];
            }
          });
        });
      $http
        .get(url + '/editor/activities/programs', {
          headers: {
            'x-session-token': token
          }
        })
        .then(function(dat) {
          vm.programs = dat.data;
        });
      $http
        .get(url + '/editor/activities/programs/series', {
          headers: {
            'x-session-token': token
          }
        })
        .then(function(dat) {
          vm.series = dat.data;
        });
      $http
        .get(url + '/editor/metadata/components?context=ACTIVITY', {
          headers: {
            'x-session-token': token
          }
        })
        .then(function(dat) {
          vm.components = dat.data;
        });
    };
    vm.reloadDestination = _loadAllDestination;
    vm.exists = function(act) {
      var olds = _getExisting(act);
      if (olds && olds.length > 0) {
        return angular.equals(olds[0], act) ? 'Same' : 'Update';
      } else {
        return 'Create';
      }
    };
    vm.importMd = function(component) {
      delete component.id;
      var existing = vm.existingComponents.filter(function(c) {
        return c.displayName === component.displayName;
      });
      if (existing.length > 0) {
        $log.debug('Found existing component - merging');
        component.id = existing[0].id;
      }
      MetadataService.saveComponent(component);
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('SectionEditCtrl', [
  '$scope',
  'activitySvc',
  'uuidSvc',
  'orderSvc',
  '$stateParams',
  'alertService',
  function($scope, activitySvc, uuidSvc, orderSvc, $stateParams, alertService) {
    if ($stateParams.section) {
      $scope.isEdit = true;
      // try to find in activity
      $scope.section = $scope.activity.activity.sections.filter(function(
        section
      ) {
        return section.name === $stateParams.section;
      })[0];
    }
    // if we didn't find a section with that UUID, create a new one
    if (!$scope.section) {
      var pos = $scope.activity.activity.sections.length;
      $scope.section = {
        uuid: $stateParams.section || uuidSvc.newUuid(),
        widgets: [],
        name: 'Section ' + pos,
        position: pos,
        type: 'GENERAL'
      };
      $scope.activity.activity.sections.push($scope.section);
      if (!$scope.activity.activity.firstSection) {
        $scope.activity.activity.firstSection = $scope.section.uuid;
      }
    }
    activitySvc
      .getWidgetTypes($scope.activity.activity.type.name)
      .then(function(dat) {
        $scope.widgetCategories = dat.plain();
        //remove any categories without widgets so "Add Widget" dropdown doesn't look funny
        Object.keys($scope.widgetCategories).forEach(function(k) {
          if (_.isEmpty($scope.widgetCategories[k])) {
            delete $scope.widgetCategories[k];
          }
        });
      });
    // find the friendly name for a widget
    $scope.getFriendlyName = function(ugly) {
      for (var category in $scope.widgetCategories) {
        for (var widget in $scope.widgetCategories[category]) {
          if (widget === ugly) {
            return $scope.widgetCategories[category][widget];
          }
        }
      }
      // didn't find it return the ugly name
      return ugly;
    };
    $scope.addWidget = function(type) {
      $scope.section.widgets.push({
        type: type,
        position: $scope.section.widgets.length,
        uuid: uuidSvc.newUuid(),
        properties: {}
      });
      $scope.activity.updateWidgets();
    };
    // TODO Fix these
    $scope.removeWidget = function(widget) {
      orderSvc.remove(widget, $scope.section.widgets);
    };
    $scope.move = function(widget, direction) {
      orderSvc.move(widget, direction, $scope.section.widgets);
    };

    $scope.back = function(event) {
      if (!this.form.$valid) {
        event.preventDefault();
        event.stopPropagation();
        angular.forEach(this.form.$error.required, function(field) {
          field.$setDirty();
        });

        alertService.addAlert({
          message: 'Check that all fields have been filled out correctly',
          type: 'danger'
        });
      }
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('SectionListCtrl', [
  '$scope',
  'orderSvc',
  function($scope, orderSvc) {
    var vm = this;
    vm.removeSection = function(section) {
      orderSvc.remove(section, $scope.activity.activity.sections);
    };
    vm.startingSection = function(section) {
      $scope.activity.activity.firstSection = section.uuid;
    };
  }
]);

'use strict';
angular.module('activityControllers').controller('TagEditCtrl', [
  '$scope',
  '$modalInstance',
  'tag',
  function($scope, $modalInstance, tag) {
    $scope.tag = tag;
    $scope.save = function() {
      $modalInstance.close($scope.tag);
    };
    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';
angular.module('activityControllers').controller('TagListCtrl', [
  '$scope',
  '$modal',
  'orderSvc',
  'tagSvc',
  function($scope, $modal, orderSvc, tagSvc) {
    var vm = this;

    tagSvc.getAll().then(
      function(resp) {
        vm.tags = resp;
      },
      function(error) {
        vm.tags = [
          {
            shortName: 'Failed to load Tags: ' + error.statusText
          }
        ];
      }
    );
    vm.move = function(tag, rankBump) {
      if ((tag.rank > 0 && rankBump < 0) || rankBump > 0) {
        tag.rank += rankBump;
        // save the tag
        tagSvc.save(tag);
      }
    };
    vm.editTag = function(tag) {
      var backupTag = angular.copy(tag);
      var modal = $modal.open({
        templateUrl: 'activities/templates/tagEdit.html',
        controller: 'TagEditCtrl',
        resolve: {
          tag: function() {
            return tag;
          }
        }
      });
      modal.result.then(
        function(updatedTag) {
          // save the tag
          tagSvc.save(updatedTag);
        },
        function() {
          // roll back!
          for (var k in backupTag) {
            tag[k] = backupTag[k];
          }
        }
      );
    };
  }
]);

'use strict';

angular.module('activityControllers').directive('msActivityMetadataEdit', [
  function() {
    return {
      templateUrl: 'activities/templates/activityMetadata.html',
      restrict: 'AE',
      scope: {
        values: '=msActivityMetadataEdit'
      },
      controller: [
        '$scope',
        'MetadataService',
        function($scope, MetadataService) {
          var vm = this;
          MetadataService.fetchMetadataComponents('ACTIVITY').then(function(
            resp
          ) {
            // we rebind the values coming in to retrigger the ng-init that depends on scope.components
            vm.values = $scope.values;
            vm.components = resp;
            vm.availableComponents = vm.components.filter(function(component) {
              var unique =
                !component.uniqueComp ||
                vm.values.filter(function(value) {
                  return value.componentId === component.id;
                }).length === 0;
              var validType =
                component.type === 'List' || component.type === 'Text';
              return unique && validType;
            });
          });
          vm.change = function(selected) {
            vm.values.push({
              componentId: selected.id,
              value: selected.defaultText
            });
            if (selected.uniqueComp) {
              vm.availableComponents.splice(
                vm.availableComponents.indexOf(selected),
                1
              );
            }
            vm.selected = null;
          };
        }
      ],
      controllerAs: 'vm'
    };
  }
]);

'use strict';

angular
  .module('activityControllers')
  .directive('msActivityTagger', [
    function() {
      return {
        templateUrl: 'activities/templates/activityTagger.html',
        replace: true,
        restrict: 'AE',
        controller: 'ActivityTaggerCtrl',
        scope: {
          activeTags: '=',
          tagType: '@'
        },
        link: function(scope, elem) {
          elem.bind('keyup', function(event) {
            if (scope.tagType === 'TAG' && event.which === 13 && scope.tag) {
              scope.addTag({
                shortName: scope.tag.toLowerCase().replace(/\W/g, ''),
                type: 'TAG',
                label: scope.tag
              });
              scope.$apply();
            }
          });
        }
      };
    }
  ])
  .controller('ActivityTaggerCtrl', [
    '$scope',
    'tagSvc',
    function($scope, tagSvc) {
      tagSvc.getAll().then(function(tags) {
        $scope.existingTags = angular.copy(tags.plain());
      });

      $scope.onActivity = function(tag) {
        return (
          $scope.activeTags.filter(function(active) {
            return active.shortName === tag.shortName;
          }).length === 0
        );
      };

      function addTag(tag) {
        if (tag && $scope.activeTags.indexOf(tag) === -1) {
          $scope.activeTags.push(tag);
          $scope.tag = '';
        }
      }

      function removeTag(tag) {
        var idx = $scope.activeTags.indexOf(tag);
        if (idx !== -1) {
          $scope.activeTags.splice(idx, 1);
        }
      }
      $scope.addTag = addTag;
      $scope.removeTag = removeTag;
    }
  ]);

/**
 * Apply on enter applies a model when the user hits enter.
 */
(function() {
  'use strict';

  angular.module('activityControllers').directive('applyOnEnter', applyOnEnter);

  applyOnEnter.$inject = [];

  /* @ngInject */
  function applyOnEnter() {
    var directive = {
      link: link,
      restrict: 'A',
      require: 'ngModel'
    };
    return directive;

    function link(scope, element, attr, ngModelCtrl) {
      element.on('keyup', function(e) {
        if (e.keyCode === 13) {
          ngModelCtrl.$commitViewValue();
        }
      });
    }
  }
})();

'use strict';

// http://stackoverflow.com/a/20539315/2108024
// This binding allows us to pull string values into number inputs.
// This is necessary when we have displayProperties / localizedProperties / properties that are
// numbers (since those statebags contain only strings)

angular.module('activityControllers').directive('numericbinding', function() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      model: '=ngModel'
    },
    link: function(scope) {
      if (scope.model && typeof scope.model === 'string') {
        scope.model = parseInt(scope.model);
      }
    }
  };
});

'use strict';

angular.module('activityControllers').directive('msQuestion', [
  function() {
    return {
      templateUrl: 'activities/templates/question.html',
      replace: true,
      restrict: 'AE',
      controller: 'ActivityTaggerCtrl',
      scope: false
    };
  }
]);

'use strict';

(function() {
  angular
    .module('activityControllers')
    .directive('rackspaceUpload', rackspaceUpload);

  rackspaceUpload.$inject = ['rackspaceUploadSvc', 'alertService'];

  function rackspaceUpload(rackspaceUploadSvc, alertService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        type: '=',
        ngModel: '=',
        imagePreview: '=',
        allowDefault: '@'
      },
      templateUrl: 'activities/templates/uploadFile.html',
      replace: false,
      transclude: true,
      link: link
    };

    function link(scope, ele, attrs, ngModel) {
      ele.find('input').bind('change', function(e) {
        ngModel.$validators.required = function(modelValue, viewValue) {
          return !attrs.required || !ngModel.$isEmpty(viewValue);
        };
        var file = e.target.files[0];
        var filename = e.target.value;
        scope.uploading = true;
        ngModel.$setValidity('loadingImage', false);
        rackspaceUploadSvc
          .upload(file, filename, scope.type)
          .then(
            function(data) {
              ngModel.$setViewValue(data);
            },
            function(res) {
              alertService.addAlert({
                type: 'danger',
                message: 'Failed to upload: ' + res
              });
            }
          )
          .finally(function() {
            scope.uploading = false;
            ngModel.$setValidity('loadingImage', true);
          });
        // TODO should deal with resolves better
        if (attrs.imagePreview) {
          rackspaceUploadSvc.preview(file, filename).then(function(res) {
            scope.imagePreview = res;
          });
        }
      });
    }
  }
})();

(function() {
  'use strict';

  angular.module('actionplan').service('actionPlanSvc', ActionPlanSvc);

  ActionPlanSvc.$inject = ['httpSvc'];

  /* @ngInject */
  function ActionPlanSvc(httpSvc) {
    this.getPlan = getPlan;
    this.getPlans = getPlans;
    this.upsertPlan = upsertPlan;

    function getPlan(id) {
      return getPlans().then(plans => plans.find(plan => plan.id === id));
    }

    function getPlans() {
      return httpSvc.get('API/editor/actionplan');
    }

    function upsertPlan(plan) {
      return httpSvc.post('API/editor/actionplan', plan);
    }
  }
})();

'use strict';
// We are using both httpSvc and Restangular here. Moving forward, we would like to use httpSvc only,
// but the scope of the change was high so both are side by side here.
angular.module('activityControllers').service('activitySvc', [
  'Restangular',
  'httpSvc',
  function(Restangular, httpSvc) {
    var activities = Restangular.all('editor/activities');

    function save(activity) {
      return httpSvc.post('API/editor/activities', activity);
    }

    function updateStatus(activity, newStatus) {
      return httpSvc.post(
        'API/editor/activities/' + activity.id + '/' + newStatus
      );
    }

    function get(shortName, version) {
      return activities.one(
        shortName +
          '?include=tags&include=sections&include=metadata' +
          (!!version ? '&version=' + version : '')
      );
    }

    function getWidgetTypes(context) {
      context = context ? [context, 'ALL'] : ['ALL'];
      return activities.one('widgettypes').get({ context: context });
    }

    function getAvailableTypes(all) {
      return activities.one('types').get({ all: !!all });
    }

    function getByTag(include) {
      return activities.all('categories').getList(include);
    }

    function createNewVersion(activity, version) {
      return httpSvc.post(
        'API/editor/activities/' + activity.id + '/version/' + version
      );
    }

    function indexAll() {
      return httpSvc.post('API/editor/activities/indexall');
    }

    function getPreviews(qp) {
      return activities.all('preview').getList(qp);
    }

    return {
      save: save,
      updateStatus: updateStatus,
      getAll: activities.getList,
      getPreviews: getPreviews,
      get: get,
      getByTag: getByTag,
      getWidgetTypes: getWidgetTypes,
      getAvailableTypes: getAvailableTypes,
      createNewVersion: createNewVersion,
      indexAll: indexAll
    };
  }
]);

'use strict';

angular.module('activityControllers').service('communitySvc', [
  'Restangular',
  function(Restangular) {
    var responses = Restangular.all('editor/activities/responses/shared');

    function getPage(offset, size, params) {
      return responses.all(offset + '/' + size).getList(params);
    }

    function save(response) {
      return responses.post(response);
    }

    function get(uuid) {
      return responses.one(uuid);
    }

    return {
      save: save,
      getList: getPage,
      get: get
    };
  }
]);

'use strict';

angular.module('activityControllers').service('rackspaceUploadSvc', [
  'Restangular',
  '$q',
  function(Restangular, $q) {
    function upload(file, filename, type) {
      if (!type) {
        throw 'Image type not set';
      }
      type = type.toLowerCase();
      if (type === 'audio') {
        return verifyAndUpload(file, filename, 'widget/audio', 25000000);
      }
      if (type === 'file') {
        return verifyAndUpload(file, filename, 'widget/file', 25000000);
      } else if (type === 'image') {
        return verifyAndUpload(file, filename, 'widget/image', 6000000);
      } else if (type === 'any') {
        return verifyAndUpload(file, filename, 'header', 6000000);
      } else if (type === 'square') {
        return verifyAndUpload(file, filename, 'header', 6000000, 600, 1);
      } else if (type === 'standard') {
        return verifyAndUpload(file, filename, 'header', 6000000, 1200, 4 / 3);
      } else if (type === 'vimeo') {
        return verifyAndUpload(
          file,
          filename,
          'widget/image',
          6000000,
          640,
          16 / 9
        );
      }
    }

    function verifyAndUpload(
      file,
      filename,
      endpoint,
      maxsize,
      minWidth,
      ratio
    ) {
      return verify(file, maxsize, minWidth, ratio).then(function() {
        return uploadFile(file, filename, endpoint);
      });
    }

    function verify(file, maxsize, minWidth, ratio) {
      // we can check the filesize without loading the image - do this first, and reject if too big
      if (!!maxsize && file.size > maxsize) {
        return $q.reject(
          'File too large - must be less than ' +
            Math.floor(maxsize / 1000) +
            'kb (was ' +
            Math.floor(file.size / 1000) +
            'kb)'
        );
      }
      if (typeof minWidth === 'undefined') {
        return $q.when('success');
      }
      // to check the dimensions, we need to load up the image - so defer here
      var deferred = $q.defer();
      var img = new Image();
      img.onload = function() {
        if (this.width < minWidth) {
          deferred.reject(
            'Image must be at least ' +
              minWidth +
              ' pixels wide; was ' +
              this.width
          );
          return;
        }
        if (this.width / ratio !== this.height) {
          deferred.reject(
            'Image aspect ratio must be ' +
              ratio +
              ' but was ' +
              this.width / this.height
          );
          return;
        }
        deferred.resolve('success');
      };
      img.src = window.URL.createObjectURL(file);
      return deferred.promise;
    }

    function preview(file, filename) {
      return uploadFile(file, filename, 'preview');
    }

    function uploadFile(file, filename, endpoint) {
      var extension =
        filename && filename.indexOf('.') ? filename.split('.').pop() : '';
      var fd = new FormData();
      fd.append('file', file);
      fd.append('extension', extension);
      return Restangular.all(
        'editor/activities/content/' + endpoint
      ).customPOST(fd, undefined, undefined, {
        'Content-Type': undefined
      });
    }
    return {
      preview: preview,
      upload: upload
    };
  }
]);

'use strict';

angular.module('activityControllers').service('tagSvc', [
  'Restangular',
  'alertService',
  function(Restangular) {
    var tags = Restangular.all('editor/activities/tags');

    function save(tag) {
      return Restangular.one('editor/activities/tags').customPOST(tag);
    }
    return {
      getAll: tags.getList,
      save: save
    };
  }
]);

'use strict';
angular.module('activityPrograms').controller('ProgramEditCtrl', [
  '$modal',
  '$state',
  'programSvc',
  'alertService',
  'program',
  function($modal, $state, programSvc, alertService, program) {
    var vm = this;
    vm.isEdit = !!program;
    vm.program = program || {
      status: 'PUBLISHED',
      series: [],
      metadata: [],
      tags: []
    };

    vm.statusColours = {
      PUBLISHED: 'ok',
      DRAFT: 'warn',
      INACTIVE: '',
      DELETED: 'error',
      REVIEW: 'info'
    };
    vm.typeIcons = {
      ACTIVITY_SERIES: 'fa-circle',
      YOUTH: 'fa-graduation-cap',
      CORE: 'fa-circle',
      FLASH_MIGRATION: 'fa-flash',
      CORE_ARTICLE: 'fa-file-text'
    };
    vm.tagColors = {
      CATEGORY: 'label-success',
      TAG: 'label-primary',
      METADATA: 'label-info',
      FAKE: 'label-danger'
    };

    vm.save = function() {
      if (!vm.form.$valid) {
        angular.forEach(vm.form.$error.required, function(field) {
          field.$setDirty();
        });
        alertService.addAlert({
          message: 'Check that all fields have been filled out correctly.',
          type: 'danger'
        });
        return;
      }
      programSvc.save(vm.program).then(function() {
        alertService.addAlert({
          message: 'Program Saved!',
          type: 'success'
        });
        $state.go('activityPrograms.list');
      });
    };
    vm.editSeries = function(index) {
      var modal = $modal.open({
        templateUrl: 'activityPrograms/templates/seriesEdit.html',
        size: 'lg',
        controller: 'SeriesEditCtrl',
        controllerAs: 'vm',
        resolve: {
          series: function() {
            return index === null
              ? null
              : angular.copy(vm.program.series[index]);
          }
        }
      });

      modal.result.then(function(result) {
        if (result) {
          if (index !== null) {
            vm.program.series[index] = result;
          } else {
            vm.program.series.push(result);
          }
        } else if (index !== null) {
          vm.program.series.splice(index, 1);
        }
      });
    };
  }
]);

'use strict';
angular.module('activityPrograms').controller('ProgramListCtrl', [
  '$modal',
  'alertService',
  'programSvc',
  'activityStatusColours',
  'activityTypeIcons',
  'tagColors',
  'programs',
  'series',
  function(
    $modal,
    alertService,
    programSvc,
    activityStatusColours,
    activityTypeIcons,
    tagColors,
    programs,
    series
  ) {
    var vm = this;
    vm.statusColours = activityStatusColours;
    vm.typeIcons = activityTypeIcons;
    vm.tagColors = tagColors;

    vm.programs = programs;
    vm.series = series;
    vm.editSeries = function(id) {
      var modal = $modal.open({
        templateUrl: 'activityPrograms/templates/seriesEdit.html',
        size: 'lg',
        controller: 'SeriesEditCtrl',
        controllerAs: 'vm',
        resolve: {
          series: function() {
            return !id ? null : programSvc.getSeries(id);
          }
        }
      });

      modal.result.then(function(result) {
        programSvc.saveSeries(result).then(function() {
          programSvc.getSeriesList().then(function(seriesList) {
            vm.series = seriesList;
            alertService.addAlert({ message: 'Series Saved!' });
          });
        });
      });
    };
  }
]);

'use strict';
angular.module('activityPrograms').controller('SeriesEditCtrl', [
  'series',
  '$modalInstance',
  function(series, $modalInstance) {
    var vm = this;
    vm.isEdit = !!series;
    vm.series = series || {
      status: 'PUBLISHED',
      activities: [],
      metadata: [],
      tags: []
    };
    vm.done = function() {
      $modalInstance.close(vm.series);
    };
    vm.delete = function() {
      $modalInstance.close(null);
    };
  }
]);

'use strict';
angular
  .module('activityPrograms')
  .directive('dndActivitiesList', function() {
    return {
      restrict: 'EA',
      scope: {
        activityTypes: '=?dndActivitiesList'
      },
      templateUrl: 'activityPrograms/templates/dndActivitiesList.html',
      controller: [
        'activitySvc',
        'activityStatusColours',
        'activityTypeIcons',
        'tagColors',
        function(
          activitySvc,
          activityStatusColours,
          activityTypeIcons,
          tagColors
        ) {
          var vm = this;
          if (!vm.activityTypes || !vm.activityTypes.length) {
            vm.activityTypes = ['CORE'];
          }
          vm.statusColours = activityStatusColours;
          vm.typeIcons = activityTypeIcons;
          vm.tagColors = tagColors;
          activitySvc.getAll({ type: vm.activityTypes }).then(function(resp) {
            vm.activities = resp.filter(function(a) {
              return a.status === 'PUBLISHED';
            });
          });
        }
      ],
      controllerAs: 'dndlist',
      bindToController: true
    };
  })
  .filter('dndUniqueActivities', function() {
    return function(activities) {
      var found = [];
      if (activities) {
        return activities.filter(function(activity) {
          return found.indexOf(activity.id) === -1 && found.push(activity.id);
        });
      } else {
        return activities;
      }
    };
  });

'use strict';

angular.module('activityPrograms').service('programSvc', [
  'Restangular',
  function(Restangular) {
    var programs = Restangular.all('editor/activities/programs');
    var series = Restangular.all('editor/activities/programs/series');

    function getProgram(programId) {
      return Restangular.one('editor/activities/programs', programId).get();
    }

    function getPrograms() {
      return programs.getList();
    }

    function getSeriesList() {
      return series.getList();
    }

    function getSeries(id) {
      return Restangular.one('editor/activities/programs/series', id).get();
    }

    function saveSeries(s) {
      return series.post(s);
    }

    function save(program) {
      return programs.post(program);
    }
    return {
      getPrograms: getPrograms,
      getProgram: getProgram,
      save: save,
      getSeriesList: getSeriesList,
      getSeries: getSeries,
      saveSeries: saveSeries
    };
  }
]);

'use strict';
angular.module('assessmentControllers').controller('AssessmentEditCtrl', [
  '$state',
  '$modal',
  'assessment',
  'frequencies',
  'assessmentSvc',
  'orderSvc',
  'alertService',
  function(
    $state,
    $modal,
    assessment,
    frequencies,
    assessmentSvc,
    orderSvc,
    alertService
  ) {
    var vm = this;
    vm.isEdit = !!assessment;
    vm.frequencies = frequencies.plain();
    vm.assessment = assessment || {
      shortName: '',
      leader: '',
      image: 'https://cloudfront.mystrength.com/images/myStrength-600x600.png',
      version: 'v1',
      status: 'DRAFT',
      disclaimers: {},
      categories: [],
      questions: [],
      frequency: vm.frequencies[0],
      tags: [{ shortName: 'condition:all', type: 'METADATA' }],
      metadata: []
    };
    vm.prettyPrintFrequency = assessmentSvc.prettyPrintFrequency;
    vm.save = function() {
      assessmentSvc.save(vm.assessment).then(
        function() {
          alertService.addAlert({
            message: 'Assessment Saved!',
            type: 'success'
          });
          $state.go('assessments.list');
        },
        function(err) {
          alertService.addAlert({
            message: 'Failed to save the Assessment: ' + err.statusText,
            type: 'danger'
          });
        }
      );
    };
    vm.move = function(question, direction) {
      orderSvc.move(question, direction, vm.assessment.questions);
    };
    vm.toggleQuestionDisabled = function(question) {
      //orderSvc.remove(question, vm.assessment.questions);
      question.disabled = !question.disabled;
    };
    vm.removeCategory = function(category) {
      var idx = vm.assessment.categories.indexOf(category);
      if (idx !== -1) {
        vm.assessment.categories.splice(idx, 1);
      }
    };

    vm.editQuestion = function(question) {
      var isEdit = angular.isDefined(question);
      // back up the field in case they cancel
      var questionBackup = angular.copy(question);
      var modal = $modal.open({
        templateUrl: 'assessments/templates/questionEdit.html',
        controller: 'QuestionEditCtrl',
        controllerAs: 'vm',
        windowClass: 'xl-dialog',
        resolve: {
          question: function() {
            return question;
          },
          existingCategories: function() {
            return vm.assessment.categories;
          }
        }
      });
      modal.result.then(
        function(question) {
          if (!isEdit) {
            question.positionOrdinal = vm.assessment.questions.length + 1;
            vm.assessment.questions.push(question);
          }
        },
        function() {
          // cancelled, revert the field
          if (isEdit) {
            vm.assessment.questions[
              vm.assessment.questions.indexOf(question)
            ] = questionBackup;
          }
        }
      );
    };
    vm.editCategory = function(category) {
      var isEdit = angular.isDefined(category);
      // back up the field in case they cancel
      var categoryBackup = angular.copy(category);
      var modal = $modal.open({
        templateUrl: 'assessments/templates/categoryEdit.html',
        controller: 'CategoryEditCtrl',
        controllerAs: 'vm',
        windowClass: 'xl-dialog',
        resolve: {
          category: function() {
            return category;
          }
        }
      });
      modal.result.then(
        function(category) {
          if (!isEdit) {
            vm.assessment.categories.push(category);
          } else {
            //in edit mode, update the category on each question.
            _.each(vm.assessment.questions, function(q) {
              if (q.category.name === categoryBackup.name) {
                q.category = category;
              }
            });
          }
        },
        function() {
          // cancelled, revert the field
          if (isEdit) {
            vm.assessment.categories[
              vm.assessment.categories.indexOf(category)
            ] = categoryBackup;
          }
        }
      );
    };

    vm.editDisclaimers = function() {
      // back up the field in case they cancel
      var disclaimersBackup = angular.copy(vm.assessment.disclaimers);
      var modal = $modal.open({
        templateUrl: 'assessments/templates/disclaimerEdit.html',
        controller: 'DisclaimerEditCtrl',
        windowClass: 'xl-dialog',
        resolve: {
          assessment: function() {
            return vm.assessment;
          }
        }
      });
      modal.result.then(
        function(assessment) {
          vm.assessment.disclaimers = assessment.disclaimers;
        },
        function() {
          // cancelled, revert it
          vm.assessment.disclaimers = disclaimersBackup;
        }
      );
    };
    vm.preview = function() {
      vm.save();
      $modal.open({
        templateUrl: 'assessments/templates/preview.html',
        windowClass: 'xl-dialog',
        resolve: {
          assessmentUri: function() {
            return vm.assessment.uri;
          }
        },
        controller: 'AssessmentPreviewCtrl'
      });
    };
  }
]);

'use strict';
angular.module('assessmentControllers').controller('AssessmentListCtrl', [
  'assessmentSvc',
  function(assessmentSvc) {
    var vm = this;
    vm.assessments = [
      {
        shortName: 'Loading...'
      }
    ];
    vm.frequencies = [];
    vm.prettyPrintFrequency = assessmentSvc.prettyPrintFrequency;
    vm.prettyPrintAssessmentList = function(frequency) {
      var formatAssessments = [];
      _.each(vm.assessments, function(a) {
        if (a.frequency && a.frequency.id === frequency.id) {
          formatAssessments.push(a.shortName);
        }
      });
      return _(formatAssessments).toString();
    };
    assessmentSvc.getAll().then(
      function(resp) {
        vm.assessments = resp;
      },
      function(error) {
        vm.assessments = [
          {
            shortName: 'Failed to load Assessments: ' + error.statusText
          }
        ];
      }
    );
    assessmentSvc.getFrequencies().then(
      function(resp) {
        vm.frequencies = resp;
      },
      function(error) {
        vm.frequencies = [
          {
            shortName: 'Failed to load frequencies: ' + error.statusText
          }
        ];
      }
    );
  }
]);

'use strict';

angular.module('assessmentControllers').controller('AssessmentPreviewCtrl', [
  //'assessmentUri', '$scope', '$sce', 'domainSvc',
  function() {
    //TODO - re-enable this when we have ability to deep link to an assessment in app.
    //$scope.url = $sce.trustAsResourceUrl(domainSvc.getAppUrl() + '/youth/activity/' + assessmentUri);
  }
]);

'use strict';

angular.module('assessmentControllers').controller('CategoryEditCtrl', [
  '$modalInstance',
  'orderSvc',
  'category',
  function($modalInstance, orderSvc, category) {
    var vm = this;
    vm.isEdit = angular.isDefined(category);
    vm.newValue = 0;
    vm.newThreshold = { name: '', value: 0.0 };
    vm.thresholdValidValue = /^\-?[0-9]*$/;
    vm.category = category || {
      thresholds: {},
      name: '',
      assessmentVersion: 1,
      legend: '',
      scoringType: '',
      disclaimerText: '',
      scoringMultiplier: 1.0
    };
    //existing scoring types used in API
    vm.scoringTypes = ['SUM', 'AVG'];
    vm.removeThreshold = function(threshold) {
      delete vm.category.thresholds[threshold];
    };

    vm.checkValidThreshold = function() {
      return vm.newThreshold.name === '' || !isInt(vm.newThreshold.value);
    };

    function isInt(value) {
      /*jslint bitwise: true */
      return (
        !isNaN(value) &&
        (function(x) {
          return (x | 0) === x;
        })(parseFloat(value))
      );
    }

    vm.addThreshold = function() {
      vm.category.thresholds[vm.newThreshold.name] = parseFloat(
        vm.newThreshold.value
      );
      vm.newThreshold.name = '';
      vm.newThreshold.value = 0.0;
    };

    // Close the modal and return the section
    vm.save = function() {
      $modalInstance.close(vm.category);
    };
    // Dismiss the modal
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:WellnessAssessmentPreferencesEditCtrl
 * @description
 * # WellnessAssessmentPreferencesEditCtrl
 * The controller manages the creation and edit capability for Wellness Assessment
 * customizations for an Organization.
 */
angular.module('assessmentControllers').controller('DisclaimerEditCtrl', [
  '$scope',
  '$modalInstance',
  'assessment',
  function($scope, $modalInstance, assessment) {
    var allProgramCategories = [
      'SEVERE_DEPRESSION',
      'SEVERE_ANXIETY',
      'ANXIETY',
      'DEPRESSION',
      'PREVENTION',
      'SUBSTANCE_ABUSE',
      'SEVERE_SUBSTANCE_ABUSE'
    ];

    $scope.assessment = assessment || {
      shortName: '',
      disclaimers: {}
    };

    $scope.$watch(
      'assessment',
      function() {
        $scope.availableCategories = allProgramCategories.filter(function(
          item
        ) {
          return !$scope.assessment.disclaimers.hasOwnProperty(item);
        });
      },
      true
    );

    $scope.add = function(category) {
      $scope.assessment.disclaimers[category] = '';
    };
    $scope.removeDisclaimer = function(category) {
      delete $scope.assessment.disclaimers[category];
    };
    // Close the modal and return the updated assessment
    $scope.save = function() {
      $modalInstance.close($scope.assessment);
    };
    // Dismiss the modal
    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';
angular.module('assessmentControllers').controller('FrequencyEditCtrl', [
  '$state',
  '$modal',
  'frequency',
  'assessmentSvc',
  'orderSvc',
  'alertService',
  function($state, $modal, frequency, assessmentSvc, orderSvc, alertService) {
    var vm = this;
    vm.isEdit = !!frequency;
    vm.frequency = frequency || {
      enableNotifications: true,
      frequencyType: 'CUSTOM',
      intervals: []
    };
    vm.newInterval = { interval: 0, required: false };
    vm.save = function() {
      assessmentSvc.saveFrequency(vm.frequency).then(
        function() {
          alertService.addAlert({
            message: 'Assessment Frequency Saved!',
            type: 'success'
          });
          $state.go('assessments.list');
        },
        function(err) {
          alertService.addAlert({
            message: 'Failed to save the Frequency: ' + err.statusText,
            type: 'danger'
          });
        }
      );
    };

    vm.removeInterval = function(interval) {
      var idx = vm.frequency.intervals.indexOf(interval);
      if (idx !== -1) {
        vm.frequency.intervals.splice(idx, 1);
      }
    };
    vm.checkValidInterval = function() {
      return !isInt(vm.newInterval.interval);
    };

    function isInt(value) {
      /*jslint bitwise: true */
      return (
        !isNaN(value) &&
        (function(x) {
          return (x | 0) === x;
        })(parseFloat(value))
      );
    }

    vm.addInterval = function() {
      vm.frequency.intervals.push({
        interval: vm.newInterval.interval,
        required: vm.newInterval.required
      });
      vm.newInterval.interval = 0;
      vm.newInterval.required = false;
    };
  }
]);

'use strict';

angular.module('assessmentControllers').controller('QuestionEditCtrl', [
  '$modalInstance',
  'uuidSvc',
  'question',
  'existingCategories',
  function($modalInstance, uuidSvc, question, existingCategories) {
    var vm = this;
    vm.existingCategories = existingCategories || [];
    vm.isEdit = angular.isDefined(question);
    vm.question = question || {
      questionText: '',
      questionType: '',
      activityId: null,
      scaleStartPosition: null,
      scaleSize: null
    };
    //existing question types used in API
    //TODO fetch from ActivityWidgetType
    vm.questionTypes = [
      'YES_NO',
      'TEXT_INPUT',
      'DROP_DOWN',
      'WPAI_SCALE',
      'CONFIG_SCALE'
    ];
    // Close the modal and return the section
    vm.save = function() {
      $modalInstance.close(vm.question);
    };
    // Dismiss the modal
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';

angular.module('assessmentControllers').service('assessmentSvc', [
  'Restangular',
  function(Restangular) {
    var assessments = Restangular.all('editor/assessments');
    var frequencies = Restangular.all('editor/assessments/frequencies');
    function save(assessment) {
      return assessments.post(assessment);
    }
    function get(shortName) {
      return assessments.one(shortName);
    }
    function saveFrequency(frequency) {
      return frequencies.post(frequency);
    }
    function getFrequency(id) {
      return frequencies.one(id);
    }
    function prettyPrintFrequency(frequency) {
      var formatIntervals =
        frequency.intervals.length === 1
          ? frequency.intervals[0].interval
          : _.reduce(frequency.intervals, function(result, i) {
              if (result.interval || result.interval === 0) {
                result = result.interval;
              }
              return result + ',' + i.interval;
            });
      return (
        frequency.id +
        ' - ' +
        frequency.frequencyType +
        ' - (' +
        formatIntervals +
        ' Days)'
      );
    }

    return {
      save: save,
      getAll: assessments.getList,
      get: get,
      getFrequencies: frequencies.getList,
      getFrequency: getFrequency,
      saveFrequency: saveFrequency,
      prettyPrintFrequency: prettyPrintFrequency
    };
  }
]);

(function() {
  'use strict';

  angular.module('coaching').controller('CoachEditCtrl', CoachEditCtrl);

  CoachEditCtrl.$inject = [
    '$modalInstance',
    'coachingSvc',
    'alertService',
    '$window',
    'coachData'
  ];

  function CoachEditCtrl(
    $modalInstance,
    coachingSvc,
    alertService,
    $window,
    coachData
  ) {
    var vm = this;

    vm.isNewCoach = coachData === null;
    vm.newUserId = null;

    vm.userId = null;
    vm.numberOfAssignedCoachingUsers = null;
    vm.name = '';
    vm.emailAddress = '';
    vm.biography = '';
    vm.profileImageUrl = '';
    vm.arenaApprovalNumber = '';
    vm.status = 'ACTIVE';
    vm.isTestCoach = false;

    _init();

    // vm methods
    //
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.save = function() {
      if (!_validateFormData(vm.isNewCoach)) {
        return;
      }

      const coachWillBecomeDisabled =
        vm.status === 'DISABLED' && coachData && coachData.status !== vm.status;

      if (coachWillBecomeDisabled) {
        if (
          !$window.confirm(
            'Disabling this coach will cause all of their users to ' +
              'be reassigned. Those assignments cannot be restored. Are you sure ' +
              'you want to disable this coach?'
          )
        ) {
          return;
        }
      }

      var newCoachData = {
        // Id null if is edit
        id: coachData ? coachData.id : null,
        // Use newUserId if is edit
        userId: vm.newUserId ? vm.newUserId : vm.userId,
        status: vm.status,
        biography: vm.biography,
        profileImageUrl: vm.profileImageUrl,
        arenaApprovalNumber: vm.arenaApprovalNumber,
        isTestCoach: vm.isTestCoach,
        scheduledLeave: vm.scheduledLeave
      };

      vm.form.processing = true;
      // Test profileImageUrl is valid and can load in an image tag
      _validateImage(
        newCoachData.profileImageUrl,
        () => {
          // Success
          // Save new/updated coach data
          _saveCoach(newCoachData);
        },
        () => {
          // Fail
          alertService.addAlert({
            message: 'Profile image URL invalid. Test URL in browser.',
            type: 'danger'
          });

          vm.form.processing = false;
        }
      );
    };

    // Private function(s)
    //
    function _init() {
      // Set models for coach edit
      _setCoachData();
    }

    function _setCoachData() {
      if (vm.isNewCoach) {
        return;
      }

      vm.numberOfAssignedCoachingUsers =
        coachData.numberOfAssignedCoachingUsers;
      vm.userId = coachData.userId;
      vm.name = coachData.name;
      vm.emailAddress = coachData.emailAddress;
      vm.biography = coachData.biography;
      vm.profileImageUrl = coachData.profileImageUrl;
      vm.arenaApprovalNumber = coachData.arenaApprovalNumber;
      vm.status = coachData.status;
      vm.isTestCoach = coachData.isTestCoach;
      vm.scheduledLeave = coachData.scheduledLeave
        ? {
            firstDate: coachData.scheduledLeave.firstDate,
            lastDate: coachData.scheduledLeave.lastDate
          }
        : null;
    }

    function _saveCoach(newCoachData) {
      vm.form.processing = true;

      coachingSvc
        .saveCoach(newCoachData)
        .then(
          data => {
            // Remove restangular extras
            data = data.plain();

            // Success
            alertService.addAlert({
              message: 'Coach saved',
              type: 'success'
            });
            $modalInstance.close();
          },
          err => {
            // Fail
            alertService.addAlert({
              message:
                'Coach unable to be saved. Reload and try again. Error: ' +
                err.data.errorCode +
                ': ' +
                err.data.message,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          vm.form.processing = false;
        });
    }

    function _validateImage(imageUrl, success, fail) {
      var img = new Image();
      img.onload = success;
      img.onerror = fail;
      img.src = imageUrl;
    }

    function _validateFormData(isNewCoach) {
      var isValid = true;

      // If isNewCoach validate newUserId instead of userId
      if (isNewCoach) {
        // newUserId
        if (!vm.newUserId || !_.isNumber(vm.newUserId)) {
          isValid = false;

          alertService.addAlert({
            message: 'Please input a valid myStrength userId',
            type: 'danger'
          });
        }
      } else {
        // userId
        if (!vm.userId || !_.isNumber(vm.userId)) {
          isValid = false;

          alertService.addAlert({
            message: 'users ID is not set. Reload and try again.',
            type: 'danger'
          });
        }
      }
      // biography
      if (!vm.biography || isEmpty(vm.biography)) {
        isValid = false;

        alertService.addAlert({
          message: 'Please input a biography for this coach',
          type: 'danger'
        });
      }
      // profileImageUrl
      if (!vm.profileImageUrl || isEmpty(vm.profileImageUrl)) {
        isValid = false;

        alertService.addAlert({
          message: 'Please input a valid profile Image URL for this coach',
          type: 'danger'
        });
      }
      // arenaApprovalNumber
      if (!vm.arenaApprovalNumber || isEmpty(vm.arenaApprovalNumber)) {
        isValid = false;

        alertService.addAlert({
          message: 'Please input an arena approval number for this coach',
          type: 'danger'
        });
      }
      // status
      if (!vm.status) {
        isValid = false;

        alertService.addAlert({
          message: 'Coach status incorrect. Reload and try again.',
          type: 'danger'
        });
      }

      return isValid;

      // Validation function(s)
      //

      function isEmpty(s) {
        if (!s || s.length === 0) {
          return true;
        }
        return s.trim().length === 0;
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('coaching')
    .controller('CoachingSettingsEditCtrl', CoachingSettingsEditCtrl);

  CoachingSettingsEditCtrl.$inject = [
    '$modalInstance',
    'coachingSvc',
    'alertService',
    'setting'
  ];

  function CoachingSettingsEditCtrl(
    $modalInstance,
    coachingSvc,
    alertService,
    setting
  ) {
    var vm = this;

    vm.isEdit = setting && setting !== null;
    vm.settingName = '';
    vm.assignmentNewUsers = 0;
    vm.assignmentExistingUsers = 0;

    _init();

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.save = function() {
      var validationStatus = _validateFormData();

      if (!validationStatus) {
        return;
      }

      var newCoachingSettings = {
        name: vm.settingName,
        assignmentPercentageNewUsers: vm.assignmentNewUsers,
        assignmentPercentageExistingUsers: vm.assignmentExistingUsers
      };

      // Add id if is edit
      if (vm.isEdit) {
        newCoachingSettings.id = setting.id;
      }

      vm.form.processing = true;

      coachingSvc
        .saveCoachingSettings(newCoachingSettings)
        .then(() => {
          alertService.addAlert({
            message: 'Coaching setting saved',
            type: 'success'
          });

          $modalInstance.close();
        })
        .finally(() => {
          vm.form.processing = false;
        });
    };

    function _init() {
      // Populate models for edit
      if (vm.isEdit) {
        vm.settingName = setting.name;
        vm.assignmentNewUsers = setting.assignmentPercentageNewUsers;
        vm.assignmentExistingUsers = setting.assignmentPercentageExistingUsers;
      }
    }

    function _validateFormData() {
      var isValid = true;

      // Name not falsy or empty
      if (!vm.settingName || vm.settingName === '') {
        isValid = false;

        alertService.addAlert({
          message: 'Please input a setting name',
          type: 'danger'
        });
      }
      // assignmentNewUsers not past bounds or empty
      if (
        vm.assignmentNewUsers < 0 ||
        vm.assignmentNewUsers > 100 ||
        vm.assignmentNewUsers === ''
      ) {
        isValid = false;

        alertService.addAlert({
          message:
            'Please input a valid percentage of new users to get coaching. Range: 0 - 100',
          type: 'danger'
        });
      }

      // assignmentExistingUsers not past bounds or empty
      if (
        vm.assignmentExistingUsers < 0 ||
        vm.assignmentExistingUsers > 100 ||
        vm.assignmentExistingUsers === ''
      ) {
        isValid = false;

        alertService.addAlert({
          message:
            'Please input a valid percentage of existing users to get coaching. Range: 0 - 100',
          type: 'danger'
        });
      }

      return isValid;
    }
  }
})();

(function() {
  'use strict';

  angular.module('coaching').controller('ManageCoachesCtrl', ManageCoachesCtrl);

  ManageCoachesCtrl.$inject = ['coachingSvc', '$modal', 'alertService'];

  function ManageCoachesCtrl(coachingSvc, $modal, alertService) {
    const vm = this;

    vm.coaches = [];
    _getCoaches();

    vm.editCoach = function(coachData) {
      const modal = coachingSvc.editCoach(coachData);

      modal.result.then(function() {
        // Update coaches list on modal close
        _getCoaches();
      });
    };

    vm.scheduleLeave = function(coachData) {
      const modal = _showEditLeaveModal(coachData.scheduledLeave);

      modal.result
        .then(
          function(leave) {
            const coachUpdate = {
              id: coachData.id,
              userId: coachData.userId,
              status: coachData.status,
              isTestCoach: coachData.isTestCoach,
              biography: coachData.biography,
              profileImageUrl: coachData.profileImageUrl,
              arenaApprovalNumber: coachData.arenaApprovalNumber,
              scheduledLeave: leave
            };
            return coachingSvc.saveCoach(coachUpdate);
          },
          function() {
            //Do nothing when modal is cancelled
          }
        )
        .then(
          function() {
            _getCoaches();
          },
          function(error) {
            const msg = error ? error.data.message : 'Unknown error';
            alertService.addAlert({
              message: `Could not schedule leave: ${msg}`,
              type: 'danger'
            });
          }
        );
    };

    function _getCoaches() {
      coachingSvc.getCoaches().then(coaches => {
        vm.coaches = coaches.plain().map(coach => {
          if (coach.scheduledLeave) {
            coach.scheduledLeave.summary = _getLeaveSummary(
              coach.scheduledLeave
            );
          }
          return coach;
        });
      });
    }

    function _getLeaveSummary(leave) {
      const hasLeaveData = leave.firstDate !== null || leave.lastDate !== null;
      //Technically this could only check the last date, but this helps catch
      //bad data if the last date is null
      const leaveIsOver =
        !hasLeaveData &&
        leave.firstDate < new Date() &&
        leave.lastDate < new Date();
      if (hasLeaveData && !leaveIsOver) {
        const startString = leave.firstDate
          ? new Date(leave.firstDate).toDateString()
          : 'N/A';
        const endString = leave.lastDate
          ? new Date(leave.lastDate).toDateString()
          : 'N/A';
        return `${startString} - ${endString}`;
      } else {
        return null;
      }
    }

    function _showEditLeaveModal(leave) {
      return $modal.open({
        templateUrl: 'coaching/templates/scheduleLeaveModal.html',
        controller: 'ScheduleLeaveModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          leave: function() {
            return leave;
          }
        }
      });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('coaching')
    .controller(
      'PartnerCollateralCoachingSettingsEditCtrl',
      PartnerCollateralCoachingSettingsEditCtrl
    );

  PartnerCollateralCoachingSettingsEditCtrl.$inject = [
    '$modalInstance',
    'coachingSvc',
    'alertService',
    'confirmService',
    'partnerCollateral'
  ];

  function PartnerCollateralCoachingSettingsEditCtrl(
    $modalInstance,
    coachingSvc,
    alertService,
    confirmService,
    partnerCollateral
  ) {
    var vm = this;

    vm.isEdit = partnerCollateral && partnerCollateral !== null;

    // For folder access level directive
    vm.accessLevel = 'ORGANIZATION';
    vm.orgSelected = null;
    vm.groupSelected = null;
    vm.codeSelected = null;
    vm.coachingSettings = [];
    // Multiple selections
    vm.orgSelection = [];
    vm.groupSelection = [];
    vm.codeSelection = [];
    // For access level directive edit
    vm.groupNameSelected = null;
    vm.accessCodeNameSelected = null;
    vm.orgNameSelected = null;
    // Form field models
    vm.editing = {
      coachingSetting: null
    };
    vm.recordsToAdd = null;

    vm.save = function() {
      var validationStatus = _validateFormData();
      var partnerCollateralId = null;

      // isEdit - only single records
      if (vm.isEdit) {
        // Set partnerCollateralId for org/group/ac
        if (vm.orgSelected) {
          partnerCollateralId = vm.orgSelected.partnerCollateralId;
          if (vm.groupSelected) {
            partnerCollateralId = vm.groupSelected.partnerCollateralId;
          } else if (vm.codeSelected) {
            partnerCollateralId = vm.codeSelected.partnerCollateralId;
          }
        }

        // Catch any issues before applying coachingSettings to  partnerCollateralId
        if (!validationStatus || !partnerCollateralId) {
          return;
        }

        vm.form.processing = true;

        coachingSvc
          .changeEntityCoachingSettings(
            true,
            partnerCollateralId,
            vm.editing.coachingSetting.id
          )
          .then(() => {
            alertService.addAlert({
              message: 'Coaching setting saved',
              type: 'success'
            });

            $modalInstance.close();
          })
          .finally(() => {
            vm.form.processing = false;
          });
      } else {
        // New enablement modal - possible multiple records
        if (vm.orgSelection.length > 0) {
          _addMultipleRecords(vm.orgSelection);
        } else if (vm.groupSelection.length > 0) {
          _addMultipleRecords(vm.groupSelection);
        } else if (vm.codeSelection.length > 0) {
          _addMultipleRecords(vm.codeSelection);
        }
      }
    };

    vm.delete = function() {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              if (vm.orgSelected && !vm.groupSelected && !vm.codeSelected) {
                return 'coaching settings from this access level';
              } else {
                return 'coaching settings from this access level. NOTE: coaching settings will inherit from organization, if set';
              }
            },
            item: function() {
              return '';
            }
          }
        })
        .then(function() {
          vm.form.processing = false;

          coachingSvc
            .changeEntityCoachingSettings(
              false,
              partnerCollateral.partnerCollateralId,
              null
            )
            .then(() => {
              $modalInstance.close();
            })
            .finally(() => {
              vm.form.processing = false;

              alertService.addAlert({
                message: 'Coaching setting removed',
                type: 'success'
              });
            });
        });
    };

    vm.cancel = function() {
      $modalInstance.close(false);
    };

    activate();

    function activate() {
      // Get coaching settings array
      coachingSvc.getAllCoachingSettings().then(settings => {
        // Remove restangular properties from data
        var coachingSettings = settings.map(item => {
          return item.plain();
        });

        vm.coachingSettings = coachingSettings;
        _setupFormEdit();
      });
    }

    function _setupFormEdit() {
      if (!vm.isEdit) {
        return;
      }

      // Setup edit form fields
      vm.accessLevel = partnerCollateral.accessLevel;
      vm.orgNameSelected = partnerCollateral.organizationName;

      // Set folder-access-level directive
      switch (partnerCollateral.accessLevel) {
        case 'GROUP':
          vm.groupNameSelected = partnerCollateral.groupName;
          break;
        case 'CODE':
          vm.accessCodeNameSelected = partnerCollateral.accessCode;
          break;
      }

      // Set existing coaching setting
      vm.coachingSettings.map(setting => {
        if (setting.id === partnerCollateral.coachingSettingsId) {
          vm.editing.coachingSetting = setting;
        }
      });
    }

    // Helper to validate form data
    function _validateFormData() {
      var isValid = true;

      // Validate coaching setting - is set and not empty
      if (!vm.editing.coachingSetting || vm.editing.coachingSetting === '') {
        isValid = false;
      }

      // Validate organization is set
      if (!vm.orgSelected || vm.orgSelected === '') {
        isValid = false;
      }

      return isValid;
    }

    // Helper to assign multiple coaching settings to multiple records
    // - Uses _addMultipleRecordsTick()
    function _addMultipleRecords(entityData) {
      vm.recordsToAdd = entityData.length;

      entityData.map((entity, index) => {
        var partnerCollateralId = entity.partnerCollateralId;

        coachingSvc
          .changeEntityCoachingSettings(
            true,
            partnerCollateralId,
            vm.editing.coachingSetting.id
          )
          .finally(() => {
            _addMultipleRecordsTick(index);
          });
      });
    }

    // Helper to wrangle the multiple async requests
    // - Adds alert and closes modal
    function _addMultipleRecordsTick(currentIndex) {
      if (vm.recordsToAdd === currentIndex + 1) {
        alertService.addAlert({
          message:
            vm.recordsToAdd === 1
              ? 'Coaching setting saved'
              : 'Coaching settings saved',
          type: 'success'
        });

        $modalInstance.close();
      }
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('coaching')
    .controller('ScheduleLeaveModalCtrl', ScheduleLeaveModalCtrl);

  ScheduleLeaveModalCtrl.$inject = ['$modalInstance', 'leave'];

  function ScheduleLeaveModalCtrl($modalInstance, leave) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    var vm = this;
    vm.isEditingLeave = leave !== null;
    vm.firstDate = leave && leave.firstDate ? new Date(leave.firstDate) : null;
    vm.firstDateMinimum = today;
    vm.lastDate = leave && leave.lastDate ? new Date(leave.lastDate) : null;

    vm.isValidLastDate = function(date) {
      const minimumDate = _calculateMinimumLastDate();
      const isValid = minimumDate <= date;
      return isValid;
    };

    vm.onFirstDateChange = function() {
      if (vm.firstDate > vm.lastDate) {
        vm.lastDate = null;
      }
    };

    vm.isLeaveValidToSubmit = function() {
      const bothDatesSelected = vm.firstDate && vm.lastDate;
      if (!bothDatesSelected) {
        return false;
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const firstDateIsNotAfterLastDate = !vm.firstDate.isAfter(vm.lastDate);
      const leaveIsNotInThePast = !vm.lastDate.isBefore(today);
      return firstDateIsNotAfterLastDate && leaveIsNotInThePast;
    };

    vm.isLeaveValidToClear = function() {
      return vm.isEditingLeave;
    };

    vm.submit = function() {
      $modalInstance.close({
        firstDate: vm.firstDate,
        lastDate: vm.lastDate
      });
    };

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.clear = function() {
      $modalInstance.close(null);
    };

    function _calculateMinimumLastDate() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (!vm.firstDate) {
        return today;
      } else if (vm.firstDate.getTime() <= today.getTime()) {
        return today;
      } else {
        return vm.firstDate;
      }
    }
  }
})();

'use strict';
angular.module('coaching').service('coachingSvc', [
  'Restangular',
  '$modal',
  function(Restangular, $modal) {
    var entitiesWithCoaching = Restangular.all('editor/coaching');
    var allCoachingSettings = Restangular.all('editor/coaching/settings');
    var allCoaches = Restangular.all('editor/coaching/coaches');

    function getCoachingEntities() {
      return entitiesWithCoaching.getList();
    }

    function getAllCoachingSettings() {
      return allCoachingSettings.getList();
    }

    function getCoaches() {
      return allCoaches.getList();
    }

    function changeEntityCoachingSettings(
      enableCoaching,
      partnerCollateralId,
      coachingSettingsId
    ) {
      if (enableCoaching) {
        // Change coaching_settings_id
        return Restangular.all(
          `editor/coaching/partners/${partnerCollateralId}/settings/${coachingSettingsId}`
        ).post();
      } else {
        // Delete coaching_settings_id
        return Restangular.all(
          `editor/coaching/partners/${partnerCollateralId}`
        ).remove();
      }
    }

    function saveCoachingSettings(setting) {
      return Restangular.all(`editor/coaching/settings`).post(setting);
    }

    function saveCoach(coachData) {
      return Restangular.all(`editor/coaching/coaches`).post(coachData);
    }

    function editPartnerCollateralCoachingSetting(partnerCollateral) {
      return $modal.open({
        templateUrl:
          'coaching/templates/partnerCollateralCoachingSettingsModal.html',
        controller: 'PartnerCollateralCoachingSettingsEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          partnerCollateral: function() {
            return partnerCollateral;
          }
        }
      });
    }

    function editCoachingSetting(setting) {
      return $modal.open({
        templateUrl: 'coaching/templates/coachingSettingsModal.html',
        controller: 'CoachingSettingsEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          setting: function() {
            return setting;
          }
        }
      });
    }

    function editCoach(coachData) {
      return $modal.open({
        templateUrl: 'coaching/templates/coachEditModal.html',
        controller: 'CoachEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          coachData: function() {
            return coachData;
          }
        }
      });
    }

    function removeCoachingAssignment(userId, coachId) {
      return Restangular.all(
        `editor/coaching/coaches/${coachId}/users/${userId}`
      ).remove();
    }

    function saveCoachingAssignment(userId, coachId) {
      return Restangular.all(
        `editor/coaching/coaches/${coachId}/users/${userId}`
      ).post();
    }

    return {
      // Edit prefix - modal
      editCoach: editCoach,
      editCoachingSetting: editCoachingSetting,
      editPartnerCollateralCoachingSetting: editPartnerCollateralCoachingSetting,
      // Get prefix - restangular GET
      getCoachingEntities: getCoachingEntities,
      getAllCoachingSettings: getAllCoachingSettings,
      getCoaches: getCoaches,
      // Setters below - restangular POST, PUT, DELETE
      changeEntityCoachingSettings: changeEntityCoachingSettings,
      saveCoachingSettings: saveCoachingSettings,
      saveCoach: saveCoach,
      removeCoachingAssignment: removeCoachingAssignment,
      saveCoachingAssignment: saveCoachingAssignment
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:ConfirmCtrl
 * @description
 * # ConfirmCtrl
 * This handles the ok/cancel navigation selections for a confirmation modal
 */
angular.module('myStrengthAdminApp').controller('ConfirmCtrl', [
  '$scope',
  '$modalInstance',
  'item',
  'confirmText',
  function($scope, $modalInstance, item, confirmText) {
    $scope.confirmText = confirmText;
    $scope.item = item;

    //close and return the item to the waiting promise
    $scope.ok = function() {
      $modalInstance.close($scope.item);
    };

    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';

angular.module('myStrengthAdminApp').controller('NavCtrl', [
  'domainSvc',
  'authInterceptor',
  function(domainSvc, authInterceptor) {
    var controller = this;

    controller.rootDomain = domainSvc.getAppUrl();
    controller.logoutUrl = domainSvc.getAppUrl() + '/logout';

    controller.isAuthorized = function(permissions) {
      return authInterceptor.hasPermission(permissions);
    };

    // if you'd like to add another category to the nav on the side, add it here.
    // The name will be displayed, the icon is a fontawesome icon name
    // each submenu must have a name and a location that they'll link to
    controller.categories = [
      {
        name: 'Partners',
        icon: 'fa-university',
        permissions: ['ACCOUNT_MANAGEMENT'],
        submenus: [
          {
            name: 'Organizations',
            location: 'organizations'
          },
          {
            name: 'Access Codes',
            location: 'codes'
          },
          {
            name: 'Metadata Components',
            location: 'metadata?context=PARTNER'
          },
          {
            name: 'Provider Connect',
            location: 'files'
          },
          {
            name: 'Coaching Enablement',
            location: 'coaching/enablement'
          },
          {
            name: 'Coaching Settings',
            location: 'coaching/settings'
          },
          {
            name: 'Redirects',
            location: 'redirects'
          },
          {
            name: 'SSO Identity Providers',
            location: 'sso/list'
          },
          {
            name: 'API Doc',
            location: 'apidoc'
          }
        ]
      },
      {
        name: 'Reporting',
        icon: 'fa-bar-chart',
        permissions: ['ACCOUNT_MANAGEMENT'],
        submenus: [
          {
            name: 'Reports',
            location: 'reports'
          }
        ]
      },
      {
        name: 'Users',
        icon: 'fa-users',
        permissions: ['USER_MANAGEMENT'],
        submenus: [
          {
            name: 'Users',
            location: 'users'
          },
          {
            name: 'Account Deletion Requests',
            location: 'deletionrequests'
          }
        ]
      },
      {
        name: 'Site Content',
        icon: 'fa-sitemap',
        permissions: ['EDITOR'],
        submenus: [
          {
            name: 'Activities',
            location: 'activities'
          },
          {
            name: 'Inspirations',
            location: 'activities/coreinspirations'
          },
          {
            name: 'Quizzes',
            location: 'quizzes'
          },
          {
            name: 'Programs & Series',
            location: 'activities/programs'
          },
          {
            name: 'Action Plans',
            location: 'actionplan'
          },
          {
            name: 'Tags',
            location: 'tags'
          },
          {
            name: 'Metadata Components',
            location: 'metadata?context=ACTIVITY'
          },
          {
            name: 'Train',
            location: 'activities/train'
          },
          {
            name: 'Quotes',
            location: 'quotes'
          },
          {
            name: 'Copy & Translations',
            location: 'copy'
          },
          {
            name: 'Redirects',
            location: 'redirects'
          }
        ]
      },
      {
        name: 'Coaching',
        icon: 'fa-map-signs',
        permissions: ['COACH_MANAGEMENT'],
        submenus: [
          {
            name: 'Manage Coaches',
            location: 'coaching/coaches'
          }
        ]
      },
      {
        name: 'Community Moderation',
        icon: 'fa-user-plus',
        permissions: ['COMMUNITY_MODERATION'],
        submenus: [
          {
            name: 'Inspirations',
            location: 'activities/communityinspirations'
          },
          {
            name: 'Discussions',
            location: 'discussions'
          }
        ]
      },
      {
        name: 'Configuration',
        icon: 'fa-cogs',
        permissions: ['SYSTEM_ADMIN'],
        submenus: [
          {
            name: 'Client Applications',
            location: 'systemconfiguration/client/applications'
          },
          {
            name: 'Client Actions',
            location: 'systemconfiguration/client/actions'
          },
          {
            name: 'Client Features',
            location: 'systemconfiguration/client/features'
          }
        ]
      },
      {
        name: 'System',
        icon: 'fa-cogs',
        permissions: ['SYSTEM_ADMIN'],
        submenus: [
          {
            name: 'Trackers',
            location: 'trackers'
          },
          {
            name: 'Assessments',
            location: 'assessments'
          },
          {
            name: 'Encryption Enablement',
            location: 'encryptionsettings/enablement'
          },
          {
            name: 'Email Failures',
            location: 'emailaudits'
          },
          {
            name: 'Email Send',
            location: 'email-send'
          },
          {
            name: 'Telehealth Providers',
            location: 'telehealth-providers/list'
          }
        ]
      }
    ];
  }
]);

angular.module('myStrengthAdminApp').controller('AlertCtrl', [
  '$scope',
  '$timeout',
  'alertService',
  function($scope, $timeout, alertService) {
    // when the route changes we remove non-persisting alerts
    $scope.$on('$stateChangeStart', function() {
      alertService.cleanup();
    });
    // this is called from alert service when the alerts are updated - see alertService
    alertService.setCallback(function() {
      $timeout(function() {
        $scope.alerts = alertService.getAlerts();
      });
    });
    // called when the X is clicked on an alert
    $scope.closeAlert = alertService.removeAlert;
  }
]);

angular.module('myStrengthAdminApp').directive('msAlerts', [
  function() {
    return {
      template:
        '<alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)">{{alert.message}}</alert>',
      restrict: 'AE',
      scope: {},
      controller: 'AlertCtrl'
    };
  }
]);

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:accessCodeList
 * @description This directive handles the access code list. This allows you
 * to move codes between the "available" codes list and the "active" codes list.
 */
angular.module('myStrengthAdminApp').directive('accessCodeList', function() {
  return {
    restrict: 'E',
    scope: {
      availableCodes: '=',
      activeCodes: '='
    },
    link: function(scope) {
      /**
       * Takes an access code off the available codes list and onto the active codes list
       */
      scope.addAccessCode = function(code) {
        // Move access code between lists
        scope.activeCodes.push(code);
        scope.availableCodes.splice(scope.availableCodes.indexOf(code), 1);
        // reset the typing box
        scope.currentCode = '';
      };
      /**
       * Move an access code from the active codes list and back onto the available codes list
       */
      scope.removeAccessCode = function(code) {
        scope.activeCodes.splice(scope.activeCodes.indexOf(code), 1);
        scope.availableCodes.push(code);
      };
    },
    templateUrl: 'common/templates/accesscodelist.html'
  };
});
/*
 * Get the typeahead to show the complete list on click, even without anything typed
 * http://stackoverflow.com/a/27331340/2108024
 */
angular.module('myStrengthAdminApp').directive('typeaheadFocus', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attr, ngModel) {
      element.bind('click', function() {
        var viewValue = ngModel.$viewValue;
        if (ngModel.$viewValue === ' ') {
          ngModel.$setViewValue(null);
        }
        ngModel.$setViewValue(' ');
        ngModel.$setViewValue(viewValue || ' ');
      });
      // need to have our filter accept blank space as a match
      scope.emptyOrMatch = function(actual, expected) {
        if (expected === ' ') {
          return true;
        }
        return actual.toLowerCase().indexOf(expected.toLowerCase()) > -1;
      };
    }
  };
});

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:msAccessLevel
 * @description This allows the user to select the access level
 */
angular
  .module('myStrengthAdminApp')
  .directive('msAccessLevel', function() {
    return {
      restrict: 'E',
      scope: {
        accessLevel: '=',
        codesSelected: '=',
        groupSelected: '=',
        availableCodes: '=',
        availableGroups: '=',
        orgAllowed: '=',
        isEdit: '='
      },
      link: function(scope) {
        // scrape selected info if the access level changes
        if (!scope.isEdit) {
          scope.$watch('accessLevel', function() {
            scope.codesSelected = [];
            scope.groupSelected = null;
          });
        }
      },
      replace: true,
      templateUrl: 'common/templates/accesslevel.html'
    };
  })
  /*
   * @name myStrengthAdminApp.filter:orderByAccessLevel
   * This filter will sort and array of objects with access levels. For an object to 'have' an
   * access level, it needs to have the following:
   * 'isDefault' boolean, true if the access level is Organization
   * 'accessCodeGroup' object, set if the access level is Group
   * 'accessCodes' list, set if the access level is Code
   * This puts org defaults on top, followed by groups (alphabetical) then access codes. The
   * access codes section is sorted by the highest access code in the object's list of codes.
   */
  .filter('orderByAccessLevel', [
    'orderByFilter',
    function(of) {
      var accessLevel = function(entity) {
        if (entity.isDefault) {
          // default is always first
          return 0;
        }
        if (entity.accessCodeGroup) {
          // these should be at the top of the list so prepend !
          return '!' + entity.accessCodeGroup.name;
        }
        var lastCode = 'zzz';
        if (entity.accessCodes) {
          entity.accessCodes.forEach(function(code) {
            if (lastCode > code.accessCode) {
              lastCode = code.accessCode;
            }
          });
        }
        return lastCode;
      };
      return function(stuff) {
        return of(stuff, accessLevel);
      };
    }
  ]);

'use strict';
/**
 * @ngdoc overview
 * @name blacklist
 * @description
 *
 * Based on http://stackoverflow.com/a/15090867/2108024
 *
 * This allows input fields to have a blacklist of invalid values. The blacklist should be an
 * array of values or objects.
 * Additionally, if blacklist-prop is defined, it will use that as the value to compare.
 * Example:
 * You have a list of objects that you don't want to have duplicates:
 * $scope.blacklist = [{name:'name1', otherProp:'prop1'}, {name:'NAME2', otherProp:'prop2'}]
 * You would want your DOM to look like this:
 * <input name="name" blacklist="blacklist" blacklist-prop="name">
 * <input name="otherProp" blacklist="blacklist" blacklist-prop="otherProp">
 * This would mark the input as invalid if they tried to enter name1 or name2 into the name input.
 * The blacklist is case-insensitive (BUG - see test).
 */
angular.module('myStrengthAdminApp').directive('blacklist', function() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      blacklist: '=',
      blacklistProp: '@'
    },
    link: function(scope, elem, attr, ngModel) {
      ngModel.$parsers.unshift(function(value) {
        var valid = true;
        if (typeof scope.blacklistProp !== 'undefined') {
          for (var i = 0; i < scope.blacklist.length; i++) {
            if (
              scope.blacklist[i][scope.blacklistProp] &&
              scope.blacklist[i][scope.blacklistProp].toLowerCase() ===
                value.toLowerCase()
            ) {
              valid = false;
              break;
            }
          }
        } else {
          valid = scope.blacklist.indexOf(value);
        }
        ngModel.$setValidity('blacklist', valid);
        return valid ? value : undefined;
      });
    }
  };
});

'use strict';
/**
 * @ngdoc overview
 * @name breadcrumb
 * @description
 *
 */
angular.module('myStrengthAdminApp').directive('msBreadcrumb', [
  'breadcrumbService',
  function(breadcrumbService) {
    return {
      restrict: 'E',
      template:
        '<h4>' +
        '<span style="text-transform: capitalize" ng-repeat="crumb in crumbs">' +
        '<span>' +
        '<a ui-sref="{{crumb.location}}">{{crumb.name}}</a> / ' +
        '</span>' +
        '</span>' +
        '</h4>',
      replace: true,
      link: function(scope) {
        scope.$on('$stateChangeSuccess', function() {
          scope.crumbs = breadcrumbService.getCrumb();
        });
      }
    };
  }
]);

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:msFormGroup
 * @description This creates form groups. msFormGroup should have a label attribute,
 * which will be displayed on the side and used to creation the "for" attribute.
 * Form groups will generally have an ms-form-input section for the input field,
 * and a ms-form-info which will have the help popup and any warnings. They can also
 * have a ms-form-save-button, which expects isEdit and form.processing to be used.
 */
angular
  .module('myStrengthAdminApp')
  .directive('msFormGroup', function() {
    return {
      restrict: 'EA',
      transclude: true,
      scope: {
        label: '@',
        useIndex: '='
      },
      templateUrl: 'common/templates/form/formgroup.html'
    };
  })
  /*
   * The input section of the form (textfields / radios / selectors ect)
   */
  .directive('msFormInput', function() {
    return {
      restrict: 'E',
      transclude: true,
      require: '^msFormGroup',
      template: '<div class="col-sm-6"><div ng-transclude></div>'
    };
  })
  /*
   * The information section of a form, including the help buttons and errors
   */
  .directive('msFormInfo', function() {
    return {
      restrict: 'EA',
      transclude: true,
      require: '^msFormGroup',
      template: '<div class="col-sm-4"><div ng-transclude></div>'
    };
  })
  /*
   * This wraps the content in ms-form-info, ms-help-popover and ms-what-is-this to create a very simple popup
   */
  .directive('msSimpleInfo', [
    function() {
      return {
        restrict: 'EA',
        transclude: true,
        template:
          '<ms-form-info><ms-help-popover><ms-what-is-this><div ng-transclude></div></ms-what-is-this></ms-help-popover></ms-form-info>'
      };
    }
  ])
  /*
   * Bootstrap style radio buttons. This takes the model to assign values to as well as an array
   * of "button" objects, which are simple objects containing a 'label' which is displayed on the
   * option and a value that is assigned to the model when that option is selected. Example:
   * [{label:'Text',value:'TEXT'},{label:'Dropdown',value:'DROP_DOWN'}]
   * if disabled, then the button will be disabled and only the selected value will be displayed.
   */
  .directive('msFormRadioButtons', function() {
    return {
      restrict: 'EA',
      transclude: true,
      scope: {
        model: '=',
        buttons: '=',
        disabled: '=',
        ngChange: '='
      },
      require: '^msFormGroup',
      templateUrl: 'common/templates/form/formradio.html'
    };
  })
  /*
   * A button that says either "create" or "update" depending on if it's editing. If
   * the form is marked as processing, it is disabled and gets a spinner
   */
  .directive('msFormSaveButton', [
    function() {
      return {
        restrict: 'EA',
        transclude: true,
        templateUrl: 'common/templates/form/formbutton.html',
        compile: function(ele, attrs) {
          if (!attrs.saveFn) {
            attrs.saveFn = 'save';
          }
          if (!attrs.isEdit) {
            attrs.isEdit = 'isEdit';
          }
        },
        scope: {
          saveFn: '=',
          isEdit: '='
        }
      };
    }
  ])
  /*
   * A checkbox that has the string values of 'true' and 'false' as their true and false values.
   * This is necessary for saving boolean values in the various statebags.
   */
  .directive('msStringCheckbox', [
    function() {
      return {
        transclude: true,
        scope: {
          msStringCheckbox: '='
        },
        link: function(scope, element, attrs) {
          // check to see if we should assign it the true value by default
          if ('defaultTrue' in attrs) {
            scope.msStringCheckbox = scope.msStringCheckbox || 'true';
          }
          if ('isDisabled' in attrs && attrs.isDisabled === 'true') {
            scope.isDisabled = true;
          }
        },
        templateUrl: 'common/templates/form/stringCheckbox.html'
      };
    }
  ])
  // Changes a label to lowercase alpha only
  .filter('lowerAlpha', function() {
    return function(value) {
      return !value ? '' : value.replace(/\W+/g, '').toLowerCase();
    };
  });

(function() {
  'use strict';

  /**
   * @ngdoc directive (modeled after accessCodeList)
   * @name myStrengthAdminApp.directive:groupList
   * @description This directive handles the access group group list. This allows you
   * to move codes between the "available" groups list and the "active" groups list.
   */
  angular.module('myStrengthAdminApp').directive('groupList', function() {
    return {
      restrict: 'E',
      scope: {
        availableGroups: '=',
        activeGroups: '='
      },
      link: function(scope) {
        scope.$watch(scope.availableGroups, function(newVal, oldValue) {
          //reset whenever groups change
          if (!_.isEmpty(oldValue)) {
            scope.activeGroups = [];
          }
        });
        /**
         * Takes a group off the available list and onto the active list
         */
        scope.addGroup = function(group) {
          // Move group between lists
          scope.activeGroups.push(group);
          scope.availableGroups.splice(scope.availableGroups.indexOf(group), 1);
          // reset the typing box
          scope.currentGroup = '';
        };
        /**
         * Move a group from the active list and back onto the available list
         */
        scope.removeGroup = function(group) {
          scope.activeGroups.splice(scope.activeGroups.indexOf(group), 1);
          scope.availableGroups.push(group);
        };
      },
      templateUrl: 'common/templates/grouplist.html'
    };
  });
})();

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:helpPopover
 * @description This creates a help popover.
 * When you hover over the element, a popover should appear with the transcluded contents.
 * You should probably use msWhatIsThis and msWhatIsValid to fill this out.
 */
angular.module('myStrengthAdminApp').directive('msHelpPopover', function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    link: function(scope, element) {
      // adjust height to make sure bubble lines up correctly
      var popover = element.find('#popover');
      popover.hide();
      element.mouseover(function() {
        popover.show();
        popover.css('top', 11 - popover.height() / 2);
      });
      element.mouseout(function() {
        popover.hide();
      });
    },
    templateUrl: 'common/templates/helppopover.html'
  };
});
/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:helpPopover msWhatIsThis
 * @description This creates the 'What is this' section of the help popover.
 * The content of this element will be used as the content of that section.
 */
angular.module('myStrengthAdminApp').directive('msWhatIsThis', function() {
  return {
    replace: true,
    transclude: true,
    restrict: 'E',
    template:
      '<span><h3 class="popover-title">What is this?</h3><div class="popover-content"><div ng-transclude></div></div></span>'
  };
});
/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:helpPopover msWhatIsValid
 * @description This creates the 'What is valid' section of the help popover.
 * The content of this element will be used as the content of that section.
 */
angular.module('myStrengthAdminApp').directive('msWhatIsValid', function() {
  return {
    replace: true,
    transclude: true,
    restrict: 'E',
    template:
      '<span><h3 class="popover-title">What is valid?</h3><div class="popover-content"><div ng-transclude></div></div></span>'
  };
});

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:invalidInput
 * @description This creates a warning message when the user has invalid input
 */
angular.module('myStrengthAdminApp').directive('invalidInput', [
  function() {
    return {
      template:
        '<div ng-show="formElement.$dirty && formElement.$error[errorType]" style="display: inline-block;">' +
        '<i class="fa fa-warning fa-2x error"></i>' +
        '<div class="popover right" placement="top" style="top: -10px; left: 75px; display: block;">' +
        '<div class="arrow"></div>' +
        '<div class="popover-inner">' +
        '<div class="popover-content">' +
        '<ng-transclude></ng-transclude>' +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>',
      replace: true,
      transclude: true,
      restrict: 'E',
      scope: {
        errorType: '@errorType',
        formElement: '='
      }
    };
  }
]);

(function() {
  'use strict';

  angular.module('myStrengthAdminApp').directive('msMetadata', metadata);

  metadata.$inject = [];

  /* @ngInject */
  function metadata() {
    var directive = {
      bindToController: true,
      controller: MetaDataCtrl,
      controllerAs: 'vm',
      templateUrl: 'common/templates/metadata.html',
      restrict: 'A',
      scope: {
        msAssociationId: '=',
        msAssociationType: '@msAssociationType'
      }
    };
    return directive;
  }

  MetaDataCtrl.$inject = [
    '$log',
    'MetadataService',
    '$mdDialog',
    '$q',
    'Restangular'
  ];

  /* @ngInject */
  function MetaDataCtrl($log, MetadataService, $mdDialog, $q, Restangular) {
    var vm = this;
    vm.recordList = [];
    vm.selectedComponent = [];

    vm.saveRecords = saveRecords;
    vm.deleteRecordConfirm = deleteRecordConfirm;
    vm.addComponent = addComponent;
    vm.addGroup = addGroup;
    vm.toggleComponents = toggleComponents;

    activate();

    /////////////////// public
    function activate() {
      $q.all([_fetchPartnerMetadata(), getPartnerComponentGroups()]).then(
        function(data) {
          vm.components = data[0];
          vm.groups = data[1];
          _fetchExistingRecords(vm.msAssociationType, vm.msAssociationId);
        }
      );
    }

    /**
     * Fetch all components that are stored in the db
     * @returns {*}
     * @private
     */
    function _fetchPartnerMetadata() {
      return MetadataService.fetchMetadataComponents('PARTNER').then(function(
        data
      ) {
        return data;
      });
    }

    function getPartnerComponentGroups() {
      MetadataService.fetchMetadataComponentGroup().then(function(data) {
        vm.groups = data;
      });
    }

    function toggleComponents() {
      if (vm.selectedComponent.length > 0) {
        vm.selectedComponent = [];
      } else {
        vm.selectedComponent = vm.components;
      }
    }

    /**
     * Fetch existing partner records for a given association type and association id
     * @param aType the association type (i.e. organizations, access_code_groups or access_codes)
     * @param aId the partner collateral id for this association
     * @private
     */
    function _fetchExistingRecords(aType, aId) {
      if (aType && aId) {
        MetadataService.fetchExistingPartnerRecords(aType, aId).then(function(
          data
        ) {
          // Initialize record list with newly fetched records
          vm.recordList = [];
          _.each(data, function(c) {
            var _existing = _getComponent(c.partnerMetadataId);
            // For existing record, get component and set value
            if (_existing) {
              var _record = angular.extend({}, _existing, c);
              if (_record.type === 'Date') {
                _record.value = new Date(c.value);
              } else {
                _record.value = c.value;
              }
              // Add record to list
              vm.recordList.push(_record);
              if (_record.uniqueComp) {
                // Remove populated component from selection list
                _splicePopulatedComponents(_record.partnerMetadataId);
              }
            }
          });
          $log.debug('Records loaded: ', vm.recordList);
        });
      }
    }

    /**
     * Add a component from a selection of available PartnerMetadata components
     */
    function addComponent() {
      _.each(Restangular.stripRestangular(vm.selectedComponent), function(c) {
        var _comp = _.clone(_getComponent(c.id));
        _processComponent(_comp);
      });
      vm.selectedComponent = [];
    }

    function addGroup() {
      var _group = _.clone(_getGroup(vm.selectedGroup));
      if (_.isUndefined(vm.selectedGroup)) {
        return;
      }
      _.each(_group.components, function(c) {
        var _comp = _.clone(_getComponent(c.id));
        if (_comp) {
          _processComponent(_comp);
        }
      });
    }

    /**
     * Create/Update a batch of PartnerMetadataValue records
     * @param records
     */
    function saveRecords(records) {
      MetadataService.saveRecords(
        records,
        vm.msAssociationType,
        vm.msAssociationId
      );
    }

    /**
     * Delete a record for the given id from the database
     * @param evt
     * @param id
     */
    function deleteRecordConfirm(evt, id) {
      var _record = _getRecord(id);
      var confirm = $mdDialog
        .confirm()
        .title('Would you like to data record "' + _record.displayName + '"?')
        .textContent('This action is not reversible!')
        .ariaLabel('Delete')
        .targetEvent(evt)
        .ok('Delete It')
        .cancel('No');
      $mdDialog.show(confirm).then(function() {
        MetadataService.deleteRecord(id).then(function() {
          _fetchPartnerMetadata().then(function(components) {
            vm.components = components;
            _fetchExistingRecords(vm.msAssociationType, vm.msAssociationId);
          });
        });
      });
    }

    ///////////////// private
    function _processComponent(comp) {
      if (comp.type === 'Text') {
        comp.value = comp.defaultText;
      }
      // When adding a component, the component id becomes the partner_metadata_id and id becomes
      // the partner_metadata_values id
      comp.partnerMetadataId = comp.id;
      comp.id = null;
      vm.recordList.push(comp);
      if (comp.uniqueComp) {
        _splicePopulatedComponents(comp.partnerMetadataId);
      }
    }

    function _splicePopulatedComponents(id) {
      vm.components.splice(
        _.findIndex(vm.components, function(elem) {
          return elem.id === id;
        }),
        1
      );
    }

    function _getComponent(id) {
      return _.find(vm.components, function(elem) {
        return elem.id === id;
      });
    }

    function _getGroup(id) {
      return _.find(vm.groups, function(group) {
        return group.id === id;
      });
    }

    function _getRecord(id) {
      return _.find(vm.recordList, function(elem) {
        return elem.id === id;
      });
    }
  }
})();

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:stringToNumber
 * @description Converts ng-model to a number so you can use type="number" with string field
 *              Current use case is with widget properties
 */
angular.module('myStrengthAdminApp').directive('stringToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(value) {
        return '' + value;
      });
      ngModel.$formatters.push(function(value) {
        return parseFloat(value);
      });
    }
  };
});

'use strict';

angular
  .module('myStrengthAdminApp')
  .filter('timestampToMilliseconds', function() {
    return function(timestamp) {
      return timestamp * 1000;
    };
  });

'use strict';

angular.module('myStrengthAdminApp').filter('truncate', function() {
  return function(s, count) {
    if (!count) {
      count = 50;
    }
    if (s && s.length > count && s.length !== count + 1) {
      return s.substring(0, count) + '…';
    } else {
      return s;
    }
  };
});

'use strict';
/**
 * @ngdoc overview
 * @name alertService
 * @description
 *
 * This keeps track of all the alerts that should be displayed on the page. Nav.js does the
 * work to actually display these errors, and is where the callback function should actually live.
 * The callback exists to tell nav.js when it should reload the alerts. It is called whenever there
 * is a change to the alerts list.
 *
 * To display an alert to the user, you should use the addAlert function. Do not push alerts manually to the list,
 * as they may not be displayed.
 * addAlert takes an alert object of the form:
 * {type: 'success || info || warning || danger', message: 'Message to display', persist: (ShouldKeepOnPageChange)}
 * Alerts are normally removed when the page changes. The persist setting will persist the alert over one view change.
 */
(function() {
  angular.module('myStrengthAdminApp').factory('alertService', alertService);
  // alertService was named before we started using 'Svc' suffix. This aliases the service out as alertSvc as well as alertService
  angular.module('myStrengthAdminApp').factory('alertSvc', [
    'alertService',
    function(alertService) {
      return alertService;
    }
  ]);

  alertService.$inject = ['$timeout', '$log'];

  /* @ngInject */
  function alertService($timeout, $log) {
    var svc = {
      getAlerts: getAlerts,
      addAlert: addAlert,
      success: success,
      error: error,
      removeAlert: removeAlert,
      cleanup: cleanup,
      setCallback: setCallback
    };
    var alerts = [];
    var cb = function() {};
    /**
     * Get a list of the currently active alerts
     */
    function getAlerts() {
      return alerts;
    }
    /**
     * Push an alert to the list, and run the callback to ensure it is displayed
     */
    function addAlert(alert) {
      alerts.push(alert);
      cb();
      // remove the alert after 5s
      $timeout(function() {
        alerts.splice(alerts.indexOf(alert), 1);
      }, alert.timeout || 5000);
    }
    /**
     * Push a success message
     * @param  {String} message The message to display to the user
     */
    function success(message) {
      addAlert({
        type: 'success',
        message: message || 'Success'
      });
    }
    /**
     * Display an error message
     * @param  {String} message The message to display. If this is an object, it will be treated as the cause
     * @param  {Object} cause The cause of the error, which is logged
     */
    function error(message, cause) {
      $log.error(message, cause);
      // if we only got a cause, build a default message
      if (typeof message === 'object') {
        if (
          message.hasOwnProperty('data') &&
          message.data.hasOwnProperty('message')
        ) {
          // if the response has a data object with a message property, we got a response from the API - use that as the message
          message = message.data.message;
        } else if (message.statusText && message.status) {
          // If we didn't have an API message coming back, just display the status text
          message =
            'A ' + message.status + ' error occurred: ' + message.statusText;
        } else {
          // Don't know what happened, generic message
          message = 'An unknown error occurred';
        }
      }
      addAlert({
        type: 'danger',
        message: message || 'An error occured'
      });
    }
    /**
     * Remove an alert from the list, and run the callback
     */
    function removeAlert(index) {
      alerts.splice(index, 1);
    }
    /**
     * Cleans up the alerts, removing any alerts that weren't meant to persist.
     * Any persisted alerts are marked for removal on the next cleanup.
     */
    function cleanup() {
      var i = alerts.length;
      while (i--) {
        if (alerts[i].persist) {
          alerts[i].persist = false;
        } else {
          alerts.splice(i, 1);
        }
      }
      cb();
    }
    /**
     * Set the callback method that will consume alerts
     */
    function setCallback(callback) {
      cb = callback;
    }
    return svc;
  }
})();

'use strict';

/**
 * @ngdoc overview
 * @name authInterceptor
 * @description
 *
 * This intercepts all http calls and if they're going to the API server,
 * redirects them to the correct URL and adds the auth token to the headers.
 * This only intercepts URLs that begin with API, leaving all others untouched.
 * If there's not a valid JWT then it will try to get one from the server - if
 * this fails it will display an error message, or redirect if the user has
 * just timed out
 */
(function() {
  var app = angular.module('myStrengthAdminApp');
  app.factory('authInterceptor', [
    '$window',
    '$cookies',
    '$q',
    'alertService',
    'domainSvc',
    function($window, $cookies, $q, alertService, domainSvc) {
      /**
       * Fetches the the session auth token from the cookie
       */
      function getToken() {
        return $cookies.get('token');
      }
      /**
       * @return True if the user has a token and the token is not expired and token indicates MFA token was verified
       */
      function isLoggedIn() {
        return !!getToken() && !tokenExpired() && isMFAVerified();
      }

      function isMFAVerified() {
        var userPerms = getPermissions(null);
        return userPerms.includes('MFA_VERIFIED');
      }

      /**
       * Get user permissions from token passed in or from previously stored token
       * @param {string} jwt (Optional) web token to check
       * @return null if token doesn't exist
       *         else, return array of available permissions
       */
      function getPermissions(jwt) {
        jwt = jwt || getToken();
        if (!jwt) {
          return [];
        }

        try {
          var perms = JSON.parse(atob(jwt.split('.')[1])).permissions;
          if (!tokenExpired(jwt) && angular.isArray(perms)) {
            return perms;
          }
        } catch (e) {
          return [];
        }
        return [];
      }

      /**
       * Check if permission(s) exist in token.
       * @param permissions an array of permissions to check
       * @returns true if user has any of the permission(s) asked for
       */
      function hasPermission(permissions) {
        if (!permissions || permissions.length === 0) {
          return true;
        }
        var userPerms = getPermissions(null);
        if (userPerms.includes('SYSTEM_MANAGEMENT')) {
          return true;
        }

        let hasPermission = false;

        permissions.forEach(function(permissionName) {
          if (userPerms.includes(permissionName)) {
            hasPermission = true;
          }
        });
        return hasPermission;
      }

      /**
       * Get the expiration of the current token
       * @param  {string} jwt (Optional) Web token to check for expiration
       * @return {Date}                  The time when the token will expire
       */
      function getExpiration(jwt) {
        jwt = jwt || getToken();
        if (!jwt) {
          return true;
        }
        try {
          // split the JWT into three chunks of base64 - decode the middle chunk, parse it as JSON
          // and extract the .exp value then multiply by 1000 to get MS. Make a date from it.
          return new Date(JSON.parse(atob(jwt.split('.')[1])).exp * 1000);
        } catch (e) {
          return true;
        }
      }
      /**
       * @param  {string} jwt (Optional) Web token to check for expiration
       * @return {boolean}               True if the token is expired
       */
      function tokenExpired(jwt) {
        return getExpiration(jwt) < new Date();
      }

      function shouldRefreshToken() {
        var tokenExpireDate = getExpiration();
        var tokenExpireTimestamp =
          tokenExpireDate !== null ? tokenExpireDate.getTime() : 0;
        var tokenCanRefreshTimestamp = new Date().getTime() + 300000; // 5min
        // Update token if not yet set OR expires within 5 min
        if (
          tokenExpireDate === null ||
          tokenExpireTimestamp <= tokenCanRefreshTimestamp
        ) {
          return true;
        }
        return false;
      }

      /**
       * Fetches the the Reauth token from the cookie
       */
      function getReauthToken() {
        return $cookies.get('reauth_token');
      }

      /**
       * @return {boolean} true if the reauth cookie exists
       */
      function hasReauthCookie() {
        return !_.isEmpty($cookies.get('reauth_token'));
      }

      var authService = {
        isLoggedIn: isLoggedIn,
        getToken: getToken,
        getReauthToken: getReauthToken,
        getExpiration: getExpiration,
        hasPermission: hasPermission,
        hasReauthCookie: hasReauthCookie,
        shouldRefreshToken: shouldRefreshToken,
        request: function(config) {
          if (config.url.indexOf('API') !== 0) {
            // If it doesn't start with API, don't intercept
            return config;
          }
          config.url = config.url.replace('API', domainSvc.getApiUrl());
          config.headers['x-session-token'] = getToken();
          return config;
        },
        response: function(response) {
          return response;
        },
        responseError: function(rejection) {
          if (rejection.status === 401) {
            alertService.addAlert({
              type: 'danger',
              message: 'Access denied - authorization failed'
            });
          }
          return $q.reject(rejection);
        }
      };
      return authService;
    }
  ]);
})();

'use strict';
/**
 * @ngdoc overview
 * @name breadcrumbService
 * @description
 *
 */
angular.module('myStrengthAdminApp').factory('breadcrumbService', [
  '$location',
  '$state',
  function($location, $state) {
    var breadcrumbService = {
      addCrumb: addCrumb,
      getCrumb: getCrumb
    };
    breadcrumbService.crumbCache = [];
    /**
     * Adds a crumb for the given location, or current location if none given.
     * This will replace any crumb that currently exists for that location.
     */
    function addCrumb(crumb, location) {
      if (typeof location === 'undefined') {
        location = $location.path();
      }
      for (var i = 0; i < breadcrumbService.crumbCache.length; i++) {
        if (breadcrumbService.crumbCache[i].location === location) {
          // replace the old crumb & we're done
          breadcrumbService.crumbCache[i].crumb = crumb;
          return;
        }
      }
      // the crumb doens't exist, push to end of list
      breadcrumbService.crumbCache.push({
        location: location,
        crumb: crumb
      });
    }
    /**
     * Find a crumb for the given location, or the current location if none is given.
     * This will first try and find a crumb from the cache, and if that fails it will
     * grab the default crumb.
     */
    function getCrumb(location) {
      if (typeof location === 'undefined') {
        location = $location.path();
      }
      return getCache(location) || getDefault(location);
    }
    /**
     * Attempt to find the crumb from the cache.
     */
    function getCache(location) {
      for (var i = 0; i < breadcrumbService.crumbCache.length; i++) {
        if (breadcrumbService.crumbCache[i].location === location) {
          return breadcrumbService.crumbCache[i].crumb;
        }
      }
    }
    /**
     * Builds a crumb based on the location. This will break out the path into pieces,
     * returning crumb that contains every part of the path with a link. Adds the crumb
     * to the cache when it's done.
     */
    function getDefault(location) {
      var state = $state.$current;
      var crumbList = [];
      while (angular.isDefined(state) && state.name !== '') {
        if (state.self.crumb) {
          var crumb = angular.copy(state.self.crumb);
          // replace any param values
          for (var key in $state.params) {
            crumb.name = crumb.name.replace(key, $state.params[key]);
          }
          crumbList.unshift(crumb);
        }
        // up the list
        state = state.parent;
      }
      // save in cache for next time
      breadcrumbService.addCrumb(crumbList, location);
      return crumbList;
    }
    return breadcrumbService;
  }
]);

'use strict';
/**
 * @ngdoc overview
 * @name confirmService
 * @description This provides centralized access to confirmation modal prompts
 */
angular.module('myStrengthAdminApp').factory('confirmService', [
  '$timeout',
  '$modal',
  function($timeout, $modal) {
    var confirmService = {};
    //the default config is a large confirm template with std controller
    confirmService.defaultConfig = {
      size: 'lg',
      templateUrl: 'common/templates/confirm.html',
      controller: 'ConfirmCtrl'
    };
    /**
     * Show a confirmation modal
     */
    confirmService.show = function(config) {
      //extend the defaults with any config passed in
      var resolvedConfig = angular.extend(
        {},
        confirmService.defaultConfig,
        config
      );
      confirmService.modal = $modal.open(resolvedConfig);
      //return a promise on the result
      return confirmService.modal.result;
    };

    return confirmService;
  }
]);

'use strict';
/**
 * @ngdoc overview
 * @name domainSvc
 * @description
 *
 * A domain name utility service for building fully qualified URLs that
 * are environment specific.
 */
(function() {
  var app = angular.module('myStrengthAdminApp');
  const localhostLocations = {
    api: 'http://localhost:9080',
    webUI: 'http://localhost:5050'
  };
  app.factory('domainSvc', [
    '$location',
    function($location) {
      var rootRegex = /([^.]*\.)?(.*\.[^:]*):?[0-9]*/;
      var nonProdPrefixRegex = /(.*\-)+([^.]*\.).*\.[^:]*:?[0-9]*/; //split the first group on the dash

      return {
        getRootDomain: getRootDomain,
        getCurrentDomain: getCurrentDomain,
        getDomainPrefix: getDomainPrefix,
        getLoginUrl: getLoginUrl,
        getAppUrl: getAppUrl,
        getRelativeUrl: getRelativeUrl,
        getApiUrl: getApiUrl
      };

      function isLocalhost() {
        return $location.host() === 'localhost';
      }

      function getRootDomain() {
        var host = $location.host();
        var matches = rootRegex.exec(host);
        var domain =
          matches && matches.length === 3 ? matches[2] : host.split(':')[0];
        return domain;
      }

      function getCurrentDomain() {
        return $location.host();
      }

      function getDomainPrefix() {
        var host = $location.host();
        var matches = nonProdPrefixRegex.exec(host);
        var prefix = matches && matches.length === 3 ? matches[2] : '';
        return prefix;
      }

      function getRelativeUrl(relativeUrl) {
        return 'https://' + getDomainPrefix() + getRootDomain() + relativeUrl;
      }

      function getLoginUrl(redirect) {
        return getAppUrl() + '/login?redirecturl=' + redirect;
      }

      function getAppUrl() {
        if (isLocalhost()) {
          return localhostLocations.webUI;
        }
        return buildUrlForSubDomain('web-ui');
      }

      function getApiUrl() {
        if (isLocalhost()) {
          return localhostLocations.api;
        }
        return buildUrlForSubDomain('api');
      }

      function buildUrlForSubDomain(subDomain) {
        let resultDomain =
          'https://' + $location.host().replace('admin', subDomain);
        if (
          endsWith(resultDomain, '.dev') &&
          $location.port() !== '80' &&
          $location.port() !== '443'
        ) {
          //preserve the port number for *.mystrength.dev local locations, useful for kubernetes ingress routing
          return resultDomain + ':' + $location.port();
        } else {
          return resultDomain;
        }
      }

      function endsWith(searchIn, searchFor) {
        if (searchFor.length > searchIn.length) {
          return false;
        }
        return (
          searchIn.substring(searchIn.length - searchFor.length) === searchFor
        );
      }
    }
  ]);
})();

(function() {
  'use strict';

  angular.module('myStrengthAdminApp').factory('httpSvc', httpSvc);

  httpSvc.$inject = [
    '$http',
    '$q',
    '$cookies',
    '$state',
    'alertService',
    'domainSvc',
    'authInterceptor'
  ];

  /**
   * A simple service that wraps $http with some default .then actions
   */
  function httpSvc(
    $http,
    $q,
    $cookies,
    $state,
    alertService,
    domainSvc,
    authInterceptor
  ) {
    const X_SESSION_TOKEN = 'x-session-token';
    const X_REAUTH_TOKEN = 'x-reauth-token';
    const X_SESSION_TOKEN_ISSUED = 'x-session-token-issued';

    var isReauthing = false;
    var service = {
      get: get,
      post: post,
      delete: del,
      reauth: reauth
    };
    return service;

    ////////////////
    /**
     * Call through to $http.get, and on a response we extract res.data. Alert or reauth on error
     */
    function get() {
      let params = cloneArgs.apply(null, arguments);
      return $http.get.apply(null, arguments).then(
        function(res) {
          return res.data;
        },
        err => {
          return error(err, get, params);
        }
      );
    }
    /**
     * Call through to $http.post.  Alert or reauth on error
     * @return {[type]} [description]
     */
    function post() {
      let params = cloneArgs.apply(null, arguments);
      return $http.post.apply(null, arguments).then(
        function(res) {
          alertService.success();
          return res.data;
        },
        err => {
          return error(err, post, params);
        }
      );
    }
    /**
     * Delete an object.  Alert or reauth on error
     */
    function del() {
      if (!window.confirm('Are you sure you want to delete?')) {
        return $q.reject('User cancelled action');
      }
      let params = cloneArgs.apply(null, arguments);
      return $http.delete.apply(null, arguments).then(
        function(res) {
          alertService.success('Deleted');
          return res.data;
        },
        err => {
          return error(err, del, params);
        }
      );
    }

    /**
     * Update the token. This will also store the token in the cookie to ensure it survives a page refresh.
     * @param  {string}  jwt The new token
     * @param  {boolean} forceTokenRefresh if true will force a token refresh
     * @return {boolean} true if the token is set successfully, false if not
     */
    function setToken(jwt) {
      if (jwt) {
        $cookies.put('token', jwt, {
          domain: domainSvc.getRootDomain(),
          path: '/',
          expires: authInterceptor.getExpiration(jwt)
        });
        // make sure we can retrieve the token
        return authInterceptor.getToken() === jwt;
      }

      return true;
    }

    /**
     * Update or set the reauth token cookie. This will store the reauth token in a cookie to ensure it survives a page refresh.
     * @param  {string}  jwt The new reauth token
     * @return {boolean} true if the token is set successfully, false if not
     */
    function setReauthToken(jwt) {
      if (jwt) {
        $cookies.put('reauth_token', jwt, {
          domain: domainSvc.getRootDomain(),
          path: '/'
        });
        // make sure we can retrieve the token
        return authInterceptor.getReauthToken() === jwt;
      }
      return true;
    }

    /**
     * Performs a reauth API call to reset the tokens for this application
     * @param {string} timezone
     */
    function reauth() {
      if (authInterceptor.hasReauthCookie() && !isReauthing) {
        var reauthView = {
          reauthToken: authInterceptor.getReauthToken()
        };
        // I'm using this as a quasi mutex to avoid multiple reauths while one is resolve it's promise
        // the scenario for this is the loading of a page such as an Organization detail, which makes a
        // boatload of API calls concurrently without appropriately daisy-chaining the promises
        isReauthing = true;
        return $http
          .post('API/public/reauth/web', reauthView)
          .then(_verifyLoggedInUserResponse, () => {
            $cookies.remove('reauth_token', {
              domain: domainSvc.getRootDomain(),
              path: '/'
            });
          })
          .then(() => {
            // Reload the current state
            alertService.addAlert({
              type: 'success',
              message: 'ReAuth Successful - Page reloading...',
              persist: true
            });
            $state.reload();
          });
      }
      return $q.reject();
    }

    /**
     * Handles the validation of the reauth response object
     * @param {Object} response
     */
    function _verifyLoggedInUserResponse(response) {
      var jwtToken = response.headers(X_SESSION_TOKEN);
      var issued = response.headers(X_SESSION_TOKEN_ISSUED);
      var reauthToken = response.headers(X_REAUTH_TOKEN);
      var authResponse = response.data;
      isReauthing = false;
      if (!jwtToken) {
        return {
          error: {
            message: 'An unknown error has occurred',
            contact: true
          }
        };
      }
      if (!setToken(jwtToken) || !setReauthToken(reauthToken)) {
        return {
          error: {
            message: 'Cookies must be enabled to use myStrength'
          }
        };
      }
      // If login returns pwExpired skip ahead to pw change redirect
      if (authResponse.pwExpired) {
        return response;
      }
      if (!verifyTokenIssued(issued)) {
        return {
          error: {
            message: `Your computer's time appears incorrect - please verify it and try again`
          }
        };
      }
      return response;
    }

    /**
     * @param  {string} issued  the JWT issue date to verify against the local time.
     * @return {boolean}               true if the issued time is within timeout range.
     */
    function verifyTokenIssued(issued) {
      var parsedIssueDate = new Date(parseInt(issued)),
        parsedUpperBound = new Date(new Date().getTime() + 600000),
        parsedLowerBound = new Date(new Date().getTime() - 600000);
      return (
        parsedIssueDate <= parsedUpperBound &&
        parsedIssueDate >= parsedLowerBound
      );
    }

    /**
     * clone the arguments into an array for spreadability in replays
     */
    function cloneArgs() {
      let params = [];
      if (!angular.isUndefined(arguments[0])) {
        [].push.call(params, arguments[0]);
      }
      if (!angular.isUndefined(arguments[1])) {
        [].push.call(params, arguments[1]);
      }
      if (!angular.isUndefined(arguments[2])) {
        [].push.call(params, arguments[2]);
      }
      return params;
    }

    /**
     * Generic error handler
     */
    function error(err, fn, params) {
      if (
        err.status === 401 &&
        err.data.errorCode &&
        (err.data.errorCode === 'TOKEN_EXPIRED' ||
          err.data.errorCode === 'ACCESS_DENIED')
      ) {
        if (authInterceptor.hasReauthCookie()) {
          return reauth().then(() => {
            //recurse and try again
            return fn.apply(null, params);
          });
        }
      }
      //always reject if we didn't hit the retry case - the reject will be ignored in a windowNavigate scenario (401)
      alertService.error(err);
      return $q.reject(err);
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('myStrengthAdminApp')
    .service('MetadataService', MetadataService);

  MetadataService.$inject = ['$log', 'Restangular', 'alertService'];

  /* @ngInject */
  function MetadataService($log, Restangular, alertService) {
    this.fetchMetadataComponents = fetchMetadataComponents;
    this.saveComponent = saveComponent;
    this.saveMetadataGroup = saveMetadataGroup;
    this.deleteComponent = deleteComponent;
    this.deleteRecord = deleteRecord;
    this.saveRecords = saveRecords;
    this.fetchExistingPartnerRecords = fetchExistingPartnerRecords;
    this.fetchMetadataComponentGroup = fetchMetadataComponentGroup;
    this.listDeleteVerify = listDeleteVerify;

    ////////////////

    /**
     * Fetch all PartnerMetadata components that are stored in the db
     * @returns {*}
     */
    function fetchMetadataComponents(context) {
      return Restangular.one(
        '/editor/metadata/components' + (!!context ? '?context=' + context : '')
      )
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error(
              'There was a problem retrieving metadata components: ',
              err
            );
          }
        );
    }

    /**
     * Fetch existing partner records for a given association type and association id
     * @param associationType the association type (i.e. organizations, access_code_groups or access_codes)
     * @param associationId the partner collateral id for this association
     * @returns {*}
     */
    function fetchExistingPartnerRecords(associationType, associationId) {
      return Restangular.one(
        '/editor/metadata/partner',
        associationType.toUpperCase()
      )
        .one('', associationId)
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem saving record: ', err);
          }
        );
    }

    function saveMetadataGroup(group) {
      return Restangular.one('/editor/metadata/components/group')
        .customPOST(group)
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem saving component group: ', err);
          }
        );
    }

    function fetchMetadataComponentGroup() {
      return Restangular.one('/editor/metadata/components/group')
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a fetching component group records: ', err);
          }
        );
    }

    /**
     * Create/Update a ParnterMetadata component
     * @param component
     * @returns {*}
     */
    function saveComponent(component) {
      $log.debug('savePartnerMetadata: ', component);
      return Restangular.one('/editor/metadata/components')
        .customPOST(component)
        .then(
          function() {
            alertService.addAlert({
              message: 'Partner metadata component saved!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message:
                'Failed to save organization metadata component: ' +
                err.statusText,
              type: 'danger'
            });
          }
        );
    }

    /**
     * Delete a PartnerMetadata component for the given id
     * @param id
     * @returns {*}
     */
    function deleteComponent(id) {
      return Restangular.one('/editor/metadata/components', id)
        .remove()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem deleting data component: ', err);
          }
        );
    }

    /**
     * Delete a PartnerMetadataValue record for the given id
     * @param id
     * @returns {*}
     */
    function deleteRecord(id) {
      return Restangular.one('/editor/metadata/partner', id)
        .remove()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem deleting data record: ', err);
          }
        );
    }

    /**
     * This will return a list of tuples representing existing values associated to a component. This is used to validate
     * if a list item can be deleted from a metadata component.
     * @param component The component to be checked
     * @param value The value that was requested to be deleted.
     * @returns {*}
     */
    function listDeleteVerify(component, value) {
      return Restangular.one('/editor/metadata/components/verify')
        .customGET(component.id, { value: value })
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem verifying data component: ', err);
          }
        );
    }

    /**
     * Create/Update a batch of PartnerMetadataValue records
     * @param records collection of PartnerMetadataValue objects to save
     * @param associationType the association type (i.e. organizations, access_code_groups or access_codes)
     * @param associationId the partner collateral id for this association
     * @returns {*}
     */
    function saveRecords(records, associationType, associationId) {
      var _entries = [];
      _.each(records, function(r) {
        _entries.push({
          id: r.id,
          partnerMetadataId: r.partnerMetadataId,
          value: r.value
        });
      });
      return Restangular.one('/editor/metadata/partner', associationType)
        .one('', associationId)
        .customPOST(_entries)
        .then(
          function(resp) {
            alertService.addAlert({
              message: 'Partner metadata record(s) saved!',
              type: 'success'
            });
            return resp;
          },
          function(err) {
            alertService.addAlert({
              message:
                'Failed to save organization metadata record: ' +
                err.statusText,
              type: 'danger'
            });
            $log.error(err.data.message);
          }
        );
    }
  }
})();

'use strict';

angular.module('myStrengthAdminApp').service('notificationSettingsSvc', [
  'Restangular',
  '$http',
  '$log',
  function(Restangular, $http, $log) {
    function getMandrillTemplates() {
      return Restangular.one('editor/config')
        .get()
        .then(function(config) {
          if (config.mandrillKey) {
            return $http
              .post('https://mandrillapp.com/api/1.0/templates/list.json', {
                key: config.mandrillKey
              })
              .then(function(resp) {
                return resp.data;
              });
          } else {
            $log.error('No mandrill key configured for this environment.');
          }
        });
    }

    return {
      getMandrillTemplates: getMandrillTemplates
    };
  }
]);

'use strict';
angular.module('myStrengthAdminApp').service('orderSvc', [
  function() {
    function getPosVar(item) {
      if (item.hasOwnProperty('positionOrdinal')) {
        return 'positionOrdinal';
      }
      return 'position';
    }

    function move(item, direction, array) {
      var positionVar = getPosVar(item);
      var position = item[positionVar];
      var other = array.filter(function(i) {
        return position + direction === i[positionVar];
      })[0];
      item[positionVar] = other[positionVar];
      other[positionVar] = position;
    }

    function remove(item, array) {
      var idx = array.indexOf(item);
      if (idx !== -1) {
        var positionVar = getPosVar(item);
        var pos = item[positionVar];
        array.splice(idx, 1);
        array.forEach(function(other) {
          if (other[positionVar] > pos) {
            other[positionVar]--;
          }
        });
      }
    }
    return {
      move: move,
      remove: remove
    };
  }
]);

'use strict';

/**
 * @ngdoc overview
 * @name sessionService
 * @description
 *
 * This keeps track of the variables used for the session - currently the domain
 * to resolve API requests to, and the session token to send over.
 */
(function() {
  var app = angular.module('myStrengthAdminApp');
  app.factory('sessionService', [
    function() {
      var sessionService = {
        apiDomain: '',
        token: '',
        expiration: 0
      };
      return sessionService;
    }
  ]);
})();

'use strict';

angular.module('activityControllers').service('uuidSvc', [
  function() {
    function newUuid() {
      return window.uuid.v4();
    }

    return {
      newUuid: newUuid
    };
  }
]);

'use strict';
/**
 * @ngdoc overview
 * @name windowSvc
 * @description
 *
 * This serves as a wrapper svc around window.location redirects
 * with the explicit use of mocking in mind for testing.
 */
(function() {
  var app = angular.module('myStrengthAdminApp');
  app.factory('windowSvc', [
    '$window',
    function($window) {
      function _windowNavigate(destinationUrl) {
        $window.location.href = destinationUrl;
      }

      return {
        windowNavigate: _windowNavigate
      };
    }
  ]);
})();

(function() {
  'use strict';

  angular.module('copy').controller('CopyListCtrl', CopyListCtrl);

  CopyListCtrl.$inject = ['$stateParams', '$state', 'copySvc', 'copy'];

  /* @ngInject */
  function CopyListCtrl($stateParams, $state, copySvc, copy) {
    var vm = this;
    vm.title = 'CopyListCtrl';
    vm.isObject = angular.isObject;
    vm.update = update;
    vm.changeLang = changeLang;
    vm.keyPattern = /^([\w\:]+\.?)+\w$/;

    activate();

    ////////////////

    function activate() {
      vm.copy = copy;
      vm.lang = $stateParams.language || 'en-US';
    }

    function update(fullKey, val) {
      var translation = {
        language: vm.lang,
        key: fullKey,
        value: val
      };
      copySvc.update(translation);
    }

    function changeLang(language) {
      $state.go('.', { language: language });
    }
  }
})();

(function() {
  'use strict';

  angular.module('copy').service('copySvc', copySvc);

  copySvc.$inject = ['httpSvc'];

  /* @ngInject */
  function copySvc(httpSvc) {
    return {
      getCopy: getCopy,
      update: update
    };

    function getCopy(language) {
      return httpSvc.get(
        'API/public/translations' + (language ? '?lang=' + language : '')
      );
    }

    function update(translation) {
      return httpSvc.post('API/editor/translations', translation);
    }
  }
})();

'use strict';

angular
  .module('discussion')
  .controller('DiscussionListCtrl', [
    '$modal',
    '$sce',
    'domainSvc',
    'discussionSvc',
    function($modal, $sce, domainSvc, discussionSvc) {
      var vm = this;
      vm.flaggedComments = {};
      vm.recentListOffset = 0;
      vm.recentListPageSize = 10;

      function init() {
        //clear the buckets
        vm.flaggedComments.OTHER = [];
        vm.flaggedComments.HARMFUL = [];
        vm.flaggedComments.BAD_ADVICE = [];
        vm.flaggedComments.OFFENSIVE = [];
        // reload the lists
        discussionSvc.getFlagged().then(function(res) {
          res.forEach(function(comment) {
            vm.flaggedComments[comment.flag].push(comment);
          });
          // get the list of recent discussions
          discussionSvc
            .getRecentApprovedDiscussions(
              vm.recentListOffset,
              vm.recentListPageSize
            )
            .then(function(res) {
              vm.recentUnflaggedDiscussions = res;
            });
        });
      }
      init();
      vm.nextPage = function() {
        vm.recentListOffset = vm.recentListOffset + vm.recentListPageSize;
        // get the list of recent discussions
        discussionSvc
          .getRecentApprovedDiscussions(
            vm.recentListOffset,
            vm.recentListPageSize
          )
          .then(function(res) {
            vm.recentUnflaggedDiscussions = vm.recentUnflaggedDiscussions.concat(
              res
            );
          });
      };
      // view an individual discussion
      vm.view = function(comment) {
        var modal = $modal.open({
          templateUrl: 'discussions/templates/discussionDetail.html',
          controller: 'DiscussionDetailCtrl',
          windowClass: 'xl-dialog',
          controllerAs: 'vm',
          resolve: {
            discussion: function() {
              return discussionSvc.getDiscussionForComment(comment);
            }
          }
        });
        modal.result.then(function() {
          init();
        });
      };
    }
  ])
  .controller('DiscussionDetailCtrl', [
    '$modalInstance',
    'discussionSvc',
    'discussion',
    function($modalInstance, discussionSvc, discussion) {
      var vm = this;
      vm.discussion = discussion;
      vm.moderate = function(comment, status) {
        // save the new status
        comment.status = status;
        discussionSvc.saveStatus(comment);
      };

      vm.done = function() {
        $modalInstance.close(vm.discussion);
      };
    }
  ]);

'use strict';

angular.module('activityControllers').service('discussionSvc', [
  '$q',
  'Restangular',
  function($q, Restangular) {
    var responses = Restangular.all('editor/discussion');

    function saveStatus(discussion) {
      return responses.all('status').post(discussion);
    }

    function getFlagged() {
      return $q
        .all([
          responses.all('status/FLAGGED').getList(),
          responses.all('status/AUTOMODERATED').getList()
        ])
        .then(function(res) {
          return res[0].concat(res[1]);
        });
    }

    function getRecentApprovedDiscussions(offset, size) {
      return responses.all('recent/' + offset + '/' + size).getList();
    }

    function getDiscussionForComment(comment) {
      var id = comment.parentId || comment.id;
      return responses
        .one('comment')
        .all(id)
        .getList();
    }

    return {
      saveStatus: saveStatus,
      getFlagged: getFlagged,
      getRecentApprovedDiscussions: getRecentApprovedDiscussions,
      getDiscussionForComment: getDiscussionForComment
    };
  }
]);

'use strict';

angular.module('emailControllers').controller('EmailAuditListCtrl', [
  'emailAuditSvc',
  function(emailAuditSvc) {
    var vm = this;
    vm.audits = [];
    vm.page = 0;
    vm.$onInit = fetchMore;
    vm.fetchMore = fetchMore;

    function fetchMore() {
      emailAuditSvc
        .findUnsentEmails(vm.page++)
        .then(audits => (vm.audits = vm.audits.concat(audits)));
    }
  }
]);

'use strict';

angular.module('emailControllers').controller('EmailSendCtrl', EmailSendCtrl);
EmailSendCtrl.$inject = ['$scope', 'emailSendSvc'];

function EmailSendCtrl($scope, emailSendSvc) {
  var vm = this;

  // Default merge variable values
  const mergeVariables = {
    general: {
      firstName: 'String',
      url: 'URL',
      settingsUrl: 'URL',
      year: new Date().getFullYear()
    },
    referral: {
      referrerName: 'String',
      accessCode: 'String',
      orgDisplayName: 'String',
      partnerBrandingUrl: 'URL',
      partnerBrandingNumber: 'String'
    },
    coaching: {
      coachName: 'String',
      coachImageUrl: 'URL',
      coachImageNumber: 'String'
    },
    mfa: {
      code: 'Number',
      minsToExpiration: 'Number'
    },
    challenge: {
      token: 'String',
      domain: 'String'
    }
  };

  // Associate mergeVariables to emailTypes
  const emailTypeToDefaultMergeVariables = {
    DEFAULT: { ...mergeVariables.general },
    PASSWORD_RESET: { ...mergeVariables.general },
    PASSWORD_RESET_DISABLED: { ...mergeVariables.general },
    SSO_RETURNING: { ...mergeVariables.general },
    MULTIFACTOR_AUTH: { ...mergeVariables.general, ...mergeVariables.mfa },
    GOOGLE_AUTH_SETUP: { ...mergeVariables.general },
    REFERRAL: { ...mergeVariables.general, ...mergeVariables.referral },
    ASSESSMENT_REMINDER: { ...mergeVariables.general },
    SLEEP_DIARY_REMINDER: { ...mergeVariables.general },
    NEW_COACHING_MESSAGE: {
      ...mergeVariables.general,
      ...mergeVariables.coaching
    },
    CHALLENGE_ANGER: { ...mergeVariables.general, ...mergeVariables.challenge },
    CHALLENGE_CALM: { ...mergeVariables.general, ...mergeVariables.challenge },
    CHALLENGE_COMPASSION: {
      ...mergeVariables.general,
      ...mergeVariables.challenge
    },
    CHALLENGE_ESTEEM: {
      ...mergeVariables.general,
      ...mergeVariables.challenge
    },
    CHALLENGE_FOCUS: { ...mergeVariables.general, ...mergeVariables.challenge },
    CHALLENGE_GRATITUDE: {
      ...mergeVariables.general,
      ...mergeVariables.challenge
    },
    CHALLENGE_GRIEF: { ...mergeVariables.general, ...mergeVariables.challenge },
    CHALLENGE_INTRO: { ...mergeVariables.general, ...mergeVariables.challenge },
    CHALLENGE_MOTION: {
      ...mergeVariables.general,
      ...mergeVariables.challenge
    },
    CHALLENGE_PARENTING: {
      ...mergeVariables.general,
      ...mergeVariables.challenge
    }
  };

  // VM variables
  vm.mergeVariablesTextAreaInvalid = false;
  vm.emailSendView = {
    emailAddress: null,
    emailType: null,
    iteration: 1,
    bhVersion: 'MY1_0',
    useProvidedValues: false
    // Merge variables added at send time
  };
  vm.emailTypes = [];
  vm.mergeVariablesTextArea = JSON.stringify(
    vm.emailSendView.mergeVariables,
    null,
    2
  );
  vm.emailSendPayload = null;

  // Controller methods
  const methods = {
    init() {
      this.getEmailTypes();
      this.watchEmailType();
    },
    getEmailTypes() {
      emailSendSvc.getEmailTypes().then(resp => {
        vm.emailTypes = resp;
      });
    },
    watchEmailType() {
      $scope.$watch(
        'vm.emailSendView.emailType',
        emailType => {
          this.setMergeVariablesForEmailType(emailType);
        },
        true
      );
    },
    setMergeVariablesForEmailType(emailType) {
      if (emailType === null) {
        return;
      }

      const emailTypeMergeVars = emailTypeToDefaultMergeVariables[emailType];

      if (typeof emailTypeMergeVars !== 'undefined') {
        vm.mergeVariablesTextArea = JSON.stringify(emailTypeMergeVars, null, 2);
      } else {
        // Default set fallback
        console.warn(
          'Unsupported emailType: ',
          emailType,
          ' Consider updating emailSendCtrl'
        );
        vm.mergeVariablesTextArea = JSON.stringify(
          emailTypeToDefaultMergeVariables['DEFAULT'],
          null,
          2
        );
      }
    },
    sendEmail() {
      const useProvidedValues = vm.emailSendView.useProvidedValues;
      if (
        useProvidedValues &&
        !methods.isJsonString(vm.mergeVariablesTextArea)
      ) {
        vm.mergeVariablesTextAreaInvalid = true;
        return;
      }

      // Reset validation
      vm.mergeVariablesTextAreaInvalid = false;
      vm.emailSendResponse = null;

      // Send payload
      // - If useProvidedValues, add merge variables
      const emailSendPayload = useProvidedValues
        ? {
            ...vm.emailSendView,
            ...JSON.parse(vm.mergeVariablesTextArea)
          }
        : vm.emailSendView;

      // Send email
      emailSendSvc.sendEmail(emailSendPayload).then(resp => {
        vm.emailSendResponse = resp;
      });
    },
    isJsonString(string) {
      try {
        JSON.parse(string);
      } catch (e) {
        console.log('string: ', string);
        console.log('parse exception: ', e);
        return false;
      }
      return true;
    }
  };

  /////////////////////////

  // VM methods
  vm.sendEmail = methods.sendEmail;

  // Init
  methods.init();
}

'use strict';
angular.module('emailControllers').service('emailAuditSvc', [
  'Restangular',
  'alertService',
  function(Restangular, alertService) {
    this.findUnsentEmails = function(page, limit) {
      return Restangular.one(
        '/admin/emailaudit?limit=' + (limit || 100) + '&page=' + (page || 0)
      )
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function() {
            alertService.addAlert({
              message: 'Failed to load',
              type: 'danger'
            });
          }
        );
    };
  }
]);

'use strict';

angular.module('emailControllers').service('emailSendSvc', emailSendSvc);

emailSendSvc.$inject = ['httpSvc'];

function emailSendSvc(httpSvc) {
  const getEmailTypes = () => {
    return httpSvc.get('API/admin/emailtypes');
  };
  const sendEmail = payload => {
    return httpSvc.post('API/admin/sendemail', payload);
  };

  return { getEmailTypes, sendEmail };
}

(function() {
  'use strict';

  angular
    .module('encryption')
    .controller('EditEncryptionAssignmentCtrl', EditEncryptionAssignmentCtrl);

  EditEncryptionAssignmentCtrl.$inject = [
    '$modalInstance',
    'encryptionSvc',
    'orgSvc',
    'confirmService',
    'alertService',
    'assignment'
  ];

  function EditEncryptionAssignmentCtrl(
    $modalInstance,
    encryptionSvc,
    orgSvc,
    confirmService,
    alertService,
    assignment
  ) {
    var vm = this;

    vm.availableEncryptionSettings = [];
    vm.availableOrganizations = [];
    vm.isEdit = typeof assignment !== 'undefined' && assignment !== null;

    vm.selectedOrganization = null;
    vm.selectedEncryptionSettings = null;

    _loadData().then(() => {
      if (vm.isEdit) {
        _setupFormForEdit(assignment);
      }
    });

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.save = function() {
      var validationStatus = vm.isFormValid();
      if (!validationStatus) {
        return;
      }

      vm.form.processing = true;

      var selectedEncryptionSettingsId = vm.selectedEncryptionSettings.id;
      var selectedPartnerCollateralId = null;
      if (vm.isEdit) {
        selectedPartnerCollateralId = assignment.partnerCollateralId;
      } else {
        selectedPartnerCollateralId = _getSelectedPartnerCollateralId();
      }

      encryptionSvc
        .saveEncryptionAssignment(
          selectedPartnerCollateralId,
          selectedEncryptionSettingsId
        )
        .then(
          () => {
            $modalInstance.close();
            alertService.addAlert({
              message: 'Encryption settings assignment saved',
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertService.addAlert({
              message: `Failed to save encryption assignment: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          vm.form.processing = false;
        });
    };

    vm.delete = function() {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              return 'assigned encryption settings from this organization';
            },
            item: function() {
              return '';
            }
          }
        })
        .then(function() {
          vm.form.processing = true;
          var selectedPartnerCollateralId = assignment.partnerCollateralId;

          encryptionSvc
            .deleteEncryptionAssignment(selectedPartnerCollateralId)
            .then(
              () => {
                $modalInstance.close();
                alertService.addAlert({
                  message: 'Encryption settings assignment removed',
                  type: 'success'
                });
              },
              error => {
                let message = error ? error.data.message : 'Unknown error';
                alertService.addAlert({
                  message: `Failed to remove encryption settings assignment: ${message}`,
                  type: 'danger'
                });
              }
            )
            .finally(() => {
              vm.form.processing = false;
            });
        });
    };

    vm.isFormValid = function() {
      var isValid = true;

      if (
        !vm.selectedEncryptionSettings ||
        vm.selectedEncryptionSettings === ''
      ) {
        isValid = false;
      }

      if (!vm.selectedOrganization || vm.selectedOrganization === '') {
        isValid = false;
      }

      return isValid;
    };

    function _setupFormForEdit(assignment) {
      vm.isEdit = true;

      vm.selectedEncryptionSettings = vm.availableEncryptionSettings.find(
        function(settings) {
          return settings.id === assignment.encryptionSettingsId;
        }
      );

      vm.selectedOrganization = vm.availableOrganizations.find(function(org) {
        return org.name === assignment.organizationName;
      });

      return true;
    }

    function _loadData() {
      var loadOrgs = orgSvc
        .getAll()
        .then(function(orgs) {
          return orgs.map(item => {
            return item.plain();
          });
        })
        .then(function(orgs) {
          vm.availableOrganizations = _.sortBy(orgs, 'name');
        });

      var loadSettings = encryptionSvc
        .getAllAvailableSettings()
        .then(settings => {
          var encryptionSettings = settings.map(item => {
            return item.plain();
          });

          vm.availableEncryptionSettings = encryptionSettings;
        });
      return Promise.all([loadOrgs, loadSettings]);
    }

    function _getSelectedPartnerCollateralId() {
      var partnerCollateralId = null;
      if (vm.selectedOrganization) {
        partnerCollateralId = vm.selectedOrganization.partnerCollateralId;
      }
      return partnerCollateralId;
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('encryption')
    .controller('EditEncryptionSettingsCtrl', EditEncryptionSettingsCtrl);

  EditEncryptionSettingsCtrl.$inject = [
    '$modalInstance',
    'encryptionSvc',
    'alertService'
  ];

  function EditEncryptionSettingsCtrl(
    $modalInstance,
    encryptionSvc,
    alertService
  ) {
    var vm = this;

    vm.keyToAdd = null;

    vm.name = '';
    vm.encryptionMethod = '';
    vm.keyIds = [];

    vm.addKey = function(newKey) {
      if (vm.keyIds.includes(newKey)) {
        alertService.addAlert({
          message: 'Cannot have duplicate keys for the same settings',
          type: 'danger'
        });
        return;
      }

      vm.keyIds.push(newKey);
      vm.keyToAdd = null;
    };

    vm.removeKey = function(oldKey) {
      vm.keyIds = vm.keyIds.filter(key => key !== oldKey);
    };

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.save = function() {
      var validationStatus = _validateFormData();

      if (!validationStatus) {
        return;
      }

      var newEncryptionSettings = {
        name: vm.name,
        encryptionMethod: vm.encryptionMethod,
        keyIds: vm.keyIds
      };

      vm.form.processing = true;

      encryptionSvc
        .saveEncryptionSettings(newEncryptionSettings)
        .then(
          () => {
            alertService.addAlert({
              message: 'Encryption settings saved',
              type: 'success'
            });
          },
          error => {
            alertService.addAlert({
              message: `Failed to save encryption settings: ${error.data.message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          vm.form.processing = false;
          $modalInstance.close();
        });
    };

    function _validateFormData() {
      var isValid = true;

      // Name not falsy or empty
      if (!vm.name || vm.name === '') {
        isValid = false;

        alertService.addAlert({
          message: 'Please input a setting name',
          type: 'danger'
        });
      }

      // Must have the correct amount of keys and keys cannot be falsy
      if (!vm.keyIds) {
        isValid = false;

        alertService.addAlert({
          message: 'Invalid keys',
          type: 'danger'
        });
      } else {
        if (vm.encryptionMethod === 'ENCODING' && vm.keyIds.length !== 1) {
          isValid = false;

          alertService.addAlert({
            message: 'Encoding requires exactly 1 key',
            type: 'danger'
          });
        }

        if (vm.encryptionMethod === 'KMS' && vm.keyIds.length < 1) {
          isValid = false;

          alertService.addAlert({
            message: 'KMS encryption requires at least 1 key',
            type: 'danger'
          });
        }

        if (vm.keyIds.some(key => !key)) {
          isValid = false;

          alertService.addAlert({
            message: 'all keys must be non-empty',
            type: 'danger'
          });
        }
      }

      return isValid;
    }
  }
})();

(function() {
  'use strict';
  /**
   * @ngdoc function
   * @name myStrengthAdminApp.controller:EncryptionEnablementCtrl
   * @description
   * # EncryptionEnablementCtrl
   * This edits the encryption settings assigned to a given organization
   */
  angular
    .module('encryption')
    .controller('EncryptionEnablementCtrl', EncryptionEnablementCtrl);

  EncryptionEnablementCtrl.$inject = ['encryptionSvc'];

  function EncryptionEnablementCtrl(encryptionSvc) {
    var vm = this;
    _loadData();

    vm.openEditEncryptionSettingsModal = function() {
      var modal = encryptionSvc.openEditEncryptionSettingsModal();
      modal.result.then(function() {
        _loadData();
      });
    };

    vm.openEditEncryptionAssignmentModal = function(assignment) {
      var modal = encryptionSvc.openEditEncryptionAssignmentModal(assignment);
      modal.result.then(function() {
        _loadData();
      });
    };

    function _loadData() {
      encryptionSvc.getAllAvailableSettings().then(settings => {
        vm.encryptionsettings = settings
          .map(setting => {
            return setting.plain();
          })
          .sort((a, b) => {
            var nameA = a.name.toUpperCase();
            var nameB = b.name.toUpperCase();

            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          });
      });

      encryptionSvc.getAllAssignments().then(assignments => {
        vm.encryptionassignments = assignments
          .map(assignment => {
            return assignment.plain();
          })
          .sort((a, b) => {
            var nameA = a.organizationName.toUpperCase();
            var nameB = b.organizationName.toUpperCase();

            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
          });
      });
    }
  }
})();

'use strict';
angular.module('encryption').service('encryptionSvc', [
  'Restangular',
  '$modal',
  function(Restangular, $modal) {
    var encryptionSettingsAssignments = Restangular.all(
      'editor/encryption/assignments'
    );
    var availableEncryptionSettings = Restangular.all(
      'editor/encryption/settings'
    );

    function getAllAvailableSettings() {
      return availableEncryptionSettings.getList();
    }

    function saveEncryptionSettings(settings) {
      return Restangular.all('editor/encryption/settings').post(settings);
    }

    function openEditEncryptionSettingsModal() {
      return $modal.open({
        templateUrl: 'encryption/templates/editEncryptionSettingsModal.html',
        controller: 'EditEncryptionSettingsCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {}
      });
    }

    function getAllAssignments() {
      return encryptionSettingsAssignments.getList();
    }

    function saveEncryptionAssignment(
      partnerCollateralId,
      encryptionSettingsId
    ) {
      return Restangular.all(
        `editor/encryption/assignments/${partnerCollateralId}`
      ).post(encryptionSettingsId);
    }

    function deleteEncryptionAssignment(partnerCollateralId) {
      return Restangular.all(
        `editor/encryption/assignments/${partnerCollateralId}`
      ).remove();
    }

    function openEditEncryptionAssignmentModal(assignment) {
      return $modal.open({
        templateUrl: 'encryption/templates/editEncryptionAssignmentModal.html',
        controller: 'EditEncryptionAssignmentCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          assignment: function() {
            return assignment;
          }
        }
      });
    }

    return {
      getAllAvailableSettings: getAllAvailableSettings,
      saveEncryptionSettings: saveEncryptionSettings,
      openEditEncryptionSettingsModal: openEditEncryptionSettingsModal,
      getAllAssignments: getAllAssignments,
      saveEncryptionAssignment: saveEncryptionAssignment,
      deleteEncryptionAssignment: deleteEncryptionAssignment,
      openEditEncryptionAssignmentModal: openEditEncryptionAssignmentModal
    };
  }
]);

'use strict';

angular.module('files').controller('FileEditCtrl', [
  '$state',
  'alertService',
  'categories',
  'file',
  'fileSvc',
  function($state, alertService, categories, file, fileSvc) {
    var vm = this;
    vm.file = file;
    vm.isEdit = file;
    vm.categories = categories;
    vm.editCategory = function() {
      fileSvc.editCategory(vm.file.categoryId);
    };
    vm.save = function() {
      fileSvc.updateFile(vm.file).then(
        () => {
          alertService.addAlert({
            message: 'File saved',
            type: 'success'
          });
        },
        () => {
          alertService.addAlert({
            message: 'An error occured',
            type: 'error'
          });
        }
      );
    };
    vm.delete = function() {
      fileSvc.deleteFile(vm.file.id).then(function() {
        $state.go('files.list');
      });
    };
  }
]);

(function() {
  'use strict';
  angular
    .module('files')
    .controller('FileFolderGroupCtrl', FileFolderGroupCtrl);

  FileFolderGroupCtrl.$inject = ['fileSvc', 'alertSvc', '$modal'];

  function FileFolderGroupCtrl(fileSvc, alertSvc, $modal) {
    var vm = this;
    vm.showModal = false;

    vm.$onInit = () => {
      getGroups();
    };

    vm.createGroup = () => {
      if (!isValidName(vm.newGroupName)) {
        return;
      }
      fileSvc
        .saveCategoryGroup({ id: 0, name: vm.newGroupName })
        .then(function() {
          vm.newGroupName = null;
          alertSvc.addAlert({
            type: 'success',
            message: 'Folder Group has been created'
          });
          getGroups();
        });
    };

    vm.editGroup = group => {
      var modal = $modal.open({
        templateUrl: 'files/templates/fileFolderGroupEdit.html',
        controller: [
          'group',
          'fileSvc',
          '$modalInstance',
          function(group, fileSvc, $modalInstance) {
            var vm = this;
            vm.group = group;
            vm.save = () => {
              if (!isValidName(group.name)) {
                return;
              }
              fileSvc.saveCategoryGroup(group).then(function() {
                $modalInstance.close('ok');
              });
            };
            vm.cancel = () => {
              $modalInstance.close();
            };
          }
        ],
        controllerAs: 'vm',
        resolve: { group: _.clone(group) }
      });
      modal.result.then(result => {
        if (result) {
          alertSvc.addAlert({
            type: 'success',
            message: 'Group has been updated'
          });
          getGroups();
        }
      });
    };

    function isValidName(newName) {
      if (_.isEmpty(newName)) {
        alertSvc.addAlert({
          type: 'danger',
          message: 'Group Name cannot be blank'
        });
        return false;
      }

      var match = _.find(vm.folderGroups, function(group) {
        return group.name.toLowerCase() === newName.toLowerCase();
      });
      if (match) {
        alertSvc.addAlert({
          type: 'danger',
          message: 'Group Name cannot be the same as an existing name'
        });
        return false;
      }
      return true;
    }

    vm.deleteGroup = function(id) {
      fileSvc.deleteCategoryGroup(id).then(function() {
        alertSvc.addAlert({
          type: 'success',
          message: 'Group has been deleted'
        });
        getGroups();
      });
    };

    function getGroups() {
      fileSvc.getCategoryGroups().then(function(data) {
        vm.folderGroups = data;
      });
    }
  }
})();

'use strict';

angular.module('files').controller('FileListCtrl', [
  'categories',
  'files',
  '$stateParams',
  '$state',
  function(categories, files, $stateParams, $state) {
    var vm = this;
    vm.files = files;
    vm.categories = categories;
    vm.categoryMap = categories.reduce(function(res, item) {
      res[item.id] = item.name;
      return res;
    }, {});
    vm.limit = 20;
    vm.orderType = 'modifiedOn';
    vm.orderReverse = true;
    vm.$onInit = function() {
      if (!_.isEmpty($stateParams.folder)) {
        var match = _.find(vm.categories, {
          id: parseInt($stateParams.folder)
        });
        if (match) {
          vm.categoryFilter = match.id;
        }
      }
    };

    vm.categoryChange = function() {
      $state.transitionTo(
        'files.list',
        { folder: vm.categoryFilter },
        { notify: false }
      );
    };
  }
]);

(function() {
  'use strict';

  angular.module('files').controller('FilesPreviewCtrl', FilesPreviewCtrl);

  FilesPreviewCtrl.$inject = ['fileSvc', 'alertSvc'];

  /* @ngInject */
  function FilesPreviewCtrl(fileSvc, alertSvc) {
    var vm = this;
    vm.accessLevel = '';
    vm.orgSelected = null;
    vm.codeSelected = null;
    vm.groupSelected = null;

    vm.search = function() {
      if (isValidInput()) {
        getCategories().then(function(data) {
          vm.results = data.plain();
        });
      }
    };

    function isValidInput() {
      if (!vm.orgSelected) {
        alertSvc.addAlert({
          type: 'error',
          message: 'An organization must be selected'
        });
        return false;
      }
      if (vm.accessLevel === 'GROUP' && !vm.groupSelected) {
        alertSvc.addAlert({
          type: 'error',
          message: 'A group must be selected'
        });
        return false;
      }

      if (vm.accessLevel === 'CODE' && !vm.codeSelected) {
        alertSvc.addAlert({
          type: 'error',
          message: 'An access code must be selected'
        });
        return false;
      }
      return true;
    }

    function getCategories() {
      switch (vm.accessLevel) {
        case 'ORGANIZATION':
          return fileSvc.getFilesForOrganization(vm.orgSelected.id);
        case 'GROUP':
          return fileSvc.getFilesForGroup(
            vm.orgSelected.id,
            vm.groupSelected.id
          );
        case 'CODE':
          return fileSvc.getFilesForAccessCode(
            vm.orgSelected.id,
            vm.codeSelected.id
          );
      }
    }
  }
})();

(function() {
  'use strict';

  angular.module('files').controller('FolderEditCtrl', FolderEditCtrl);

  FolderEditCtrl.$inject = [
    '$modalInstance',
    'category',
    'categories',
    'categoryGroups',
    'fileSvc',
    'alertService'
  ];

  function FolderEditCtrl(
    $modalInstance,
    category,
    categories,
    categoryGroups,
    fileSvc,
    alertService
  ) {
    var vm = this;
    vm.isEdit = !!category;
    // the category we're currently editing
    vm.editing = category || {
      partners: []
    };
    vm.categoryGroups = categoryGroups;
    // get all the categories for the blacklist
    vm.allCategories = categories;
    // fields for access level directive
    vm.accessLevel = 'ORGANIZATION';
    vm.orgSelected = null;
    vm.orgSelection = [];
    vm.codeSelection = [];
    vm.groupSelection = [];
    activate();

    function activate() {
      if (category) {
        vm.accessLevel = category.accessLevel;
        switch (category.accessLevel) {
          case 'ORGANIZATION':
            vm.orgSelection = _.clone(vm.editing.partners);
            break;
          case 'GROUP':
            vm.groupSelection = _.clone(vm.editing.partners);
            break;
          case 'CODE':
            vm.codeSelection = _.clone(vm.editing.partners);
            break;
        }
      }
    }

    function setPartners() {
      vm.editing.accessLevel = vm.accessLevel;
      switch (vm.accessLevel) {
        case 'ORGANIZATION':
          vm.editing.partners = _.map(vm.orgSelection, mapPartner);
          break;
        case 'GROUP':
          vm.editing.partners = _.map(vm.groupSelection, mapPartner);
          break;
        case 'CODE':
          vm.editing.partners = _.map(vm.codeSelection, mapPartner);
          break;
      }
    }

    function mapPartner(p) {
      var orgId = vm.accessLevel === 'ORGANIZATION' ? p.id : vm.orgSelected.id;
      return {
        id: p.id,
        name: p.name,
        partnerCollateralId: p.partnerCollateralId,
        organizationId: orgId
      };
    }

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
    // Save the category
    vm.save = function() {
      vm.form.processing = true;
      setPartners();
      fileSvc
        .saveCategory(vm.editing)
        .then(function() {
          alertService.addAlert({
            message: 'Folder saved',
            type: 'success'
          });
          $modalInstance.close();
        })
        .finally(function() {
          vm.form.processing = false;
        });
    };

    // Delete this category
    vm.delete = function() {
      if (window.confirm('Delete category?')) {
        fileSvc.deleteCategory(category.id).then(function() {
          $modalInstance.close();
          alertService.addAlert({
            message: 'Folder deleted',
            type: 'warn'
          });
        });
      }
    };
  }
})();

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:msAccessLevel
 * @description This allows the user to select the access level. This can be used in two modes:
 *  single-select or multi-select.
 */
angular
  .module('myStrengthAdminApp')
  .directive('msFolderAccessLevel', function() {
    return {
      restrict: 'E',
      scope: {
        //@param {string} The access level [ORGANIZATION|GROUP|CODE]
        accessLevel: '=',
        //@param {bool} whether multiple of selected type can be selected
        allowMultipleSelections: '=',
        //@param {Object} the selected organization
        orgSelected: '=',
        //@param {Object} the group selected if accessLevel = GROUP
        groupSelected: '=',
        //@param {Object} the code selected if accessLevel = CODE
        codeSelected: '=',
        /** the following are used if allowMultipleSelections = true **/
        //@param {array} the list of orgs selected
        orgSelection: '=',
        //@param {array} the list of groups selected if accessLevel = GROUP
        groupSelection: '=',
        //@param {array} the list of codes selected if accessLevel = CODE
        codeSelection: '=',
        //@param {string} a org name to match in list and set
        orgNameSelected: '=',
        //@param {string} a group name to match in list and set
        groupNameSelected: '=',
        //@param {string} a access code to match in list and set
        accessCodeNameSelected: '='
      },
      bindToController: true,
      controller: FolderAccessLevelCtrl,
      controllerAs: 'vm',
      replace: true,
      templateUrl: 'files/templates/folderAccessLevel.html'
    };
  });

FolderAccessLevelCtrl.$inject = ['$scope', 'orgSvc', '$q'];

function FolderAccessLevelCtrl($scope, orgSvc, $q) {
  var vm = this;
  vm.availableOrgs = [];
  vm.availableGroups = [];
  vm.availableCodes = [];
  vm.orgOrderReverse = false;
  vm.orgOrderType = 'name';

  vm.$onInit = () => {
    if (!vm.accessLevel) {
      vm.accessLevel = 'ORGANIZATION';
    }
    orgSvc.getAll().then(function(orgs) {
      vm.availableOrgs = _.sortBy(orgs, 'name');
      if (vm.allowMultipleSelections) {
        initValues();
      }
      if (vm.orgNameSelected && vm.orgNameSelected.length > 0) {
        setByOrgName();
      }
    });
  };

  $scope.$watch('vm.accessLevel', function() {
    vm.getMatchesForOrg();
  });

  $scope.$watch('vm.orgSelected', function() {
    vm.getMatchesForOrg();
  });

  vm.getMatchesForOrg = function() {
    if (vm.orgSelected) {
      var deferred = $q.defer();
      switch (vm.accessLevel) {
        case 'GROUP':
          orgSvc.getGroups(vm.orgSelected.shortName).then(function(data) {
            vm.availableGroups = data;
            setByGroupName();
            deferred.resolve();
          });
          break;
        case 'CODE':
          orgSvc.getAccessCodes(vm.orgSelected.shortName).then(function(data) {
            vm.availableCodes = data;
            setByAccessCode();
            deferred.resolve();
          });
          break;
        default:
          deferred.resolve();
          break;
      }
      return deferred.promise;
    }
    return $q.when();
  };

  vm.orgIndex = function(org) {
    return _.findIndex(vm.orgSelection, { id: org.id });
  };

  // Add or remove an org from the list
  vm.toggleOrg = function(org) {
    var idx = vm.orgIndex(org);
    if (idx >= 0) {
      vm.orgSelection.splice(idx, 1);
    } else {
      vm.orgSelection.push(org);
    }
  };

  vm.selectAllOrgs = function($event) {
    $event.preventDefault();
    vm.orgSelection = _.clone(vm.availableOrgs);
  };

  vm.removeAllOrgs = function($event) {
    $event.preventDefault();
    vm.orgSelection = [];
  };

  function initValues() {
    switch (vm.accessLevel) {
      case 'GROUP':
        if (!_.isEmpty(vm.groupSelection)) {
          vm.orgSelected = _.find(vm.availableOrgs, {
            id: vm.groupSelection[0].organizationId
          });
          vm.getMatchesForOrg();
        }
        break;
      case 'CODE':
        if (!_.isEmpty(vm.codeSelection)) {
          vm.orgSelected = _.find(vm.availableOrgs, {
            id: vm.codeSelection[0].organizationId
          });
          vm.getMatchesForOrg().then(function() {
            _.each(vm.codeSelection, function(value) {
              var match = _.find(vm.availableCodes, { id: value.id });
              if (match) {
                value.accessCode = match.accessCode;
              }
            });
          });
        }
        break;
    }
  }

  // Set model by org name
  function setByOrgName() {
    vm.availableOrgs.map(org => {
      if (vm.orgNameSelected === org.name) {
        vm.orgSelected = org;
      }
    });
  }

  // Set model by group name
  function setByGroupName() {
    vm.availableGroups.map(group => {
      if (vm.groupNameSelected === group.name) {
        vm.groupSelected = group;
      }
    });
  }

  // Set model by access code name
  function setByAccessCode() {
    vm.availableCodes.map(code => {
      if (vm.accessCodeNameSelected === code.accessCode) {
        vm.codeSelected = code;
      }
    });
  }
}

'use strict';

angular.module('files').directive('thOrderable', [
  function() {
    return {
      templateUrl: 'files/templates/thOrderable.html',
      restrict: 'A',
      transclude: true,
      scope: {
        type: '@thOrderable',
        orderBy: '=',
        reverse: '='
      },
      link: function(scope, ele, attrs) {
        ele.addClass('clickable');
        ele.on('click', function() {
          if (scope.orderBy === attrs.thOrderable) {
            scope.reverse = !scope.reverse;
          }
          scope.orderBy = attrs.thOrderable;
          scope.$apply();
        });
      }
    };
  }
]);

'use strict';

angular.module('myStrengthAdminApp').directive('updateLibraryFile', [
  'fileSvc',
  'alertSvc',
  function(fileSvc, alertSvc) {
    return {
      require: '?ngModel',
      restrict: 'A',
      scope: { file: '=' },
      link: function(scope, element) {
        function upload(file) {
          scope.processing = true;
          element.parent().addClass('disabled');
          scope.file.fileName = file.name;
          fileSvc.saveFile(file, scope.file.plain()).then(
            function() {
              alertSvc.success('File updated');
              element.parent().removeClass('disabled');
            },
            function(err) {
              alertSvc.error('Failed to update file', err);
              element.parent().removeClass('disabled');
            }
          );
        }
        element.bind('change', function(e) {
          upload(e.target.files[0]);
        });
      }
    };
  }
]);

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:uploadResizeImage
 * @description This applies to a file upload input. This will take the selected file (which should
 * be an image of type jpg, png or gif) and resize it to a height of 65px (while maintaining the
 * aspect ratio) then convert the image to base64.
 * # uploadResizeImage
 */
angular.module('myStrengthAdminApp').directive('uploadLibraryFile', [
  'fileSvc',
  function(fileSvc) {
    return {
      require: '?ngModel',
      restrict: 'A',
      scope: { category: '=' },
      link: function(scope, element, attr, ngModel) {
        function upload(file) {
          var data = {
            fileName: file.name,
            categoryId: scope.category.id
          };
          ngModel.$viewValue.push(data);
          fileSvc.saveFile(file, data).then(function(res) {
            data.id = res.id;
          });
        }
        element.bind('change', function(e) {
          scope.$apply(function() {
            scope.queued = e.target.files.length;
          });
          for (var i = 0; i < e.target.files.length; i++) {
            upload(e.target.files[i]);
          }
        });
      }
    };
  }
]);

'use strict';
angular.module('files').service('fileSvc', [
  'Restangular',
  '$modal',
  function(Restangular, $modal) {
    var files = Restangular.all('editor/libraryfiles');
    var categories = files.all('categories');
    var categoryGroups = files.all('categorygroups');

    function getCategories() {
      return categories.getList();
    }

    function getCategory(categoryId) {
      return files.one('categories', categoryId).get();
    }

    function saveCategory(category) {
      return categories.post(category);
    }

    function editCategory(categoryId) {
      return $modal.open({
        templateUrl: 'files/templates/fileFolderModal.html',
        controller: 'FolderEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          category: function() {
            return categoryId ? getCategory(categoryId) : null;
          },
          categories: getCategories,
          categoryGroups: getCategoryGroups
        }
      });
    }

    function getCategoryGroups() {
      return categoryGroups.getList();
    }

    function saveCategoryGroup(categoryGroup) {
      return categoryGroups.post(categoryGroup);
    }

    function deleteCategoryGroup(id) {
      return files.one('categorygroups', id).remove();
    }

    function getFile(fileId) {
      return files.one(fileId).get();
    }

    function getFilesForOrganization(id) {
      return files.one('organizations', id).get();
    }

    // Fetch files for group, including files inherited from org
    function getFilesForGroup(orgId, id) {
      return files
        .one('organizations', orgId)
        .one('groups', id)
        .get();
    }

    //Fetch files for access code, including files inherited from group and org
    function getFilesForAccessCode(orgId, id) {
      return files
        .one('organizations', orgId)
        .one('codes', id)
        .get();
    }

    function updateFile(file) {
      return files.post(file);
    }

    function deleteFile(fileId) {
      return Restangular.all('editor')
        .one('libraryfiles', fileId)
        .remove();
    }

    function deleteCategory(categoryId) {
      return files.one('categories', categoryId).remove();
    }

    function saveFile(file, metadata) {
      var fd = new FormData();
      fd.append('file', file);
      fd.append('data', angular.toJson(metadata));
      return files
        .withHttpConfig({
          transformRequest: angular.identity
        })
        .customPOST(fd, undefined, undefined, {
          'Content-Type': undefined
        });
    }

    return {
      getAll: files.getList,
      get: getFile,
      getFilesForOrganization: getFilesForOrganization,
      getFilesForGroup: getFilesForGroup,
      getFilesForAccessCode: getFilesForAccessCode,
      deleteFile: deleteFile,
      deleteCategory: deleteCategory,
      getCategories: getCategories,
      saveCategory: saveCategory,
      editCategory: editCategory,
      getCategoryGroups: getCategoryGroups,
      saveCategoryGroup: saveCategoryGroup,
      deleteCategoryGroup: deleteCategoryGroup,
      saveFile: saveFile,
      updateFile: updateFile
    };
  }
]);

(function() {
  'use strict';
  angular.module('moodSliderControllers').component('groups', {
    bindings: {},
    templateUrl: 'moodsliders/templates/groups.html',
    controller: TypesCtrl,
    require: {
      parent: '^moodSliders'
    }
  });

  TypesCtrl.$inject = ['$log', 'alertService', '$mdDialog', 'moodSlidersSvc'];

  function TypesCtrl($log, alertService, $mdDialog, moodSlidersSvc) {
    var vm = this;
    vm.$onInit = _init;
    vm.getGroupType = getGroupType;
    vm.validateDefault = validateDefault;
    vm.getInUseLength = getInUseLength;
    vm.showInUse = showInUse;
    vm.deleteGroup = deleteGroup;

    ////////// Public functions /////////////
    function getGroupType(g) {
      return _.find(vm.parent.components.types, function(t) {
        return g.typeId === t.id;
      }).type;
    }

    function validateDefault(group) {
      _.each(vm.parent.components.groups, function(g) {
        if (g.typeId === group.typeId && g.groupDefault && g.id !== group.id) {
          // If same type and is already marked as default and not in the same group
          $log.debug(
            'The following group is already set as the default: ',
            g.groupName
          );
          alertService.addAlert({
            message:
              g.groupName +
              ' is already set as the default group for this type.',
            type: 'danger'
          });
          group.groupDefault = false;
        }
      });
    }

    /**
     * Find all groups in use of type 'login' or type 'onboarding'
     * @param group
     */
    function getInUseLength(group) {
      return _.filter(vm.parent.components.groupsInUse, function(item) {
        return (
          group.id === item.loginGroupId || group.id === item.sliderGroupId
        );
      }).length;
    }

    function showInUse(evt, group) {
      var inUse = _.filter(vm.parent.components.groupsInUse, function(item) {
        return (
          group.id === item.loginGroupId || group.id === item.sliderGroupId
        );
      });
      $mdDialog.show({
        templateUrl: 'moodsliders/templates/in_use.html',
        parent: angular.element(document.body),
        targetEvent: evt,
        clickOutsideToClose: true,
        controller: [
          '$scope',
          '$mdDialog',
          function($scope, $mdDialog) {
            $scope.inUse = inUse;
            $scope.close = function() {
              $mdDialog.cancel();
            };
          }
        ]
      });
    }

    function deleteGroup(evt, g) {
      var confirm = $mdDialog
        .confirm()
        .title('Would you like to set group "' + g.groupName + '" to inactive?')
        .textContent('This action can be undone directly in the database.')
        .ariaLabel('Remove')
        .targetEvent(evt)
        .ok('Remove It')
        .cancel('No');
      $mdDialog.show(confirm).then(function() {
        moodSlidersSvc.deleteMoodGroup(g).then(function() {
          vm.parent.components.groups.splice(
            _.findIndex(vm.parent.components.groups, function(item) {
              return g.id === item.id;
            }),
            1
          );
          _init();
        });
      });
    }

    ////////// Private functions /////////////
    function _init() {
      vm.groups = vm.parent.components.groups;
      // Edit group is defined in the parent component.
      vm.editGroup = vm.parent.editGroup;
    }
  }
})();

(function() {
  'use strict';
  angular.module('moodSliderControllers').component('groupEdit', {
    bindings: {
      isEdit: '<',
      group: '<',
      groupTypes: '<',
      components: '<',
      cancelAction: '&',
      saveGroup: '&'
    },
    templateUrl: 'moodsliders/templates/groupEdit.html',
    controller: GroupEditCtrl
  });

  GroupEditCtrl.$inject = ['$log'];

  function GroupEditCtrl($log) {
    var vm = this;
    vm.$onInit = _init;
    vm.saveGrouping = saveGrouping;
    vm.cancel = cancel;

    ////////// Public functions /////////////
    function saveGrouping(group) {
      _.each(group.moodSliders, (item, idx) => {
        // Update slider position field
        item.position = idx;
      });
      vm.saveGroup({
        group: group
      });
    }

    function cancel() {
      $log.debug('Cancel action called');
      vm.cancelAction();
    }

    ////////// Private functions /////////////
    function _init() {
      if (vm.group) {
        vm.components = _.filter(vm.components, function(c) {
          return !_.some(vm.group.moodSliders, function(s) {
            return c.id === s.id;
          });
        });
        vm.group.moodSliders = _.sortBy(vm.group.moodSliders, ['position']);
      } else {
        // This is a create new, not an edit
        vm.group = {
          moodSliders: []
        };
      }
      vm.list = [];
      vm.models = {
        selected: null,
        lists: {
          comp: [],
          group: []
        }
      };
    }
  }
})();

(function() {
  'use strict';
  angular.module('moodSliderControllers').component('moodSliders', {
    bindings: {
      components: '<'
    },
    templateUrl: 'moodsliders/templates/trackerHome.html',
    controller: MoodSliderCtrl
  });

  MoodSliderCtrl.$inject = ['$modal', 'moodSlidersSvc', '$log'];

  function MoodSliderCtrl($modal, moodSlidersSvc, $log) {
    var vm = this;
    vm.$onInit = _init;
    vm.editComp = editComp;
    vm.editGroup = editGroup;

    ////////// Public functions /////////////
    /**
     * Open the modal for Creating/Editing gropus.
     * @param isEdit boolean - False means this is a new record.
     * @param groupIn - The group being edited
     */
    function editGroup(isEdit, groupIn) {
      // Fetch index so we can update the parent model.
      if (isEdit && groupIn) {
        var idx = _.findIndex(vm.components.groups, g => g.id === groupIn.id);
      }
      var _modal = (vm.editModal = $modal.open({
        template:
          '<group-edit is-edit="$ctrl.isEdit" group="$ctrl.group" group-types="$ctrl.groupTypes" components="$ctrl.components" save-group="$ctrl.saveGroup(group)" cancel-action="$ctrl.cancelAction()"/>',
        size: 'lg',
        controllerAs: '$ctrl',
        controller: function() {
          this.isEdit = isEdit;
          this.group = angular.copy(groupIn);
          this.groupTypes = angular.copy(vm.components.types);
          this.components = angular.copy(vm.components.components);
          this.cancelAction = _cancelAction;
          this.saveGroup = _saveGroup;
        }
      }));
      _modal.result.then(
        () => {},
        group => {
          if (group.groupName) {
            if (idx !== undefined) {
              // Update model with new values
              vm.components.groups[idx] = group;
            } else {
              vm.components.groups.push(group);
            }
          }
        }
      );
    }

    /**
     * Open the modal for Creating/Editing mood sliders.
     * @param editMode boolean - False means this is a new record.
     * @param id - The slider id being edited
     */
    function editComp(editMode, c) {
      if (c) {
        c.tags = c.tags || [];
      }
      $modal
        .open({
          templateUrl: 'moodsliders/templates/trackerEdit.html',
          controller: 'MoodSliderEditCtrl',
          controllerAs: 'vm',
          size: 'lg',
          resolve: {
            optionalScope: () => {
              return {
                isEdit: editMode,
                record: !_.isEmpty(c) ? angular.copy(c) : { tags: [] }
              };
            }
          }
        })
        .result.then(comp => {
          if (editMode) {
            var idx = _.findIndex(
              vm.components.components,
              c => comp.id === c.id
            );
            vm.components.components[idx] = comp;
          } else {
            vm.components.components.unshift(comp);
          }
        });
    }

    ////////// Private functions /////////////
    function _init() {
      // Reset group being edited.
      vm.tabs = [
        {
          title: 'Trackers'
        },
        {
          title: 'Groups'
        }
      ];
      vm.selectedIndex = null;
      _setTrackerUseFlag();
      $log.debug('moodSliderComp VM: ', vm);
    }

    function _setTrackerUseFlag() {
      let trackerIds = [];
      _.forEach(vm.components.groups, function(g) {
        _.forEach(g.moodSliders, function(m) {
          if (!trackerIds.includes(m.id)) {
            trackerIds.push(m.id);
          }
        });
      });
      _.forEach(vm.components.components, function(t) {
        t.inUse = trackerIds.includes(t.id);
      });
    }

    function _saveGroup(group) {
      moodSlidersSvc
        .saveMoodSliderGrouping(group)
        .then(() => vm.editModal.dismiss(group));
    }

    function _cancelAction() {
      vm.editModal.close('cancel');
    }
  }
})();

'use strict';

angular.module('moodSliderControllers').controller('MoodSliderEditCtrl', [
  '$modalInstance',
  'optionalScope',
  'moodSlidersSvc',
  '$log',
  function($modalInstance, optionalScope, moodSlidersSvc, $log) {
    var vm = this;

    _.extend(vm, optionalScope); // Copy properties from optionalScope into vm
    $log.debug('MoodSliderEditCtrl started');

    // Close the modal and return the section
    vm.save = function(slider) {
      if (_.isUndefined(slider.isRequired)) {
        slider.isRequired = false;
      }
      moodSlidersSvc.saveMoodSlider(slider).then(function(result) {
        $modalInstance.close(result);
      });
    };

    vm.saveGroupType = function(type) {
      moodSlidersSvc.saveMoodSliderGroupTyoes(type).then(function() {
        $modalInstance.close(type);
      });
    };

    // Dismiss the modal
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

(function() {
  'use strict';
  angular.module('moodSliderControllers').component('sliders', {
    bindings: {},
    templateUrl: 'moodsliders/templates/trackers.html',
    controller: ComponentsCtrl,
    require: {
      parent: '^moodSliders'
    }
  });

  ComponentsCtrl.$inject = ['moodSlidersSvc', '$mdDialog'];

  function ComponentsCtrl(moodSlidersSvc, $mdDialog) {
    var vm = this;
    vm.deleteComp = deleteComp;
    vm.$onInit = _init;

    ////////// Public functions /////////////
    function deleteComp(evt, c) {
      var confirm = $mdDialog
        .confirm()
        .title(
          'Would you like to set mood slider "' + c.name + '" to inactive?'
        )
        .textContent('This can be undone directly in the database.')
        .ariaLabel('Remove')
        .targetEvent(evt)
        .ok('Remove It')
        .cancel('No');
      $mdDialog.show(confirm).then(function() {
        moodSlidersSvc.deleteMoodSlider(c.id).then(function() {
          vm.parent.components.components.splice(
            _.findIndex(vm.parent.components.components, function(item) {
              return c.id === item.id;
            }),
            1
          );
          _init();
        });
      });
    }

    ////////// Private functions /////////////
    function _init() {
      vm.components = vm.parent.components.components;
      // Edit comp is defined in the parent component.
      vm.editComp = vm.parent.editComp;
    }
  }
})();

'use strict';
/**
 * Handle all the API interaction around quotes.
 */
angular.module('moodSliderControllers').service('moodSlidersSvc', [
  '$log',
  'Restangular',
  'alertService',
  function($log, Restangular, alertService) {
    /**
     * Find all email templates.
     * @return {promise}       A promise that will eventually have the quote's detailed information
     */
    this.getAllMoodSliders = function() {
      return Restangular.one('/editor/moodslider/sliders')
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem retrieving mood sliders: ', err);
          }
        );
    };

    /**
     * Update the mood slider
     * @param  {Object} The template to update
     */
    this.saveMoodSlider = function(slider) {
      return Restangular.one('/editor/moodslider/sliders')
        .customPOST(slider)
        .then(
          function(result) {
            alertService.addAlert({
              message: 'Tracker saved!',
              type: 'success'
            });
            return result;
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to save tracker: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    this.saveMoodSliderGrouping = function(grouping) {
      return Restangular.one('/editor/moodslider/groups')
        .customPOST(grouping)
        .then(
          function() {
            alertService.addAlert({
              message: 'Tracker group saved!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to save tracker grouping: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    this.getAllMoodGroups = function() {
      return Restangular.one('/editor/moodslider/groups')
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem retrieving tracker groups: ', err);
          }
        );
    };

    this.getMoodGroupsByType = function(type) {
      $log.debug('Getting mood slider groups of type: ', type);
      return Restangular.one('/editor/moodslider/groups', type)
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem retrieving tracker groups: ', err);
          }
        );
    };

    this.getAllGroupTypes = function() {
      return Restangular.one('/editor/moodslider/groups/types')
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error(
              'There was a problem retrieving tracker group types: ',
              err
            );
          }
        );
    };

    this.deleteMoodSlider = function(id) {
      $log.debug('Deleting mood slider with id: ', id);
      return Restangular.one('/editor/moodslider/slider', id)
        .remove()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem deleting tracker: ', err);
          }
        );
    };

    this.deleteMoodGroup = function(group) {
      return Restangular.one('/editor/moodslider/groups', group.id)
        .remove()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem deleting tracker group: ', err);
          }
        );
    };

    this.groupsInUse = function() {
      return Restangular.one('/editor/moodslider/groups/inuse').get();
    };
  }
]);

'use strict';

angular.module('accessCodeControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('codes', {
        templateUrl: 'partner/codes/templates/codelist.html',
        controller: 'CodeListCtrl',
        url: '/codes'
      })
      .state('orgs.org.codes', {
        abstract: true,
        template: '<ui-view/>',
        url: '/codes'
      })
      .state('orgs.org.codes.new', {
        url: '/new',
        templateUrl: 'partner/codes/templates/codeedit.html',
        controller: 'CodeEditCtrl',
        resolve: {
          code: function() {
            return null;
          }
        }
      })
      .state('orgs.org.codes.edit', {
        url: '/:codeid/edit',
        templateUrl: 'partner/codes/templates/codeedit.html',
        controller: 'CodeEditCtrl',
        resolve: {
          code: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization.one('accesscodes', $stateParams.codeid).get();
            }
          ]
        }
      })
      .state('orgs.org.codes.bulk', {
        url: '/bulkupload',
        templateUrl: 'partner/codes/templates/codebulk.html',
        controller: 'CodeBulkCtrl'
      });
  }
]);

'use strict';

angular.module('groupControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.groups', {
        abstract: true,
        template: '<ui-view/>',
        url: '/groups'
      })
      .state('orgs.org.groups.new', {
        url: '/new',
        templateUrl: 'partner/groups/templates/groupedit.html',
        controller: 'GroupEditCtrl',
        resolve: {
          group: function() {
            return null;
          }
        }
      })
      .state('orgs.org.groups.edit', {
        url: '/:groupid/edit',
        templateUrl: 'partner/groups/templates/groupedit.html',
        controller: 'GroupEditCtrl',
        resolve: {
          group: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization.one('groups', $stateParams.groupid).get();
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('headerControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.headers', {
        abstract: true,
        template: '<ui-view/>',
        url: '/headers'
      })
      .state('orgs.org.headers.new', {
        url: '/new',
        templateUrl: 'partner/headers/templates/headeredit.html',
        controller: 'HeaderEditCtrl',
        resolve: {
          isClone: function() {
            return false;
          },
          isNew: function() {
            return true;
          }
        }
      })
      .state('orgs.org.headers.edit', {
        url: '/:headerid/edit',
        templateUrl: 'partner/headers/templates/headeredit.html',
        controller: 'HeaderEditCtrl',
        resolve: {
          isClone: function() {
            return false;
          },
          isNew: function() {
            return false;
          }
        }
      })
      .state('orgs.org.headers.clone', {
        url: '/:headerid/clone',
        templateUrl: 'partner/headers/templates/headeredit.html',
        controller: 'HeaderEditCtrl',
        resolve: {
          isClone: function() {
            return true;
          },
          isNew: function() {
            return false;
          }
        }
      });
  }
]);

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:uploadResizeImage
 * @description This applies to a file upload input. This will take the selected file (which should
 * be an image of type jpg, png or gif) and resize it to a height of 65px (while maintaining the
 * aspect ratio) then convert the image to base64.
 * # uploadResizeImage
 */
angular.module('myStrengthAdminApp').directive('uploadResizeImage', function() {
  return {
    scope: {
      // deal with linking of uploadResizeImage="x" to this
      uploadResizeImage: '=',
      imageFile: '=?',
      imageFileName: '=',
      maxHeight: '=',
      maxWidth: '='
    },
    restrict: 'AE',
    link: function(scope, element) {
      /**
       * Shrinks an image in a way that causes it to blur a little.
       * The number of iterations is how blurry it should be - a good
       * rule of thumb is ln(size/targetsize). This works by shrinking
       * the image halfway to the target repeatedly.
       * http://stackoverflow.com/a/17862644/2108024
       */
      function shrinkImage(img, targetWidth, targetHeight, iterations) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        iterations--;
        if (iterations <= 0) {
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
          return canvas;
        } else {
          var width = img.width - Math.round((img.width - targetWidth) / 2);
          var height = img.height - Math.round((img.height - targetHeight) / 2);
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          return shrinkImage(canvas, targetWidth, targetHeight, iterations);
        }
      }
      // Note this happens on change - if the file stays the same it won't activate
      element.bind('change', function(e) {
        // set up a file reader to read the file
        var reader = new FileReader();
        reader.onload = function(load) {
          var img = new Image();
          img.onload = function() {
            var maxHeight = scope.maxHeight || 65;
            if (typeof maxHeight !== 'number') {
              throw 'Max height must be a number';
            }
            // TODO max width
            // draw the image to a canvas to resize it
            var targetWidth, targetHeight;
            // only resize if it's too big
            if (img.height > maxHeight) {
              targetWidth = Math.floor(maxHeight * (img.width / img.height));
              targetHeight = maxHeight;
            } else {
              targetWidth = img.width;
              targetHeight = img.height;
            }
            // number of iterations depends on difference between two images
            var iter = Math.ceil(
              Math.log(img.height / maxHeight) / Math.log(2)
            );
            var canvas = shrinkImage(img, targetWidth, targetHeight, iter);
            scope.$apply(function() {
              var base64Image = canvas.toDataURL('image/png', 1.0);
              // check to see if we should apply to editor or write back to uploadResizeImage
              if (
                !angular.isDefined(scope.uploadResizeImage) &&
                angular.isDefined(scope.$parent.$editor)
              ) {
                scope.$parent
                  .$editor()
                  .wrapSelection('insertImage', base64Image);
                scope.$parent.defer.resolve();
              } else {
                scope.uploadResizeImage = base64Image;
              }
            });
          };
          img.src = load.target.result;
        };
        if (e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
          // read the value of the first file selected
          reader.readAsDataURL(e.target.files[0]);
          // set the file to be uploaded
          scope.imageFile = e.target.files[0];
          scope.imageFileName = e.target.value;
          // reset the file input for reuse
          element.val(null);
        } else {
          // alert if they uploaded something invalid (should never happen)
          window.alert('File must be one of these types: jpg jpeg png gif');
        }
      });
    }
  };
});

'use strict';

angular.module('identityProviderControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.identityproviders', {
        abstract: true,
        template: '<ui-view/>',
        url: '/identityproviders'
      })
      // We currently provide only for the creation of new identity providers (or
      // deletion) because we have only one field, the identity provider ID.  As
      // other fields are added (e.g. SSO custom error message text), another state
      // should be added along with supporting fields.
      .state('orgs.org.identityproviders.new', {
        url: '/new',
        templateUrl:
          'partner/identityproviders/templates/identityprovideredit.html',
        controller: 'IdentityProviderEditCtrl',
        resolve: {
          identityprovider: function() {
            return null;
          }
        }
      })
      .state('orgs.org.identityproviders.edit', {
        url: '/:identityproviderid/edit',
        templateUrl:
          'partner/identityproviders/templates/identityprovideredit.html',
        controller: 'IdentityProviderEditCtrl',
        resolve: {
          identityprovider: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization
                .one('identityproviders', $stateParams.identityproviderid)
                .get();
            }
          ]
        }
      });
  }
]);

(function() {
  'use strict';
  angular.module('metadataControllers', []).config([
    '$stateProvider',
    function($stateProvider) {
      $stateProvider
        .state('metadata', {
          abstract: true,
          template: '<ui-view/>',
          url: '/metadata'
        })
        .state('metadata.list', {
          templateUrl: 'partner/metadata/templates/metaList.html',
          controller: 'MetadataListCtrl',
          controllerAs: 'vm',
          url: '?context'
        });
    }
  ]);
})();

'use strict';

angular.module('notificationControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.notifications', {
        abstract: true,
        template: '<ui-view/>',
        url: '/notifications'
      })
      .state('orgs.org.notifications.new', {
        url: '/new',
        templateUrl: 'partner/notifications/templates/notificationedit.html',
        controller: 'NotificationEditCtrl',
        resolve: {
          notification: function() {
            return null;
          },
          templates: [
            'notificationSettingsSvc',
            function(notificationSettingsSvc) {
              return notificationSettingsSvc.getMandrillTemplates();
            }
          ]
        }
      })
      .state('orgs.org.notifications.edit', {
        url: '/:notificationid/edit',
        templateUrl: 'partner/notifications/templates/notificationedit.html',
        controller: 'NotificationEditCtrl',
        resolve: {
          notification: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization
                .one('notifications', $stateParams.notificationid)
                .get();
            }
          ],
          templates: [
            'notificationSettingsSvc',
            function(notificationSettingsSvc) {
              return notificationSettingsSvc.getMandrillTemplates();
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('providerPreferencesControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.preferences', {
        abstract: true,
        template: '<ui-view/>',
        url: '/preferences'
      })
      .state('orgs.org.preferences.new', {
        url: '/new',
        templateUrl: 'partner/preferences/templates/preferencesedit.html',
        controller: 'ProviderPreferencesEditCtrl as vm',
        resolve: {
          preferences: function() {
            return null;
          },
          loginGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('login')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          onboardingGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('onboarding')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          featureTypes: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/preferences')
                .getList('featureTypes')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          isClone: function() {
            return false;
          }
        }
      })
      .state('orgs.org.preferences.edit', {
        url: '/:preferencesid/edit',
        templateUrl: 'partner/preferences/templates/preferencesedit.html',
        controller: 'ProviderPreferencesEditCtrl as vm',
        resolve: {
          preferences: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization
                .one('preferences', $stateParams.preferencesid)
                .get();
            }
          ],
          loginGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('login')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          onboardingGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('onboarding')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          featureTypes: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/preferences')
                .getList('featureTypes')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          isClone: function() {
            return false;
          }
        }
      })
      .state('orgs.org.preferences.clone', {
        url: '/:preferencesid/clone',
        templateUrl: 'partner/preferences/templates/preferencesedit.html',
        controller: 'ProviderPreferencesEditCtrl as vm',
        resolve: {
          preferences: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization
                .one('preferences', $stateParams.preferencesid)
                .get();
            }
          ],
          loginGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('login')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          onboardingGroups: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/moodslider/groups')
                .getList('onboarding')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          featureTypes: [
            'Restangular',
            function(Restangular) {
              return Restangular.one('editor/preferences')
                .getList('featureTypes')
                .then(function(resp) {
                  return resp.plain();
                });
            }
          ],
          isClone: function() {
            return true;
          }
        }
      });
  }
]);

'use strict';

angular.module('referralControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.referral', {
        abstract: true,
        template: '<ui-view/>',
        url: '/referral'
      })
      .state('orgs.org.referral.edit', {
        url: '/:referralid/edit',
        templateUrl: 'partner/referral/templates/referraledit.html',
        controller: 'ReferralEditCtrl',
        controllerAs: 'vm',
        resolve: {
          referral: [
            '$stateParams',
            'Restangular',
            'organization',
            function($stateParams, Restangular, organization) {
              return organization
                .one('referralpagesettings', $stateParams.referralid)
                .get();
            }
          ]
        }
      })
      .state('orgs.org.referral.new', {
        url: '/new',
        templateUrl: 'partner/referral/templates/referraledit.html',
        controller: 'ReferralEditCtrl',
        controllerAs: 'vm',
        resolve: {
          referral: function() {
            return null;
          }
        }
      });
  }
]);

'use strict';

angular.module('signupControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('orgs.org.signup', {
        abstract: true,
        template: '<ui-view/>',
        url: '/signup'
      })
      .state('orgs.org.signup.new', {
        url: '/new',
        templateUrl: 'partner/signup/templates/signupedit.html',
        controller: 'SignupEditCtrl',
        resolve: {
          signup: function() {
            return null;
          }
        }
      })
      .state('orgs.org.signup.edit', {
        url: '/:signupid/edit',
        templateUrl: 'partner/signup/templates/signupedit.html',
        controller: 'SignupEditCtrl',
        resolve: {
          signup: [
            '$stateParams',
            'organization',
            function($stateParams, organization) {
              return organization.one('signups', $stateParams.signupid).get();
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('ssoControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('sso', {
        abstract: true,
        template: '<ui-view/>',
        url: '/sso',
        crumb: {
          name: 'Identity Providers',
          location: 'sso.list'
        }
      })
      .state('sso.list', {
        url: '/list',
        templateUrl: 'partner/sso/templates/ssolist.html',
        controller: 'SsoListCtrl',
        resolve: {
          org: [
            function() {
              return null;
            }
          ]
        }
      })
      // org context for putting the list into 'add' mode.
      .state('sso.add', {
        url: '/add/:orgid',
        templateUrl: 'partner/sso/templates/ssolist.html',
        controller: 'SsoListCtrl',
        resolve: {
          org: [
            '$stateParams',
            function($stateParams) {
              return { orgShortName: $stateParams.orgid };
            }
          ]
        }
      })
      .state('sso.edit', {
        url: '/edit/:idpid',
        templateUrl: 'partner/sso/templates/ssoedit.html',
        controller: 'SsoEditCtrl',
        resolve: {
          identityprovider: [
            '$stateParams',
            'ssoSvc',
            function($stateParams, ssoSvc) {
              return ssoSvc.getIdp($stateParams.idpid);
            }
          ]
        }
      })
      .state('sso.new', {
        url: '/new',
        templateUrl: 'partner/sso/templates/ssoedit.html',
        controller: 'SsoEditCtrl',
        resolve: {
          identityprovider: [
            function() {
              return { isNew: true };
            }
          ]
        }
      });
  }
]);

'use strict';

angular.module('telehealthControllers', []).config([
  '$stateProvider',
  function($stateProvider) {
    $stateProvider
      .state('telehealth-providers', {
        abstract: true,
        template: '<ui-view/>',
        url: '/telehealth-providers'
      })
      .state('telehealth-providers.list', {
        url: '/list',
        templateUrl:
          'partner/telehealth-providers/templates/telehealth-providers.html',
        controller: 'telehealthListCtrl'
      })
      .state('telehealth-providers.edit', {
        url: '/edit/:provId',
        templateUrl:
          'partner/telehealth-providers/templates/telehealth-edit.html',
        controller: 'telehealthEditCtrl',
        params: {
          providerInfo: null
        }
      })
      .state('telehealth-providers.add', {
        url: '/add/:orgShortName/:collId',
        templateUrl:
          'partner/telehealth-providers/templates/telehealth-org-edit.html',
        controller: 'telehealthOrgEditCtrl',
        params: {
          partner: null,
          organization: null
        }
      });
  }
]);

(function() {
  'use strict';

  angular.module('quizControllers').controller('QuizEditCtrl', QuizEditCtrl);

  QuizEditCtrl.$inject = [
    '$state',
    '$stateParams',
    '$modal',
    'quizSvc',
    'quizQuestionSvc',
    'alertService',
    'uuidSvc',
    'orderSvc',
    '$log'
  ];

  function QuizEditCtrl(
    $state,
    $stateParams,
    $modal,
    quizSvc,
    quizQuestionSvc,
    alertService,
    uuidSvc,
    orderSvc,
    $log
  ) {
    var vm = this;
    vm.isEdit = false;
    vm.$onInit = activate;
    vm.save = save;
    vm.addQuestion = addQuestion;
    vm.editQuestion = editQuestion;
    vm.moveQuestion = moveQuestion;
    vm.removeQuestion = removeQuestion;
    vm.editThreshold = editThreshold;
    vm.removeThreshold = removeThreshold;
    vm.quizTypes = Object.values(quizQuestionSvc.quizTypes);

    //////////////////////////////
    function activate() {
      if ($stateParams.id && !$stateParams.quiz) {
        $state.go('quizzes.list'); //go back to list
      }

      if ($stateParams.quiz) {
        vm.isEdit = true;
      }

      vm.quiz = $stateParams.quiz || {
        name: '',
        title: '',
        lead: '',
        type: 'STANDARD_QUIZ',
        status: 'DRAFT',
        questions: [],
        thresholds: []
      };
    }

    function save() {
      if (!vm.form.$valid) {
        vm.message = 'Please be sure to enter all required fields!';
        return;
      }

      vm.message = null;
      if (!vm.quiz.uuid || vm.quiz.uuid === '') {
        vm.quiz.uuid = uuidSvc.newUuid();
      }

      quizSvc.save(vm.quiz).then(
        function(resp) {
          alertService.addAlert({
            message: 'Quiz Saved!',
            type: 'success'
          });
          if (!vm.isEdit) {
            vm.isEdit = true;
            vm.quiz.id = resp;
          }
        },
        function(err) {
          alertService.addAlert({
            message: 'Failed to save the quiz: ' + err.statusText,
            type: 'danger'
          });
        }
      );
    }

    function addQuestion() {
      var question = {
        type: '',
        position: vm.quiz.questions.length,
        uuid: uuidSvc.newUuid(),
        properties: {
          answerCount: 0
        }
      };
      editQuestion(question);
    }

    function editQuestion(question) {
      var isEdit = vm.quiz.questions.indexOf(question) !== -1;
      var questionBackup = angular.copy(question);
      var modal = $modal.open({
        templateUrl: 'quizzes/templates/questionEdit.html',
        controller: 'QuizQuestionEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          question: function() {
            return question;
          },
          isEdit: function() {
            return isEdit;
          },
          quizType: function() {
            return vm.quiz.type;
          }
        }
      });
      modal.result.then(
        function(question) {
          if (isEdit) {
            vm.quiz.questions[vm.quiz.questions.indexOf(question)] = question;
          } else {
            vm.quiz.questions.push(question);
          }
          $log.debug('Updated questions:');
          $log.debug(vm.quiz.questions);
        },
        function() {
          //cancelled
          if (isEdit) {
            vm.quiz.questions[
              vm.quiz.questions.indexOf(question)
            ] = questionBackup;
          }
        }
      );
    }

    function moveQuestion(question, direction) {
      orderSvc.move(question, direction, vm.quiz.questions);
    }

    function removeQuestion(question) {
      orderSvc.remove(question, vm.quiz.questions);
    }

    function editThreshold(threshold) {
      var isEdit = angular.isDefined(threshold);
      var thresholdBackup = angular.copy(threshold);
      var modal = $modal.open({
        templateUrl: 'quizzes/templates/thresholdEdit.html',
        controller: 'QuizThresholdEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          threshold: function() {
            return threshold;
          }
        }
      });
      modal.result.then(
        function(threshold) {
          if (isEdit) {
            vm.quiz.thresholds[
              vm.quiz.thresholds.indexOf(threshold)
            ] = threshold;
          } else {
            vm.quiz.thresholds.push(threshold);
          }
          $log.debug('Updated thresholds:');
          $log.debug(vm.quiz.thresholds);
        },
        function() {
          // cancelled, revert the field
          if (isEdit) {
            vm.quiz.thresholds[
              vm.quiz.thresholds.indexOf(threshold)
            ] = thresholdBackup;
          }
        }
      );
    }

    function removeThreshold(threshold) {
      var idx = vm.quiz.thresholds.indexOf(threshold);
      if (idx !== -1) {
        vm.quiz.thresholds.splice(idx, 1);
      }
    }
  }
})();

(function() {
  'use strict';

  angular.module('quizControllers').controller('QuizListCtrl', QuizListCtrl);

  QuizListCtrl.$inject = ['$state', 'quizSvc'];

  function QuizListCtrl($state, quizSvc) {
    var vm = this;
    vm.quizzes = [
      {
        name: 'Loading...'
      }
    ];
    vm.$onInit = activate;
    //vm.deleteQuiz = deleteQuiz;

    ////////////////////////////
    function activate() {
      quizSvc.getAll().then(
        function(resp) {
          vm.quizzes = resp;
        },
        function(error) {
          vm.quizzes = [
            {
              name: 'Failed to load quizzes: ' + error.statusText
            }
          ];
        }
      );
    }

    // function deleteQuiz(quiz) {
    //  //TODO: activate this
    // }
  }
})();

(function() {
  'use strict';

  angular
    .module('quizControllers')
    .controller('QuizQuestionEditCtrl', QuizQuestionEditCtrl);

  QuizQuestionEditCtrl.$inject = [
    '$modalInstance',
    'quizQuestionSvc',
    'tagSvc',
    'question',
    'isEdit',
    'quizType'
  ];

  function QuizQuestionEditCtrl(
    $modalInstance,
    quizQuestionSvc,
    tagSvc,
    question,
    isEdit,
    quizType
  ) {
    var vm = this;
    vm.answerOptions = answerOptions;
    vm.showField = showField;
    vm.addAnswer = addAnswer;
    vm.deleteAnswer = deleteAnswer;
    vm.save = save;
    vm.cancel = cancel;
    vm.questionTypes = Object.values(quizQuestionSvc.questionTypes);

    activate();

    ////////////////////
    function activate() {
      if (!question.type) {
        question.type = quizQuestionSvc.questionTypes.RADIO_BUTTONS.value;
      }

      vm.question = question;
      vm.isEdit = isEdit;

      tagSvc.getAll().then(
        function(resp) {
          vm.tags = resp;
        },
        function(error) {
          vm.tags = [
            {
              shortName: 'Failed to load Tags: ' + error.statusText
            }
          ];
        }
      );
    }

    function showField(fieldName) {
      return quizQuestionSvc.answerFieldApplies(
        fieldName,
        quizType,
        vm.question.type
      );
    }

    function answerOptions() {
      return new Array(parseInt(vm.question.properties.answerCount));
    }

    function addAnswer() {
      vm.question.properties.answerCount++;
    }

    function deleteAnswer(idx) {
      quizQuestionSvc.deleteAnswer(question, idx);
      vm.question.properties.answerCount--;
    }

    function save() {
      var error = quizQuestionSvc.validateAnswers(vm.question, quizType);
      if (error) {
        vm.message = error;
        vm.form.$valid = false;
        return;
      }

      vm.message = null;
      $modalInstance.close(
        quizQuestionSvc.removeExtraFields(vm.question, quizType)
      );
    }

    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('quizControllers')
    .controller('QuizSelectCtrl', QuizSelectCtrl);
  QuizSelectCtrl.$inject = [
    '$modalInstance',
    'quizSelected',
    'quizQuestionSvc'
  ];

  function QuizSelectCtrl($modalInstance, quizSelected, quizQuestionSvc) {
    var vm = this;
    vm.cancel = cancel;
    vm.quizTypes = quizQuestionSvc.quizTypes;
    vm.quizSelected = null;
    vm.answerOptions = answerOptions;
    vm.getQuestionTypeLabel = getQuestionTypeLabel;
    activate();

    /////////////////////////
    function activate() {
      vm.quizSelected = quizSelected;
    }

    function answerOptions(question) {
      return new Array(parseInt(question.properties.answerCount));
    }

    function getQuestionTypeLabel(type) {
      var match = Object.values(quizQuestionSvc.questionTypes).find(function(
        v
      ) {
        return v.value === type;
      });
      return match.label;
    }

    // Dismiss the modal
    function cancel() {
      $modalInstance.dismiss('cancel');
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('quizControllers')
    .controller('QuizThresholdEditCtrl', QuizThresholdEditCtrl);

  QuizThresholdEditCtrl.$inject = ['$modalInstance', 'threshold', 'tagSvc'];

  function QuizThresholdEditCtrl($modalInstance, threshold, tagSvc) {
    var vm = this;
    vm.isEdit = angular.isDefined(threshold);
    vm.threshold = threshold || {};
    activate();

    function activate() {
      tagSvc.getAll().then(
        function(resp) {
          vm.tags = resp;
        },
        function(error) {
          vm.tags = [
            {
              shortName: 'Failed to load Tags: ' + error.statusText
            }
          ];
        }
      );
    }

    vm.save = function() {
      if (!vm.threshold.title) {
        vm.threshold.title = null;
      }
      if (vm.threshold.imagePath && !vm.threshold.imageAltText) {
        vm.message = 'Alt Text is required for image.';
        vm.form.$valid = false;
        return;
      }
      $modalInstance.close(vm.threshold);
    };
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
})();

(function() {
  'use strict';

  //Constants and methods for working with quiz questions and answers
  angular.module('quizControllers').service('quizQuestionSvc', quizQuestionSvc);

  function quizQuestionSvc() {
    const quizTypes = {
      STANDARD_QUIZ: {
        label: 'Simple Sum',
        value: 'STANDARD_QUIZ'
      },
      WEIGHTED_SCORE_QUIZ: {
        label: 'Weighted Score',
        value: 'WEIGHTED_SCORE_QUIZ'
      },
      NO_SCORE_QUIZ: {
        label: 'No Score',
        value: 'NO_SCORE_QUIZ'
      }
    };
    const questionTypes = {
      RADIO_BUTTONS: {
        label: 'Radio Buttons',
        value: 'quiz/quiz_radio_buttons'
      },
      CHECK_BOXES: {
        label: 'Check boxes',
        value: 'quiz/quiz_check_boxes'
      },
      DROP_DOWN: {
        label: 'Dropdown',
        value: 'quiz/quiz_drop_down'
      }
    };
    const answerProps = [
      'answer',
      'readout',
      'isCorrect',
      'weightedScore',
      'preference'
    ];

    function answerFieldApplies(fieldName, quizType, questionType) {
      switch (fieldName) {
        case 'readout':
          return questionType !== questionTypes.CHECK_BOXES.value;
        case 'isCorrect':
          return quizType !== quizTypes.NO_SCORE_QUIZ.value;
        case 'weightedScore':
          return quizType === quizTypes.WEIGHTED_SCORE_QUIZ.value;
        default:
          return true;
      }
    }

    function answerFieldIsNullOrEmpty(properties, fieldName, idx) {
      return (
        properties.hasOwnProperty('choice_' + idx + '_' + fieldName) &&
        (properties['choice_' + idx + '_' + fieldName] === null ||
          properties['choice_' + idx + '_' + fieldName] === '')
      );
    }

    function isValidWeightedScore(properties, idx) {
      if (properties.hasOwnProperty('choice_' + idx + '_weightedScore')) {
        //a weighted score is not required but it has be great than 0 if specified
        if (
          _.isEmpty(properties['choice_' + idx + '_weightedScore']) ||
          properties['choice_' + idx + '_weightedScore'].toString() === 'null'
        ) {
          return true;
        } else {
          var score = parseFloat(
            properties['choice_' + idx + '_weightedScore']
          );
          return score > 0;
        }
      }
    }

    function mustHaveOneAnswer(question, quizType) {
      return (
        quizType === quizTypes.STANDARD_QUIZ.value &&
        (question.type === questionTypes.RADIO_BUTTONS.value ||
          question.type === questionTypes.DROP_DOWN)
      );
    }

    function validateAnswers(question, quizType) {
      var answerCount = question.properties.answerCount;

      if (answerCount < 2) {
        return 'At least 2 answers must be specified';
      }
      //Tally the number of correct answers
      if (quizType !== quizTypes.NO_SCORE_QUIZ.value) {
        var correctCount = 0;
        for (var i = 0; i < answerCount; i++) {
          if (
            question.properties.hasOwnProperty('choice_' + i + '_isCorrect') &&
            question.properties['choice_' + i + '_isCorrect'].toString() ===
              'true'
          ) {
            if (
              quizType === quizTypes.WEIGHTED_SCORE_QUIZ.value &&
              !isValidWeightedScore(question.properties, i)
            ) {
              return 'Weighted score must be greater than 0';
            }
            correctCount++;
          }
        }

        if (mustHaveOneAnswer(question, quizType) && correctCount !== 1) {
          return 'One correct answer must be chosen';
        } else if (correctCount === 0) {
          return 'At least one correct answer must be chosen';
        }
      }

      if (question.type !== questionTypes.CHECK_BOXES.value) {
        var cntReadouts = 0;
        for (var y = 0; y < answerCount; y++) {
          if (
            question.properties.hasOwnProperty('choice_' + y + '_readout') &&
            !_.isEmpty(question.properties['choice_' + y + '_readout'])
          ) {
            cntReadouts = cntReadouts + 1;
          }
        }
        if (cntReadouts > 0 && cntReadouts < answerCount) {
          return 'If readout is set for one answer, it must be set for all of them.';
        }
      }

      return null;
    }

    function deleteAnswer(question, idx) {
      answerProps.forEach(function(propName) {
        if (question.properties['choice_' + idx + '_' + propName]) {
          delete question.properties['choice_' + idx + '_' + propName];
        }
      });
    }

    function removeExtraFields(question, quizType) {
      var answerCount = question.properties.answerCount;
      for (var i = 0; i < answerCount; i++) {
        var prop;
        for (var y = 0; y < answerProps.length; y++) {
          prop = answerProps[y];
          if (
            answerFieldIsNullOrEmpty(question.properties, prop, i) ||
            !answerFieldApplies(prop, quizType, question.type)
          ) {
            delete question.properties['choice_' + i + '_' + prop];
          }
        }
      }
      return question;
    }

    return {
      quizTypes: angular.copy(quizTypes),
      questionTypes: angular.copy(questionTypes),
      answerFieldApplies: answerFieldApplies,
      validateAnswers: validateAnswers,
      deleteAnswer: deleteAnswer,
      removeExtraFields: removeExtraFields
    };
  }
})();

(function() {
  'use strict';

  angular.module('quizControllers').service('quizSvc', quizSvc);
  quizSvc.$inject = ['Restangular'];

  function quizSvc(Restangular) {
    function getAll() {
      return Restangular.all('editor/quizzes').getList();
    }

    function getByStatus(status) {
      return Restangular.all('editor/quizzes?status=' + status).getList();
    }

    function save(quiz) {
      return Restangular.all('editor/quizzes').post(quiz);
    }

    return {
      save: save,
      getAll: getAll,
      getByStatus: getByStatus
    };
  }
})();

'use strict';

angular.module('quoteControllers').controller('QuoteEditCtrl', [
  '$log',
  '$modalInstance',
  'optionalScope',
  'quoteSvc',
  function($log, $modalInstance, optionalScope, quoteSvc) {
    var vm = this;
    _.extend(vm, optionalScope); // Copy properties from optionalScope into vm
    vm.quote = vm.record;

    $log.debug('model scope: ', vm);

    // Close the modal and return the section
    vm.save = function(quote) {
      quoteSvc.saveQuote(quote).then(function() {
        $modalInstance.close();
      });
    };

    // Dismiss the modal
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';

angular.module('quoteControllers').controller('QuoteListCtrl', [
  '$log',
  'quotes',
  '$modal',
  'quoteSvc',
  'topics',
  function($log, quotes, $modal, quoteSvc, topics) {
    var vm = this;

    vm.gridOptions = {
      data: quotes,
      columnDefs: [
        { name: 'id', displayName: 'Id' },
        { name: 'language', displayName: 'Language' },
        { name: 'title', displayName: 'Title' },
        { name: 'body', displayName: 'Quote' },
        { name: 'topic', displayName: 'Topic' },
        { name: 'source', displayName: 'Source' },
        { name: 'sourceUrl', displayName: 'Source URL' },
        {
          name: 'createdOn',
          displayName: 'Created On',
          type: 'date',
          cellFilter: 'date:"MM-dd-yyyy"'
        },
        {
          name: 'modifiedOn',
          displayName: 'Modified On',
          type: 'date',
          cellFilter: 'date:"MM-dd-yyyy"'
        },
        {
          name: 'actions',
          displayName: 'Actions',
          field: 'id',
          cellTemplate:
            '<div class="ui-grid-cell-contents action-items"><a data-ng-click="grid.appScope.vm.showEdit(false, COL_FIELD)">edit</a> | <a data-ng-click="grid.appScope.vm.deleteQuote(COL_FIELD)">delete</a></div>'
        }
      ]
    };

    /**
     * Open the modal for Creating/Editing quotes.
     * @param editMode boolean - False means this is a new record.
     */
    vm.showEdit = function(editMode, id) {
      var _record = findQuoteById(id);
      $modal
        .open({
          templateUrl: 'quote/templates/quoteEdit.html',
          controller: 'QuoteEditCtrl',
          controllerAs: 'vm',
          size: 'lg',
          resolve: {
            optionalScope: function() {
              return {
                isEdit: editMode,
                record: _record,
                uniqueTopics: topics
              };
            }
          }
        })
        .result.then(function(resp) {
          quoteSvc.findQuotes().then(function(resp) {
            vm.gridOptions.data = resp;
          });
          $log.debug('Saved resp: ', resp);
        });
    };

    /**
     * Delete a quote from the database
     * @param quoteId
     */
    vm.deleteQuote = function(quoteId) {
      var _record = findQuoteById(quoteId);
      if (
        window.confirm(
          'You are about to delete quote "' + _record.title + ' - continue?'
        )
      ) {
        quoteSvc.deleteQuote(quoteId).then(function() {
          quoteSvc.findQuotes().then(function(resp) {
            vm.gridOptions.data = resp;
          });
        });
      }
    };

    function findQuoteById(quoteId) {
      return _.isNumber(quoteId)
        ? _.find(vm.gridOptions.data, function(record) {
            return record.id === quoteId;
          })
        : undefined;
    }
  }
]);

'use strict';
/**
 * Handle all the API interaction around quotes.
 */
angular.module('quoteControllers').service('quoteSvc', [
  '$log',
  'Restangular',
  'alertService',
  function($log, Restangular, alertService) {
    /**
     * Find all quotes.
     * @return {promise}       A promise that will eventually have the quote's detailed information
     */
    this.findQuotes = function() {
      return Restangular.one('/user/quotes')
        .get()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem retrieving quotes: ', err);
          }
        );
    };

    /**
     * Update the quote
     * @param  {Object} quote The quote to update
     */
    this.saveQuote = function(quote) {
      $log.debug('updating quote: ', quote);
      return Restangular.one('editor/quotes')
        .customPOST(quote)
        .then(
          function() {
            alertService.addAlert({
              message: 'Quote saved!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to save quote: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    this.deleteQuote = function(quoteId) {
      $log.debug('Deleting quote with id: ', quoteId);
      return Restangular.one('/editor/quotes', quoteId)
        .remove()
        .then(
          function(resp) {
            return resp;
          },
          function(err) {
            $log.error('There was a problem deleting quote: ', err);
          }
        );
    };
  }
]);

'use strict';

angular.module('redirects').controller('RedirectListCtrl', [
  'redirects',
  'redirectSvc',
  'alertService',
  function(redirects, redirectSvc, alertService) {
    var vm = this;
    vm.save = function() {
      if (!vm.form.$valid) {
        angular.forEach(vm.form.$error.required, function(field) {
          field.$setDirty();
        });
        return;
      }
      redirectSvc.save(vm.editing).then(function() {
        redirectSvc.getAll().then(function(response) {
          vm.redirects = response;
          vm.editing = null;
          alertService.addAlert({
            message: 'Redirect saved',
            type: 'success'
          });
        });
      });
    };
    vm.redirects = redirects;
  }
]);

'use strict';
angular.module('redirects').service('redirectSvc', [
  'Restangular',
  function(Restangular) {
    var redirects = Restangular.all('editor/redirect');

    function save(redirect) {
      return redirects.post(redirect);
    }

    function getAll() {
      return redirects.getList();
    }
    return {
      save: save,
      getAll: getAll
    };
  }
]);

(function() {
  'use strict';

  angular
    .module('reportingControllers')
    .controller('ReportListCtrl', ReportListCtrl);

  ReportListCtrl.$inject = [];

  /* @ngInject */
  function ReportListCtrl() {
    var vm = this;
    vm.reportList = [
      {
        name: 'Daily Consumer Flash Report',
        location: 'reports.flash'
      },
      {
        name: 'Consumer Breakout Report',
        location: 'reports.consumerBreakout'
      },
      {
        name: 'Commercial Engagement Report',
        location: 'reports.commercialEngagement'
      },
      {
        name: 'Public Engagement Report',
        location: 'reports.publicEngagement'
      }
    ];
  }
})();

(function() {
  'use strict';

  angular
    .module('reportingControllers')
    .controller('ReportViewCtrl', ReportViewCtrl);

  ReportViewCtrl.$inject = ['$log', 'reportName'];

  function ReportViewCtrl($log, reportName) {
    var vm = this;
    vm.reportName = reportName;
    $log.debug('loading report: ', reportName);
  }
})();

(function() {
  'use strict';

  angular
    .module('reportingControllers')
    .directive('msTableauReport', tableauReport);

  tableauReport.$inject = ['$compile'];

  /* @ngInject */
  function tableauReport($compile) {
    var directive = {
      restrict: 'EA',
      scope: {
        msReportName: '='
      },
      link: function(scope, element) {
        var template =
          '<script type="text/javascript" src="https://reporting.mystrength.com/javascripts/api/viz_v1.js"></script>' +
          '<div class="tableauPlaceholder" style="width: 1000px; height: 1400px;">' +
          '<object class="tableauViz" width="1220px" height="1550px" style="display:none;">' +
          '  <param name="host_url" value="https%3A%2F%2Freporting.mystrength.com%2F" />' +
          '   <param name="site_root" value="" />' +
          '   <param name="name" value="' +
          scope.msReportName +
          '" />' +
          '   <param name="tabs" value="no" />' +
          '   <param name="toolbar" value="yes" />' +
          '   <param name="showShareOptions" value="true" />' +
          ' </object>' +
          '</div>';
        var linkFn = $compile(template);
        var content = linkFn(scope);
        element.append(content);
      }
    };
    return directive;
  }
})();

(function() {
  'use strict';
  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:clientActionsCtrl
   * @description
   * # ClientActionsCtrl
   * Allows editing of actions that should be taken on clients
   */
  angular
    .module('systemconfiguration')
    .controller('ClientActionsCtrl', ClientActionsCtrl);

  ClientActionsCtrl.$inject = [
    'clientActionsSvc',
    'clientApplicationSvc',
    'alertService'
  ];

  function ClientActionsCtrl(actionsSvc, clientSvc, alertService) {
    var vm = this;
    _initialize();

    vm.requestLocalCachePurge = function(client) {
      actionsSvc
        .requestLocalCachePurge(client)
        .then(
          () => {
            alertService.addAlert({
              message: 'Action updated',
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertService.addAlert({
              message: `Failed to update action: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          _loadData();
        });
    };

    vm.requestForcedLogout = function(client) {
      actionsSvc
        .requestForcedLogout(client)
        .then(
          () => {
            alertService.addAlert({
              message: 'Action updated',
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertService.addAlert({
              message: `Failed to update action: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          _loadData();
        });
    };

    vm.toggleCollapse = function(client) {
      vm.isCollapsed[client] = !vm.isCollapsed[client];
    };

    function _initialize() {
      vm.isCollapsed = {};
      _loadData().then(() => {
        vm.clients.forEach(client => {
          vm.isCollapsed[client] = false;
        });
      });
    }

    function _loadData() {
      vm.clients = [];
      vm.clientActions = {};

      return clientSvc
        .getAllClientApplications()
        .then(allClients => {
          allClients.forEach(client => {
            vm.clients.push(client.name);
            vm.clientActions[client.name] = {
              PURGE_LOCAL_CACHE: { timeToPurgeLocalCache: 'N/A' },
              FORCE_CLIENT_LOGOUT: { timeToForceLogout: 'N/A' }
            };
          });
        })
        .then(() => {
          return actionsSvc.getAllClientActions();
        })
        .then(allActions => {
          allActions.forEach(action => {
            vm.clientActions[action.client][action.action.actionType] =
              action.action;
          });
        });
    }
  }
})();

(function() {
  'use strict';
  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:clientApplicationCtrl
   * @description
   * # ClientCApplicationCtrl
   * Allows registering and configuration of client applications
   */
  angular
    .module('systemconfiguration')
    .controller('ClientApplicationCtrl', ClientApplicationCtrl);

  ClientApplicationCtrl.$inject = [
    'clientApplicationSvc',
    'alertService',
    '$modal'
  ];

  function ClientApplicationCtrl(clientSvc, alertService, $modal) {
    var vm = this;
    _initialize();

    vm.editExistingClient = function(client) {
      vm.isProcessing = true;
      _saveExistingConfiguration(client)
        .then(() => {
          return _loadData();
        })
        .finally(() => {
          vm.isProcessing = false;
        });
    };

    vm.registerNewClient = function() {
      vm.isProcessing = true;
      _showRegisterNewClientApplicationModal()
        .then(function(clientName) {
          return _registerNewClientApplication(clientName);
        })
        .then(() => {
          return _loadData();
        })
        .finally(() => {
          vm.isProcessing = false;
        });
    };

    function _initialize() {
      vm.isProcessing = false;
      _loadData();
    }

    function _loadData() {
      vm.clientConfigurations = {};
      clientSvc.getAllClientApplications().then(clients => {
        clients.forEach(function(client) {
          vm.clientConfigurations[client.name] = {};
          vm.clientConfigurations[client.name]['original'] = client;
          vm.clientConfigurations[client.name]['updated'] = JSON.parse(
            JSON.stringify(client)
          );
        });

        vm.clientNames = Object.keys(vm.clientConfigurations);
      });
    }

    function _showRegisterNewClientApplicationModal() {
      var modal = $modal.open({
        templateUrl:
          'systemconfiguration/templates/registerNewClientModal.html',
        controller: 'RegisterNewClientModalCtrl',
        controllerAs: 'vm',
        size: 'lg'
      });
      return modal.result;
    }

    function _saveExistingConfiguration(client) {
      return clientSvc.updateClientApplication(client).then(
        () => {
          alertService.addAlert({
            message: 'Configuration updated',
            type: 'success'
          });
        },
        error => {
          let message = error ? error.data.message : 'Unknown error';
          alertService.addAlert({
            message: `Failed to update configuration: ${message}`,
            type: 'danger'
          });
        }
      );
    }

    function _registerNewClientApplication(clientName) {
      return clientSvc.registerNewClientApplication(clientName).then(
        client => {
          alertService.addAlert({
            message: `Registered Client: ${client.name}`,
            type: 'success'
          });
        },
        error => {
          let message = error ? error.data.message : 'Unknown error';
          alertService.addAlert({
            message: `Failed to register client: ${message}`,
            type: 'danger'
          });
        }
      );
    }
  }
})();

(function() {
  'use strict';
  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:clientFeaturesCtrl
   * @description
   * # ClientFeaturesCtrl
   * Allows the features available on a client application to be configured
   */
  angular
    .module('systemconfiguration')
    .controller('ClientFeaturesCtrl', ClientFeaturesCtrl);

  ClientFeaturesCtrl.$inject = [
    'clientFeatureSvc',
    'clientApplicationSvc',
    'alertService',
    '$modal'
  ];

  function ClientFeaturesCtrl(featureSvc, clientSvc, alertSvc, $modal) {
    var vm = this;
    _initializeScreen();

    vm.startEditing = function() {
      vm.isEditing = true;
    };

    vm.stopEditing = function() {
      vm.isEditing = false;
    };

    vm.registerNewFeature = function() {
      _showRegisterNewFeatureModal().then(feature => {
        return featureSvc
          .registerNewClientFeature(feature)
          .then(
            feature => {
              alertSvc.addAlert({
                message: `Registered Feature: ${feature.name}`,
                type: 'success'
              });
            },
            error => {
              let message = error ? error.data.message : 'Unknown error';
              alertSvc.addAlert({
                message: `Failed to register feature: ${message}`,
                type: 'danger'
              });
            }
          )
          .then(() => {
            return _loadData();
          });
      });
    };

    vm.editFeature = function(feature) {
      _showEditFeatureModal(feature).then(editedFeature => {
        return featureSvc
          .editClientFeature(editedFeature)
          .then(
            () => {
              alertSvc.addAlert({
                message: `Updated Feature: ${editedFeature.name}`,
                type: 'success'
              });
            },
            error => {
              let message = error ? error.data.message : 'Unknown error';
              alertSvc.addAlert({
                message: `Failed to update feature: ${message}`,
                type: 'danger'
              });
            }
          )
          .then(() => {
            return _loadData();
          });
      });
    };

    vm.deleteFeature = function(feature) {
      _showDeleteFeatureModal(feature).then(() => {
        return featureSvc
          .deleteClientFeature(feature)
          .then(
            () => {
              alertSvc.addAlert({
                message: `Deleted Feature: ${feature.name}`,
                type: 'success'
              });
            },
            error => {
              let message = error ? error.data.message : 'Unknown error';
              alertSvc.addAlert({
                message: `Failed to delete feature: ${message}`,
                type: 'danger'
              });
            }
          )
          .then(() => {
            return _loadData();
          });
      });
    };

    vm.addFeatureFlag = function(feature, client) {
      featureSvc
        .registerFeatureFlag(feature, client)
        .then(
          () => {
            alertSvc.addAlert({
              message: `Added ${feature.name} flag for ${client}`,
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertSvc.addAlert({
              message: `failed to add feature flag: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          _loadData();
        });
    };

    vm.removeFeatureFlag = function(feature, client) {
      featureSvc
        .removeFeatureFlag(feature, client)
        .then(
          () => {
            alertSvc.addAlert({
              message: `Removed feature flag`,
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertSvc.addAlert({
              message: `failed to remove feature flag: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          _loadData();
        });
    };

    vm.toggleFeature = function(feature, client, wasOn) {
      featureSvc
        .setFeatureFlag(feature, client, !wasOn)
        .then(
          () => {
            let newStatus = wasOn ? 'OFF' : 'ON';
            alertSvc.addAlert({
              message: `${feature.name} turned ${newStatus} for ${client}`,
              type: 'success'
            });
          },
          error => {
            let message = error ? error.data.message : 'Unknown error';
            alertSvc.addAlert({
              message: `failed to toggle feature: ${message}`,
              type: 'danger'
            });
          }
        )
        .finally(() => {
          _loadData();
        });
    };

    function _initializeScreen() {
      vm.isEditing = false;
      _loadData();
    }

    function _loadData() {
      featureSvc
        .getAllFlaggableClientFeatures()
        .then(features => (vm.features = features))
        .then(() => clientSvc.getAllClientApplications())
        .then(clients => (vm.clients = clients.map(client => client.name)));
    }

    function _showRegisterNewFeatureModal() {
      var modal = $modal.open({
        templateUrl: 'systemconfiguration/templates/editFeatureModal.html',
        controller: 'editFeatureModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          feature: function() {
            return null;
          }
        }
      });
      return modal.result;
    }

    function _showEditFeatureModal(feature) {
      var modal = $modal.open({
        templateUrl: 'systemconfiguration/templates/editFeatureModal.html',
        controller: 'editFeatureModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          feature: function() {
            return feature;
          }
        }
      });
      return modal.result;
    }

    function _showDeleteFeatureModal(feature) {
      var modal = $modal.open({
        templateUrl:
          'systemconfiguration/templates/confirmFeatureDeletionModal.html',
        controller: 'confirmFeatureDeletionModalCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          feature: function() {
            return feature;
          }
        }
      });
      return modal.result;
    }
  }
})();

(function() {
  'use strict';

  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:confirmFeatureDeletionModalCtrl.js
   * @description
   * # confirmFeatureDeletionModalCtrl
   */
  angular
    .module('systemconfiguration')
    .controller(
      'confirmFeatureDeletionModalCtrl',
      ConfirmFeatureDeletionModalCtrl
    );

  ConfirmFeatureDeletionModalCtrl.$inject = ['feature', '$modalInstance'];

  function ConfirmFeatureDeletionModalCtrl(feature, $modalInstance) {
    var vm = this;
    vm.feature = feature;

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.confirm = function() {
      $modalInstance.close();
    };
  }
})();

(function() {
  'use strict';

  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:editFeatureModalCtrl.js
   * @description
   * # editFeatureModalCtrl
   */
  angular
    .module('systemconfiguration')
    .controller('editFeatureModalCtrl', EditFeatureModalCtrl);

  EditFeatureModalCtrl.$inject = ['feature', '$modalInstance'];

  function EditFeatureModalCtrl(feature, $modalInstance) {
    var vm = this;

    if (feature) {
      vm.inEditMode = true;
      vm.feature = {
        id: feature.id,
        name: feature.name,
        description: feature.description,
        featureFlags: feature.featureFlags
      };
    } else {
      vm.inEditMode = false;
      vm.feature = {
        name: '',
        description: ''
      };
    }

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.confirm = function() {
      $modalInstance.close(vm.feature);
    };
  }
})();

(function() {
  'use strict';

  /**
   * @ngdoc function
   * @name mystrengthAdminApp.controller:registerNewClientModalCtrl.js
   * @description
   * # registerNewClientModalCtrl
   */
  angular
    .module('systemconfiguration')
    .controller('RegisterNewClientModalCtrl', RegisterNewClientModalCtrl);

  RegisterNewClientModalCtrl.$inject = ['$modalInstance'];

  function RegisterNewClientModalCtrl($modalInstance) {
    var vm = this;
    vm.clientApplicationName = '';

    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };

    vm.confirm = function() {
      $modalInstance.close(vm.clientApplicationName);
    };
  }
})();

'use strict';
angular.module('systemconfiguration').service('clientActionsSvc', [
  'Restangular',
  function(Restangular) {
    var clientActions = Restangular.all('/editor/clientconfiguration/actions');

    function getAllClientActions() {
      return clientActions.getList().then(actions => {
        return actions.map(action => {
          return action.plain();
        });
      });
    }

    function requestLocalCachePurge(client) {
      return clientActions
        .post({
          client: client,
          action: {
            actionType: 'PURGE_LOCAL_CACHE',
            timeToPurgeLocalCache: new Date()
          }
        })
        .then(action => {
          return action.plain();
        });
    }

    function requestForcedLogout(client) {
      return clientActions
        .post({
          client: client,
          action: {
            actionType: 'FORCE_CLIENT_LOGOUT',
            timeToForceLogout: new Date()
          }
        })
        .then(action => {
          return action.plain();
        });
    }

    return {
      getAllClientActions: getAllClientActions,
      requestLocalCachePurge: requestLocalCachePurge,
      requestForcedLogout: requestForcedLogout
    };
  }
]);

'use strict';
angular.module('systemconfiguration').service('clientApplicationSvc', [
  'Restangular',
  function(Restangular) {
    var clientApplications = Restangular.all(
      `/editor/clientconfiguration/clients`
    );

    function getAllClientApplications() {
      return clientApplications.getList().then(clients => {
        return clients.map(client => {
          return client.plain();
        });
      });
    }

    function registerNewClientApplication(clientName) {
      return clientApplications
        .post({
          name: clientName
        })
        .then(newClient => {
          return newClient.plain();
        });
    }

    function updateClientApplication(client) {
      return Restangular.all(`/editor/clientconfiguration/clients/${client.id}`)
        .post(client)
        .then(updatedClient => {
          return updatedClient.plain();
        });
    }

    return {
      getAllClientApplications: getAllClientApplications,
      registerNewClientApplication: registerNewClientApplication,
      updateClientApplication: updateClientApplication
    };
  }
]);

'use strict';
angular.module('systemconfiguration').service('clientFeatureSvc', [
  'Restangular',
  function(Restangular) {
    var allFeatures = Restangular.all('editor/clientconfiguration/features');

    function getAllFlaggableClientFeatures() {
      return allFeatures.getList().then(features => {
        return features.map(feature => {
          return feature.plain();
        });
      });
    }

    function registerNewClientFeature(feature) {
      return allFeatures.post(feature).then(newFeature => {
        return newFeature.plain();
      });
    }

    function editClientFeature(feature) {
      return Restangular.all(
        `editor/clientconfiguration/features/${feature.id}`
      )
        .post(feature)
        .then(newFeature => {
          return newFeature.plain();
        });
    }

    function deleteClientFeature(feature) {
      return Restangular.all(
        `editor/clientconfiguration/features/${feature.id}`
      ).remove();
    }

    function registerFeatureFlag(feature, client) {
      return setFeatureFlag(feature, client, false);
    }

    function setFeatureFlag(feature, client, newStatus) {
      return Restangular.one(
        `editor/clientconfiguration/features/${feature.id}/clients/${client}`
      ).post('', {
        isActivated: newStatus
      });
    }

    function removeFeatureFlag(feature, client) {
      return Restangular.one(
        `editor/clientconfiguration/features/${feature.id}/clients/${client}`
      ).remove();
    }

    return {
      getAllFlaggableClientFeatures: getAllFlaggableClientFeatures,
      registerNewClientFeature: registerNewClientFeature,
      editClientFeature: editClientFeature,
      deleteClientFeature: deleteClientFeature,
      registerFeatureFlag: registerFeatureFlag,
      setFeatureFlag: setFeatureFlag,
      removeFeatureFlag: removeFeatureFlag
    };
  }
]);

'use strict';
/**
 * Allows admins to change a user's access code. This lists out all the organizations,
 * and when one is selected it lists the access codes to choose from.
 */
angular.module('userControllers').controller('UserAccessCodeCtrl', [
  '$scope',
  'userSvc',
  'orgSvc',
  function($scope, userSvc, orgSvc) {
    $scope.isEdit = true;
    $scope.userTenancy = {};
    $scope.userTenancy.user = $scope.user;
    $scope.userTenancy.organizations = [];
    refreshAccessCodes();

    /**
     * Load all the available organizations to have a list they can select from
     */
    orgSvc.getAll().then(function(resp) {
      $scope.userTenancy.organizations = resp;
    });

    // load access codes
    $scope.orgChange = function() {
      refreshAccessCodes();
    };

    $scope.save = function() {
      $scope.form.processing = true;
      userSvc
        .updateAccessCode(
          $scope.userTenancy.user,
          $scope.userTenancy.user.accessCode
        )
        .finally(function() {
          $scope.form.processing = false;
        });
    };

    // Getting access codes
    function refreshAccessCodes() {
      if ($scope.userTenancy.user.organization) {
        orgSvc
          .getAccessCodes($scope.userTenancy.user.organization.shortName)
          .then(function(resp) {
            $scope.userTenancy.accessCodes = resp;
          });
      }
    }
  }
]);

'use strict';
/**
 * Allows admins to change a user's access code. This lists out all the organizations,
 * and when one is selected it lists the access codes to choose from.
 */
angular
  .module('userControllers')
  .controller('UserAssessmentsCtrl', UserAssessmentCtrl);
UserAssessmentCtrl.$inject = ['$scope', 'userSvc'];

function UserAssessmentCtrl($scope, userSvc) {
  var vm = this;

  vm.assessmentResponseSummaries = $scope.user.assessmentResponseSummaries;
  vm.processing = false;

  vm.getAssessmentResponseSummaries = function() {
    if (vm.processing || vm.assessmentResponseSummaries) {
      return;
    }
    vm.processing = true;
    userSvc
      .getAssessmentResponseSummaries($scope.user.id)
      .then(function(response) {
        vm.assessmentResponseSummaries = response.plain();
      })
      .finally(function() {
        vm.processing = false;
      });
  };

  vm.getDaysBetween = function(d1, d2) {
    var _diff = new TimeSpan(new Date(d1) - new Date(d2));
    return Math.abs(_diff.days);
  };
}

'use strict';
angular
  .module('userControllers')
  .controller('UserAuthorizationsCtrl', UserAuthorizationsCtrl);
UserAuthorizationsCtrl.$inject = [
  '$modalInstance',
  'userSvc',
  'orgSvc',
  'userId',
  'organization',
  'permission'
];

function UserAuthorizationsCtrl(
  $modalInstance,
  userSvc,
  orgSvc,
  userId,
  organization,
  permission
) {
  var vm = this;
  vm.orgName = organization.name;
  vm.accessLevel = 'ORGANIZATION';
  vm.availableGroups = [];
  vm.availableCodes = [];
  vm.codeSelection = [];
  vm.groupSelection = [];
  vm.permission = permission;
  activate();

  function activate() {
    userSvc.getPartnerAuthorizations(userId).then(
      function(resp) {
        vm.partnerAuths = _.filter(resp.plain(), {
          permissionName: permission
        });
        getGroupsForOrg();
        getAccessCodesForOrg();
      },
      function(err) {
        vm.message = 'Unable to retrieve authorizations: ' + err;
      }
    );
  }

  function getGroupsForOrg() {
    orgSvc.getGroups(organization.shortName, true).then(function(resp) {
      if (resp.length > 0) {
        vm.availableGroups = resp;
        _.forEach(vm.partnerAuths, function(a) {
          let match = _.find(vm.availableGroups, {
            partnerCollateralId: a.partnerCollateralId
          });
          if (match) {
            vm.groupSelection.push(match);
            vm.availableGroups.splice(vm.availableGroups.indexOf(match), 1);
          }
        });
        if (vm.groupSelection.length > 0) {
          vm.accessLevel = 'GROUP';
        }
      }
    });
  }

  function getAccessCodesForOrg() {
    orgSvc.getAccessCodes(organization.shortName).then(function(resp) {
      if (resp.length > 0) {
        vm.availableCodes = _.filter(resp.plain(), { type: 'CONSUMER' });
        _.forEach(vm.partnerAuths, function(a) {
          let match = _.find(vm.availableCodes, {
            partnerCollateralId: a.partnerCollateralId
          });
          if (match) {
            vm.codeSelection.push(match);
            vm.availableCodes.splice(vm.availableCodes.indexOf(match), 1);
          }
        });
        if (vm.codeSelection.length > 0) {
          vm.accessLevel = 'CODE';
        }
      }
    });
  }

  vm.resetMsg = () => {
    vm.message = '';
  };

  vm.save = () => {
    let newAuths = [];
    switch (vm.accessLevel) {
      case 'ORGANIZATION':
        // list of newAuths only need to include ac or grp
        break;
      case 'GROUP':
        if (vm.groupSelection.length === 0) {
          vm.message = 'At least one group must be selected';
          return;
        }
        _.forEach(vm.groupSelection, function(val) {
          newAuths.push({ partnerCollateralId: val.partnerCollateralId });
        });
        break;
      case 'CODE':
        if (vm.codeSelection.length === 0) {
          vm.message = 'At least one access code must be selected';
          return;
        }
        _.forEach(vm.codeSelection, function(val) {
          newAuths.push({ partnerCollateralId: val.partnerCollateralId });
        });
        break;
      default:
        return;
    }

    userSvc
      .updatePartnerAuthorizations(userId, newAuths, permission)
      .then(function() {
        $modalInstance.close();
      });
  };

  vm.cancel = () => {
    $modalInstance.dismiss('cancel');
  };
}

'use strict';
/**
 * This is an admin component that allows admin to manage clinical coaching for a member
 */
angular
  .module('userControllers')
  .controller('UserClinicalCoachingCtrl', UserClinicalCoachingCtrl);
UserClinicalCoachingCtrl.$inject = ['$scope', 'userSvc'];

function UserClinicalCoachingCtrl($scope, userSvc) {
  var vm = this;

  const defaultSelectedCoach = {
    id: null,
    schedulerId: null,
    label: '-- Please choose a clinical coach --'
  };

  vm.reason = '';
  vm.clinicalCoachingStatusFetched = false;
  vm.clinicalCoachingStatus = '';
  vm.clinicalCoaches = [];
  vm.selectedClinicalCoach = defaultSelectedCoach;
  vm.currentClinicalCoachId = null;

  _init();

  vm.deactivate = function() {
    if (window.confirm('confirm?') === true) {
      userSvc
        .deactivateUserClinicalCoaching($scope.user.memberUuid, vm.reason)
        .then(() => {
          _getClinicalCoachingStatus();
        });
    }
  };

  vm.changeClinicalCoach = function() {
    userSvc
      .changeClinicalCoach(
        $scope.user.memberUuid,
        vm.selectedClinicalCoach.schedulerId
      )
      .then(() => {
        _init();
      });
  };

  function _getClinicalCoachingStatus() {
    if (!$scope.user.memberUuid) {
      vm.clinicalCoachingStatus = 'INACTIVE';
      vm.clinicalCoachingStatusFetched = true;
    } else {
      userSvc
        .getUserClinicalCoachingStatus($scope.user.memberUuid)
        .then(response => {
          vm.clinicalCoachingStatusFetched = true;
          vm.clinicalCoachingStatus = response.memberStatus;
          vm.currentClinicalCoachId = response.currentCoachId;

          // Call after status to avoid race condition
          _getClinicalCoachList();
        });
    }
  }

  function _getClinicalCoachList() {
    userSvc.getClinicalCoachList().then(response => {
      const coachesList = response
        .plain()
        .filter(coach => {
          return coach.active === true || coach.active === 'true';
        })
        .map(coach => {
          return {
            id: coach.id,
            schedulerId: coach.schedulerId,
            label: `${coach.name} - Acuity schedulerId: ${coach.schedulerId}`
          };
        });

      // Add initial value
      coachesList.unshift(defaultSelectedCoach);

      // Update vm value
      vm.clinicalCoaches = coachesList;

      // Set selected coach
      if (vm.currentClinicalCoachId) {
        const currentCoach = vm.clinicalCoaches.find(
          coach => coach.id === vm.currentClinicalCoachId
        );

        vm.selectedClinicalCoach = currentCoach || defaultSelectedCoach;
      }
    });
  }

  function _init() {
    _getClinicalCoachingStatus();
  }
}

'use strict';
angular
  .module('userControllers')
  .controller('UserCoachAssignmentCtrl', UserCoachAssignmentCtrl);
UserCoachAssignmentCtrl.$inject = [
  '$modalInstance',
  'alertService',
  'coachingSvc',
  'userId',
  'coachingAssignment'
];

function UserCoachAssignmentCtrl(
  $modalInstance,
  alertService,
  coachingSvc,
  userId,
  coachingAssignment
) {
  const vm = this;
  vm.coach = '';
  const hasCoach = coachingAssignment && coachingAssignment.permanentCoachId;
  activate();

  function activate() {
    coachingSvc.getCoaches().then(data => {
      vm.coaches = data.plain().map(coach => {
        coach.optionText = _getCoachOptionText(coach);
        return coach;
      });

      if (hasCoach) {
        vm.coachSelected = _.find(vm.coaches, {
          id: coachingAssignment.permanentCoachId
        });
      }
    });
  }

  vm.save = () => {
    if (!vm.coachSelected && hasCoach) {
      coachingSvc
        .removeCoachingAssignment(userId, coachingAssignment.currentCoachId)
        .then(() => $modalInstance.close(null));
    } else if (vm.coachSelected) {
      coachingSvc.saveCoachingAssignment(userId, vm.coachSelected.id).then(
        result => $modalInstance.close(result),
        error => {
          const msg = error ? error.data.message : 'Unknown error';
          alertService.addAlert({
            message: `Could not assign user to coach: ${msg}`,
            type: 'danger'
          });
        }
      );
    }
  };

  vm.cancel = () => {
    $modalInstance.dismiss('cancel');
  };

  function _getCoachOptionText(coach) {
    let text = coach.name;
    if (coach.isTestCoach) {
      text += ' - Test Coach';
    }
    return text;
  }
}

'use strict';
angular
  .module('userControllers')
  .controller('UserCoachingCtrl', UserCoachingCtrl);

UserCoachingCtrl.$inject = ['$scope', '$modal', 'authInterceptor'];

function UserCoachingCtrl($scope, $modal, authInterceptor) {
  $scope.allowCoachAssignment = authInterceptor.hasPermission([
    'COACH_MANAGEMENT'
  ]);
  $scope.openModal = function() {
    let modal = $modal.open({
      templateUrl: 'users/templates/userCoachingModal.html',
      controller: 'UserCoachAssignmentCtrl',
      controllerAs: 'vm',
      size: 'lg',
      resolve: {
        userId: $scope.user.id,
        coachingAssignment: $scope.user.coachingAssignment
      }
    });
    modal.result.then(result => {
      // update user
      if (!result) {
        $scope.user.coachingAssignment = null;
      } else {
        $scope.user.coachingAssignment = result;
      }
    });
  };
}

'use strict';
/**
 * This is an admin component that allows admin to request an account deletion on behalf of user.
 */
angular.module('userControllers').controller('UserDeleteCtrl', UserDeleteCtrl);
UserDeleteCtrl.$inject = ['$scope', 'userSvc'];

function UserDeleteCtrl($scope, userSvc) {
  var vm = this;
  vm.reason = '';

  vm.save = function() {
    userSvc.requestUserDeletion($scope.user.id, vm.reason);
  };
}

'use strict';
angular
  .module('userControllers')
  .controller('UserDeletionRequestsCtrl', UserDeletionRequestsCtrl);

UserDeletionRequestsCtrl.$inject = ['userSvc', 'alertService'];

function UserDeletionRequestsCtrl(userSvc, alertService) {
  var vm = this;
  vm.cancel = _cancel;
  vm.getRequests = _getRequests;
  vm.statusFilter = '';
  vm.deletionRequests = [];
  _getRequests();

  function _getRequests() {
    userSvc.getUserDeletionRequests(vm.statusFilter).then(function(result) {
      vm.deletionRequests = result;
    });
  }

  function _cancel(userId) {
    if (window.confirm('Are you sure you want to cancel this request?')) {
      userSvc.cancelUserDeletionRequest(userId).then(function() {
        alertService.addAlert({
          type: 'success',
          message: 'Request was cancelled'
        });
        _getRequests();
      });
    }
  }
}

/**
 * Handle the saving of user details
 */
(function() {
  'use strict';

  angular
    .module('userControllers')
    .controller('UserDetailCtrl', UserDetailCtrl);

  UserDetailCtrl.$inject = ['$scope', 'userSvc', 'alertService'];

  function UserDetailCtrl($scope, userSvc, alertService) {
    $scope.showSleepReminderEmailToggle =
      $scope.user.userPreferences['sleep_diary_enabled:true'];
    $scope.user.userPreferences.optedIntoSleepDiaryEmails = $scope.user
      .userPreferences['sleep_diary_reminder:email']
      ? 'true'
      : 'false';
    $scope.showPushNotificationPreferences = !!$scope.user.userPreferences[
      'pushOptInPromptCount'
    ];

    $scope.isEdit = true;
    $scope.save = function() {
      if (!$scope.user.emailAddress) {
        alertService.addAlert({
          message: `Unable to save user, no email address is specified`,
          type: 'danger'
        });
        return;
      }

      $scope.form.processing = true;
      userSvc
        .update($scope.user)
        .then(function(updatedUser) {
          $scope.user = updatedUser;
          $scope.user.userPreferences.optedIntoSleepDiaryEmails = $scope.user
            .userPreferences['sleep_diary_reminder:email']
            ? 'true'
            : 'false';
        })
        .finally(function() {
          $scope.form.processing = false;
        });
    };
  }
})();

'use strict';
angular.module('userControllers').controller('UserMfaCtrl', UserMfaCtrl);

UserMfaCtrl.$inject = ['$scope', 'userSvc', 'alertService'];

function UserMfaCtrl($scope, userSvc, alertService) {
  $scope.isEdit = false;
  $scope.showAuthForm = false;

  this.$onInit = function() {
    if ($scope.user && $scope.user.id) {
      userSvc.getUserMfaSettings($scope.user.id).then(function(resp) {
        $scope.authSettings = resp.plain();
        $scope.showAuthForm = true;
        $scope.isEdit = true;
      });
    }
  };

  $scope.mfaKeyTypes = [
    { id: 'AUTHENTICATOR', label: 'Google Authenticator' },
    { id: 'EMAIL', label: 'Email' }
  ];

  $scope.enableForm = function() {
    $scope.showAuthForm = true;
    $scope.authSettings = {
      mfaKeyType: 'AUTHENTICATOR'
    };
  };

  $scope.save = function() {
    if ($scope.authSettings.mfaKeyType === 'EMAIL' && !canHaveEmailMfa()) {
      alertService.addAlert({
        message: `Unable to select email MFA type. This is only available if user is limited to User Dashboard role.`,
        type: 'danger'
      });
      return;
    }
    userSvc
      .updateUserMfaSettings($scope.user.id, $scope.authSettings, $scope.isEdit)
      .then(function(resp) {
        $scope.authSettings = resp.plain();
        $scope.isEdit = true;
      });
  };

  function canHaveEmailMfa() {
    return (
      $scope.user.roles.length === 1 && $scope.user.roles[0].name === 'CONSOLE'
    );
  }
}

'use strict';
/**
 * Facilitates an admin saving a user's password
 */
angular.module('userControllers').controller('UserPasswordCtrl', [
  '$scope',
  'userSvc',
  'alertService',
  function($scope, userSvc, alertService) {
    $scope.isEdit = true;
    $scope.save = function() {
      if (!$scope.user.password) {
        alertService.addAlert({
          message: 'A password must be entered before it can be updated'
        });
        return;
      }
      $scope.form.processing = true;
      userSvc
        .updatePassword($scope.user, $scope.user.password)
        .finally(function() {
          $scope.form.processing = false;
        });
    };
  }
]);

'use strict';
angular.module('userControllers').controller('UserRolesCtrl', [
  '$scope',
  '$modal',
  'userSvc',
  'authInterceptor',
  function($scope, $modal, userSvc, authInterceptor) {
    $scope.isEdit = true;
    $scope.employeeRoles = [
      {
        label: 'Customer Support',
        info: 'Assists customers with account issues',
        tag: 'CUSTOMER_SUPPORT',
        showAuthOptions: false
      },
      {
        label: 'Account Manager',
        info: 'Manages orgs, access codes, and other partner-related data',
        tag: 'ACCOUNT_MANAGER',
        showAuthOptions: false
      },
      {
        label: 'Community Moderator',
        info: 'Manages user content such as inspirations and discussions',
        tag: 'COMMUNITY_MODERATOR',
        showAuthOptions: false
      },
      {
        label: 'Coach',
        info: 'Uses coaching dashboard to respond to coaching participants',
        tag: 'COACH',
        showAuthOptions: false
      },
      {
        label: 'Coach Supervisor',
        info: 'Manages coaches',
        tag: 'COACH_SUPERVISOR',
        showAuthOptions: false
      },
      {
        label: 'Content Editor',
        info: 'Manages site content such as activities and inspirations',
        tag: 'EDITOR',
        showAuthOptions: false
      },
      {
        label: 'Content Read-only',
        info: 'Has read-only access to site content',
        tag: 'CONTENT_VIEWER',
        showAuthOptions: false
      },
      {
        label: 'System Admin',
        info: 'An employee with full administrative access',
        tag: 'SYSTEM_ADMIN',
        showAuthOptions: false
      }
    ];
    $scope.partnerRoles = [
      {
        label: 'User Dashboard',
        info: 'Can access user dashboard',
        tag: 'CONSOLE',
        showAuthOptions: true
      },
      {
        label: 'User Dashboard - single user',
        info: 'Can access single user on user dashboard',
        tag: 'CONSOLE_SINGLE_PATIENT',
        showAuthOptions: true
      },
      {
        label: 'Partner API',
        info: 'Can get authorized data via Partner API',
        tag: 'PARTNER',
        showAuthOptions: true
      }
    ];

    $scope.isSystemAdmin = false;

    this.$onInit = function() {
      if (authInterceptor.hasPermission(['SYSTEM_MANAGEMENT'])) {
        $scope.isSystemAdmin = true;
      }

      if ($scope.user && $scope.user.roles) {
        $scope.employeeRoles.forEach(function(theRole) {
          setRoleEnabled(theRole);
        });
        $scope.partnerRoles.forEach(function(theRole) {
          setRoleEnabled(theRole);
        });
      }
    };

    function setRoleEnabled(theRole) {
      var match = _.find($scope.user.roles, function(role) {
        return role.name.toUpperCase() === theRole.tag;
      });
      if (match) {
        theRole.enabled = 'true';
      } else {
        theRole.enabled = 'false';
      }
    }

    $scope.openAuthModal = function(permissionName) {
      $modal.open({
        templateUrl: 'users/templates/userAuthorizationsModal.html',
        controller: 'UserAuthorizationsCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          userId: $scope.user.id,
          organization: $scope.user.organization,
          permission: function() {
            return permissionName;
          }
        }
      });
    };

    $scope.save = function() {
      $scope.form.processing = true;
      const roles = $scope.employeeRoles.concat($scope.partnerRoles);
      const newRoles = _.filter(roles, { enabled: 'true' });
      const roleTags = _.map(newRoles, 'tag');
      userSvc
        .updateRoles($scope.user, roleTags)
        .then(function() {
          userSvc.findUser($scope.user.emailAddress).then(function(user) {
            $scope.user = user;
          });
        })
        .finally(function() {
          $scope.form.processing = false;
        });
    };

    //init();
  }
]);

'use strict';
/**
 * A controller to find a user in the database by email
 */
angular.module('userControllers').controller('UserSearchCtrl', [
  '$scope',
  'userSvc',
  'alertService',
  '$location',
  '$anchorScroll',
  function($scope, userSvc, alertService, $location, $anchorScroll) {
    $scope.email = '';
    $scope.exactMatch = '';
    $scope.loading = false;

    /**
     * Find the user with the email current in scope
     * @return {[type]} [description]
     */
    $scope.find = function() {
      if (!$scope.email) {
        alertService.addAlert({
          message: 'This does not appear to be a valid email'
        });
        return;
      }
      $scope.user = null;
      $scope.userList = null;
      $scope.loading = true;
      if ($scope.exactMatch === '') {
        userSvc.findUser($scope.email).then(function(resp) {
          $scope.user = resp;
          $scope.loading = false;
        });
      } else {
        userSvc.searchUsers($scope.email).then(function(resp) {
          $scope.userList = resp;
          $scope.loading = false;
        });
      }
    };

    $scope.setUser = function(user) {
      $scope.user = user;
      $location.hash('userTop');
      $anchorScroll();
    };
  }
]);

'use strict';
/**
 * Handle the saving of user status and account type
 */
angular.module('userControllers').controller('UserStatusCtrl', [
  '$scope',
  'userSvc',
  'alertService',
  function($scope, userSvc, alertService) {
    let newAccountType = null;

    $scope.isBH20 = $scope.user.clientOfferingVersion === 'BH2_0';
    $scope.isEdit = true;
    $scope.accountTypes = [
      { value: 'DEFAULT', label: 'DEFAULT - the standard account type' },
      {
        value: 'TEST',
        label: 'TEST - account is used for testing and can be auto-deleted'
      },
      {
        value: 'PRIVILEGED',
        label:
          'PRIVILEGED - account has/had access to other user data and is external to myStrength'
      },
      {
        value: 'NOT_REPORTED',
        label: 'NOT REPORTED - account should not be included in reports'
      },
      {
        value: 'EMPLOYEE',
        label:
          'EMPLOYEE - myStrength employee with user roles or who needs test access'
      },
      {
        value: 'SYSTEM',
        label: 'SYSTEM - account is used for backend processing'
      }
    ];
    $scope.typeUpdateDisabled =
      $scope.user.accountType === 'EMPLOYEE' ||
      $scope.user.accountType === 'SYSTEM';

    function setAccountType() {
      $scope.selectedAccountType = _.find($scope.accountTypes, {
        value: $scope.user.accountType
      });
    }

    $scope.changeAccountType = function(selected) {
      newAccountType = selected.value;
    };

    $scope.$watch('user.accountType', function() {
      setAccountType();
    });

    $scope.saveStatus = function() {
      $scope.form.processing = true;
      userSvc
        .updateStatus($scope.user.id, $scope.user.status)
        .then(function() {
          userSvc.findUser($scope.user.emailAddress).then(function(user) {
            $scope.user = user;
          });
        })
        .finally(function() {
          $scope.form.processing = false;
        });
    };

    $scope.saveAccountType = function() {
      if (
        $scope.user.accountType === 'PRIVILEGED' &&
        newAccountType !== 'EMPLOYEE' &&
        newAccountType !== 'SYSTEM'
      ) {
        alertService.addAlert({
          message:
            'PRIVILEGED account type can only be changed to EMPLOYEE or SYSTEM ',
          type: 'danger'
        });
        return;
      }
      $scope.form.processing = true;
      userSvc
        .updateAccountType($scope.user.id, newAccountType)
        .then(function() {
          $scope.user.accountType = newAccountType;
        })
        .finally(function() {
          $scope.form.processing = false;
        });
    };
  }
]);

'use strict';
/**
 * This is an admin component that allows an admin to change the user's access code
 */
angular.module('userControllers').directive('msUserAccessCode', [
  function() {
    return {
      templateUrl: 'users/templates/useraccesscode.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserAccessCode'
      },
      controller: 'UserAccessCodeCtrl'
    };
  }
]);

'use strict';
/**
 * this is cloned from the app repo, and should be replaced with some sort of component at some point...
 */
angular.module('userControllers').directive('msAgeInput', [
  function() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(scope, element, attr, ngModelCtrl) {
        ngModelCtrl.$parsers.push(function(text) {
          var allNum = text.replace(/[^0-9]/g, '');
          if (allNum !== text) {
            ngModelCtrl.$setViewValue(allNum);
            ngModelCtrl.$render();
          }
          var age = parseInt(allNum);
          if (age < 13) {
            ngModelCtrl.$setValidity('age', false);
          } else {
            ngModelCtrl.$setValidity('age', true);
          }
          return age;
        });
      }
    };
  }
]);

'use strict';
/**
 * This is an admin component that allows an admin to change the user's access code
 */
angular.module('userControllers').directive('msUserAssessments', [
  function() {
    return {
      templateUrl: 'users/templates/userassessments.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserAssessments'
      },
      controller: 'UserAssessmentsCtrl',
      controllerAs: 'vm'
    };
  }
]);

(function() {
  'use strict';
  /**
   * Allows admins to make clinical coaching changes associated with a user
   */
  angular.module('userControllers').directive('msUserClinicalCoaching', [
    function() {
      return {
        templateUrl: 'users/templates/userClinicalCoaching.html',
        replace: true,
        restrict: 'AE',
        scope: {
          reason: '=reason',
          user: '=msUserClinicalCoaching'
        },
        controller: 'UserClinicalCoachingCtrl',
        controllerAs: 'vm'
      };
    }
  ]);
})();

(function() {
  'use strict';
  /**
   * Allows admins to change a user's roles/permissions
   */
  angular.module('userControllers').directive('msUserCoaching', [
    function() {
      return {
        templateUrl: 'users/templates/userCoaching.html',
        replace: true,
        restrict: 'AE',
        scope: {
          user: '=msUserCoaching'
        },
        controller: 'UserCoachingCtrl'
      };
    }
  ]);
})();

(function() {
  'use strict';
  /**
   * This is an admin component that allows admin to request an account deletion on behalf of user.
   */
  angular.module('userControllers').directive('msUserDelete', [
    function() {
      return {
        templateUrl: 'users/templates/userDelete.html',
        replace: true,
        restrict: 'AE',
        scope: {
          user: '=msUserDelete'
        },
        controller: 'UserDeleteCtrl',
        controllerAs: 'vm'
      };
    }
  ]);
})();

'use strict';
/**
 * Lists out all of the user's attributes and allows admin changes
 */
angular.module('userControllers').directive('msUserDetail', [
  function() {
    return {
      templateUrl: 'users/templates/userdetail.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserDetail'
      },
      controller: 'UserDetailCtrl'
    };
  }
]);

(function() {
  'use strict';
  /**
   * Allows admins to change a user's MFA settings
   */
  angular.module('userControllers').directive('msUserMfa', [
    function() {
      return {
        templateUrl: 'users/templates/userMfa.html',
        replace: true,
        restrict: 'AE',
        scope: {
          user: '=msUserMfa'
        },
        controller: 'UserMfaCtrl'
      };
    }
  ]);
})();

'use strict';
/**
 * Allows admins to change a user's password
 */
angular.module('userControllers').directive('msUserPassword', [
  function() {
    return {
      templateUrl: 'users/templates/userpassword.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserPassword'
      },
      controller: 'UserPasswordCtrl'
    };
  }
]);

'use strict';
/**
 * The complete user record - contains user detail and other directives
 */
angular.module('userControllers').directive('msUserRecord', [
  function() {
    return {
      templateUrl: 'users/templates/userRecord.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserRecord'
      }
    };
  }
]);

(function() {
  'use strict';
  /**
   * Allows admins to change a user's roles/permissions
   */
  angular.module('userControllers').directive('msUserRoles', [
    function() {
      return {
        templateUrl: 'users/templates/userRoles.html',
        replace: true,
        restrict: 'AE',
        scope: {
          user: '=msUserRoles'
        },
        controller: 'UserRolesCtrl'
      };
    }
  ]);
})();

'use strict';
/**
 * Lists out all of the user's attributes and allows admin changes
 */
angular.module('userControllers').directive('msUserStatus', [
  function() {
    return {
      templateUrl: 'users/templates/userstatus.html',
      replace: true,
      restrict: 'AE',
      scope: {
        user: '=msUserStatus'
      },
      controller: 'UserStatusCtrl'
    };
  }
]);

'use strict';
/**
 * Stolen from the app repo - this should become a component at some point
 *
 * Validates a password. This requires that the password:
 * 'upper': Contains at least upper case character
 * 'lower':Contains at least lower case character
 * 'digit': Contains at least one digit
 * 'length': Eight characters or more
 * If any of these are untrue, it will set the validity of the field to false. The value will still be passed
 * to model, even if it's invalid.
 */
angular.module('userControllers').directive('msValidatePassword', [
  function() {
    return {
      replace: false,
      restrict: 'A',
      require: 'ngModel',
      link: function postLink(scope, iElement, iAttrs, ngModel) {
        ngModel.$parsers.unshift(function(value) {
          if (value) {
            ngModel.$setValidity('length', value.length > 7);
            ngModel.$setValidity('upper', value.toLowerCase() !== value);
            ngModel.$setValidity('lower', value.toUpperCase() !== value);
            ngModel.$setValidity('digit', value.match(/\d+/g) !== null);
          }
          return value;
        });
      }
    };
  }
]);

'use strict';
/**
 * Handle all the API interaction around users.
 */
angular.module('userControllers').service('userSvc', [
  'Restangular',
  'alertService',
  function(Restangular, alertService) {
    /**
            /* Get the user by id
            */
    this.get = function(userId) {
      return Restangular.one('admin/user', userId)
        .get()
        .then(
          function(user) {
            return user;
          },
          function(err) {
            alertService.addAlert({
              message: err.statusText
                ? err.statusText
                : 'Server error: ' + err.status,
              type: 'danger'
            });
          }
        );
    };

    /**
     * Update the user's detailed information.
     * @param  {object} user The user object to update
     */
    this.update = function(user) {
      return Restangular.one('admin/user', user.id)
        .post('update', {
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          alias: user.alias,
          gender: user.gender,
          age: user.age,
          language: user.language,
          optedIntoCoachingEmails: user.userPreferences.optedIntoCoachingEmails,
          optedIntoAssessmentEmails:
            user.userPreferences.optedIntoAssessmentEmails,
          optedIntoConsumerEmails: user.userPreferences.optedIntoConsumerEmails,
          optedIntoSleepDiaryEmails:
            user.userPreferences.optedIntoSleepDiaryEmails,
          optedIntoWellnessPushNotifications:
            user.userPreferences.optedIntoWellnessPushNotifications
        })
        .then(
          function(updatedUser) {
            alertService.addAlert({
              message: 'User updated!',
              type: 'success'
            });
            return updatedUser;
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update user: ' + err.statusText,
              type: 'danger'
            });
            console.log(err);
          }
        );
    };
    this.updateAccountType = function(userId, accountType) {
      return Restangular.one('admin/user', userId)
        .post('account_type', accountType)
        .then(function() {
          alertService.addAlert(
            {
              message: 'Account type updated!',
              type: 'success'
            },
            function(err) {
              alertService.addAlert({
                message: 'Failed to update account type: ' + err.statusText,
                type: 'danger'
              });
            }
          );
        });
    };
    this.updateStatus = function(userId, status) {
      return Restangular.one('admin/user', userId)
        .post('status', status)
        .then(
          function() {
            alertService.addAlert({
              message: 'User status updated!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update user status: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };
    /**
     * Update the access code the user is associated with
     * @param  {Object} user       The user to update the access code on
     * @param  {Object} accessCode The access code to change the user to
     */
    this.updateAccessCode = function(user, accessCode) {
      return Restangular.one('admin/user', user.id)
        .post('accesscode', accessCode.id)
        .then(
          function() {
            alertService.addAlert({
              message: 'Access code updated!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update access code: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    /**
     * Update the roles the user is associated with
     * @param  {Object} user       The user to update the access code on
     * @param  {Object} roles      The list of roles the user should have
     */
    this.updateRoles = function(user, roles) {
      return Restangular.one('admin/user/', user.id)
        .post('roles', roles)
        .then(
          function(resp) {
            alertService.addAlert({
              message:
                'Roles updated! Multi-factor authentication is required for all roles except "Dashboard - single user"',
              type: 'success'
            });
            return resp;
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update roles: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };
    /**
     * Updates the user's password
     * @param  {object} user        The user to change the password on
     * @param  {string} newPassword the new password proposal
     */
    this.updatePassword = function(user, newPassword) {
      return Restangular.one('admin/user', user.id)
        .post('password', newPassword)
        .then(
          function() {
            alertService.addAlert({
              message: 'Password updated!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update password: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };
    /**
     * Get the authentication/MFA settings for a user
     * @param {number} userId - the id of user
     */
    this.getUserMfaSettings = function(userId) {
      return Restangular.one('admin/user/' + userId + '/authentication').get();
    };
    /** Updates the authentication/MFA settings for a user
     * @param {number} userId - the id of users
     * @param {object} settings - the new authentication settings
     */
    this.updateUserMfaSettings = function(userId, settings, isUpdate) {
      return Restangular.one('admin/user', userId)
        .post('authentication', settings)
        .then(
          function(resp) {
            var msg = isUpdate
              ? 'User MFA settings updated!'
              : 'User MFA enabled!';
            alertService.addAlert({
              message: msg,
              type: 'success'
            });
            return resp;
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update MFA settings: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };
    /** Gets the partner authorizations for the user's current organization
     */
    this.getPartnerAuthorizations = function(userId) {
      return Restangular.one('admin/user', userId).getList('partnerauths');
    };
    /** Updates the partner authorizations for a user
     * @param {array} partnerAuths - The list of partner authorizations
     */
    this.updatePartnerAuthorizations = function(
      userId,
      partnerAuths,
      permission
    ) {
      return Restangular.one('admin/user', userId)
        .post('partnerauths/' + permission, partnerAuths)
        .then(
          function() {
            alertService.addAlert({
              message: 'User Authorizations updated!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to update authorizations: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };
    /**
     * Find a user by their email address. If the user is not found this will display an error
     * @param  {string} email  email address to search for users
     * @return {promise}       A promise that will eventually have the user's detailed information
     */
    this.findUser = function(email) {
      return Restangular.one('admin/user')
        .post('find-by-email/', email)
        .then(
          function(resp) {
            return resp;
          },
          function() {
            alertService.addAlert({
              message: 'No user found with email ' + email
            });
          }
        );
    };
    /**
     * Find users that match the requested email (LIKE = '%email%')
     * @param {string} email - The email address to search on
     * @return {array} - A list of matching users
     */
    this.searchUsers = function() {
      alertService.addAlert({
        message: 'Unable to search for users'
      });
    };

    /**
     * Admin submits deletion request on behalf of user
     */
    this.requestUserDeletion = function(userId, reason) {
      return Restangular.one('admin/user/', userId)
        .post('requestuserdeletion', reason)
        .then(
          function() {
            alertService.addAlert({
              message: 'Submited deletion request!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message: 'Failed to submit deletion request: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    /**
     * Get users clinical coaching status
     */
    this.getUserClinicalCoachingStatus = function(memberId) {
      return Restangular.one('/admin/clinical-coaching/member/', memberId)
        .one('/status')
        .get();
    };

    /**
     * Get list of clinical coaches in the system
     */
    this.getClinicalCoachList = function() {
      return Restangular.one('/admin/clinical-coaching/coach').get();
    };

    /**
     * Change clinical coach assigned to a member
     */
    this.changeClinicalCoach = function(memberId, coachSchedulerId) {
      // TODO - Align with backend on api route and contract
      return Restangular.one('/admin/clinical-coaching/coach/assignment')
        .customPUT({
          memberId: memberId,
          coachSchedulerId: coachSchedulerId
        })
        .then(
          () => {
            alertService.addAlert({
              message: 'Members clinical coach updated!',
              type: 'success'
            });
          },
          err => {
            alertService.addAlert({
              message:
                'Failed to change members clinical coach: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    /**
     * Deactivate user in clinical coaching
     */
    this.deactivateUserClinicalCoaching = function(memberId, reason) {
      return Restangular.one('admin/clinical-coaching/member/', memberId)
        .post('deactivate', { reason: reason })
        .then(
          function() {
            alertService.addAlert({
              message: 'Member successfully deactivated!',
              type: 'success'
            });
          },
          function(err) {
            alertService.addAlert({
              message:
                'Failed to submit deactivation request: ' + err.statusText,
              type: 'danger'
            });
          }
        );
    };

    /**
     * Get account deletion requests inititated by user
     */
    this.getUserDeletionRequests = function(deletionStatus) {
      var queryParam = deletionStatus ? '/?status=' + deletionStatus : '';
      return Restangular.one('/admin/user/deletionrequests' + queryParam).get();
    };

    /**
     * Cancel a user deletion request
     */
    this.cancelUserDeletionRequest = function(userId) {
      return Restangular.one('/admin/user', userId)
        .one('deletionrequests')
        .remove();
    };

    this.getAssessmentResponseSummaries = function(userId) {
      return Restangular.one('admin/user', userId)
        .getList('assessment-response-summaries')
        .then(
          function(response) {
            console.log('response was:', response);
            return response;
          },
          function(err) {
            alertService.addAlert({
              message:
                'Failed to get assessment response summaries: ' +
                err.statusText,
              type: 'danger'
            });
          }
        );
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('ActionPlanWidgetCtrl', [
  '$scope',
  'actionPlanSvc',
  function($scope, actionPlanSvc) {
    let vm = this;
    actionPlanSvc.getPlans().then(plans => {
      // Convert all the action item IDs to strings, since widgets only store strings
      plans.forEach(p =>
        p.actionItems.forEach(ai => (ai.id = ai.id.toString()))
      );
      vm.plans = plans;
      // Find the plan associated with the current value
      if ($scope.widget.properties.actionItemId) {
        vm.plan = plans.filter(
          p =>
            p.actionItems.filter(
              ai => ai.id === $scope.widget.properties.actionItemId
            ).length > 0
        )[0];
      }
    });
  }
]);

'use strict';

angular.module('activityControllers').controller('AudioWidgetCtrl', [
  '$scope',
  function($scope) {
    $scope.setPreset = function(preset) {
      var values = {};
      if (preset === 'visualizer') {
        values = {
          visualizer: 'true',
          playPause: 'false',
          jumpBack: 'false',
          scrubbing: 'false',
          timeRemaining: 'false',
          autoplay: 'false'
        };
      } else if (preset === 'slim') {
        values = {
          visualizer: 'false',
          playPause: 'true',
          jumpBack: 'true',
          scrubbing: 'true',
          timeRemaining: 'true',
          autoplay: 'true'
        };
      }
      angular.extend($scope.widget.properties, values);
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('CarouselWidgetCtrl', [
  '$scope',
  function($scope) {
    $scope.widget.properties.size = $scope.widget.properties.size || 1;
    $scope.add = function() {
      if (
        $scope.widget.properties[
          'section_' + ($scope.widget.properties.size - 1)
        ]
      ) {
        $scope.widget.properties.size++;
      }
    };
    $scope.optionCount = function() {
      return new Array(parseInt($scope.widget.properties.size));
    };
    $scope.remove = function() {
      $scope.widget.properties.size--;
      delete $scope.widget.properties[
        'section_' + $scope.widget.properties.size
      ];
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('DropdownCtrl', [
  '$scope',
  function($scope) {
    $scope.widget.properties.size = $scope.widget.properties.size || 1;
    $scope.add = function() {
      if (
        $scope.widget.properties[
          'choice_' + ($scope.widget.properties.size - 1)
        ]
      ) {
        $scope.widget.properties.size++;
      }
    };
    $scope.optionCount = function() {
      return new Array(parseInt($scope.widget.properties.size));
    };
    $scope.remove = function() {
      $scope.widget.properties.size--;
      delete $scope.widget.properties[
        'choice_' + $scope.widget.properties.size
      ];
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('NextBtnCtrl', [
  '$scope',
  '$state',
  'uuidSvc',
  function($scope, $state, uuidSvc) {
    $scope.newSection = function(widget) {
      widget.properties.nextSection = uuidSvc.newUuid();
      $state.go('^.section', {
        section: widget.properties.nextSection
      });
    };
  }
]);

(function() {
  'use strict';

  angular
    .module('activityControllers')
    .controller('QuizWidgetCtrl', QuizWidgetCtrl);
  QuizWidgetCtrl.$inject = ['$scope', '$log', '$modal', 'quizSvc'];

  function QuizWidgetCtrl($scope, $log, $modal, quizSvc) {
    $scope.quizOnChange = quizOnChange;
    $scope.quizPreview = quizPreview;
    var quizSelected;
    activate();

    function activate() {
      $log.debug('QuizWidgetCtrl activate');

      quizSvc.getByStatus('PUBLISHED').then(
        function(resp) {
          $log.debug(resp);
          if (!resp) {
            $scope.quizzes = [];
            return;
          }
          $scope.quizzes = resp;
          quizOnChange();
        },
        function(error) {
          $scope.quizzes = [
            {
              name: 'Failed to load quizzes: ' + error.statusText
            }
          ];
        }
      );
    }

    function quizOnChange() {
      if (!$scope.widget.properties.section && $scope.quizzes.length === 1) {
        //default to 1st item
        $scope.widget.properties.section = $scope.quizzes[0].uuid;
      }
      var uuid = $scope.widget.properties.section;
      if (!uuid) {
        return;
      }

      quizSelected = $scope.quizzes.find(q => q.uuid === uuid);

      var thisActivity = $scope.activity.activity;
      if (!thisActivity.sections) {
        thisActivity.sections = []; //need to initialize if new activity
      }

      var idx = thisActivity.sections.findIndex(s => s.uuid === uuid);
      if (idx === -1) {
        //need to add to activity if it doesn't already exist
        //only need top-level section info to be added to activity
        var newSection = {
          id: quizSelected.id,
          uuid: quizSelected.uuid,
          name: quizSelected.name,
          type: quizSelected.type,
          status: quizSelected.status,
          title: quizSelected.title,
          lead: quizSelected.lead,
          allowHistoryView: false,
          position: 100 /* a large number so it appears at end of list*/
        };
        thisActivity.sections.push(newSection);
        $log.debug('added new section to activity: ');
        $log.debug(newSection);
      }
    }

    function quizPreview() {
      $modal.open({
        templateUrl: 'quizzes/templates/quizSelect.html',
        controller: 'QuizSelectCtrl as vm',
        size: 'lg',
        resolve: {
          quizSelected: quizSelected
        }
      });
    }
  }
})();

'use strict';

angular.module('activityControllers').controller('ScaleCtrl', [
  '$scope',
  function($scope) {
    $scope.widget.properties.size = $scope.widget.properties.size || 1;
    $scope.add = function() {
      if (
        $scope.widget.properties[
          'choice_' + ($scope.widget.properties.size - 1)
        ]
      ) {
        $scope.widget.properties.size++;
      }
    };
    $scope.optionCount = function() {
      var count = 0;
      for (var prop in $scope.widget.properties) {
        if (prop && prop.indexOf('choice_') === 0) {
          count++;
        }
      }
      return new Array(count);
    };
    $scope.remove = function() {
      $scope.widget.properties.size--;
      delete $scope.widget.properties[
        'choice_' + $scope.widget.properties.size
      ];
      delete $scope.widget.properties[
        'display_' + $scope.widget.properties.size
      ];
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('TAStripCtrl', [
  '$scope',
  function($scope) {
    $scope.strip = function(html) {
      return angular.element('<div>' + html + '</div>').text();
    };
  }
]);

'use strict';

angular.module('activityControllers').controller('TrackerCtrl', [
  '$scope',
  '$state',
  '$log',
  'moodSlidersSvc',
  function($scope, $state, $log, moodSlidersSvc) {
    moodSlidersSvc.getMoodGroupsByType('tracker').then(function(groups) {
      $log.debug(groups);
      $scope.trackerGroup = groups[0];
    });
  }
]);

'use strict';

angular.module('accessCodeControllers').controller('CodeBulkCtrl', [
  '$scope',
  'organization',
  'alertService',
  function($scope, organization, alertService) {
    var existingCodes = [];
    $scope.codeLimit = 9999;
    $scope.organization = organization;
    $scope.bulkMode = true;
    $scope.accessCodes = [];
    organization
      .all('accesscodes')
      .getList()
      .then(function(resp) {
        existingCodes = resp;
      });

    $scope.$watch('accessCodes', function() {
      $scope.accessCodes.forEach(function(code) {
        var existing = existingCodes.filter(function(existing) {
          return existing.accessCode === code.accessCode;
        });
        if (existing.length === 1) {
          code.id = existing[0].id;
        }
      });
    });

    $scope.save = function() {
      let hasSeenError = false;
      $scope.accessCodes.forEach(function(code) {
        organization
          .all('accesscodes')
          .post(code)
          .then(
            function() {
              $scope.accessCodes.splice($scope.accessCodes.indexOf(code), 1);
            },
            function() {
              if (hasSeenError) {
                return;
              }
              hasSeenError = true;
              alertService.addAlert({
                type: 'danger',
                message:
                  'Failed to save access codes - file format might have broken? Dont use excel'
              });
            }
          );
      });
    };
  }
]);

'use strict';

angular
  .module('accessCodeControllers')
  .directive('msDownloadAccessCodes', function() {
    return {
      scope: {
        msDownloadAccessCodes: '='
      },
      restrict: 'A',
      link: function(scope, element) {
        scope.$watch(
          'msDownloadAccessCodes',
          function() {
            if (!scope.msDownloadAccessCodes) {
              return;
            }
            var csv = 'AccessCode,Name,phoneNumber,type,status\n';
            scope.msDownloadAccessCodes.forEach(function(code) {
              csv +=
                '"' +
                code.accessCode +
                '","' +
                code.name +
                '","' +
                code.phoneNumber +
                '","' +
                code.type +
                '","' +
                code.status +
                '",' +
                '\n';
            });
            element.attr(
              'href',
              'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
            );
          },
          true
        );
      }
    };
  });

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:CodeNewCtrl
 * @description
 * # CodeNewCtrl
 * This creates a new access code
 */
angular.module('accessCodeControllers').controller('CodeEditCtrl', [
  '$scope',
  'Restangular',
  'CodePersist',
  'organization',
  'code',
  '$window',
  function($scope, Restangular, CodePersist, organization, code, $window) {
    // pattern for allowed codes
    $scope.codePattern = /^[a-zA-Z0-9 \_\-]*$/;
    // the code we'll be editing/creating.
    $scope.code = code || { status: 'ENABLED' };
    // store existing code so we can compare if it has changed
    $scope.origCode = _.clone($scope.code);
    // if we're editing or creating a new code (changes wording)
    $scope.isEdit = code !== null;
    // get a list of groups the access code could be assigned to
    $scope.groups = [];
    // function to save the code
    $scope.save = save;
    //  check for duplicate access codes
    $scope.isAccessCodeDuplicate = function() {
      return (
        $scope.allCodes &&
        $scope.allCodes.indexOf($scope.code.accessCode) !== -1
      );
    };
    // get the available groups
    organization
      .all('groups')
      .getList()
      .then(function(resp) {
        $scope.groups = resp.plain();
      });
    // get a list of access codes to blacklist
    organization
      .all('accesscodes')
      .getList()
      .then(function(resp) {
        $scope.existingCodes = resp.plain();
        // remove this code if editing
        if ($scope.isEdit) {
          for (var i = 0; i < $scope.existingCodes.length; i++) {
            if ($scope.existingCodes[i].accessCode === $scope.code.accessCode) {
              $scope.existingCodes.splice(i, 1);
              break;
            }
          }
        }
      });
    // we load the organization because we need the full name to generate the code name
    organization.get().then(function(data) {
      organization.name = data.name;
    });
    Restangular.all('/collections/accesscodes/codesonly')
      .getList()
      .then(function(resp) {
        if (code && code.accessCode) {
          $scope.allCodes = _.without(resp.plain(), code.accessCode);
        } else {
          $scope.allCodes = resp.plain();
        }
      });
    // fill in the name field automatically
    $scope.$watchGroup(['code.accessCode', 'code.type'], function() {
      // only fill in the default if they haven't entered anything
      if (!$scope.isEdit && $scope.form.name.$pristine) {
        $scope.code.name =
          (organization.name ? organization.name : '') +
          ($scope.code.type ? ' - ' + $scope.code.type : '') +
          ($scope.code.accessCode ? ' - ' + $scope.code.accessCode : '');
        if ($scope.code.name.length > 250) {
          $scope.code.name = $scope.code.name.substring(0, 250);
        }
      }
    });
    // save the code
    function save(form) {
      if (
        $scope.code.status !== $scope.origCode.status &&
        $scope.code.status === 'DISABLED'
      ) {
        if (
          $window.confirm(
            'Are you sure you want to DISABLE this access code? All users in this access code will be automatically disabled as well.'
          )
        ) {
          CodePersist.persist(form, $scope.code, organization);
        }
      } else {
        CodePersist.persist(form, $scope.code, organization);
      }
    }
  }
]);
/**
 * @ngdoc overview
 * @name CodePersist
 * @description
 *
 * This service validates the access code, then submits it to the server to be persisted.
 */
angular.module('accessCodeControllers').factory('CodePersist', [
  '$state',
  'alertService',
  function($state, alertService) {
    return {
      persist: persist
    };

    function persist(form, code, organization) {
      var isEdit = code.hasOwnProperty('id');
      if (isEdit && form.code.$pristine) {
        // don't check pattern if untouched
        form.code.$setValidity('pattern', true);
        // forcing validation doesn't fill out the value so do it by hand
        code.accessCode = code.accessCode || form.code.$viewValue;
      }
      if (form.$valid) {
        // set processing so they can't click the button until done
        form.processing = true;
        organization
          .all('accesscodes')
          .post(code)
          .then(
            function() {
              alertService.addAlert({
                type: 'success',
                message: 'Access code ' + (isEdit ? 'updated' : 'created'),
                persist: true
              });
              form.processing = false;
              $state.go('orgs.org.detail', {
                orgid: organization.shortName
              });
            },
            function(response) {
              alertService.addAlert({
                type: 'danger',
                message:
                  'Failed to ' +
                  (isEdit ? 'update' : 'create') +
                  ' access code.' +
                  (response.status === 422 ? ' ' + response.data.message : '')
              });
              form.processing = false;
            }
          );
      } else {
        alertService.addAlert({
          type: 'danger',
          message:
            'Please check that all fields have been filled out correctly. '
        });
        form.code.$dirty = true;
        form.name.$dirty = true;
      }
    }
  }
]);

angular.module('accessCodeControllers').filter('joinAccessCodes', function() {
  return function(codes) {
    return codes
      ? codes
          .map(function(code) {
            return code.accessCode;
          })
          .join(', ')
      : '';
  };
});

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:HeaderListCtrl
 * @description
 * # HeaderListCtrl
 * This lists the headers for a given organization, by access code
 */

angular.module('accessCodeControllers').controller('CodeListCtrl', [
  '$scope',
  'Restangular',
  function($scope, Restangular) {
    $scope.accessCodes = undefined;
    $scope.codeLimit = 10;
    // restangularcodes is the Restangular object that holds a reference to all codes (changes if there's an org)
    var restangularCodes = $scope.organization
      ? $scope.organization.all('accesscodes')
      : Restangular.all('/collections/accesscodes');
    restangularCodes.getList().then(
      function(resp) {
        $scope.accessCodes = resp;
        // if we're just over the limit, increase the limit
        if ($scope.accessCodes.length === $scope.codeLimit + 1) {
          $scope.codeLimit++;
        }
        if (!$scope.organization) {
          // populate the orgs after codes are loaded
          Restangular.all('/editor/organizations')
            .getList()
            .then(function(orgs) {
              // find the org for each code
              $scope.accessCodes.forEach(function(code) {
                code.organization = orgs.filter(function(org) {
                  return org.id === code.organizationId;
                })[0];
              });
            });
        }
      },
      function() {
        $scope.accessCodes = [
          {
            accessCode: 'Failed to load access codes'
          }
        ];
      }
    );
  }
]);

'use strict';

angular.module('myStrengthAdminApp').directive('msUploadAccessCodes', [
  '$window',
  function($window) {
    return {
      scope: {
        msUploadAccessCodes: '='
      },
      restrict: 'A',
      link: function(scope, element) {
        element.bind('change', function(e) {
          var reader = new FileReader();
          reader.onload = function(load) {
            var lines = load.target.result.replace(/[\n\r]/g, '\n').split('\n');
            // if the first line isn't a CSV with 3 values
            if (
              lines[0].split(/,+(?=(?:(?:[^"]*"){2})*[^"]*$)/g).length !== 6
            ) {
              $window.alert(
                'This file does not appear to be properly formatted'
              );
              return;
            }
            scope.msUploadAccessCodes = [];
            lines.forEach(function(item) {
              // split on commas outside of quotes
              var option = item.split(/,+(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
              if (option[0].toLowerCase() === 'accesscode') {
                return;
              }
              // must have label, value and enabled/disabled
              if (option.length === 6) {
                // replace any quotes
                var ac = {
                  accessCode: option[0].replace(/"/g, ''),
                  name: option[1].replace(/"/g, ''),
                  phoneNumber: option[2].replace(/"/g, ''),
                  type: option[3].replace(/"/g, '').toUpperCase(),
                  status: option[4].replace(/"/g, '').toUpperCase()
                };
                scope.msUploadAccessCodes.push(ac);
              }
            });
            scope.$apply();
          };
          if (e.target.files[0].name.match(/\.(csv)$/)) {
            // read the value of the first file selected
            reader.readAsText(e.target.files[0]);
            // reset the file input for reuse
            element.val(null);
          } else {
            // alert if they uploaded something invalid (should never happen)
            window.alert('File must be one of these types: csv');
          }
        });
      }
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:GroupNewCtrl
 * @description
 * # GroupNewCtrl
 * This creates a new group
 */
angular.module('groupControllers').controller('GroupEditCtrl', [
  '$scope',
  'Restangular',
  'group',
  'organization',
  'GroupPersist',
  function($scope, Restangular, group, organization, GroupPersist) {
    // save the group defined in the scope
    $scope.save = save;
    // allowed shortname pattern
    $scope.shortPattern = /^[a-zA-Z0-9\-\_]*$/;
    // list of codes that could be assigned to this group
    $scope.availableCodes = [];
    // if we didn't get a group, we're not editing
    $scope.isEdit = group !== null;
    // assign the scope group
    $scope.group = group || {
      name: '',
      shortName: '',
      accessCodes: []
    };
    // get the list of availble access codes
    organization.getList('accesscodes/without/groups').then(function(resp) {
      $scope.availableCodes = resp.plain();
    });
    // get all the groups so we can't reuse the name
    Restangular.all('editor/groups')
      .getList()
      .then(function(resp) {
        $scope.existingGroups = resp.plain();
        // remove this group so we can use the current name
        if ($scope.isEdit) {
          for (var i = 0; i < $scope.existingGroups.length; i++) {
            if ($scope.existingGroups[i].shortName === group.shortName) {
              $scope.existingGroups.splice(i, 1);
              break;
            }
          }
        }
      });

    function save(form) {
      GroupPersist.persist(form, $scope.group, organization);
    }
  }
]);

/**
 * @ngdoc overview
 * @name GroupPersist
 * @description
 *
 * This services does the work to validate the form, then make the rest calls to update the group.
 */
angular.module('groupControllers').factory('GroupPersist', [
  '$state',
  'alertService',
  function($state, alertService) {
    return {
      persist: persist
    };

    function persist(form, group, organization) {
      // if we have an ID on the group, then it's from the DB and we're editing
      var isEdit = group.hasOwnProperty('id');
      if (form.$valid) {
        form.processing = true;
        organization
          .all('groups')
          .post(group)
          .then(
            function() {
              alertService.addAlert({
                type: 'success',
                message: 'Group ' + (isEdit ? 'updated' : 'created'),
                persist: true
              });
              $state.go('orgs.org.detail', {
                orgid: organization.shortName
              });
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message: 'Failed to update group.'
              });
              form.processing = false;
            }
          );
      } else {
        alertService.addAlert({
          type: 'danger',
          message:
            'Please check that all fields have been filled out correctly. '
        });
        form.name.$dirty = true;
        form.shortName.$dirty = true;
      }
    }
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:HeaderListCtrl
 * @description
 * # HeaderListCtrl
 * This lists the headers for a given organization, by access code
 */

angular.module('groupControllers').controller('GroupListCtrl', [
  '$scope',
  function($scope) {
    $scope.groups = [
      {
        name: 'Loading ...',
        accessCodes: []
      }
    ];
    $scope.organization
      .all('groups')
      .getList()
      .then(
        function(resp) {
          $scope.groups = resp;
        },
        function() {
          $scope.groups = [
            {
              name: 'Failed to load groups',
              accessCodes: []
            }
          ];
        }
      );
  }
]);

'use strict';
/*global $:false */
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:HeadereditCtrl
 * @description
 * # HeadereditCtrl
 * Controller of the header editing page.
 */
(function() {
  var app = angular.module('headerControllers');
  app.controller('HeaderEditCtrl', [
    '$scope',
    '$stateParams',
    '$templateCache',
    '$modal',
    '$filter',
    '$state',
    'confirmService',
    'alertService',
    'organization',
    'isNew',
    'isClone',
    function(
      $scope,
      $stateParams,
      $templateCache,
      $modal,
      $filter,
      $state,
      confirmService,
      alertService,
      organization,
      isNew,
      isClone
    ) {
      // ========== SCOPE FUNCTIONS ==========
      // see function implementations below for the actual implementations
      /**
       * Adds the HTML for the second image. This looks for the first image tag, then injects a
       * a second image tag immediately after. If the first image tag can't be found, this displays
       * an error message telling the user how to manually add it.
       */
      $scope.addImageHTML = addImageHTML;
      /**
       * Removes the given image. If passed 1, it will remove the main image - if 2 it removes the
       * secondary image. The first image can't be removed if the second image exists.
       */
      $scope.removeImage = removeImage;
      /**
       * Add a new replacement target.
       */
      $scope.addReplacement = addReplacement;
      /**
       * Removes a target/replacement pair from the list of available pairs.
       */
      $scope.removeReplacement = removeReplacement;
      /**
       * Check the HTML for a replacement target. This returns true if the target contains the
       * replacement target, false if it does not.
       */
      $scope.htmlContains = htmlContains;
      /**
       * Open up the preview modal with our new header in it
       */
      $scope.preview = preview;
      /**
       * Save the header. This will save the header and the content, then the access codes.
       * This might make more sense in a service.
       */
      $scope.save = save;
      /**
       * Remove the header.  This will delete the header and it's content.
       */
      $scope.remove = remove;
      // ========== SCOPE VARIABLES ==========
      // access level of this header
      $scope.accessLevel = '';
      // a list of access codes that are current available
      $scope.availableCodes = [];
      // list of groups for the organization
      $scope.groups = [];
      // boolean assigned to if their html is valid
      $scope.htmlValid = true;
      // if it's new we don't give them a revert button
      $scope.isNew = isNew;
      // if it's a clone the title changes
      $scope.isClone = isClone;
      // implied by the other two
      $scope.isEdit = !isNew && !isClone;
      // a possible new target/replacement pair
      $scope.newContent = {};
      // the organization this header is for
      $scope.organization = organization;
      // set the shortname
      $scope.organization.shortName = $stateParams.orgid;
      // non-generic replacements
      $scope.special = {
        PGPhonenumber: null,
        PGName: null,
        image: null
      };
      // Build out default header
      $scope.header = {
        id: $stateParams.headerid,
        htmlTemplate: '',
        content: [
          {
            target: 'introText',
            replacement: 'Access Information'
          },
          {
            target: 'web',
            replacement: ''
          }
        ],
        isDefault: false,
        accessCodeGroup: null,
        accessCodes: [],
        brandingUrl: null,
        arenaApprovalNum: null
      };
      // watch the html to check if it's still valid or if we should warn them
      $scope.$watch('header.htmlTemplate', function() {
        var html = $filter('parseHtml')(
          $scope.header.htmlTemplate,
          $scope.header.content,
          $scope.special
        );
        // create fake element to write html to
        var doc = document.createElement('div');
        doc.innerHTML = html;
        // if chrome didn't reformat the html, it's valid
        $scope.htmlValid = doc.innerHTML === html.toString();
      });
      // If the access codes change we might want to change the payergroup phonenumber
      $scope.$watchCollection('header.accessCodes', function() {
        // go through the access codes to try and get a phone number and name to use
        for (var i = 0; i < $scope.header.accessCodes.length; i++) {
          if ($scope.header.accessCodes[i].phoneNumber) {
            $scope.special.PGPhonenumber =
              $scope.header.accessCodes[i].phoneNumber;
          }
          if ($scope.header.accessCodes[i].name) {
            $scope.special.PGName = $scope.header.accessCodes[i].name;
          }
        }
        // set some non-null defaults
        $scope.special.PGPhonenumber =
          $scope.special.PGPhonenumber || '###-###-####';
        $scope.special.PGName = $scope.special.PGName || 'Payer group name';
      });
      // ========== OBJECT FETCHING ==========
      $scope.organization.get().then(function(resp) {
        $scope.organization = resp;
      });
      // get a list of access codes we can use with this header
      $scope.organization
        .getList('accesscodes/without/headers')
        .then(function(resp) {
          $scope.availableCodes = resp.plain().sort();
        });
      // get a list of groups we can use with this header
      $scope.organization
        .getList('groups/without/headers')
        .then(function(resp) {
          $scope.groups = $scope.groups.concat(resp.plain());
        });
      // load up all the content
      if ($scope.isNew) {
        // at startup load the default header html
        $scope.header.htmlTemplate = $templateCache.get(
          'common/templates/defaultHeader.html'
        );
      } else {
        // get the existing header
        $scope.organization
          .one('headers', $scope.header.id)
          .get()
          .then(
            function(resp) {
              $scope.header = resp.plain();
              // extract images
              var i = $scope.header.content.length;
              while (i--) {
                if ($scope.header.content[i].target === 'image') {
                  $scope.special.image = $scope.header.content[i].replacement;
                  $scope.header.content.splice(i, 1);
                }
              }
              // set the access level
              if ($scope.header.isDefault) {
                $scope.accessLevel = 'Organization';
              } else if ($scope.header.accessCodeGroup) {
                $scope.accessLevel = 'Group';
              } else {
                $scope.accessLevel = 'Code';
              }
              // remove information if we're cloning - to prevent saving over what we've cloned
              if (isClone) {
                delete $scope.header.id;
                $scope.header.accessCodes = [];
                $scope.header.accessCodeGroup = null;
              } else {
                // make the group available to the list
                $scope.groups.push($scope.header.accessCodeGroup);
              }
              // set optional fields, which are not returned in json if null
              if (!$scope.header.hasOwnProperty('brandingUrl')) {
                $scope.header.brandingUrl = null;
              }
              if (!$scope.header.hasOwnProperty('arenaApprovalNum')) {
                $scope.header.arenaApprovalNum = null;
              }
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message: 'An error occured loading the header.',
                persist: true
              });
              $state.go('orgs.org.detail', {
                orgid: $scope.organization.shortName
              });
            }
          );
      }
      // ========== FUNCTION IMPLEMENTATIONS ==========
      function addImageHTML() {
        var tag = '<img src="{{image}}" style="height: 65px;">';
        var html = $scope.header.htmlTemplate;
        var imageIndex = html.indexOf(tag);
        if (imageIndex !== -1) {
          $scope.header.htmlTemplate =
            html.substring(0, imageIndex + tag.length) +
            '\n    <img src="{{imageAlt}}" style="height: 65px;">' +
            html.substring(imageIndex + tag.length, html.length);
        } else {
          // we couldn't find the old tag, so we're not going to risk injecting it at random
          alertService.addAlert({
            type: 'warning',
            message:
              'Unable to automatically add image tag - please add this to your html: <img src="{{imageAlt}}">'
          });
        }
      }

      function removeImage(which) {
        if (which === 1) {
          $scope.special.image = null;
        } else {
          alertService.addAlert({
            type: 'danger',
            message:
              'Only one image is allowed on a header, you are trying to remove one that cannot exist'
          });
        }
      }

      function addReplacement() {
        if (
          $scope.newContent.target === '' ||
          $scope.newContent.replacement === ''
        ) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please enter a target and replacement'
          });
          return;
        }
        // check to see if they're trying to add a replacement that already exists.
        for (var i = 0; i < $scope.header.content.length; i++) {
          if ($scope.header.content[i].target === $scope.newContent.target) {
            alertService.addAlert({
              type: 'danger',
              message:
                'Replacement ' + $scope.newContent.target + ' already exists!'
            });
            return;
          }
        }
        $scope.header.content.push($scope.newContent);
        // reset the replacement adding form
        $scope.newContent = {
          target: '',
          replacement: ''
        };
      }

      function removeReplacement(index) {
        $scope.header.content.splice(index, 1);
      }

      function htmlContains(text) {
        return $scope.header.htmlTemplate.indexOf('{{' + text + '}}') !== -1;
      }

      function preview() {
        $modal.open({
          templateUrl: 'common/templates/preview.html',
          windowClass: 'xl-dialog',
          controller: 'PreviewInstanceCtrl',
          resolve: {
            html: function() {
              return $filter('parseHtml')(
                $scope.header.htmlTemplate,
                $scope.header.content,
                $scope.special
              );
            }
          }
        });
      }

      function isValidBranding() {
        // saving is valid if both brandingUrl & arenaApprovalNum are defined or neither, but not just one
        let isValid = false;
        if (
          ($scope.header.brandingUrl === null ||
            $scope.header.brandingUrl.length === 0) &&
          ($scope.header.arenaApprovalNum === null ||
            $scope.header.arenaApprovalNum.length === 0)
        ) {
          isValid = true;
        }
        if (
          $scope.header.brandingUrl !== null &&
          $scope.header.brandingUrl.length > 0 &&
          $scope.header.arenaApprovalNum !== null &&
          $scope.header.arenaApprovalNum.length > 0
        ) {
          isValid = true;
        }
        return isValid;
      }

      function save() {
        // clone the header so we don't break it if things fail
        var header = JSON.parse(JSON.stringify($scope.header));

        if (!$scope.special.image) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add a header image'
          });
          return;
        }

        // Set up the access code level
        if ($scope.accessLevel === 'Organization') {
          header.isDefault = true;
        } else if ($scope.accessLevel === 'Group') {
          header.isDefault = false;
          if (header.accessCodeGroup === null) {
            alertService.addAlert({
              type: 'danger',
              message: 'Please select a group'
            });
            return;
          }
        } else if ($scope.accessLevel === 'Code') {
          header.isDefault = false;
          if ($scope.header.accessCodes.length === 0) {
            alertService.addAlert({
              type: 'danger',
              message: 'Please add at least one access code'
            });
            return;
          }
        } else {
          alertService.addAlert({
            type: 'danger',
            message: 'Please select an access level'
          });
          return;
        }

        if (!isValidBranding()) {
          alertService.addAlert({
            type: 'danger',
            message:
              'Branding URL and Arena Approval Number are not required, but both must be filled in if entered.'
          });
          return;
        }
        // add the image to content list
        header.content.push({
          target: 'image',
          replacement: $scope.special.image
        });

        var extension =
          $scope.imageName && $scope.imageName.indexOf('.')
            ? $scope.imageName.split('.').pop()
            : '';
        // create form data with image and json
        var fd = new FormData();
        fd.append('header', angular.toJson(header));
        fd.append('image', $scope.imageFile);
        fd.append('extension', extension);
        // POST the header
        $scope.organization
          .all('headers')
          .withHttpConfig({
            transformRequest: angular.identity
          })
          .customPOST(fd, undefined, undefined, {
            'Content-Type': undefined
          })
          .then(
            function() {
              alertService.addAlert({
                type: 'success',
                message: 'Header ' + ($scope.isNew ? 'created' : 'updated'),
                persist: true
              });
              $state.go('orgs.org.detail', {
                orgid: $scope.organization.shortName
              });
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message:
                  'An error occured while saving - check all fields and try again.'
              });
            }
          );
      }
      /**
       *Removes the header after confirming with the user
       *that they are sure.
       */
      function remove() {
        confirmService
          .show({
            resolve: {
              confirmText: function() {
                var header = $scope.header;
                if (header.isDefault) {
                  return 'the default header for the organization';
                } else if (
                  header.accessCodes &&
                  header.accessCodes.length > 0
                ) {
                  return (
                    'the header associated with access codes ' +
                    header.accessCodes
                      .map(function(item) {
                        return item.accessCode;
                      })
                      .join(', ')
                  );
                } else if (header.accessCodeGroup) {
                  return (
                    'the header associated with the group ' +
                    header.accessCodeGroup.name
                  );
                }
                return 'the header with Id ' + header.id;
              },
              item: function() {
                return $scope.header;
              }
            }
          })
          .then(function(confirmed) {
            // DELETE the header
            $scope.organization
              .one('headers', confirmed.id)
              .remove()
              .then(
                function() {
                  alertService.addAlert({
                    type: 'success',
                    message: 'Header removed ',
                    persist: true
                  });
                  $state.go('orgs.org.detail', {
                    orgid: $scope.organization.shortName
                  });
                },
                function() {
                  alertService.addAlert({
                    type: 'danger',
                    message: 'An error occured while deleting - try again.'
                  });
                }
              );
          });
      }
    }
  ]);
  /*
   * A filter to take the raw html typed by the user, and inject the image and the content items.
   */
  app.filter('parseHtml', [
    '$sce',
    function($sce) {
      return function(input, replacements, specialReplacements) {
        var sanitizedHtml = input;
        if (sanitizedHtml) {
          // if they've uploaded an image, use that - otherwise the mystrength logo
          sanitizedHtml = sanitizedHtml.replace(
            '{{image}}',
            specialReplacements.image || 'images/logo.png'
          );
          sanitizedHtml = sanitizedHtml.replace(
            '{{payerGroupPhonenumber}}',
            specialReplacements.PGPhonenumber
          );
          sanitizedHtml = sanitizedHtml.replace(
            '{{payerGroupName}}',
            specialReplacements.PGName
          );
          replacements.forEach(function(replacement) {
            // replace all replacement.target with replacement.replacement
            sanitizedHtml = sanitizedHtml
              .split('{{' + replacement.target + '}}')
              .join(replacement.replacement);
          });
        }
        return $sce.trustAsHtml(sanitizedHtml);
      };
    }
  ]);
  /*
   * Controller for the preview window.
   */
  app.controller('PreviewInstanceCtrl', [
    '$scope',
    '$modalInstance',
    '$sce',
    'html',
    'domainSvc',
    function($scope, $modalInstance, $sce, html, domainSvc) {
      $scope.getHtml = function() {
        return html.toString();
      };
      $scope.closePreview = function() {
        $modalInstance.dismiss();
      };
      $scope.rootDomain = $sce.trustAsResourceUrl(
        'https://' +
          domainSvc.getDomainPrefix() +
          domainSvc.getRootDomain() +
          '/portal'
      );
    }
  ]);
  /*
   * Preview directive, to control the iFrame and inject the html into.
   */
  app.directive('previewHtml', function() {
    return {
      restrict: 'A',
      link: function(scope, element) {
        // wait until the payerGroupHeader is loaded
        function findRecurse() {
          var header = element.contents().find('.payerGroupHeader');
          // make sure the header is loaded
          if (header.length > 0 && header.html() && header.html() !== '') {
            // wait an additional 100ms to ensure it's really loaded
            setTimeout(function() {
              element.show();
              $('.iframeLoading').hide();
              header.html(scope.getHtml());
            }, 500);
          } else {
            setTimeout(findRecurse, 50);
          }
        }
        $(element).load(function() {
          // hide the iframe until it's loaded
          element.hide();
          $('.iframeLoading').show();
          findRecurse();
        });
      }
    };
  });
})();

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:HeaderListCtrl
 * @description
 * # HeaderListCtrl
 * This lists the headers for a given organization, by access code
 */

angular.module('headerControllers').controller('HeaderListCtrl', [
  '$scope',
  '$state',
  'confirmService',
  'alertService',
  function($scope, $state, confirmService, alertService) {
    $scope.hasDefault = true;
    $scope.organization
      .getList('accesscodes/unresolvable/headers')
      .then(function(resp) {
        $scope.missingCodes = resp;
      });
    $scope.removeHeader = removeHeader;
    $scope.headerLimit = 10;
    $scope.organization
      .all('headers')
      .getList()
      .then(function(resp) {
        $scope.headers = resp;
        // if there is going to be one hidden header, don't hide it
        if (resp.length === $scope.headerLimit + 1) {
          $scope.headerLimit++;
        }
      });

    /**
     *Removes the selected header after confirming with the user
     *that they are sure.
     */
    function removeHeader(header) {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              if (header.accessCodes && header.accessCodes.length > 0) {
                return (
                  'the header associated with access codes ' +
                  header.accessCodes
                    .map(function(item) {
                      return item.accessCode;
                    })
                    .join(', ')
                );
              } else if (header.accessCodeGroup) {
                return (
                  'the header associated with the group ' +
                  header.accessCodeGroup.name
                );
              } else if (header.isDefault) {
                return 'the default header for the organization';
              }
            },
            item: function() {
              return header;
            }
          }
        })
        .then(function(confirmed) {
          // DELETE the header
          $scope.organization
            .one('headers', confirmed.id)
            .remove()
            .then(
              function() {
                alertService.addAlert({
                  type: 'success',
                  message: 'Header removed ',
                  persist: true
                });
                $state.go(
                  'orgs.org.detail',
                  {
                    orgid: $scope.organization.shortName
                  },
                  {
                    reload: true //reload the current view
                  }
                );
              },
              function() {
                alertService.addAlert({
                  type: 'danger',
                  message: 'An error occured while deleting - try again.'
                });
              }
            );
        });
    }
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:IdentityProviderEditCtrl
 * @description
 * # IdentityProviderEditCtrl
 * This facilitates editing SSO identity providers for a given organization
 */
angular
  .module('identityProviderControllers')
  .controller('IdentityProviderEditCtrl', [
    '$scope',
    '$state',
    'alertService',
    'organization',
    'identityprovider',
    'orgSvc',
    function(
      $scope,
      $state,
      alertService,
      organization,
      identityprovider,
      orgSvc
    ) {
      // regex for the identity provider ID field
      $scope.idpIdPattern = /^[a-zA-Z0-9\_\-\:]*$/;
      // if we're editing or creating a new identity provider ID (changes wording)
      $scope.isEdit = identityprovider !== null;
      // parent organization
      $scope.organization = organization;
      // save function
      $scope.idpTypes = orgSvc.getIdps();
      $scope.identityprovider = identityprovider;

      $scope.save = function() {
        $scope.organization
          .all('identityproviders')
          .post($scope.identityprovider)
          .then(
            function() {
              alertService.addAlert({
                type: 'success',
                message:
                  'Identity Provider Settings ' +
                  ($scope.isNew ? 'created' : 'updated'),
                persist: true
              });
              $state.go('orgs.org.detail', {
                orgid: $scope.organization.shortName
              });
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message: 'An error occured submitting the changes'
              });
            }
          );
      };
    }
  ]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:IdentityProviderListCtrl
 * @description
 * # IdentityProviderListCtrl
 * This lists the SSO identity providers for a given organization
 */
angular
  .module('identityProviderControllers')
  .controller('IdentityProviderListCtrl', [
    '$scope',
    '$state',
    '$filter',
    'confirmService',
    'alertService',
    function($scope, $state, $filter, confirmService, alertService) {
      $scope.organization
        .all('identityproviders')
        .getList()
        .then(function(resp) {
          $scope.identityproviders = resp;
        });

      $scope.idpidLimit = 10;

      $scope.removeIdentityProvider = function(identityProvider) {
        confirmService
          .show({
            resolve: {
              confirmText: function() {
                return 'the identity provider for the organization';
              },
              item: function() {
                return identityProvider;
              }
            }
          })
          .then(function(confirmed) {
            // DELETE the identity provider
            $scope.organization
              .one('identityproviders', confirmed.ssoIdentityProviderId)
              .remove()
              .then(
                function() {
                  alertService.addAlert({
                    type: 'success',
                    message: 'Identity provider removed ',
                    persist: true
                  });
                  $state.go(
                    'orgs.org.detail',
                    {
                      orgid: $scope.organization.shortName
                    },
                    {
                      reload: true //reload the current view
                    }
                  );
                },
                function() {
                  alertService.addAlert({
                    type: 'danger',
                    message: 'An error occured while deleting - try again.'
                  });
                }
              );
          });
      };
    }
  ]);

(function() {
  'use strict';

  angular
    .module('metadataControllers')
    .controller('MetadataListCtrl', MetadataListCtrl);

  MetadataListCtrl.$inject = [
    '$scope',
    'MetadataService',
    '$mdDialog',
    '$stateParams'
  ];

  /* @ngInject */
  function MetadataListCtrl($scope, MetadataService, $mdDialog, $stateParams) {
    var vm = this;
    vm.editMode = false;
    vm.saveRecordBuilder = saveRecordBuilder;
    vm.saveGroupBuilder = saveGroupBuilder;
    vm.resetRecordBuilder = resetRecordBuilder;
    vm.resetGroupBuilder = resetGroupBuilder;
    vm.deleteRecordConfirm = deleteRecordConfirm;
    vm.showEdit = showEdit;
    vm.addToGroup = addToGroup;
    vm.querySearch = querySearch;
    vm.groupSelected = groupSelected;
    vm.isEditMode = isEditMode;
    vm.removeChip = removeChip;

    $scope.$watch('vm.records', function handleRecordChange(newRecords) {
      vm.gridOptions.data = newRecords.filter(function(record) {
        return !$stateParams.context || record.context === $stateParams.context;
      });
    });

    activate();

    ////////////////

    function activate() {
      getPartnerMetadata();
      getPartnerComponentGroups();

      vm.types = ['Text', 'TextArea', 'Date', 'List', 'Tag'];
      vm.records = [];
      vm.gridOptions = {
        data: vm.records,
        columnDefs: [
          { name: 'displayName', displayName: 'Display Name' },
          { name: 'type', displayName: 'Record Type' },
          { name: 'uniqueComp', displayName: 'Unique' },
          { name: 'helpText', displayName: 'Help Text' },
          { name: 'defaultText', displayName: 'Default Text' },
          { name: 'listValues', displayName: 'List Values' },
          { name: 'context', displayName: 'Context' },
          {
            name: 'createdOn.datetime',
            displayName: 'Created On',
            type: 'date',
            cellFilter: 'date:"MM-dd-yyyy"'
          },
          {
            name: 'modifiedOn.datetime',
            displayName: 'Modified On',
            type: 'date',
            cellFilter: 'date:"MM-dd-yyyy"'
          },
          {
            name: 'actions',
            displayName: 'Actions',
            field: 'id',
            cellTemplate: 'partner/metadata/templates/action_cell.html'
          }
        ]
      };

      // Reset Group
      vm.groupBuilder = {
        components: []
      };
      delete vm.group;

      // Reset record
      vm.recordBuilder = {
        listValues: [],
        uniqueComp: true,
        context: $stateParams.context || 'PARTNER'
      };
      vm.editMode = false;
    }

    function isEditMode() {
      return vm.editMode === true;
    }

    function removeChip(chip, index, component) {
      MetadataService.listDeleteVerify(component, chip).then(function(resp) {
        if (resp.length > 0) {
          showAlert(
            'Existing Values',
            'There are ' +
              resp.length +
              ' records using this chip.  The first one belongs to ' +
              _.keys(resp[0])[0] +
              ' - ' +
              _.values(resp[0])[0]
          );
          vm.recordBuilder.listValues.splice(index, 0, chip); // Insert back into array at index
        }
      });
    }

    function showAlert(title, msg) {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .title(title)
          .textContent(msg)
          .ariaLabel(title)
          .ok('OK')
      );
    }

    function groupSelected() {
      var _group = JSON.parse(vm.group);
      vm.groupBuilder.id = _group.id;
      vm.groupBuilder.name = _group.name;
      _.each(_group.components, function(comp) {
        addToGroup(comp.id);
      });
    }

    function saveRecordBuilder(record) {
      MetadataService.saveComponent(record).then(function() {
        vm.editMode = false;
        getPartnerMetadata();
      });
      resetRecordBuilder();
    }

    function saveGroupBuilder(record) {
      MetadataService.saveMetadataGroup(record).then(function() {
        getPartnerComponentGroups();
      });
      resetGroupBuilder();
    }

    function getPartnerMetadata() {
      MetadataService.fetchMetadataComponents($stateParams.context).then(
        function(data) {
          vm.records = _.filter(data, function(elem) {
            // Should already be filtered by server, but just in case
            return elem.status === 'ACTIVE';
          });
        }
      );
    }

    function getPartnerComponentGroups() {
      MetadataService.fetchMetadataComponentGroup().then(function(data) {
        vm.groups = data;
      });
    }

    function resetGroupBuilder() {
      activate();
    }

    function querySearch(query) {
      return query ? vm.records.filter(_createFilterFor(query)) : [];
    }

    function resetRecordBuilder() {
      activate();
    }

    function deleteRecordConfirm(evt, id) {
      var _record = _findComponentById(id);
      var confirm = $mdDialog
        .confirm()
        .title('Would you like to deprecate "' + _record.displayName + '"?')
        .textContent(
          'This component will be marked as "deprecated" and will no longer be available in the console. All existing records that use this component will no longer be visible.  This will NOT delete any data. Please see a dev if you need to reverse this action. Would you like to continue?'
        )
        .ariaLabel('Deprecate')
        .targetEvent(evt)
        .ok('Deprecate It')
        .cancel('No');
      $mdDialog.show(confirm).then(function() {
        MetadataService.deleteComponent(id).then(function() {
          getPartnerMetadata();
        });
      });
    }

    function showEdit(id) {
      vm.editMode = true;
      vm.recordBuilder = _findComponentById(id);
    }

    function addToGroup(id) {
      var comp = _findComponentById(id);
      if (!_.includes(vm.groupBuilder.components, comp)) {
        vm.groupBuilder.components.push(comp);
      }
    }

    function _createFilterFor(query) {
      var lowercaseQuery = angular.lowercase(query);
      return function filterFn(record) {
        return (
          angular.lowercase(record.displayName).indexOf(lowercaseQuery) === 0
        );
      };
    }

    function _findComponentById(id) {
      return _.find(vm.gridOptions.data, function(record) {
        return record.id === id;
      });
    }
  }
})();

'use strict';

angular.module('notificationControllers').controller('NotificationEditCtrl', [
  '$log',
  '$scope',
  '$state',
  'alertService',
  'organization',
  'notification',
  'templates',
  function(
    $log,
    $scope,
    $state,
    alertService,
    organization,
    notification,
    templates
  ) {
    // Mandrill template names
    $scope.mandrillTemplates = templates;
    // a crumby regex that loosely matches URLs
    $scope.urlRegex = /^https?:\/\/[^\s\/$.?#].[^\s]*$/;
    // parent organization
    $scope.organization = organization;
    // if we recieve a null notification we're editing
    $scope.isEdit = !!notification;
    // a list of groups that can have notification settings assigned to them
    $scope.availableGroups = [];
    // a list of access codes that can have notification settings assigned to them
    $scope.availableCodes = [];
    // save function
    $scope.save = save;
    // set up the default notification if needed
    $scope.notification = notification || {
      disableMobilePopup: false,
      disableEmail: false,
      enableScorecards: false,
      enableVNextMigration: false,
      loginUrl: null
    };
    // get the organization so we know if it already has notification settings
    $scope.organization.get().then(function(data) {
      $scope.organization = data;
    });
    // get a list of groups we can use
    $scope.organization
      .getList('groups/without/notifications')
      .then(function(resp) {
        $scope.availableGroups = $scope.availableGroups.concat(resp.plain());
      });
    // get a list of access codes that can be used
    $scope.organization
      .getList('accesscodes/without/notifications')
      .then(function(resp) {
        $scope.availableCodes = resp.plain();
      });
    // set the access level if editing
    if ($scope.isEdit) {
      if ($scope.notification.isDefault) {
        $scope.accessLevel = 'Organization';
      } else if ($scope.notification.accessCodeGroup) {
        $scope.accessLevel = 'Group';
      } else {
        $scope.accessLevel = 'Code';
      }
      // allow the current group
      $scope.availableGroups.push($scope.notification.accessCodeGroup);
    }
    /**
     * This saves the notification settings
     */
    function save() {
      // copy so we don't break things if the save fails
      var notification = angular.copy($scope.notification);
      if (!$scope.form.$valid) {
        alertService.addAlert({
          type: 'danger',
          message: 'Verify that login URL is filled out correctly.'
        });
        return;
      }
      // Set up the access code level
      if ($scope.accessLevel === 'Organization') {
        notification.isDefault = true;
      } else if ($scope.accessLevel === 'Group') {
        notification.isDefault = false;
        if (notification.accessCodeGroup === null) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please select a group'
          });
          return;
        }
      } else if ($scope.accessLevel === 'Code') {
        notification.isDefault = false;
        if (notification.accessCodes.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add at least one access code'
          });
          return;
        }
      } else {
        alertService.addAlert({
          type: 'danger',
          message: 'Please select an access level'
        });
        return;
      }
      if (notification.loginUrl && notification.loginUrl.length === 0) {
        notification.loginUrl = null;
      }
      organization
        .all('notifications')
        .post(notification)
        .then(
          function() {
            alertService.addAlert({
              type: 'success',
              message:
                'Notification Settings ' +
                ($scope.isNew ? 'created' : 'updated'),
              persist: true
            });
            $state.go('orgs.org.detail', {
              orgid: $scope.organization.shortName
            });
          },
          function() {
            alertService.addAlert({
              type: 'danger',
              message: 'An error occured submitting the changes'
            });
            return;
          }
        );
    }
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:NotificationListCtrl
 * @description
 * # NotificationListCtrl
 * This lists the notifications for a given organization, by access code
 */

angular.module('notificationControllers').controller('NotificationListCtrl', [
  '$scope',
  '$log',
  '$state',
  '$filter',
  'confirmService',
  'alertService',
  function($scope, $log, $state, $filter, confirmService, alertService) {
    $scope.notifications = [];
    $scope.organization
      .all('notifications')
      .getList()
      .then(function(resp) {
        $scope.notifications = resp;
      });
    $scope.removeNotification = removeNotification;
    /**
     * Removes the selected header after confirming with the user
     * that they are sure.
     */
    function removeNotification(notification) {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              if (notification.isDefault) {
                return 'the default notification for this organization';
              } else if (notification.accessCodeGroup) {
                return (
                  'the notification settings for the ' +
                  notification.accessCodeGroup.name +
                  ' group'
                );
              } else {
                return (
                  'the notification settings for the access codes ' +
                  $filter('joinAccessCodes')(notification.accessCodes)
                );
              }
            },
            item: function() {
              return notification;
            }
          }
        })
        .then(function(confirmed) {
          // DELETE the header
          $scope.organization
            .one('notifications', confirmed.id)
            .remove()
            .then(
              function() {
                alertService.addAlert({
                  type: 'success',
                  message: 'Notification removed ',
                  persist: true
                });
                $state.go(
                  'orgs.org.detail',
                  {
                    orgid: $scope.organization.shortName
                  },
                  {
                    reload: true //reload the current view
                  }
                );
              },
              function() {
                alertService.addAlert({
                  type: 'danger',
                  message: 'An error occured while deleting - try again.'
                });
              }
            );
        });
    }
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:OrgListCtrl
 * @description
 * # OrgListCtrl
 * This lists all of the organizations
 */
angular.module('orgControllers').controller('OrgListCtrl', [
  '$scope',
  'Restangular',
  function($scope, Restangular) {
    $scope.clientVersionSelection = null;
    $scope.clientVersionOptions = [
      { key: 'All', value: null },
      { key: 'BH 1.0', value: 'MY1_0' },
      { key: 'BH 2.0', value: 'BH2_0' },
      { key: 'BH 3.0', value: 'BH3_0' }
    ];

    $scope.orgTypes = {
      COMMERCIAL: {
        filter: 'COMMERCIAL',
        displayName: 'Commercial',
        icon: 'fa-circle',
        color: 'ok'
      },
      PUBLIC: {
        filter: 'PUBLIC',
        displayName: 'Public',
        icon: 'fa-circle-o',
        color: 'ok'
      },
      INACTIVE: {
        filter: 'INACTIVE',
        displayName: 'Inactive',
        icon: 'fa-ban',
        color: 'error'
      },
      TEST: {
        filter: 'TEST',
        displayName: 'Test',
        icon: 'fa-bomb',
        color: 'warn'
      },
      OTHER: {
        filter: 'OTHER',
        displayName: 'Other',
        icon: 'fa-question-circle',
        color: 'ok'
      },
      DEMO: {
        filter: 'DEMO',
        displayName: 'Demo',
        icon: 'fa-circle',
        color: 'warn'
      },
      GUEST: {
        filter: 'GUEST',
        displayName: 'Guest',
        icon: 'fa-circle-o-notch',
        color: 'ok'
      }
    };

    Restangular.all('editor/organizations/')
      .getList()
      .then(
        function(resp) {
          $scope.organizations = resp.plain();
        },
        function(error) {
          $scope.organizations = [
            {
              name: 'Failed to load organizations: ' + error.statusText
            }
          ];
        }
      );
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:OrgDetailCtrl
 * @description
 * This shows all the details of an organization and links to editing pages
 */
angular.module('orgControllers').controller('OrgDetailCtrl', [
  '$scope',
  '$state',
  'organization',
  'alertService',
  function($scope, $state, organization, alertService) {
    $scope.loading = true;
    $scope.isEdit = true;
    $scope.organization = organization;

    $scope.organization.get().then(
      function(resp) {
        $scope.organization = resp;
        $scope.loading = false;
      },
      function(error) {
        alertService.addAlert({
          type: 'danger',
          message:
            'An error occured loading the organization: ' + error.statusText,
          persist: true
        });
        if (error.status === 404) {
          $state.go('404', {
            orgid: $scope.organization.shortName
          });
        }
      }
    );
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:OrgNewCtrl
 * @description
 * # OrgNewCtrl
 * This creates a new organization.
 *
 */
angular.module('orgControllers').controller('OrgEditCtrl', [
  '$scope',
  'OrgPersist',
  'Restangular',
  function($scope, OrgPersist, Restangular) {
    $scope.save = save;
    // Short names must be alphanumeric
    $scope.shortPattern = /^[a-zA-Z0-9\-\_]*$/;
    // if we're processing a request, disabled button
    $scope.processing = false;
    if (!$scope.isEdit) {
      $scope.organization = {
        name: '',
        shortName: '',
        type: 'PUBLIC',
        enableSharedInspirations: true,
        clientVersion: 'MY1_0'
      };
    }

    function save(form) {
      // make sure that the organization at least exists
      OrgPersist.persistOrg(form, $scope.organization);
    }
    // get a list of organizations so we can blacklist them names
    $scope.existingOrgs = [];
    Restangular.all('editor/organizations/')
      .getList()
      .then(function(data) {
        // remove the current org from the blacklist
        if (
          $scope.organization &&
          $scope.organization.hasOwnProperty('shortName')
        ) {
          for (var i = 0; i < data.length; i++) {
            if (
              data[i].hasOwnProperty('shortName') &&
              data[i].shortName === $scope.organization.shortName
            ) {
              data.splice(i, 1);
              break;
            }
          }
        }
        $scope.existingOrgs = data;
      });
  }
]);

angular.module('orgControllers').factory('OrgPersist', [
  '$state',
  'alertService',
  'Restangular',
  function($state, alertService, Restangular) {
    return {
      persistOrg: persistOrg
    };

    function persistOrg(form, organization) {
      form.processing = true;
      var isEdit = organization.hasOwnProperty('id');
      // check if the form is valid. If it's not valid, check if things have been edited.
      if (isEdit && form.shortName.$pristine) {
        form.shortName.$setValidity('pattern', true);
      }
      if (form.$valid) {
        // check one more time for duplicates
        Restangular.all('editor/organizations/')
          .post(organization)
          .then(
            function(resp) {
              alertService.addAlert({
                type: 'success',
                message: 'Organization ' + (isEdit ? 'updated' : 'created'),
                persist: !isEdit || form.shortName.$dirty
              });
              form.processing = false;
              $state.go('orgs.org.detail', {
                orgid: resp.shortName
              });
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message:
                  'Failed to ' +
                  (isEdit ? 'update' : 'create') +
                  ' organization.'
              });
              form.processing = false;
            }
          );
      } else {
        alertService.addAlert({
          type: 'danger',
          message:
            'Please check that all fields have been filled out correctly. '
        });
        form.name.$dirty = true;
        form.shortName.$dirty = true;
        form.processing = false;
      }
    }
  }
]);

'use strict';
/**
 * Handles the server communication around organizations.
 */
angular.module('orgControllers').service('orgSvc', [
  'Restangular',
  function(Restangular) {
    var orgs = Restangular.all('editor/organizations');
    /**
     * Get a list of all the organizations
     * @return {array} All the orgs
     */
    this.getAll = function() {
      return orgs.getList();
    };

    /**
     * Get the access codes for the given organization
     * @param  {string} orgShort The short name of the organization
     * @return {array}           A list of all the access codes in the given organization
     */
    this.getAccessCodes = function(orgShort) {
      return Restangular.one('editor/organizations', orgShort).getList(
        'accesscodes'
      );
    };

    /**
    * Get the groups for the given organization
    * @param  {string} orgShort The short name of the organization
    * @param  {boolean} notEmpty - If set, will only return groups that have at least 1 access code
     * @return {array}           A list of all the groups in the given organization
    }
    */
    this.getGroups = function(orgShort, notEmpty) {
      if (notEmpty) {
        return Restangular.one('editor/organizations', orgShort).getList(
          'groups',
          /*jshint camelcase: false */
          { not_empty: 'true' }
        );
      }
      return Restangular.one('editor/organizations', orgShort).getList(
        'groups'
      );
    };

    this.getIdps = function() {
      return Restangular.one('/editor/identityproviders')
        .getList()
        .then(function(idps) {
          return idps;
        });
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:WellnessAssessmentPreferencesEditCtrl
 * @description
 * # WellnessAssessmentPreferencesEditCtrl
 * The controller manages the creation and edit capability for Wellness Assessment
 * customizations for an Organization.
 */
angular
  .module('providerPreferencesControllers')
  .controller('WellnessAssessmentPreferencesEditCtrl', [
    '$scope',
    '$modalInstance',
    'assessmentSvc',
    'preferences',
    function($scope, $modalInstance, assessmentSvc, preferences) {
      $scope.frequencies = [];
      $scope.preferences = preferences || {
        shortName: '',
        disclaimers: {},
        frequencyId: ''
      };

      $scope.prettyPrintFrequency = assessmentSvc.prettyPrintFrequency;
      assessmentSvc.getFrequencies().then(
        function(resp) {
          $scope.frequencies = resp.plain();
        },
        function(error) {
          $scope.frequencies = [
            {
              shortName: 'Failed to load frequencies: ' + error.statusText
            }
          ];
        }
      );
      // Close the modal and return the assessment preference
      $scope.save = function() {
        $modalInstance.close($scope.preferences);
      };
      // Dismiss the modal
      $scope.cancel = function() {
        $modalInstance.dismiss('cancel');
      };
    }
  ]);

(function() {
  'use strict';
  angular
    .module('providerPreferencesControllers')
    .controller('ProviderPreferencesEditCtrl', ProviderPreferencesEditCtrl);

  ProviderPreferencesEditCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$http',
    '$modal',
    '$filter',
    '$state',
    'uuidSvc',
    'alertService',
    'orderSvc',
    'assessmentSvc',
    'organization',
    'preferences',
    'isClone',
    'loginGroups',
    'onboardingGroups',
    'featureTypes'
  ];
  /**
   * @ngdoc function
   * @name myStrengthAdminApp.controller:ProviderPreferencesEditCtrl
   * @description
   * # ProviderPreferencesEditCtrl
   * The controller manages the creation and edit capability for Provider Preferences
   * customizations for an Organization.
   */
  function ProviderPreferencesEditCtrl(
    $scope,
    $stateParams,
    $http,
    $modal,
    $filter,
    $state,
    uuidSvc,
    alertService,
    orderSvc,
    assessmentSvc,
    organization,
    preferences,
    isClone,
    loginGroups,
    onboardingGroups,
    featureTypes
  ) {
    var vm = this;

    vm.save = save;
    vm.move = move;
    vm.addFeature = addFeature;
    vm.removeFeature = removeFeature;
    vm.preferencesContainsFeature = preferencesContainsFeature;
    vm.addAssessmentPreference = addAssessmentPreference;
    vm.editAssessmentPreference = editAssessmentPreference;
    vm.removeAssessmentPreference = removeAssessmentPreference;

    vm.isNew = true;
    // ========== SCOPE VARIABLES ==========
    // access level of these preferences
    vm.accessLevel = '';
    // a list of access codes that are current available
    vm.availableCodes = [];
    // list of groups for the organization
    vm.groups = [];
    // the organization this preferences are for
    vm.organization = organization;
    // set the shortname
    vm.organization.shortName = $stateParams.orgid;

    vm.featureTypes = featureTypes;
    vm.loginGroups = loginGroups;
    vm.onboardingGroups = onboardingGroups;

    vm.preferences = preferences || {
      id: 0,
      features: [],
      defaultDestinationUri: '',
      wellnessAssessments: [],
      isDefault: false,
      accessCodeGroup: null,
      accessCodes: []
    };

    _init();

    function _init() {
      var _loginGroup, _onboardingGroup;
      if (preferences) {
        _loginGroup = _.find(vm.loginGroups, function(e) {
          return e.id === preferences.loginGroup;
        });
        _onboardingGroup = _.find(vm.onboardingGroups, function(e) {
          return e.id === preferences.onboardingGroup;
        });
      }
      vm.preferences.loginGroup = _loginGroup ? _loginGroup : loginGroups[0];
      vm.preferences.onboardingGroup = _onboardingGroup
        ? _onboardingGroup
        : onboardingGroups[0];

      if (isClone) {
        vm.preferences.id = 0;
        vm.preferences.accessCodes = [];
        vm.accessCodeGroup = null;
      } else if (preferences) {
        vm.isNew = false;
      }
      // implied by the other two
      vm.isEdit = !vm.isNew;

      loadObjects();
    }

    function loadObjects() {
      vm.availableFeatures = vm.featureTypes.filter(function(item) {
        return !preferencesContainsFeature(item);
      });
      // ========== OBJECT FETCHING ==========
      vm.organization.get().then(function(data) {
        vm.organization = data;
      });
      // get a list of access codes we can use with this assessment
      vm.organization
        .getList('accesscodes/without/preferences')
        .then(function(data) {
          vm.availableCodes = data.plain().sort();
        });
      // get a list of groups we can use with this assessment
      vm.organization
        .getList('groups/without/preferences')
        .then(function(data) {
          vm.groups = vm.groups.concat(data.plain());
        });
      assessmentSvc.getAll().then(
        function(resp) {
          vm.assessments = resp;
          vm.availableAssessments = vm.assessments.filter(function(item) {
            return preferencesContainsAssessment(item.shortName).length === 0;
          });
        },
        function(error) {
          vm.assessments = [
            {
              shortName: 'Failed to load Assessments: ' + error.statusText
            }
          ];
        }
      );

      $scope.$watch(
        'vm.preferences',
        function() {
          if (vm.assessments) {
            vm.availableAssessments = vm.assessments.filter(function(item) {
              return preferencesContainsAssessment(item.shortName).length === 0;
            });
          }
          if (vm.featureTypes) {
            vm.availableFeatures = vm.featureTypes.filter(function(item) {
              return !preferencesContainsFeature(item);
            });
          }
        },
        true
      );
      // load up all the content
      if (vm.isEdit) {
        // set the access level
        if (vm.preferences.isDefault) {
          vm.accessLevel = 'Organization';
        } else if (vm.preferences.accessCodeGroup) {
          vm.accessLevel = 'Group';
        } else {
          vm.accessLevel = 'Code';
        }

        if (vm.preferences.accessCodeGroup) {
          // make the group available to the list
          vm.groups.push(vm.preferences.accessCodeGroup);
        }
      }
    }

    function addFeature(type) {
      var newFeature = {
        providerPreferenceId: vm.preferences.id,
        providerPreferenceType: type,
        value: null,
        flag: true
      };
      vm.preferences.features.push(newFeature);
    }
    function removeFeature(feature) {
      var idx = vm.preferences.features.indexOf(feature);
      if (idx !== -1) {
        vm.preferences.features.splice(idx, 1);
      }
    }

    function addAssessmentPreference(assessment) {
      var assessmentPreference = {
        shortName: assessment.shortName,
        activityId: assessment.id,
        disclaimers: angular.copy(assessment.disclaimers),
        frequencyId: assessment.frequency.id
      };
      vm.editAssessmentPreference(assessmentPreference, true);
    }

    function editAssessmentPreference(assessmentPreference, isNew) {
      var isEdit = !isNew;
      // back up the field in case they cancel
      var assessmentPreferenceBackup = angular.copy(assessmentPreference);
      var modal = $modal.open({
        templateUrl:
          'partner/preferences/templates/assessmentpreferencesedit.html',
        controller: 'WellnessAssessmentPreferencesEditCtrl',
        windowClass: 'xl-dialog',
        resolve: {
          preferences: function() {
            return assessmentPreference;
          }
        }
      });
      modal.result.then(
        function(assessmentPreference) {
          if (!isEdit) {
            assessmentPreference.positionOrdinal =
              vm.preferences.wellnessAssessments.length + 1;
            vm.preferences.wellnessAssessments.push(assessmentPreference);
          }
        },
        function() {
          // cancelled, revert the field
          if (isEdit) {
            vm.preferences.wellnessAssessments[
              vm.preferences.wellnessAssessments.indexOf(assessmentPreference)
            ] = assessmentPreferenceBackup;
          }
        }
      );
    }

    function removeAssessmentPreference(assessmentPreference) {
      var idx = vm.preferences.wellnessAssessments.indexOf(
        assessmentPreference
      );
      if (idx !== -1) {
        vm.preferences.wellnessAssessments.splice(idx, 1);
      }
    }

    function move(assessmentPreference, direction) {
      orderSvc.move(
        assessmentPreference,
        direction,
        vm.preferences.wellnessAssessments
      );
    }

    /**
     * Save the wellness assessment. This will save the assessment then the access codes.
     * This might make more sense in a service.
     */
    function save() {
      // clone the header so we don't break it if things fail
      var onboardingPreferences = angular.copy(vm.preferences);

      // Convert slider groups from object to id
      onboardingPreferences.loginGroup = onboardingPreferences.loginGroup.id;
      onboardingPreferences.onboardingGroup =
        onboardingPreferences.onboardingGroup.id;

      // Set up the access code level
      if (vm.accessLevel === 'Organization') {
        onboardingPreferences.isDefault = true;
      } else if (vm.accessLevel === 'Group') {
        onboardingPreferences.isDefault = false;
        if (onboardingPreferences.accessCodeGroup === null) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please select a group'
          });
          return;
        }
      } else if (vm.accessLevel === 'Code') {
        onboardingPreferences.isDefault = false;
        if (onboardingPreferences.accessCodes.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add at least one access code'
          });
          return;
        }
      } else {
        alertService.addAlert({
          type: 'danger',
          message: 'Please select an access level'
        });
        return;
      }
      if (
        onboardingPreferences.customTermsOfUse &&
        onboardingPreferences.customTermsOfUse.length === 0
      ) {
        onboardingPreferences.customTermsOfUse = null;
      }
      // POST the preferences
      vm.organization
        .all('preferences')
        .post(onboardingPreferences)
        .then(
          function() {
            alertService.addAlert({
              type: 'success',
              message:
                'Partner preferences ' + (vm.isNew ? 'created' : 'updated'),
              persist: true
            });
            $state.go('orgs.org.detail', {
              orgid: vm.organization.shortName
            });
          },
          function() {
            alertService.addAlert({
              type: 'danger',
              message:
                'An error occured while saving - check all fields and try again.'
            });
          }
        );
    }

    ////////////////////////////

    function preferencesContainsAssessment(shortName) {
      if (
        vm.preferences.wellnessAssessments &&
        vm.preferences.wellnessAssessments.length
      ) {
        return vm.preferences.wellnessAssessments.filter(function(item) {
          return item.shortName === shortName;
        });
      }
      return [];
    }

    function preferencesContainsFeature(type) {
      if (vm.preferences.features.length && type) {
        var found = vm.preferences.features.filter(
          f => f.providerPreferenceType.id === type.id
        );
        return found.length > 0;
      }
      return false;
    }
  }
})();

(function() {
  'use strict';
  angular
    .module('providerPreferencesControllers')
    .controller('ProviderPreferencesListCtrl', ProviderPreferencesListCtrl);

  ProviderPreferencesListCtrl.$inject = [
    '$scope',
    '$state',
    'alertService',
    'confirmService'
  ];
  /**
   * @ngdoc function
   * @name myStrengthAdminApp.controller:ProviderPreferencesListCtrl
   * @description
   * # ProviderPreferencesListCtrl
   * This controller lists the available Provider preferences customization
   * definitions within the provided organization.
   */

  function ProviderPreferencesListCtrl(
    $scope,
    $state,
    alertService,
    confirmService
  ) {
    var vm = this;
    vm.preferenceProfiles = [
      {
        loading: true
      }
    ];
    vm.removePreferences = removePreferences;
    $scope.organization
      .all('preferences')
      .getList()
      .then(function(resp) {
        vm.preferenceProfiles = resp.plain();
      });
    /**
     *Removes the selected on-boarding perfs after confirming with the user
     *that they are sure.
     */
    function removePreferences(preferences) {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              if (
                preferences.accessCodes &&
                preferences.accessCodes.length > 0
              ) {
                return (
                  'the provider preferences associated with access codes ' +
                  preferences.accessCodes
                    .map(function(item) {
                      return item.accessCode;
                    })
                    .join(', ')
                );
              } else if (preferences.accessCodeGroup) {
                return (
                  'the provider preferences associated with the group ' +
                  preferences.accessCodeGroup.name
                );
              } else if (preferences.isDefault) {
                return 'the default provider preferences for the organization';
              }
            },
            item: function() {
              return preferences;
            }
          }
        })
        .then(function(confirmed) {
          // DELETE the on-boarding preferences
          $scope.organization
            .one('preferences', confirmed.id)
            .remove()
            .then(
              function() {
                alertService.addAlert({
                  type: 'success',
                  message: 'Provider preferences removed ',
                  persist: true
                });
                $state.go(
                  'orgs.org.detail',
                  {
                    orgid: $scope.organization.shortName
                  },
                  {
                    reload: true //reload the current view
                  }
                );
              },
              function() {
                alertService.addAlert({
                  type: 'danger',
                  message: 'An error occured while deleting - try again.'
                });
              }
            );
        });
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('referralControllers')
    .controller('ReferralEditCtrl', ReferralEditCtrl);

  ReferralEditCtrl.$inject = [
    'Restangular',
    '$scope',
    '$modal',
    'alertService',
    '$state',
    'organization',
    'referral',
    'notificationSettingsSvc'
  ];

  /* @ngInject */
  function ReferralEditCtrl(
    Restangular,
    $scope,
    $modal,
    alertService,
    $state,
    organization,
    referral,
    notificationSettingsSvc
  ) {
    var vm = this;
    // hard code this for now, we can make this more dynamic and clean some things up when we turn off all Mandrill referrals
    vm.LIVONGO_REFERRAL = 'mys-trns-referral';
    $scope.isEdit = referral !== null;
    vm.titlePattern = /^[\s\w'ñáéíóúüÁÉÍÓÚÑÜ¿¡=_\-\!\?\.\$€]*$/;
    vm.urlPattern = /^https?:\/\/[^\s\/$.?#].[^\s]*$/;
    vm.availableGroups = [];
    vm.availableCodes = [];
    vm.orgAllowedToggle = false;
    vm.allAccessCodeChoices = [];
    var orgAccessCodes = [];

    notificationSettingsSvc.getMandrillTemplates().then(function(t) {
      t.push({ name: vm.LIVONGO_REFERRAL, slug: vm.LIVONGO_REFERRAL });
      vm.templateNames = t;
    });

    // get the organization
    organization.get().then(function(data) {
      vm.organization = data;
      vm.referral = referral || {
        referralTitle: '',
        referralDescription: '',
        accessLevel: '',
        referralCustomFields: [],
        templateName: '',
        firstNameEnabled: true,
        emailEnabled: true,
        referrerNameEnabled: true,
        referrerEmailEnabled: true,
        menuEnabled: true,
        singleTemplate: false,
        useCobrandingImage: true,
        seriesCount: 3,
        firstNameLabel: '',
        emailLabel: '',
        referrerNameLabel: '',
        referrerEmailLabel: '',
        accessCodeLabel: '',
        accessCodeType: '',
        accessCodeHelpToolTip: '',
        accessCodeChoices: []
      };
      //added to keep access level widget from defaulting to null
      if ($scope.isEdit) {
        vm.accessLevel = vm.referral.accessLevel;
        vm.allAccessCodeChoices = vm.referral.accessCodeChoices;
        if (
          vm.referral.accessLevel === 'Organization' ||
          vm.referral.accessLevel === 'ORGANIZATION'
        ) {
          organization.getList('accesscodes').then(function(resp) {
            addCodes(resp.plain(), true);
          });
        }
        if (vm.referral.accessCodeGroup) {
          organization
            .one('groups', vm.referral.accessCodeGroup.shortName)
            .get()
            .then(function(resp) {
              addCodes(resp.plain().accessCodes, true);
            });
        }
      }
      //watch widget for changes if not edit
      $scope.$watch('vm.accessLevel', function() {
        vm.referral.accessLevel = vm.accessLevel;
        if (!$scope.isEdit) {
          if (vm.accessLevel === 'Organization') {
            addCodes(orgAccessCodes, false);
          } else if (
            vm.accessLevel === 'Group' &&
            !vm.referral.accessCodeGroup
          ) {
            vm.allAccessCodeChoices = [
              {
                label: ' ',
                value: 'Select a group',
                positionOrdinal: 0
              }
            ];
          } else {
            //defaults
            vm.referral.accessCodeChoiceEnabled = false;
            vm.allAccessCodeChoices = [];
            vm.referral.accessCodeChoices = [];
            vm.referral.accessCodeLabel = '';
            vm.referral.accessCodeType = '';
            vm.accessCodeHelpToolTip = '';
          }
        }
      });
      // if a group is selected, the access codes will be our choices
      $scope.$watch('vm.referral.accessCodeGroup', function() {
        if (
          !$scope.isEdit &&
          vm.referral.accessCodeGroup &&
          vm.referral.accessCodeGroup.accessCodes
        ) {
          addCodes(vm.referral.accessCodeGroup.accessCodes, false);
        }
      });
    });

    //filter to only show email templates for referrals
    vm.filterTemplates = function(element) {
      return element.name.match(/referral/) ? true : false;
    };

    // get a list of groups we can use with these referral page settings
    organization
      .getList('groups/without/referralpagesettings')
      .then(function(resp) {
        vm.availableGroups = vm.availableGroups.concat(resp.plain());
      });

    // get a list of access codes for this organization
    organization
      .getList('accesscodes/without/referralpagesettings')
      .then(function(resp) {
        vm.availableCodes = resp.plain();
      });

    //determine whether or not this organization already has a referral page settings object associated with it
    organization
      .one('without/referralpagesettings')
      .get()
      .then(function(resp) {
        // API returns a string boolean
        // and msAccessLevel prop org-allowed uses a boolean
        vm.orgAllowedToggle = resp === 'true' || resp === true;
      });
    // get a list of the access codes for this organization
    organization.getList('accesscodes').then(function(resp) {
      orgAccessCodes = resp.plain();
      // if we're loading an organization
      if (
        vm.accessLevel === 'Organization' ||
        vm.accessLevel === 'ORGANIZATION'
      ) {
        addCodes(resp.plain(), true);
      }
    });

    //merge a list of access codes into the list of choices
    function addCodes(codes, merge) {
      if (!merge) {
        vm.allAccessCodeChoices = [];
      }
      codes.forEach(function(code) {
        var matchingChoices = vm.allAccessCodeChoices.filter(function(choice) {
          return choice.value === code.accessCode;
        });
        if (matchingChoices.length === 1) {
          matchingChoices[0].status = code.status;
        } else if (matchingChoices.length === 0) {
          vm.allAccessCodeChoices.push({
            accessCodeId: code.id,
            value: code.accessCode,
            enabled: true,
            status: code.status
          });
        }
      });
      vm.allAccessCodeChoices = vm.allAccessCodeChoices.filter(function(
        choice
      ) {
        var status = choice.status;
        delete choice.status;
        return status === 'ENABLED';
      });
    }
    //Open referral page settings editor
    vm.editField = function editField(referralField) {
      var isEdit = angular.isDefined(referralField);
      if (isEdit) {
        // back up the referralField in case they cancel
        var referralFieldBackup = angular.copy(referralField);
      }
      var modal = $modal.open({
        templateUrl: 'partner/referral/templates/referralfieldedit.html',
        controller: 'ReferralFieldEditCtrl',
        controllerAs: 'vm',
        size: 'lg',
        resolve: {
          referralField: function() {
            return referralField;
          },
          allFields: function() {
            return vm.referral.referralCustomFields;
          },
          signUpViews: function() {
            return organization.getList('signups').then(function(resp) {
              var views = resp.plain();
              if (
                vm.accessLevel &&
                vm.accessLevel.toLowerCase() === 'organization'
              ) {
                return _.filter(views, function(v) {
                  return v.isDefault;
                });
              } else if (
                vm.accessLevel &&
                vm.accessLevel.toLowerCase() === 'group'
              ) {
                return _.filter(views, function(v) {
                  return (
                    v.isDefault ||
                    v.accessCodeGroup === vm.referral.accessCodeGroup.groupName
                  );
                });
              }
              return views;
            });
          }
        }
      });
      modal.result.then(
        function(referralField) {
          if (!isEdit) {
            vm.referral.referralCustomFields.push(referralField);
          }
        },
        function() {
          //cancelled revert the field
          if (isEdit) {
            vm.referral.referralCustomFields[
              vm.referral.referralCustomFields.indexOf(referralField)
            ] = referralFieldBackup;
          }
        }
      );
    };

    //delete referral custom field
    vm.deleteField = function deleteField(index) {
      //check custom field has been loaded in database
      if ('undefined' !== typeof vm.referral.referralCustomFields[index].id) {
        organization
          .one(
            'referralpagesettings/referralcustomfields',
            vm.referral.referralCustomFields[index].id
          )
          .remove()
          .then(
            function() {
              alertService.addAlert({
                type: 'success',
                message: 'Referral Custom Field Removed',
                persist: true
              });
            },
            function() {
              alertService.addAlert({
                type: 'danger',
                message: 'An error occured while deleting - try again.'
              });
            }
          );
        vm.referral.referralCustomFields.splice(index, 1);
      }
      //if it hasn't been saved in the db yet, just remove from angular array
      else {
        vm.referral.referralCustomFields.splice(index, 1);
        alertService.addAlert({
          type: 'success',
          message: 'Referral Custom Field Removed',
          persist: true
        });
      }
    };

    vm.save = function save() {
      //save settings to database. Call /editor/referralpagesettings API
      if (!vm.form.$valid || typeof vm.referral.accessLevel === 'undefined') {
        alertService.addAlert({
          type: 'danger',
          message: 'Please check that all fields are filled out correctly'
        });
        return;
      }

      // SendGrid emails should be transactional only
      if (vm.referral.templateName === vm.LIVONGO_REFERRAL) {
        vm.referral.transactionalOnly = true;
      }

      //check access level
      if (vm.referral.accessLevel === 'Group') {
        if (vm.referral.accessCodeGroup === null) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please select a group'
          });
          return;
        }
      } else if (vm.referral.accessLevel === 'Code') {
        if (vm.referral.accessCodes.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add at least one access code'
          });
          return;
        }
      }
      //Set access code choices if dropdown
      if (vm.referral.accessCodeType === 'DROP_DOWN') {
        vm.allAccessCodeChoices = vm.allAccessCodeChoices.filter(function(
          choice
        ) {
          return choice.label;
        });
        if (vm.allAccessCodeChoices.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message:
              'Please add at least one access code to the dropdown options'
          });
          return;
        }
        vm.referral.accessCodeChoices = vm.allAccessCodeChoices;
      } else {
        //delete all access code choices
        vm.referral.accessCodeChoices = [];
      }
      //set access level to upper case before saving
      vm.referral.accessLevel.toUpperCase();
      organization
        .all('referralpagesettings')
        .post(vm.referral)
        .then(
          function() {
            alertService.addAlert({
              type: 'success',
              message: 'Settings Created',
              persist: true
            });
            $state.go('orgs.org.detail', {
              orgid: organization.shortName
            });
          },
          function(response) {
            if (response.status === 422) {
              alertService.addAlert({
                type: 'danger',
                message: response.data.message
              });
            } else {
              alertService.addAlert({
                type: 'danger',
                message: 'Failed to update referral settings.'
              });
            }
          }
        );
    };
  }
})();

'use strict';

angular.module('referralControllers').controller('ReferralFieldEditCtrl', [
  '$modalInstance',
  '$scope',
  'referralField',
  'signUpViews',
  function($modalInstance, $scope, referralField, signUpViews) {
    var vm = this;
    vm.signUpFields = [];
    vm.isEdit = angular.isDefined(referralField);
    //names can only contain alphanumerics, underscores and spaces
    vm.namePattern = /^[a-zA-Z0-9_ ]*$/;
    vm.referralField = referralField || {
      fieldName: '',
      fieldLabel: '',
      validationRule: '',
      choices: [],
      enabled: true,
      required: false,
      signUpFieldCollectionId: null,
      signUpFieldName: null
    };

    _.each(signUpViews, function(v) {
      _.each(v.signUpFieldViews, function(f) {
        var accessLevel = v.isDefault
          ? 'ORG'
          : v.accessCodeGroup
          ? 'GROUP'
          : 'ACCESS_CODE';
        f.label = accessLevel + '_' + f.collectionId + '_' + f.name;
        if (
          vm.isEdit &&
          f.name === vm.referralField.signUpFieldName &&
          f.collectionId === vm.referralField.signUpFieldCollectionId
        ) {
          vm.selectedSignUpField = f;
        }
        vm.signUpFields.push(f);
      });
    });

    //ensure disabled fields aren't required
    $scope.$watch('vm.referralField.enabled', function() {
      if (vm.referralField.enabled === false) {
        vm.referralField.required = false;
      }
    });

    //Change the positon of dropdown list options
    vm.move = function(option, dir) {
      for (var i = 0; i < vm.referralField.choices.length; i++) {
        if (
          vm.referralField.choices[i].positionOrdinal ===
          option.positionOrdinal + dir
        ) {
          vm.referralField.choices[i].positionOrdinal = option.positionOrdinal;
          option.positionOrdinal += dir;
          break;
        }
      }
    };

    // Add an entry to the dropdown list
    vm.addDropdownOption = function() {
      if (vm.form.dropdownLabel.$valid && vm.form.dropdownValue.$valid) {
        vm.referralField.choices.push(
          angular.extend(vm.newDropdown, {
            positionOrdinal: vm.referralField.choices.length
          })
        );
        vm.newDropdown = {
          enabled: true
        };
        vm.form.$setPristine();
      } else {
        vm.form.dropdownLabel.$setDirty();
        vm.form.dropdownValue.$setDirty();
      }
    };

    //check to see if the field has been filled out correctly
    vm.isValid = function() {
      return (
        vm.form.fieldname.$valid &&
        vm.form.fieldlabel.$valid &&
        angular.isDefined(vm.referralField.enabled) &&
        angular.isDefined(vm.referralField.inputType) &&
        (vm.referralField.inputType !== 'DROP_DOWN' ||
          vm.referralField.choices.length)
      );
    };

    //Save the field
    vm.save = function() {
      if (vm.isValid()) {
        //assign the sign-up field mapping if possible
        if (vm.selectedSignUpField) {
          vm.referralField.signUpFieldCollectionId =
            vm.selectedSignUpField.collectionId;
          vm.referralField.signUpFieldName = vm.selectedSignUpField.name;
        }
        $modalInstance.close(vm.referralField);
      }
    };

    //cancel field edit
    vm.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
  }
]);

'use strict';

angular.module('referralControllers').controller('ReferralListCtrl', [
  '$scope',
  '$state',
  '$stateParams',
  'alertService',
  'confirmService',
  function($scope, $state, $stateParams, alertService, confirmService) {
    $scope.organization
      .all('referralpagesettings')
      .getList()
      .then(function(resp) {
        $scope.referralPageSettings = resp;
      });

    $scope.deleteReferralSettings = function deleteReferralSettings(
      referralPageSetting
    ) {
      confirmService
        .show({
          resolve: {
            confirmText: function() {
              if (referralPageSetting.accessLevel === 'ORGANIZATION') {
                return 'the default referral setting for the organization';
              } else if (
                referralPageSetting.accessCodes &&
                referralPageSetting.accessCodes.length > 0
              ) {
                return (
                  'the referral setting associated with access codes ' +
                  referralPageSetting.accessCodes
                    .map(function(item) {
                      return item.accessCode;
                    })
                    .join(', ')
                );
              } else if (referralPageSetting.accessCodeGroup) {
                return (
                  'the referral setting associated with the group ' +
                  referralPageSetting.accessCodeGroup.name
                );
              }
              return 'the referral settings with Id ' + referralPageSetting.id;
            },
            item: function() {
              return referralPageSetting;
            }
          }
        })
        .then(function(confirmed) {
          $scope.organization
            .one('referralpagesettings', confirmed.id)
            .remove()
            .then(
              function() {
                alertService.addAlert({
                  type: 'success',
                  message: 'Referral Page Settings Removed',
                  persist: true
                });
                $state.go(
                  'orgs.org.detail',
                  {
                    orgid: $scope.organization.shortName
                  },
                  {
                    reload: true //reload the current view
                  }
                );
              },
              function() {
                alertService.addAlert({
                  type: 'danger',
                  message: 'An error occured while deleting - try again.'
                });
              }
            );
        });
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:FieldEditCtrl
 * @description
 * Edit field
 */

angular.module('signupControllers').controller('FieldEditCtrl', [
  '$scope',
  '$modalInstance',
  'field',
  'allFields',
  function($scope, $modalInstance, field, allFields) {
    $scope.isEdit = angular.isDefined(field);
    // names can only contain alphanumerics, underscores and spaces
    $scope.namePattern = /^[a-zA-Z0-9_ ]*$/;
    $scope.field = field || {
      choices: []
    };
    $scope.allFields = allFields;
    // holds the value of a potential new dropdown
    $scope.newDropdown = {
      enabled: true
    };
    // if the field is disabled, it shouldnt be required
    $scope.$watch('field.enabled', function() {
      if (!$scope.field.enabled) {
        $scope.field.required = false;
      }
    });
    // Move a dropdown option up or down
    $scope.move = function(option, dir) {
      for (var i = 0; i < $scope.field.choices.length; i++) {
        if (
          $scope.field.choices[i].positionOrdinal ===
          option.positionOrdinal + dir
        ) {
          $scope.field.choices[i].positionOrdinal = option.positionOrdinal;
          option.positionOrdinal += dir;
          break;
        }
      }
    };
    // Add an entry to the dropdown list
    $scope.addDropdownOption = function() {
      if (
        $scope.form.dropdownLabel.$valid &&
        $scope.form.dropdownValue.$valid
      ) {
        $scope.field.choices.push(
          angular.extend($scope.newDropdown, {
            positionOrdinal: $scope.field.choices.length
          })
        );
        $scope.newDropdown = {
          enabled: true
        };
        $scope.form.$setPristine();
      } else {
        $scope.form.dropdownLabel.$setDirty();
        $scope.form.dropdownValue.$setDirty();
      }
    };
    // Check if they should have a validation failed message
    $scope.shouldValidate = function() {
      // If it's a text field, we only have validation text if there's a validation rule;
      return (
        ($scope.field.type === 'TEXT' && $scope.field.validationRule) ||
        // If it's a checkbox, we only have validation text if the checkbox is required
        ($scope.field.type === 'CHECK_BOX' && $scope.field.required) ||
        // If it's a dropdown, we only have validation text if the dropdown is required
        ($scope.field.type === 'DROP_DOWN' && $scope.field.required)
      );
    };
    // Close the modal and return the field
    $scope.save = function() {
      if ($scope.isValid()) {
        $modalInstance.close($scope.field);
      }
    };
    // Dismiss the modal
    $scope.cancel = function() {
      $modalInstance.dismiss('cancel');
    };
    // Check to see if the field has been filled out sucessfully
    $scope.isValid = function() {
      // Check fieldname and label are correct, and that all radios have a selection
      // If dropdown, make sure there's at least one option
      return (
        $scope.form.fieldname.$valid &&
        $scope.form.label.$valid &&
        $scope.form.validationfailedtext.$valid &&
        angular.isDefined($scope.field.enabled) &&
        angular.isDefined($scope.field.required) &&
        angular.isDefined($scope.field.type) &&
        ($scope.field.type !== 'DROP_DOWN' || $scope.field.choices.length)
      );
    };
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SignupEditCtrl
 * @description
 * Edit a signup flow
 */

angular.module('signupControllers').controller('SignupEditCtrl', [
  '$scope',
  '$modal',
  '$state',
  'alertService',
  'organization',
  'signup',
  function($scope, $modal, $state, alertService, organization, signup) {
    const INVALID_FIELD_NAMES = [
      'last_name',
      'last name',
      'date_of_birth',
      'date of birth',
      'dob'
    ];

    // if signup is passed as null, we're creating a new signup
    $scope.isEdit = signup !== null;
    // main signup object
    $scope.signup = $scope.isEdit
      ? signup.plain()
      : {
          isDefault: false,
          accessCodeGroup: null,
          accessCodes: [],
          signUpFieldViews: []
        };
    //the groups that the signup could be assigned to
    $scope.availableGroups = [];
    // the codes that the signup could be assigned to
    $scope.availableCodes = [];
    // all of the access codes for the organization
    var orgAccessCodes = [];
    // all the groups for this org
    $scope.orgGroups = [];
    // This organization
    $scope.organization = organization;
    // if access level = Org, possibly show groupDropdown
    $scope.groupDropdownStatus = 'disabled';
    // Change the order of the list
    $scope.move = move;
    // Save this signup
    $scope.save = save;

    // Access code field, this has some defaults as it is special
    var acDefault = {
      // defaults
      name: 'ACCESS_CODE',
      containsSensitiveData: false,
      required: false,
      enabled: true,
      positionOrdinal: 0,
      //editable values
      label: '',
      type: '',
      helpTooltip: '',
      choices: []
    };
    // start the acField off as a copy of the default
    $scope.acField = angular.copy(acDefault);

    $scope.groupField = {
      name: 'ACCESS_CODE_GROUP',
      required: false,
      enabled: false,
      positionOrdinal: 0,
      label: '',
      type: 'DROP_DOWN',
      helpTooltip: '',
      choices: []
    };
    // get the organization
    $scope.organization.get().then(function(resp) {
      $scope.organization = resp;
    });
    // get a list of groups we can use with this signup
    $scope.organization.getList('groups/without/signups').then(function(resp) {
      $scope.availableGroups = $scope.availableGroups.concat(resp.plain());
    });
    // get a list of access codes that can be used
    $scope.organization
      .getList('accesscodes/without/signups')
      .then(function(resp) {
        $scope.availableCodes = resp.plain();
      });
    // get a list of the access codes for this organization
    $scope.organization.getList('accesscodes').then(function(resp) {
      orgAccessCodes = resp.plain();
      // if we're loading an organization
      if ($scope.accessLevel === 'Organization') {
        addCodes(resp.plain(), true);
      }
    });
    // get a list of groups for this org
    $scope.organization.getList('groups').then(function(resp) {
      $scope.orgGroups = resp.plain();
      if ($scope.accessLevel === 'Organization') {
        addGroups(resp.plain(), true);
      }
    });

    // if the access level is set to organization, the access code choices are all the access codes for the org
    $scope.$watch('accessLevel', function() {
      if (!$scope.isEdit) {
        if ($scope.accessLevel === 'Organization') {
          addCodes(orgAccessCodes, false);
          addGroups($scope.orgGroups, false);
        } else if (
          $scope.accessLevel === 'Group' &&
          !$scope.signup.accessCodeGroup
        ) {
          // if group they'll need to select a group before the dropdown shows up
          $scope.acField.choices = [
            {
              label: ' ',
              value: 'Select a group',
              positionOrdinal: 0
            }
          ];
        } else {
          $scope.acField = angular.copy(acDefault);
        }
      }
    });
    // if a group is selected, the access codes will be our choices
    $scope.$watch('signup.accessCodeGroup', function() {
      if (
        !$scope.isEdit &&
        $scope.signup.accessCodeGroup &&
        $scope.signup.accessCodeGroup.accessCodes
      ) {
        addCodes($scope.signup.accessCodeGroup.accessCodes, false);
      }
    });
    // if editing we do some more parsing
    if ($scope.isEdit) {
      if ($scope.signup.isDefault) {
        $scope.accessLevel = 'Organization';
      } else if ($scope.signup.accessCodeGroup) {
        $scope.accessLevel = 'Group';
        // fetch group access codes for list
        $scope.organization
          .one('groups', $scope.signup.accessCodeGroup.shortName)
          .get()
          .then(function(resp) {
            addCodes(resp.plain().accessCodes, true);
          });
      } else {
        $scope.accessLevel = 'Code';
      }
      $scope.availableGroups.push($scope.signup.accessCodeGroup);
      if ($scope.signup.signUpFieldViews) {
        setSpecialFields();
      }
    }

    function setSpecialFields() {
      var acIndex = $scope.signup.signUpFieldViews
        .map(s => s.name)
        .indexOf('ACCESS_CODE');
      if (acIndex >= 0) {
        $scope.acField = $scope.signup.signUpFieldViews.splice(acIndex, 1)[0];
        $scope.acField.choices.forEach(function(choice) {
          if (!choice.enabled) {
            choice.label = '';
          }
          choice.enabled = true;
        });
      }
      var groupIndex = $scope.signup.signUpFieldViews
        .map(s => s.name)
        .indexOf('ACCESS_CODE_GROUP');
      if (groupIndex >= 0) {
        $scope.groupField = $scope.signup.signUpFieldViews.splice(
          groupIndex,
          1
        )[0];
        $scope.groupField.choices.forEach(function(choice) {
          if (!choice.enabled) {
            choice.label = '';
          }
          choice.enabled = true;
        });
      }
    }

    // merge a list of access codes into the list of choices
    function addCodes(codes, merge) {
      if (!merge) {
        $scope.acField.choices = [];
      }
      codes.forEach(function(code) {
        var matchingChoices = $scope.acField.choices.filter(function(choice) {
          return choice.value === code.accessCode;
        });
        if (matchingChoices.length === 1) {
          // a choice is already filled out so just set the status
          matchingChoices[0].status = code.status;
        } else if (matchingChoices.length === 0) {
          // this choice doens't currently exist and needs to be available to the user
          $scope.acField.choices.push({
            value: code.accessCode,
            enabled: true,
            status: code.status
          });
        }
      });
      // filter out any codes that aren't ENABLED
      $scope.acField.choices = $scope.acField.choices.filter(function(choice) {
        var status = choice.status;
        delete choice.status;
        return status === 'ENABLED';
      });
    }

    function addGroups(groups, merge) {
      if (!merge) {
        $scope.groupField.choices = [];
      }

      var i = 0;
      groups.forEach(function(group) {
        var matchingChoices = $scope.groupField.choices.filter(function(
          choice
        ) {
          return choice.value === group.shortName;
        });
        if (matchingChoices.length === 1) {
          matchingChoices[0].status = group.status;
        } else if (matchingChoices.length === 0) {
          // this choice doesn't currently exist and needs to be available to the user
          $scope.groupField.choices.push({
            value: group.shortName,
            enabled: true,
            positionOrdinal: i
          });
        }
        i++;
      });
    }

    // Open the add field popover
    $scope.editField = function(field) {
      var isEdit = angular.isDefined(field);
      if (isEdit) {
        // back up the field in case they cancel
        var fieldBackup = angular.copy(field);
      }
      var modal = $modal.open({
        templateUrl: 'partner/signup/templates/fieldedit.html',
        controller: 'FieldEditCtrl',
        size: 'lg',
        resolve: {
          field: function() {
            return field;
          },
          allFields: function() {
            return $scope.signup.signUpFieldViews;
          }
        }
      });
      modal.result.then(
        function(field) {
          if (!isEdit) {
            $scope.signup.signUpFieldViews.push(field);
          }
        },
        function() {
          // cancelled, revert the field
          if (isEdit) {
            $scope.signup.signUpFieldViews[
              $scope.signup.signUpFieldViews.indexOf(field)
            ] = fieldBackup;
          }
        }
      );
    };
    $scope.deleteField = function(index) {
      $scope.signup.signUpFieldViews.splice(index, 1);
    };
    // move a dropdown option up or down
    function move(option, dir) {
      for (var i = 0; i < $scope.acField.choices.length; i++) {
        if (
          $scope.acField.choices[i].positionOrdinal ===
          option.positionOrdinal + dir
        ) {
          $scope.acField.choices[i].positionOrdinal = option.positionOrdinal;
          option.positionOrdinal += dir;
          break;
        }
      }
    }

    // move a custom field up or down
    $scope.moveField = function(field, dir) {
      for (var i = 0; i < $scope.signup.signUpFieldViews.length; i++) {
        if (
          $scope.signup.signUpFieldViews[i].positionOrdinal ===
          field.positionOrdinal + dir
        ) {
          $scope.signup.signUpFieldViews[i].positionOrdinal =
            field.positionOrdinal;
          field.positionOrdinal += dir;
          break;
        }
      }
    };
    // validate this signup and save it
    function save() {
      // clone object so we can modify it
      var signup = JSON.parse(JSON.stringify($scope.signup));
      var acField = JSON.parse(JSON.stringify($scope.acField));
      var groupField = JSON.parse(JSON.stringify($scope.groupField));
      // check if the form is valid - right now that's only the "Access code input type"
      if (!$scope.form.$valid && $scope.accessLevel !== 'Code') {
        alertService.addAlert({
          type: 'danger',
          message: 'Please check that all fields are filled out correctly'
        });
        return;
      }
      // set things depending on the access level
      if ($scope.accessLevel === 'Organization') {
        signup.isDefault = true;
      } else if ($scope.accessLevel === 'Group') {
        if (signup.accessCodeGroup === null) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please select a group'
          });
          return;
        }
      } else if ($scope.accessLevel === 'Code') {
        if (signup.accessCodes.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add at least one access code'
          });
          return;
        }
        if (signup.signUpFieldViews.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message: 'Please add at least one field'
          });
          return;
        }

        let invalidFieldNames = signup.signUpFieldViews
          .filter(
            field =>
              INVALID_FIELD_NAMES.includes(field.name.toLowerCase()) &&
              field.enabled
          )
          .map(field => field.name);
        if (invalidFieldNames.length) {
          alertService.addAlert({
            type: 'danger',
            message: 'Invalid field name: ' + invalidFieldNames.join(', ')
          });
          return;
        }
      } else {
        alertService.addAlert({
          type: 'danger',
          message: 'Please select an access level'
        });
        return;
      }

      if (groupField.enabled) {
        if (_.isEmpty(groupField.label)) {
          alertService.addAlert({
            type: 'danger',
            message: 'If group dropdown is selected, group label is required.'
          });
          return;
        }
        if (_.isEmpty(groupField.helpTooltip)) {
          alertService.addAlert({
            type: 'danger',
            message:
              'If group dropdown is selected, Group Help ToolTip is required.'
          });
          return;
        }
        if (acField.type !== 'DROP_DOWN') {
          alertService.addAlert({
            type: 'danger',
            message:
              'If group dropdown is selected, accessCode must also be a dropdown.'
          });
          return;
        }
        // remove anything that doesn't have a label
        groupField.choices = groupField.choices.filter(function(choice) {
          return choice.label;
        });
        if (groupField.choices.length === 0) {
          alertService.addAlert({
            type: 'danger',
            message:
              'If group dropdown is selected, at least one group must be selected.'
          });
          return;
        }
      }

      // If it's text erase the choices
      if (acField.type === 'TEXT') {
        acField.choices = [];
      } else {
        // remove anything they haven't set a label for
        acField.choices = acField.choices.filter(function(choice) {
          return choice.label;
        });
      }

      // enforce correct position
      if (signup && groupField.enabled) {
        groupField.positionOrdinal = 0;
      }
      // make sure acField is AFTER groupField, or at least before custom fields
      acField.positionOrdinal = 1;

      // custom fields are always after group and ac
      if (signup && signup.signUpFieldViews) {
        var position = 2;
        _.forEach(signup.signUpFieldViews, function(field) {
          field.positionOrdinal = position;
          position++;
        });
      }

      // Set default AC label if they haven't set one
      acField.label = acField.label || 'Access Code';
      if ($scope.accessLevel === 'Organization' && groupField.enabled) {
        signup.signUpFieldViews.push(groupField);
      }
      if ($scope.accessLevel !== 'Code') {
        signup.signUpFieldViews.push(acField);
      }

      $scope.form.processing = true;
      $scope.organization
        .all('signups')
        .post(signup)
        .then(
          function() {
            alertService.addAlert({
              type: 'success',
              message: 'Successfully saved signup flow',
              persist: true
            });
            $state.go('orgs.org.detail', {
              orgid: $scope.organization.shortName
            });
          },
          function(err) {
            $scope.form.processing = false;
            let msg =
              !err || !err.data || !err.data.message
                ? 'Unknown'
                : err.data.message;
            alertService.addAlert({
              type: 'danger',
              message: 'An error occured while saving: ' + msg,
              error: err
            });
          }
        );
    }
  }
]);

'use strict';
/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SignupListCtrl
 * @description
 * This lists the custom signup flows for a given organization
 */

angular.module('signupControllers').controller('SignupListCtrl', [
  '$scope',
  function($scope) {
    $scope.signups = [
      {
        loading: true
      }
    ];
    $scope.listLimit = 10;
    $scope.organization
      .all('signups')
      .getList()
      .then(function(resp) {
        $scope.signups = resp.plain();
        if ($scope.signups.length === $scope.listLimit + 1) {
          $scope.listLimit++;
        }
      });
  }
]);

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:uploadDropdownOptions
 * @description This allows the user to download dropdown options.
 */
angular
  .module('myStrengthAdminApp')
  .directive('msDownloadDropdownOptions', function() {
    return {
      scope: {
        msDownloadDropdownOptions: '='
      },
      restrict: 'A',
      link: function(scope, element) {
        scope.$watch(
          'msDownloadDropdownOptions',
          function() {
            var csv = 'Label,Value,Enabled\n';
            scope.msDownloadDropdownOptions.forEach(function(choice) {
              csv +=
                '"' +
                choice.label +
                '","' +
                choice.value +
                '",' +
                choice.enabled +
                '\n';
            });
            // set the href to be a data href with the csv data
            element.attr(
              'href',
              'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
            );
          },
          true
        );
      }
    };
  });

'use strict';

/**
 * @ngdoc directive
 * @name myStrengthAdminApp.directive:uploadDropdownOptions
 * @description This applies to a file upload input and takes a CSV that contains a list of values.
 * Those values are applied to the array given by msUploadDropdownOptions.
 *
 * Expects a CSV of the form:
 * Label,Value,Enabled
 */
angular.module('myStrengthAdminApp').directive('msUploadDropdownOptions', [
  '$window',
  function($window) {
    return {
      scope: {
        msUploadDropdownOptions: '='
      },
      restrict: 'A',
      link: function(scope, element) {
        element.bind('change', function(e) {
          var reader = new FileReader();
          reader.onload = function(load) {
            var lines = load.target.result.replace(/[\n\r]/g, '\n').split('\n');
            // if the first line isn't a CSV with 3 values
            if (
              lines[0].split(/,+(?=(?:(?:[^"]*"){2})*[^"]*$)/g).length !== 3
            ) {
              $window.alert(
                'This file does not appear to be properly formatted'
              );
              return;
            }
            lines.forEach(function(item) {
              // split on commas outside of quotes
              var option = item.split(/,+(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
              // must have label, value and enabled/disabled
              if (option.length === 3) {
                // replace any quotes
                var label = option[0].replace(/"/g, '');
                var value = option[1].replace(/"/g, '');
                var enabled = option[2];
                // enabled must either be true or false, otherwise invalid
                if (
                  enabled.toLowerCase() === 'true' ||
                  enabled.toLowerCase() === 'false'
                ) {
                  var existing = scope.msUploadDropdownOptions.filter(function(
                    existingOption
                  ) {
                    return existingOption.value === value;
                  });
                  // if there is no existing dropdown with this value, create it
                  if (existing.length === 0) {
                    scope.msUploadDropdownOptions.push({
                      label: label,
                      value: value,
                      enabled: enabled.toLowerCase() === 'true',
                      positionOrdinal: scope.msUploadDropdownOptions.length
                    });
                  } else {
                    // update existing value
                    existing[0].label = label;
                    existing[0].enabled = enabled.toLowerCase() === 'true';
                  }
                }
              }
            });
            scope.$apply();
          };
          if (e.target.files[0].name.match(/\.(csv)$/)) {
            // read the value of the first file selected
            reader.readAsText(e.target.files[0]);
            // reset the file input for reuse
            element.val(null);
          } else {
            // alert if they uploaded something invalid (should never happen)
            window.alert('File must be one of these types: csv');
          }
        });
      }
    };
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SsoEditCtrl
 * @description
 * # SsoEditCtrl
 * This edits an SSO identity provider record
 */
angular.module('ssoControllers').controller('SsoEditCtrl', [
  '$scope',
  '$state',
  '$filter',
  'confirmService',
  'alertService',
  'ssoSvc',
  'identityprovider',
  function(
    $scope,
    $state,
    $filter,
    confirmService,
    alertService,
    ssoSvc,
    identityprovider
  ) {
    $scope.isEdit = !identityprovider.isNew;
    $scope.identityprovider = identityprovider;
    ssoSvc.getTypes().then(function(types) {
      $scope.idpTypes = types;
    });

    $scope.save = function(form) {
      if (!form.$valid) {
        alertService.addAlert({
          type: 'danger',
          message: 'Please confirm that all fields are filled out.'
        });
        return;
      }
      delete $scope.identityprovider.isNew;
      ssoSvc.save($scope.identityprovider).then(function(resp) {
        if (resp.succeeded) {
          alertService.addAlert({
            type: 'success',
            message:
              'Identity Provider Settings ' +
              ($scope.isNew ? 'created' : 'updated'),
            persist: true
          });
        } else {
          alertService.addAlert({
            type: 'danger',
            message: 'An error occurred submitting the changes'
          });
        }
      });
    };
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SsoListCtrl
 * @description
 * # SsoListCtrl
 * This lists the created SSO identity providers for a given organization
 * Also has an add mode for associating existing IDP's to orgs.
 */
angular.module('ssoControllers').controller('SsoListCtrl', [
  '$scope',
  '$state',
  '$filter',
  'confirmService',
  'alertService',
  'ssoSvc',
  'org',
  function($scope, $state, $filter, confirmService, alertService, ssoSvc, org) {
    $scope.org = org;
    ssoSvc.getAll().then(function(resp) {
      $scope.identityproviders = resp;
    });
    $scope.addIdentityProvider = function(org, idpid) {
      ssoSvc.add(org, idpid).then(function(resp) {
        if (resp.succeeded) {
          $state.go('orgs.org.detail', {
            orgid: org.orgShortName
          });
        }
      });
    };
  }
]);

'use strict';
/**
 * Handles the server communication around SSO IDP creation.
 */
angular.module('ssoControllers').service('ssoSvc', [
  'Restangular',
  function(Restangular) {
    /**
     * Get a list of all the identity providers
     * @return {array} All the idps
     */
    this.getAll = function() {
      return Restangular.all('/editor/identityproviders').getList();
    };

    this.getIdp = function(id) {
      return Restangular.one('/editor/identityproviders/detail/' + id)
        .get()
        .then(function(idp) {
          return idp;
        });
    };

    this.getTypes = function() {
      return Restangular.one('/editor/identityproviders/types')
        .get()
        .then(function(types) {
          return types;
        });
    };

    this.save = function(identityProvider) {
      return Restangular.one('editor/identityproviders')
        .customPOST(identityProvider)
        .then(function(resp) {
          return resp;
        });
    };

    this.add = function(org, idpid) {
      let idp = {
        ssoIdentityProviderId: idpid,
        disabled: false,
        disableAccountMerge: false
      };
      return Restangular.one('editor/organizations', org.orgShortName).post(
        'identityproviders',
        idp
      );
    };
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SsoEditCtrl
 * @description
 * # SsoEditCtrl
 * This edits an SSO identity provider record
 */
angular.module('telehealthControllers').controller('telehealthEditCtrl', [
  '$scope',
  '$state',
  'alertService',
  'telehealthSvc',
  '$stateParams',
  function($scope, $state, alertService, telehealthSvc, $stateParams) {
    // Check if edit.
    $scope.isEdit = $stateParams.provId !== '';
    $scope.providerInfo = {};

    // Load in Information
    if ($scope.isEdit) {
      // if passed in from the Telehealth list
      $scope.providerInfo = $stateParams.providerInfo
        ? $stateParams.providerInfo
        : // if page is reloaded
          telehealthSvc.getAllProviders().then(function(resp) {
            $scope.providerInfo = resp.find(function(element) {
              return element.id === parseInt($stateParams.provId);
            });
          });
    }

    $scope.save = function(form) {
      if (!form.$valid) {
        alertService.addAlert({
          type: 'danger',
          message: 'Please confirm that all fields are filled out.'
        });
        return;
      }
      telehealthSvc.upsertProvider($scope.providerInfo).then(function(resp) {
        if (resp.succeeded) {
          alertService.addAlert({
            type: 'success',
            message:
              'Telehealth Provider ' + ($scope.isEdit ? 'updated' : 'created'),
            persist: true
          });
          $state.go('telehealth-providers.list');
        } else {
          alertService.addAlert({
            type: 'danger',
            message: 'An error occurred submitting the changes'
          });
        }
      });
    };
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:telehealthListCtrl
 * @description
 * # telehealthListCtrl
 * This lists the created telehealth providers globally in the system
 */
angular.module('telehealthControllers').controller('telehealthListCtrl', [
  '$scope',
  'telehealthSvc',
  '$stateParams',
  function($scope, telehealthSvc, $stateParams) {
    $scope.org = $stateParams.orgid ? $stateParams.orgid : null;
    getProviders();

    $scope.removeTelehealthProvider = function(providerID) {
      telehealthSvc.deleteProvider(providerID).then(() => {
        getProviders();
      });
    };

    function getProviders() {
      telehealthSvc.getAllProviders().then(function(resp) {
        $scope.telehealthproviders = resp;
      });
    }
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SsoListCtrl
 * @description
 * # SsoListCtrl
 * This lists the created SSO identity providers for a given organization
 * Also has an add mode for associating existing IDP's to orgs.
 */
angular.module('telehealthControllers').controller('telehealthOrgEditCtrl', [
  '$scope',
  '$state',
  'alertService',
  'telehealthSvc',
  '$stateParams',
  'orgSvc',
  function($scope, $state, alertService, telehealthSvc, $stateParams, orgSvc) {
    // Incoming state param values
    $scope.isEdit = $stateParams.collId !== '';
    $scope.orgShortName = $stateParams.orgShortName;

    // Setting scope Variables
    $scope.accessLevel = 'Organization';
    $scope.accessCodes = [];
    $scope.accessCodeGroup = [];
    $scope.availableCodes = [];
    $scope.availableGroups = [];

    // Set info if passed in or refreshed
    $scope.organization = {};
    $scope.organization = $stateParams.organization
      ? $stateParams.organization
      : // if page is reloaded
        orgSvc.getAll().then(function(resp) {
          $scope.organization = resp.find(function(element) {
            return element.shortName === $stateParams.orgShortName;
          });
        });

    $scope.partner = {};
    if ($scope.isEdit) {
      $scope.partner = $stateParams.partner
        ? $stateParams.partner
        : // if page is reloaded
          telehealthSvc
            .getOrgProviders($scope.orgShortName)
            .then(function(resp) {
              $scope.partner = resp.find(function(element) {
                return (
                  element.partnerCollateralId === parseInt($stateParams.collId)
                );
              });
              setAccessLevel($scope.partner.accessLevel);
            });
    }

    // Get all providers
    telehealthSvc.getAllProviders().then(function(resp) {
      $scope.telehealthproviders = resp;
    });

    // Initializing Level Access information if new or edit
    if (!$scope.isEdit) {
      initAccessLevel();
    } else {
      // load up all the content
      // set the access level
      setAccessLevel($scope.partner.accessLevel);
    }

    function setAccessLevel(accessLevel) {
      if (accessLevel === 'ORGANIZATION') {
        $scope.accessLevel = 'Organization';
      } else if (accessLevel === 'GROUP') {
        $scope.accessLevel = 'Group';
      } else {
        $scope.accessLevel = 'Code';
      }
    }

    function initAccessLevel() {
      // Get a list of access codes that can be used with these settings
      orgSvc.getAccessCodes($scope.orgShortName).then(function(resp) {
        $scope.availableCodes = resp;
      });

      // Get a list of groups we can use with these settings
      orgSvc.getGroups($scope.orgShortName, true).then(function(resp) {
        $scope.availableGroups = resp;
      });
    }

    function buildPartnerForEdit() {
      return {
        partnerCollateralId: $scope.partner.partnerCollateralId,
        telehealthProviderId: $scope.partner.telehealthProviderId,
        partnerCollateralLinkUrl: $scope.partner.partnerCollateralLinkUrl,
        partnerDeleted: $scope.partner.partnerDeleted
      };
    }

    $scope.save = function(form) {
      if (!form.$valid) {
        alertService.addAlert({
          type: 'danger',
          message: 'Please confirm that all fields are filled out.'
        });
        return;
      }

      // Build object
      if ($scope.isEdit) {
        // If is edit, condense object and send
        var partnerEdit = buildPartnerForEdit();
        upsertTelehealthPartnerSettings($scope.orgShortName, partnerEdit);
      } else {
        // If new, selecting the right collateral ID
        if ($scope.accessLevel === 'Organization') {
          var partnerOrgNew = Object.assign({}, $scope.partner);
          partnerOrgNew.partnerCollateralId =
            $scope.organization.partnerCollateralId;
          upsertTelehealthPartnerSettings($scope.orgShortName, partnerOrgNew);
        } else if ($scope.accessLevel === 'Group') {
          var partnerGroupNew = Object.assign({}, $scope.partner);
          partnerGroupNew.partnerCollateralId =
            $scope.accessCodeGroup.partnerCollateralId;
          upsertTelehealthPartnerSettings($scope.orgShortName, partnerGroupNew);
        } else if ($scope.accessLevel === 'Code') {
          $scope.accessCodes.map(item => {
            var partnerAccessNew = Object.assign({}, $scope.partner);
            partnerAccessNew.partnerCollateralId = item.partnerCollateralId;
            upsertTelehealthPartnerSettings(
              $scope.orgShortName,
              partnerAccessNew
            );
          });
        }
      }
    };

    function upsertTelehealthPartnerSettings(org, partner) {
      telehealthSvc.upsertPartnerSettings(org, partner).then(function(resp) {
        if (resp.succeeded) {
          alertService.addAlert({
            type: 'success',
            message:
              'Telehealth Partner ' + ($scope.isEdit ? 'updated' : 'added'),
            persist: true
          });
          $state.go('orgs.org.detail', { orgid: $scope.orgShortName });
        } else {
          alertService.addAlert({
            type: 'danger',
            message: 'An error occurred submitting the changes'
          });
        }
      });
    }
  }
]);

'use strict';

/**
 * @ngdoc function
 * @name myStrengthAdminApp.controller:SsoListCtrl
 * @description
 * # SsoListCtrl
 * This lists the created SSO identity providers for a given organization
 * Also has an add mode for associating existing IDP's to orgs.
 */
angular.module('telehealthControllers').controller('telehealthOrgListCtrl', [
  '$scope',
  'alertService',
  'telehealthSvc',
  '$stateParams',
  function($scope, alertService, telehealthSvc) {
    getOrgPartners();

    $scope.removeTelehealthPartner = function(org, partnerID) {
      telehealthSvc.deletePartner(org, partnerID).then(() => {
        getOrgPartners();
      });
    };

    function getOrgPartners() {
      telehealthSvc
        .getOrgProviders($scope.organization.shortName)
        .then(function(resp) {
          $scope.telehealthproviders = resp;
        });
    }
  }
]);

'use strict';
/**
 * Handles the server communication around Telehealth Provider information.
 */

angular.module('telehealthControllers').service('telehealthSvc', [
  'httpSvc',
  function(httpSvc) {
    /**
     * Get a list of all the identity providers
     * @return {array} All the idps
     */
    this.getAllProviders = function() {
      return httpSvc.get('API/editor/telehealth/providers');
    };

    this.deleteProvider = function(providerID) {
      return httpSvc.delete('API/editor/telehealth/provider/' + providerID);
    };

    this.upsertProvider = function(providerInfo) {
      return httpSvc.post('API/editor/telehealth/provider', providerInfo);
    };

    this.getOrgProviders = function(org) {
      return httpSvc.get('API/editor/telehealth/' + org + '/providers');
    };

    this.upsertPartnerSettings = function(org, partner) {
      return httpSvc.post(
        'API/editor/telehealth/' + org + '/provider',
        partner
      );
    };

    this.deletePartner = function(org, partnerID) {
      return httpSvc.delete(
        'API/editor/telehealth/' + org + '/provider/' + partnerID
      );
    };
  }
]);
